import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ResponsiveModal from "./modal";
import { saveCurrentDate, getInviteUser, getEventById, userEnteredQuickMode, getEventsByType } from "actions/events";
import {
  getGiveAwayRequest,
  getSwitchRequest,
  getCurrentNotification,
  getNotificationsByDate,
  editNotifications,
} from "actions/notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { upperFirst } from "lodash";
import { Amplitude } from "@amplitude/react-amplitude";
import AddToMenu from "./AddToMenu";
import NotificationsModal from "./../notifications/singleNotification";
// import Switch from "@material-ui/core/Switch";
import Grow from "@material-ui/core/Grow";
import Scrollbar from "react-scrollbars-custom";
import {setErrorMessage} from "../../actions/events";
import queryString from "query-string";

const arrayToObject = array => {
  return array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};

class CalendarDetails extends PureComponent {
  state = {
    open: false,
    type: "",
    notificationPopUpOpen: false,
    notifications: [],
    buttons: true,
    quickMode: false,
    quickModeEvents: [],
    selectedEventId: null,
  };

  onHandleOpen = async (type, id, is_shifted) => {
    if (this.props.role === "blocked_user") {
      this.props.history.push("/upgrade-membership");
    } else {
      await this.setState({
        type
      });
      this.setState({ open: true });
    }
    if ((type === "Shift" || type === "Personal Events") && !is_shifted) {
      this.props.getEventById(id);
    }

    this.props.saveCurrentDate(this.props.date);
  };
  onHandleClose = () => {
    this.setState({
      open: false
    });
  };
  onOpenSingleNotification = async data => {
    const { notificationsByDate } = this.props;
    await this.props.getCurrentNotification(data.id);
    if (data.status !== "read") {
      this.props.editNotifications(data.id, {
        status: "read"
      });
    }
    const myNotifications =
      notificationsByDate &&
      notificationsByDate.map(
        data => data.notifications.map(elem => elem)
        // notificationsMyList.push(data.notifications)
      );
    const notificationsList = myNotifications && myNotifications.flat(Infinity);
    const notificationListId =
      notificationsList && arrayToObject(notificationsList);
    const currentNotification =
      notificationListId && notificationListId[data.id];
    let method = "";
    let request = "";
    if (currentNotification.text === "Invitation") {
      method = "getInviteUser";
      request = currentNotification.share_calendar_request;
    } else if (currentNotification.text === "Switch request") {
      method = "getSwitchRequest";
      request = currentNotification.shift_request;
    } else if (currentNotification.text === "Giveaway request") {
      method = "getGiveAwayRequest";
      request = currentNotification.shift_request;
    } else {
      method = "";
      request = "";
    }
    method && (await this.props[method]( request));

    const status =
      this.props.status && this.props.status.to_user_status
        ? this.props.status.to_user_status
        : this.props.status.status;
    if (status && status !== "pending") {
      await this.setState({
        buttons: false,
        singleNotification: currentNotification,
        openSwitchRequestPopup: true
      });
    } else {
      await this.setState({
        buttons: true,
        singleNotification: currentNotification,
        openSwitchRequestPopup: true
      });
    }

    this.setState({
      singleNotification: currentNotification,
      notificationPopUpOpen: true
    });
  };
  onCloseSingleNotification = () => {
    this.setState({
      notificationPopUpOpen: false
    });
  };

  componentWillUnmount() {
    this.props.saveCurrentDate(this.props.date);
  }

  async componentDidMount() {
    const quickModeEvents = await this.props.getEventsByType('shift');
    const parsed = queryString.parse(this.props.location.search);
    if(Boolean(parsed.quickmode) === true){
      this.props.userEnteredQuickMode(true);
    }
    this.setState({quickModeEvents: quickModeEvents});
  }

  quickModeEventOnClick (eventId) {
    this.setState({selectedEventId: eventId});
    this.props.getEventById(eventId);
  };

  closeQuickMode = () => {
    this.setState({quickMode: false});
    this.props.userEnteredQuickMode(false);
    this.props.history.push('/calendar');
  };

  closeErrorMessage = (errorMessage) => {
    errorMessage('');
  };

  getDuration = (shiftEvent) => {
    let duration = shiftEvent && (moment(shiftEvent.end_time, "HH:mm").diff(moment(shiftEvent.start_time, "HH:mm")) / 60000 / 60).toFixed(2);

    if (duration < 0) {
      duration = (12 + (+duration + 12)).toFixed(2);
    }
    
    if(duration === '0.00' || duration === 'NaN'){
      duration = '12.00';
    }

    return duration;
  };

  render() {

    const {
      date,
      notifications,
      // notificationsByDate,
      notes,
      myEvents,
      quickModeErrorMessage,
      quickMode
    } = this.props;

    const { quickModeEvents, selectedEventId,  } = this.state;
    // const currentDate = moment(date).format("YYYY-MM-DD");

    let personalEvent = [];
    let shiftEvent = "";
    let isEditble = false;
    // const myNotifications =
    //   notificationsByDate &&
    //   notificationsByDate.map(data => data.notifications.map(elem => elem));
    // const notificationsList = myNotifications && myNotifications.flat(Infinity);
    myEvents &&
    myEvents.map(data => {
      data.events.map(elem => {
        if (elem.type === "personal") {
          personalEvent.push(elem);
        } else if (elem.type === "shift") {
          shiftEvent = elem;
          isEditble = data.is_editable;
        }
      });
    });

    const yesterday = moment().subtract(1, "days");

    return (
      <Amplitude>
        {({ logEvent }) => (
          <Fragment>
            {!quickMode && <div className="calendar-info">
              <div className="flex align-center justify-between">
                <span className="color-text-50 fw-500 py-1">
                  {moment(date).format("MMMM Do, YYYY")}
                </span>
                {notifications || (myEvents && myEvents.length > 0) || notes ? (
                  <Link
                    to={
                      this.props.role !== "blocked_user"
                        ? `/calendar/day-details/${moment(date).format(
                        "YYYY-MM-DD"
                        )}`
                        : "/upgrade-membership"
                    }
                    onClick={() => {
                      logEvent("click Icon List");
                    }}
                    className="btn-icon color-primary"
                  >
                    <i className="icon-list" />
                  </Link>
                ) : (
                  <a
                    href="/"
                    onClick={() => {
                      logEvent("click Non Clickble Icon List", e =>
                        e.preventDefault()
                      );
                    }}
                    className="btn-icon color-primary disabled"
                  >
                    <i className="icon-list" />
                  </a>
                )}
              </div>

              {notifications || myEvents || notes ? (
                <ul className="day-details">
                  <li>
                    <a
                      className={
                        shiftEvent && shiftEvent.name !== "Off Day"
                          ? "shift"
                          : "off-day"
                      }
                      href="/"
                      onClick={e => {
                        logEvent(
                          "click Single Shift in Calendar",
                          e.preventDefault(),
                          this.onHandleOpen(
                            "Shift",
                            shiftEvent.id,
                            shiftEvent.is_shifted
                          )
                        );
                      }}
                    >
                      <span className="icon">
                        {shiftEvent && shiftEvent.start_period ? (
                          <img
                            src={require(`assets/img/${
                              shiftEvent.name === "Off Day"
                                ? "off-day"
                                : shiftEvent.start_period
                              }${
                              yesterday.isBefore(date) ? "" : "-passed"
                              }-icon.png`)}
                            alt="afternoon icon"
                          />
                        ) : (
                          <i className="bg-grey" />
                        )}
                      </span>
                      <span className={"text mr-1 text-capitalize"}>
                        {shiftEvent ? shiftEvent.name !== 'Off Day' ? upperFirst(shiftEvent.name) : 'Not Available' : "Shift (0)"}
                      </span>
                    </a>

                    {shiftEvent && shiftEvent.name === "Off Day" ? (
                      <p className="shift-time fw-500">24.00h</p>
                    ) : (
                      shiftEvent && (
                        <p className="shift-time fw-500">
                          {`${moment(shiftEvent.start_time, "hh:mm A").format(
                            "h:mm A"
                          ).replace(/^0(?:0:0?)?/, '')} `}
                          -
                          {` ${moment(shiftEvent.end_time, "hh:mm A").format(
                            "h:mm A"
                          ).replace(/^0(?:0:0?)?/, '')}`}
                          <span className="font-sm color-text-30">
                            {" "}
                            {`(${this.getDuration(shiftEvent)}h)`}
                          </span>
                        </p>
                      )
                    )}
                  </li>
                  <li>
                    {notifications &&
                    notifications.notifications.length === 1 ? (
                      <a
                        href="/"
                        onClick={e => {
                          logEvent(
                            "click Single Notification in Calendar",
                            e.preventDefault(),
                            this.onOpenSingleNotification(
                              notifications.notifications[0]
                            )
                          );
                        }}
                        className={""}
                      >
                        <span className="icon">
                          {yesterday.isBefore(date) ? (
                            <i className="bg-pink" />
                          ) : (
                            <i className="bg-pink bg-grey-dark" />
                          )}
                        </span>

                        <span className="text">
                          {upperFirst(notifications.notifications[0].text)}
                        </span>
                      </a>
                    ) : (
                      <Link
                        to={
                          this.props.role !== "blocked_user"
                            ? `/calendar/notifications/${notifications &&
                            notifications.days}`
                            : "/upgrade-membership"
                        }
                        onClick={() => {
                          logEvent("click Notifications in Calendar");
                        }}
                        className={
                          notifications &&
                          notifications.notifications.length > 0
                            ? ""
                            : "non-clickable"
                        }
                      >
                        <span className="icon">
                          {yesterday.isBefore(date) ? (
                            <i className="bg-pink" />
                          ) : (
                            <i className="bg-pink bg-grey-dark" />
                          )}
                        </span>
                        <span className="text">
                          {notifications &&
                          notifications.notifications &&
                          notifications.notifications.length > 1
                            ? `Notifications (${
                              notifications.notifications.length
                              })`
                            : notifications &&
                            notifications.notifications &&
                            notifications.notifications.length === 1
                              ? upperFirst(notifications.notifications[0].text)
                              : `Notifications (0)`}{" "}
                        </span>
                      </Link>
                    )}
                  </li>

                  {personalEvent.length === 1 ? (
                    <li>
                      <a
                        href="/"
                        onClick={e => {
                          logEvent(
                            "click Single Personal Event in Calendar",
                            e.preventDefault(),
                            this.onHandleOpen(
                              "Personal Events",
                              personalEvent[0].id
                            )
                          );
                        }}
                        className={""}
                      >
                        <span className="icon">
                          {yesterday.isBefore(date) ? (
                            <i className="bg-purple" />
                          ) : (
                            <i className="bg-purple bg-grey-dark" />
                          )}
                        </span>

                        <span className="text text-capitalize">
                          {personalEvent[0].icon_name}
                        </span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={
                          this.props.role !== "blocked_user"
                            ? `/day-personal-events/${moment(date).format(
                            "YYYY-MM-DD"
                            )}`
                            : "/upgrade-membership"
                        }
                        onClick={() => {
                          logEvent("click Personal Events in Calendar");
                        }}
                        className={
                          personalEvent && personalEvent.length > 0
                            ? ""
                            : "non-clickable"
                        }
                      >
                        <span className="icon">
                          {yesterday.isBefore(date) ? (
                            <i className="bg-purple" />
                          ) : (
                            <i className="bg-purple bg-grey-dark" />
                          )}
                        </span>

                        <span className="text text-capitalize">
                          {personalEvent && personalEvent.length > 1
                            ? `Personal Events (${personalEvent.length})`
                            : personalEvent.length === 1
                              ? personalEvent[0].name
                              : `Personal Events (0)`}
                        </span>
                      </Link>
                    </li>
                  )}
                  {notes && notes.notes && notes.notes.length === 1 ? (
                    <li>
                      <a
                        href="/"
                        onClick={e => {
                          logEvent(
                            "click Single Note in Calendar",
                            e.preventDefault(),
                            this.onHandleOpen("Notes")
                          );
                        }}
                        className={""}
                      >
                        <span className="icon">
                          {yesterday.isBefore(date) ? (
                            <i className="bg-orange" />
                          ) : (
                            <i className="bg-orange bg-grey-dark" />
                          )}
                        </span>
                        <span className="text">
                          {notes.notes[0].text}
                        </span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={
                          this.props.role !== "blocked_user"
                            ? `/day-notes/${moment(date).format("YYYY-MM-DD")}`
                            : "/upgrade-membership"
                        }
                        onClick={() => {
                          logEvent("click Notes in Calendar");
                        }}
                        className={
                          notes && notes.notes && notes.notes.length > 0
                            ? ""
                            : "non-clickable"
                        }
                      >
                        <span className="icon">
                          {yesterday.isBefore(date) ? (
                            <i className="bg-orange" />
                          ) : (
                            <i className="bg-orange bg-grey-dark" />
                          )}
                        </span>
                        <span className="text text-capitalize">
                          {notes && notes.notes && notes.notes.length > 1
                            ? `Notes (${notes && notes.notes.length})`
                            : `Notes (0)`}
                        </span>
                      </Link>
                    </li>
                  )}
                </ul>
              ) : (
                <ul className="day-details">
                  <li>
                    <a className="non-clickable">
                      <span className="icon">
                        <i className="bg-grey" />
                      </span>
                      <span className="text mr-1">Shift (0)</span>
                    </a>
                  </li>

                  <li>
                    <a className="non-clickable">
                      <span className="icon">
                        {yesterday.isBefore(date) ? (
                          <i className="bg-pink" />
                        ) : (
                          <i className="bg-pink bg-grey-dark" />
                        )}
                      </span>
                      <span className="text">Notifications (0)</span>
                    </a>
                  </li>

                  <li>
                    <a className="non-clickable">
                      <span className="icon">
                        {yesterday.isBefore(date) ? (
                          <i className="bg-purple" />
                        ) : (
                          <i className="bg-purple bg-grey-dark" />
                        )}
                      </span>
                      <span className="text"> Personal Events (0)</span>
                    </a>
                  </li>

                  <li>
                    <a className="non-clickable">
                      <span className="icon">
                        {yesterday.isBefore(date) ? (
                          <i className="bg-orange" />
                        ) : (
                          <i className="bg-orange bg-grey-dark" />
                        )}
                      </span>
                      <span className="text">Notes (0)</span>
                    </a>
                  </li>
                </ul>
              )}

              <AddToMenu
                shift={shiftEvent}
                date={date}
                role={this.props.role}
              />
              {this.state.singleNotification && (
                <NotificationsModal
                  open={this.state.notificationPopUpOpen}
                  onClose={this.onCloseSingleNotification}
                  singleNotification={this.state.singleNotification}
                  buttons={this.state.buttons}
                />
              )}
              {(myEvents || notes) && (
                <ResponsiveModal
                  open={this.state.open}
                  onClose={this.onHandleClose}
                  personalEvent={personalEvent[0]}
                  date={date}
                  isEditble={isEditble}
                  shiftEvent={shiftEvent}
                  notes={notes && notes.notes[0]}
                  type={this.state.type}
                  // eventDateId={
                  //   myEvents && myEvents.length > 0 && myEvents[0].event_date_id
                  // }
                />
              )}
            </div>}

            {/*---Start quickModeEvents--- */}
            {quickMode && <div className="quick-add">
              <p className="color-text-50 fw-500 mb-4 ml-2">Choose from the following</p>

              <div>
                <div>
                  <Grow in={quickMode}>
                    <div elevation={4}>
                      <Scrollbar
                        style={ {height: 140} }>

                        <ul className="quick-add-list">
                          {quickModeEvents.payload && quickModeEvents.payload.map( (event, key) => {
                            return (
                              <li className={selectedEventId === event.id ? 'selected' : ''} key={event.id} onClick={() => this.quickModeEventOnClick(event.id)}>
                                <img
                                  src={require(`assets/img/${
                                    event.name === "Off Day"
                                      ? "off-day"
                                      : event.start_period
                                    }-icon.png`)}
                                />

                                <p className="name text-capitalize">{event.name === "Off Day" ? "Not Available" : event.name}</p>
                                {event.name === 'Off Day' ? (<p className="duration">(24.00h)</p>) :
                                  (<p className="duration">
                                    {event.start_time.replace(/^0(?:0:0?)?/, '')} - {event.end_time.replace(/^0(?:0:0?)?/, '')}
                                  <br/>
                                    {`(${this.getDuration(event)}h)`}
                                  </p>)
                                }
                                {event.name !== 'Off Day' && <p className="type text-capitalize">{event.start_period}</p>}
                              </li>
                            );
                          })}
                          <li className="add-shift">
                            <Link to={"/add-shifts?quickmode=true"}>
                              <div className="icon">
                                <i className="icon-plus"></i>
                              </div>
                              <p className="name">Add Shift</p>
                              <p className="duration">Duration</p>
                              <p className="type">Shift Type</p>
                            </Link>
                          </li>
                        </ul>

                      </Scrollbar>
                    </div>
                  </Grow>
                </div>

                {quickModeErrorMessage && <div className="notification">
                  <button
                    onClick={() => this.closeErrorMessage(this.props.setErrorMessage)}
                    className="close"
                  >
                    <i className="icon-close" />
                  </button>
                  <div className="flex align-center">
                    <i className="icon-warning color-warning mr-3" />
                    <p>{quickModeErrorMessage}</p>
                  </div>
                </div>}

                {/*<button*/}
                {/*    className="btn-rounded btn-save"*/}
                {/*    onClick={this.closeQuickMode}*/}
                {/*>*/}
                {/*  <i className="icon-check" />*/}
                {/*</button>*/}

                <button className="btn-rounded btn-cancel" onClick={this.closeQuickMode}>
                  <i className="icon-close" />
                </button>

              </div>

            </div>}

          </Fragment>
        )}
      </Amplitude>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.user,
    status: state.notifications.status,
    quickMode: state.events.quickMode,
    quickModeErrorMessage: state.events.quickModeErrorMessage,
  };
}

export default connect(
  mapStateToProps,
  {
    saveCurrentDate,
    userEnteredQuickMode,
    getGiveAwayRequest,
    getSwitchRequest,
    getInviteUser,
    getCurrentNotification,
    getNotificationsByDate,
    editNotifications,
    getEventById,
    getEventsByType,
    setErrorMessage
  }
)(withRouter(CalendarDetails));