import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  GET_PRODUCT,
  GET_PLAN,
  GET_PLAN_BY_ID,
  GET_CUSTOMER_SUBSCRIPTION
} from "./types";

export const getProduct = () => dispatch => {
  const url = "/stripe-products/";
  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_PRODUCT,
        payload: res.data
      });
    });
};
export const getPlan = () => dispatch => {
  const url = "/stripe-plan/";
  return axiosToken
    .get(
      url
    
    )
    .then(res => {
      return dispatch({
        type: GET_PLAN,
        payload: res.data
      });
    });
};
export const getPlanById = ( id) => dispatch => {
  const url = `/stripe-plan/${id}/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_PLAN_BY_ID,
        payload: res.data
      });
    });
};

export const addCard = ( data) => dispatch => {
  const url = `/stripe-card/`;
  return axiosToken
    .post(
      url,
      data
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const getCard = () => dispatch => {
  const url = `/stripe-card/`;
  return axiosToken
      .get(
          url,
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err.response;
      });
};

export const deleteCard = (cardId) => dispatch => {
  const url = `/stripe-card/${cardId}/`;

  return (
      axiosToken
          .delete(
              url
          )
          .then(res => {
            return res;
          })

          .catch(err => {
            return err.response;
          })
  );
};

export const getCardInfo = (card_id) => dispatch => {
  const url = `/customer-subscription/card-information/${card_id}/`;
  return axiosToken
      .get(
          url,
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err.response;
      });
};

export const getCustomerSubscription = () => dispatch => {
  const url = `/customer-subscription/`;
  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_CUSTOMER_SUBSCRIPTION,
        payload: res.data
      });
    });
};
export const createPayment = ( data) => dispatch => {
  const url = `/customer-subscription/`;
  return axiosToken
    .post(
      url,
      data
     
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};
export const updatePayment = ( data, id) => dispatch => {
  const url = `/customer-subscription/${id}/`;
  return axiosToken
    .patch(
      url,
      data
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

