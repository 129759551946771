import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser, editUser } from "actions/auth";
import { withRouter } from "react-router";
import moment from "moment";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "../UI/fullDatePicker";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumber from "../UI/PhoneNumber";
import loadImage from "blueimp-load-image";

class EditProfile extends React.Component {
  state = {
    isOpen: false,
    passwordFields: false,
    birthday: "",
    picture: "",
    mobile_number: "",
    phoneNumberError: "",
    gender: ""
  };
  checkNumber = () => {
    const value = this.state.mobile_number;
    !value && this.setState({ phoneNumberError: "Phone number required" });
    if (value && !isValidPhoneNumber(value)) {
      this.props.setValues({
        ...this.props.values,
        phoneNumberError: "Invalid phone number",
        mobile_number: value
      });
    } else {
      this.props.setValues({
        ...this.props.values,
        phoneNumberError: "",
        mobile_number: value
      });
    }
  };
  handleNumber = value => {
    this.props.setValues({
      ...this.props.values,
      mobile_number: value
    });
    this.setState({ mobile_number: value, phoneNumberError: "" });
  };

  async componentDidMount() {
    await this.props.getUser();

    const User = this.props.user && this.props.user.user;
    this.setState({
      first_name: User && User.first_name,
      birthday: User && User.birthday ? User.birthday : "",
      mobile_number: User && User.mobile_number,
      picture: User && User.picture,
      gender: User.gender,
      id: User.user
    });
    this.props.setValues({
      ...this.props.values,
      first_name: User.first_name,
      last_name: User.last_name,
      role: this.props.user.user.role,
      title: User.title,
      gender: User.gender,
      id: User.id,
    });
  }

  upload = async e => {
    const f = e.target.files[0];
    loadImage(
      f,
      canvas => {
        canvas.toBlob(blob => {
          var reader = new FileReader();
          reader.onloadend = () => {
            const dataURL = reader.result;
            this.setState({picture: dataURL});
            localStorage.setItem('profilePicture', dataURL);
            this.props.setValues({
              ...this.props.values,
              picture: dataURL
            });
          };

          reader.readAsDataURL(blob);
        });
      },
      {
        canvas: true,
        orientation: true,
        maxMetaDataSize: 262144,
        maxWidth: 600,
        maxHeight: 300,
        minWidth: 100,
        minHeight: 50
      }
    );
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };
  handleChange = value => {
    this.setState({
      mobile_number: value
    });
    this.props.setValues({
      ...this.props.values,
      mobile_number: value
    });
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  onKey = e => {
    e.preventDefault();
  };

  getDate = date => {
    this.setState({ birthday: date });
    this.props.setValues({
      ...this.props.values,
      birthday: date,
      picture: localStorage.getItem('profilePicture'),
    });
  };
  changePassword = () => {
    this.setState({ passwordFields: true });
  };

  handleCheck = e => {
    this.setState({ gender: e.target.value });
    this.props.setValues({
      ...this.props.values,
      gender: e.target.value
    });
  };
  render() {
    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleSubmit,
      handleBlur
    } = this.props;

    const user = this.props.user.user;
    const role = this.props.user.user.role;
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header bg-white flex-wrap">
              <Link
                to={`${
                  localStorage.getItem("firstLogin") === "true" &&
                  role !== "family_member"
                    ? "/add-manager"
                    : "/profile"
                }`}
                className="btn-icon w-30 color-text mr-5"
              >
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>
              <h1>Edit Profile</h1>
              <div className="content-header-bottom bg-white">
                <div className="user-img">
                  {!this.state.picture ? (
                    <i className="icon-user" />
                  ) : (
                    // <img className="img" alt="" src={this.state.picture} />
                    <span
                      className="img"
                      style={{
                        backgroundImage: `url(${this.state.picture})`
                      }}
                    />
                  )}
                </div>
                <label className="btn btn-link btn-upload color-primary no-padding ml-5">
                  <input type="file" accept="image/*" onChange={this.upload} />
                  <span className="font-md">Change Image</span>
                </label>
              </div>
            </div>

            {this.state.first_name && (
              <Form className="flex dir-col grow-1" onSubmit={handleSubmit}>
                <div className="content-body pb-0">
                  <div className="profile-info">
                    <div
                      className={
                        touched.first_name && errors.first_name
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">First Name</p>
                      <div className="form-input">
                        <Field
                          type="text"
                          name="first_name"
                          autoComplete="off"
                          placeholder="Enter Your First Name"
                        />
                        <span className="border" />
                      </div>
                      {touched.first_name &&
                        errors.first_name && (
                          <p className="error-msg">{errors.first_name}</p>
                        )}
                    </div>

                    <div
                      className={
                        touched.last_name && errors.last_name
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">Last Name</p>
                      <div className="form-input">
                        <Field
                          type="text"
                          name="last_name"
                          autoComplete="off"
                          placeholder="Enter Your Last Name"
                        />
                        <span className="border" />
                      </div>
                      {touched.last_name &&
                        errors.last_name && (
                          <p className="error-msg">{errors.last_name}</p>
                        )}
                    </div>
                    {role !== "family_member" && (
                      <div className="form-field">
                        <p className="form-label">Title</p>

                        <div className="form-input">
                          <Field
                            type="text"
                            name="title"
                            autoComplete="off"
                            placeholder="Enter Your Title"
                          />
                          <span className="border" />
                        </div>
                        {/* <p className="error-msg">{errors.title}</p> */}
                      </div>
                    )}
                    <div
                      className={
                        errors.mobile_number
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">Phone</p>
                      <div className="form-input">
                        <PhoneNumber
                          handleNumber={this.handleNumber}
                          value={this.state.mobile_number}
                        />
                      </div>
                      {errors.mobile_number && (
                        <p className="error-msg">{errors.mobile_number}</p>
                      )}
                      {/* <p className="error-msg">{errors.phone}</p> */}
                    </div>

                    <div className="form-field">
                      <p className="form-label">Birthday</p>
                      <DatePicker
                        getDate={this.getDate}
                        onKey={this.onKey}
                        birthday={this.state.birthday}
                      />
                      {/* <p className="error-msg">{errors.birthday}</p> */}
                    </div>

                    <div className="form-field mb-0 pb-3">
                      <p className="form-label">Gender</p>
                      <div className="flex align-center mt-2">
                        <div className="radio-wrap mr-8">
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              onChange={this.handleCheck}
                              onBlur={handleBlur}
                              checked={this.state.gender === "female"}
                            />
                            <span className="icon" />
                            <span className="text">Female</span>
                          </label>
                        </div>
                        <div className="radio-wrap">
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              onChange={this.handleCheck}
                              onBlur={handleBlur}
                              checked={this.state.gender === "male"}
                            />
                            <span className="icon" />
                            <span className="text">Male</span>
                          </label>
                        </div>
                      </div>
                      {/* <p className="error-msg with-icon">{errors.gender}</p> */}
                    </div>
                  </div>
                </div>

                <div className="content-footer">
                  <Link
                    to={`${
                      localStorage.getItem("firstLogin") === "true" &&
                      role !== "family_member"
                        ? "/add-manager"
                        : "/profile"
                    }`}
                    className="btn btn-link color-text-50"
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-white"
                    onClick={this.checkNumber}
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const EditUserFormik = withFormik({
  mapPropsToValues(props) {
    return {
      first_name: props.first_name || "",
      last_name: props.last_name || "",
      mobile_number: props.mobile_number || "",
      title: props.title || "",
      birthday: props.birthday || "",
      gender: "",
      picture: props.picture || ""
    };
  },
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is mandatory."),
    last_name: Yup.string().required("This field is mandatory.")
  }),
  async handleSubmit(values, { props, setErrors }) {
    console.log(values.picture);
    if (values.phoneNumberError === "Invalid phone number") {
      setErrors({ mobile_number: values.phoneNumberError });
    } else {
      const userData = {
        first_name: values.first_name,
        last_name: values.last_name,
        title: values.title,
        birthday: values.birthday
          ? moment(values.birthday).format("YYYY-MM-DD")
          : "",
        gender: values.gender,
        picture: values.picture,
        mobile_number: values.mobile_number
      };
      Object.keys(userData).map(data => {
        if (
          (!userData.birthday && userData[data] === "") ||
          userData[data] === "none"
        )
          delete userData[data];
      });

      if (!values.mobile_number) {
        userData.mobile_number = null;
      }

      const res = await props.editUser(userData, values.id);

      if (res && res.payload && res.payload.status === 200) {
        const firstLogin = localStorage.getItem("firstLogin");
        if (firstLogin === "true" && values.role !== "family_member") {
          props.history.push("/add-manager");
        } else {
          props.history.push("/profile");
        }
      }
      if (res && res.status === 400) {
        Object.keys(res.data).map(data => {
          setErrors({ [data]: res.data[data][0] });
        });
      }
    }
  }
})(EditProfile);

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default withRouter(
    connect(
      mapStateToProps,
      { getUser, editUser }
    )(EditUserFormik)
  )
