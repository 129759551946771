import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { getWorkById, editWork } from "actions/work";

class EditWork extends React.Component {
  state = {
    id: ""
  };
  async componentDidMount() {
   
    await this.props.getWorkById( this.props.match.params.id);
    const work = this.props.work.workById && this.props.work.workById;
    this.props.setValues({
      ...this.props.values,
      city: work.city,
      country: work.country,
      hospital_address: work.hospital_address,
      hospital_name: work.hospital_name,
      id: work.id,
      state: work.state,
    });
    this.setState({ id: work.id });
  }

  render() {
    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleSubmit,
      handleBlur
    } = this.props;
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header bg-white flex-wrap">
              <Link
                to={`/work/${this.state.id}`}
                className="btn-icon w-30 color-text mr-5"
              >
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>
              <h1>Edit Work</h1>

              <div className="content-header-bottom bg-white">
                <div className="user-img">
                  <i className="icon-building" />
                </div>
              </div>
            </div>

            <Form className="flex dir-col grow-1">
              <div className="content-body pb-0">
                <div className="profile-info">
                  <div className="form-field">
                    <p className="form-label">Name</p>
                    <div className="form-input">
                      <Field
                        type="text"
                        name="hospital_name"
                        placeholder="Enter Name"
                        autoComplete="off"
                      />
                      <span className="border" />
                    </div>
                    {touched.hospital_name &&
                      errors.hospital_name && (
                        <p className="error-msg">{errors.hospital_name}</p>
                      )}
                  </div>

                  <div className="form-field">
                    <p className="form-label">Address</p>
                    <div className="form-input">
                      <Field
                        type="text"
                        name="hospital_address"
                        placeholder="Enter Address"
                        autoComplete="off"
                      />
                      <span className="border" />
                    </div>
                    {touched.hospital_address &&
                      errors.hospital_address && (
                        <p className="error-msg">{errors.hospital_address}</p>
                      )}
                  </div>

                  <div className="form-field">
                    <p className="form-label">Country</p>
                    <div className="form-input">
                      <Field
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        autoComplete="off"
                      />
                      <span className="border" />
                    </div>
                    {touched.country &&
                      errors.country && (
                        <p className="error-msg">{errors.country}</p>
                      )}
                  </div>

                  <div className="form-field">
                    <p className="form-label">City</p>
                    <div className="form-input">
                      <Field
                        type="text"
                        name="city"
                        placeholder="Enter City"
                        autoComplete="off"
                      />
                      <span className="border" />
                    </div>
                    {touched.city &&
                      errors.city && <p className="error-msg">{errors.city}</p>}
                  </div>

                  <div className="form-field">
                    <p className="form-label">State/Province</p>
                    <div className="form-input">
                      <Field
                        type="text"
                        name="state"
                        placeholder="Enter State/Province"
                        autoComplete="off"
                      />
                      <span className="border" />
                    </div>
                    {touched.state &&
                      errors.state && (
                        <p className="error-msg">{errors.state}</p>
                      )}
                  </div>
                </div>
              </div>

              <div className="content-footer">
                <Link
                  to={`/work/${this.state.id}`}
                  className="btn btn-link color-text-50"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      </section>
    );
  }
}

const EditWorkFormik = withFormik({
  mapPropsToValues(props) {
    return {
      city: props.city,
      country: props.country,
      hospital_address: props.hospital_address,
      hospital_name: props.hospital_name,
      state: props.state
    };
  },
  validationSchema: Yup.object().shape({
    city: Yup.string().required("This field is mandatory."),
    country: Yup.string().required("This field is mandatory."),
    hospital_address: Yup.string().required("This field is mandatory."),
    hospital_name: Yup.string().required("This field is mandatory."),
    state: Yup.string().required("This field is mandatory.")
  }),
  async handleSubmit(values, { props, setErrors }) {
    const userData = {
      city: values.city,
      country: values.country,
      hospital_address: values.hospital_address,
      hospital_name: values.hospital_name,
      state: values.state
    };

    const res = await props.editWork(userData, values.id);
    if (res && res.payload && res.payload.status === 200) {
      props.history.push(`/work/${values.id}`);
    }
  }
})(EditWork);

function mapStateToProps(state) {
  return {
    work: state.work
  };
}

export default connect(
    mapStateToProps,
    { getWorkById, editWork }
  )(EditWorkFormik)
