import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { editUser } from "./../../../actions/auth";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const SelectStyles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class SimpleSelect extends React.Component {
  state = {
    name: "role",
    role: ""
  };

  handleChange = async event => {
    const role = { role: event.target.value };
    this.setState(role);
    const res = await this.props.editUser(role, this.props.id);

    res &&
      res.payload &&
      res.payload.status === 200 &&
      this.props.handleChangeRole(this.props.id, event.target.value);
  };

  componentDidMount() {
    this.setState({ role: this.props.value });
  }

  render() {
    const { classes } = this.props;

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <Select
            value={this.state.role}
            onChange={this.handleChange}
            inputProps={{ name: "role" }}
          >
            <MenuItem disabled={this.state.role === "trial_user" || this.state.role === "blocked_user" ? true : null} value={"blocked_user"}>blocked</MenuItem>
            <MenuItem disabled={this.state.role === "trial_user" ? true : null} value={"trial_user"}>trial</MenuItem>
            <MenuItem disabled={this.state.role === "ambassador_user" ? true : null} value={"ambassador_user"}>ambassador</MenuItem>

          </Select>
        </FormControl>
      </form>
    );
  }
}

SimpleSelect = withStyles(SelectStyles)(SimpleSelect);
export default connect(
  null,
  { editUser }
)(SimpleSelect);
