import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import JssProvider from "react-jss/lib/JssProvider";
import { Link } from "react-router-dom";
import { map, upperFirst } from "lodash";
import { createGenerateClassName } from "@material-ui/core/styles";
import moment from "moment";
import TimePicker from "../UI/timePicker";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import {withRouter} from "react-router";
import {connect} from "react-redux";

const styles = () => ({
  root: {
    width: "100%"
  }
});

function getSteps() {
  return ["Event Type", "Name", "Duration"];
}
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const defaultEvents = ['pet care', 'sick day', 'date night', 'movies', 'party', 'spa',  'doctor', 'shopping', 'vacation', 'birthday', 'gym'];

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: false,
    value: "",
    disabled: false
  };

  isStepOptional = step => {
    return step === 1;
  };

  handleSelect = async time => {
    if (this.state.isOpen === "Start") {
      await this.setState({ startTime: time });
      this.props.onStartTimeSelect(moment(time, "HH:mm A").format("hh:mm A"));
    }
    if (this.state.isOpen === "End") {
      await this.setState({ endTime: time });
      this.props.onEndTimeSelect(moment(time, "HH:mm A").format("hh:mm A"));
    }

    this.setState({ isOpen: "" });
  };

  handleNext = async () => {
    const { activeStep } = this.state;
    if (activeStep === 0 && this.props.disabled === "cancel") {
      await this.setState({
        activeStep: activeStep + 1,
        disabled: false
      });
    } else if (activeStep === 0 && this.props.disabled !== "cancel") {
      await this.setState({ disabled: false });
    } else {
      await this.setState({
        activeStep: activeStep + 1,
        disabled: false
      });
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
    this.setState({error: ''});
  };

  getTime = (time, period) => {
    if (period === "Start") {
      this.setState({ startTime: time, isOpen: period });
    } else if (period === "End") {
      this.setState({ endTime: time, isOpen: period });
    }
  };

  handleClick = elem => {
    this.setState({
      isOpen: elem,
      date: this.props.date,
      curentDate: moment()
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
    this.setState({error: ''});
  };

  handleChange = event => {
    this.setState({value: event.target.value, disabled: false});
    this.props.handleChange(event.target.value);
  };

  handleClickStep = async step => {
    if (step === 1 && this.props.disabled === "cancel") {
      await this.setState({
        activeStep: step,
        disabled: false
      });

    } else if (step === 1 && this.props.disabled !== "cancel") {
      await this.setState({ disabled: false });
    } else {
      await this.setState({
        activeStep: step,
        disabled: false
      });
    }
    await this.setState({error: ''});
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...props}
                  className={
                    activeStep >= index ? "step completed-step" : "step"
                  }
                  onClick={() => this.handleClickStep(index)}
                >
                  <StepLabel {...labelProps} className="step-label">
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <div className="stepper-body">
            <div className="stepper-content">
              {activeStep === 0 ? (
                <div className="content-body p-0">
                  <CustomScroll
                    allowOuterScroll
                    addScrolledClass
                    heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul>
                        {this.props.personalEvents &&
                        map(this.props.personalEvents, (data, i) => {
                          i = i.toLowerCase();
                          if(defaultEvents.indexOf(i) === -1){
                            return;
                          }
                          return (
                            <li key={data}>
                              <label className="event-checkbox">
                                <input
                                  type="radio"
                                  name="event_type"
                                  value={data}
                                  onClick={ e => {
                                    this.setState({value: upperFirst(i.replace("-", " "))});
                                    this.props.onhandleIconId(e.target.value);
                                  }
                                  }
                                />
                                <div className="card card-with-label-icon">
                                    <span className="card-label bg-purple">
                                      <i
                                        className={`icon-${
                                          defaultEvents.indexOf(i) !== -1 ? i.toLowerCase().replace(" ", "-")
                                            : "other"
                                        } color-white box-30`}
                                      />
                                    </span>
                                  <div className="card-body">
                                    <p className="font-xmd fw-500 text-capitalize">
                                      {upperFirst(i.replace("-", " "))}
                                    </p>
                                  </div>
                                </div>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </CustomScroll>
                </div>
              ) : activeStep === 1 ? (
                <div className="form-field">
                  <div className="form-input">
                    <input
                      type="text"
                      placeholder="Event Name"
                      className="text-capitalize"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <span className="border" />
                  </div>
                  {this.state.error && <p className="error-msg">{this.state.error}</p>}
                </div>
              ) : (
                <div>
                  <div className="time-picker">
                    <div className="time">
                      <TimePicker
                        getTime={(time, period) => this.getTime(time, period)}
                        handleSelect={this.handleSelect}
                        time={this.state.startTime}
                        period="Start"
                      />
                      {/* <p className="selected-time">
                        {this.state.startTime &&
                          moment(this.state.startTime, " hh:mm A").format(
                            "hh:mm A"
                          )}
                      </p> */}
                    </div>
                    <div className="time">
                      <TimePicker
                        getTime={(time, period) => this.getTime(time, period)}
                        handleSelect={this.handleSelect}
                        time={this.state.endTime}
                        period="End"
                      />
                      {/* <p className="selected-time">
                        {this.state.endTime &&
                          moment(this.state.endTime, "hh:mm A").format(
                            "hh:mm A"
                          )}
                      </p> */}
                    </div>
                    {this.state.timeError && (
                      <p className="error-msg with-icon">
                        {this.state.timeError}
                      </p>
                    )}
                    {this.props.error && (
                      <p className="error-msg with-icon">{this.props.error}</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="content-footer">
              {activeStep === 0 ? (
                <Link to={"/calendar"} className="btn btn-link color-text-50">
                  {" "}
                  Cancel{" "}
                </Link>
              ) : (
                <button
                  type="button"
                  className="btn btn-link color-text-50"
                  onClick={this.handleBack}
                >
                  {" "}
                  Back{" "}
                </button>
              )}

              {activeStep === steps.length - 1 ? (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.props.onCreateShift}
                  disabled={this.state.timeError}
                >
                  {" "}
                  Add
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.handleNext}
                  disabled={this.state.disabled}
                >
                  {" "}
                  Next{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};

const horizontalLinearStepper = withStyles(styles)(HorizontalLinearStepper);

export default withRouter(
  connect(
    null,
    { }
  )(horizontalLinearStepper)
)