import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MainMenu from "../UI/MainMenu";
import { withRouter } from "react-router";
import { getProduct, getPlan, getCustomerSubscription } from "actions/payment";
import { connect } from "react-redux";
import ResponsiveModal from "../UI/modal";
import objectCreator from "../../helpers/objectCreator";

class UpgradeMembership extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    openFreePlanPopup: false,
    openPlanPopup: false,
    type: null,
    currentPlan: null,
    isCurrent: false
  };

  onOpenFreePlanPopup = () => {
    this.setState({ openFreePlanPopup: true });
  };

  onOpenPlanPopup = (currentPlan, type, isCurrent) => {
    this.setState({ openPlanPopup: true, currentPlan, type, isCurrent });
  };

  onPopupClose = () => {
    this.setState({
      openFreePlanPopup: false,
      openPlanPopup: false,
      openMonthlyPlanPopup: false
    });
  };
  async componentDidMount() {
    this.props.getPlan();
    this.props.getProduct();
    this.props.getCustomerSubscription();
  }
  render() {
    const productsId =
      this.props.payment.products &&
      objectCreator(this.props.payment.products, "id");
    const currentPlan =
      this.props.payment.customerSubscription &&
      this.props.payment.customerSubscription.plan;

    let currentAmount = null;

    this.props.payment.plans &&
      this.props.payment.plans.map(data => {
        if (data.id === currentPlan) {
          currentAmount = data.interval = data.amount;
        }
      });

    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header">
              <MainMenu />
              <h1>Upgrade Membership</h1>
            </div>
            <div className="content-body">
              <ul className="pricing-list">
                {this.props.payment.plans &&
                  this.props.payment.plans.map(data => {
                    const productName = productsId && productsId[data.product];
                    const paymentPerMonth = data.amount;
                    return (
                      <li key={data.id}>
                        <a
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                            this.onOpenPlanPopup(
                              data,
                              productName.name,
                              currentPlan === data.id
                            );
                          }}
                          className={`${
                            currentPlan === data.id ? "current-plan" : ""
                          } ${
                            +currentAmount > +paymentPerMonth ? "disabled" : ""
                          } `}
                        >
                          <div className="pricing-content">
                            <div
                              className={`pricing-item  ${
                                productName.name === "yearly"
                                  ? "pink"
                                  : productName.name === "monthly"
                                    ? "blue-light"
                                    : "blue"
                              } 
                             
                              `}
                            >
                              {productName.name === "yearly" &&
                                <span className="type">Yearly</span>
                              }
                              {productName.name === "monthly" &&
                                <span className="type">Monthly</span>
                              }
                              <span>{`${
                                productName.name !== "standard"
                                  ? "premium"
                                  : productName.name
                              }`}</span>
                              {
                                <p>
                                  $
                                  {productName.name === "yearly"
                                    ? (data.amount / 12).toFixed(2)
                                    : data.amount}
                                  <span>/ month</span>
                                </p>
                              }
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>

        <ResponsiveModal
          open={this.state.openFreePlanPopup}
          onClose={this.onPopupClose}
        >
          <div className="modal-footer">
            <Link to={"/calendar"} className="btn btn-white lg">
              Get Started
            </Link>
          </div>
        </ResponsiveModal>

        <ResponsiveModal
          open={this.state.openPlanPopup}
          onClose={this.onPopupClose}
        >
          <div className="modal-body">
            <div className="pricing-content">
              <div
                className={`pricing-item  ${
                  this.state.type === "yearly" ? "pink" : this.state.type === "monthly" ? "blue-light" : "blue"
                }`}
              >
                {this.state.type === "yearly" &&
                  <span className="type static">Yearly</span>
                }
                {this.state.type === "monthly" &&
                  <span className="type static">Monthly</span>
                }
                <span>
                  {this.state.type !== "standard" ? "Premium" : this.state.type}
                </span>
                <p>
                  {this.state.currentPlan &&
                    `$ ${
                      this.state.type === "yearly"
                        ? this.state.currentPlan.amount / 12
                        : this.state.currentPlan.amount
                    }`}{" "}
                  <span>/ month</span>{" "}
                </p>
              </div>
            </div>

            <div className="plan-details">
              <p className="fw-700 font-md color-primary mt-3">
                7-Day Free Trial
              </p>
              {this.state.type === "standard" ? (
                <ul className="list-with-icons">
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Organize your work schedule</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited shifts</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited events</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited notes</span>
                  </li>
                </ul>
              ) : (
                <ul className="list-with-icons">
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Organize your work schedule</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited shifts</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited events</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited notes</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited switches</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Unlimited giveaways</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">Automatic switch suggestions</span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">
                      Submit requests for manager approval
                    </span>
                  </li>
                  <li>
                    <i className="icon-check font-xsm color-primary" />
                    <span className="fw-500">
                      Share work schedule with friends and family
                    </span>
                  </li>
                </ul>
              )}

              {this.state.currentPlan && (
                <p className={!this.state.isCurrent ? "color-text-50 mt-3 text-center" : "color-text-50 mt-3 text-center mb-6"}>
                  {this.state.type === "yearly"
                    ? `$ ${this.state.currentPlan.amount} USD billed yearly`
                    : `$ ${this.state.currentPlan.amount} USD billed monthly`}
                </p>
              )}
            </div>
          </div>
          {!this.state.isCurrent && (
            <div className="modal-footer">
              <Link
                to={`/payment/${this.state.currentPlan &&
                  this.state.currentPlan.id}`}
                className="btn btn-white lg"
              >
                Get Started
              </Link>
            </div>
          )}
        </ResponsiveModal>

      </section>
    );
  }
}
// getProduct

function mapStateToProps(state) {
  return {
    user: state.user,
    payment: state.payment,
    plan: state.plan
  };
}

export default connect(
    mapStateToProps,
    { getProduct, getPlan, getCustomerSubscription }
  )(UpgradeMembership)

