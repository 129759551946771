import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { map } from "lodash";
import MyComponent from "./CellComponent";

export default class CalendarDetails extends Component {

  getDuration = (shiftEvent) => {
    let duration = shiftEvent && (moment(shiftEvent.end_time, "HH:mm").diff(moment(shiftEvent.start_time, "HH:mm")) / 60000 / 60).toFixed(2);

    if (duration < 0) {
      duration = (12 + (+duration + 12)).toFixed(2);
    }

    if(duration === '0.00' || duration === 'NaN'){
      duration = '12.00';
    }

    return duration;
  };

  checkIfShiftIsAvailable = (event, data) => {
    if(event.name !== 'Off Day'){
      return <li key={event.id}>
        <div className="shift flex align-center">
          <span className="icon">
            <img
              src={require(`assets/img/${
                event.start_period
              }-icon.png`)}
              alt="afternoon icon"
            />
          </span>
          <span className="text mr-1 mt-0 text-wrap">{`${moment(
            data.days
          ).format("MMMM DD")}`}</span>
        </div>
        <p className="shift-time fw-500">
          {moment(event.start_time, "h:mm A").format("h:mm A").replace(/^0(?:0:0?)?/, '')}
          -{moment(event.end_time, "h:mm A").format("h:mm A").replace(/^0(?:0:0?)?/, '')}
          <span className="font-sm color-text-30">
                    {" "}
            <span className="font-sm color-text-30">
                {`(${this.getDuration(event)}h)`}
            </span>
          </span>
        </p>
      </li>
    }
  };

  render() {
    const { date } = this.props;
    const currentMonthEvents =
      this.props.myEvents &&
      this.props.myEvents.filter(data => {
        return (
          moment(data.days).format("MM YYYY") === moment(date).format("MM YYYY")
        );
      });

    return (
      <div className="calendar-info">
        <div className="flex align-center justify-between">
          <span className="color-text-50 fw-500 py-1">
            Shifts for {moment(date).format("MMMM, YYYY")}
          </span>
        </div>

        <ul className="day-details">
          {currentMonthEvents &&
            currentMonthEvents.map(data => {
              return data.events.map(elem => {
                {return this.checkIfShiftIsAvailable(elem, data) }
              });
            })}
        </ul>
      </div>
    );
  }
}
