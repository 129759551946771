import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import EditStepper from './EditStepper'


class EditShift extends React.Component {
  constructor(props) {
    super(props);
  }
 
  render() {
   
    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Edit Shift</h1>
          </div>

         <EditStepper eventId ={this.props.match.params.id}/>
        </div>
      </div>
    );
  }
}

export default EditShift;
