import { GET_FEEDBACK, DELETE_FEEDBACK } from "actions/types";

const INITIAL_STATE = {
  feedback: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FEEDBACK:
      return { ...state, feedback: action.payload };

    default:
      return state;
  }
}
