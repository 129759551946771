import { axiosWitoutToken, axiosToken, createAxiosToken } from "http/axios";
import { GET_CONNECTIONS } from "./types";
createAxiosToken()
export const getConnections = () => dispatch => {
  const url = `/users/shared-calendars/`;
  return axiosToken
    .get(
      url
    )
    .then(res => {
      return dispatch({
        type: GET_CONNECTIONS,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};
