import { combineReducers } from "redux";
import userAuth from "./auth";
import eventsReducer from "./events";
import notificationsReducer from "./notifications";
import notesReducer from "./notes";
import managersReducer from "./managers";
import connectionsReducer from "./connections";
import workReducer from "./work";
import paymentReducer from "./payment";
import adminReducer from "./admin";
import feedbackReducer from "./feedback";

const rootReducer = combineReducers({
  user: userAuth,
  events: eventsReducer,
  notifications: notificationsReducer,
  notes: notesReducer,
  managers: managersReducer,
  connections: connectionsReducer,
  work: workReducer,
  payment: paymentReducer,
  admin: adminReducer,
  feedback: feedbackReducer
});

export default rootReducer;
