import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getNotifications,
  getNotificationsByDate,
  getCurrentNotification,
  editNotifications,
  getGiveAwayRequest,
  getSwitchRequest
} from "actions/notifications";
import { getInviteUser } from "actions/events";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import ResponsiveModal from "./singleNotification";

const arrayToObject = array => {
  return array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};
class NotificationsList extends React.Component {
  state = {
    notifications: null,
    notificationsId: null,
    singleNotification: null,
    openSwitchRequestPopup: false,
    buttons: true,
    groupedNotifications: [],
  };
  async componentDidMount() {

    await this.props.getNotifications();
    await this.props.getNotificationsByDate(
      moment(this.props.match.params.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    const res = await this.props.getNotifications();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.notifications.notifications.length > 0 && this.state.groupedNotifications.length === 0) {
      const groups = this.props.notifications.notifications.reduce((groups, game) => {
        const date = game.created.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupedNotifications = Object.keys(groups).map((date) => {
        return {
          date,
          games: groups[date]
        };
      });


      this.setState({groupedNotifications: groupedNotifications});
    }
  }

  handleIcon = type => {
    switch (type) {
      case "Giveaway request":
        return (
          <i className="icon-calendar-remove color-text-70 font-md mr-3" />
        );
      case "Giveaway covered":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Pending giveaway acceptance from user":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Pending giveaway approval by manager":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Giveaway approved by manager":
        return <i className="icon-check font-xsm mr-3 color-success" />;
      case "Giveaway rejected by manager":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Sharing Calendars Enabled":
        return <i className="icon-eye font-md mr-3 color-text-70" />;
      case "Sharing Calendars Disabled":
        return <i className="icon-eye-disabled font-md mr-3 color-text-70" />;
      case "Pending switch acceptance from user":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Switch request":
        return <i className="icon-switch-horizontal font-xmd mr-3" />;
      case "Switch rejected by user":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Switch rejected by manager":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Switch approved by manager":
        return <i className="icon-check font-xsm mr-3 color-success" />;
      case "Switch covered":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Pending switch approval by manager":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Invitation":
        return <i className="icon-share color-text-70 font-sm mr-3" />;
      case "Invitation accepted":
        return <i className="icon-check font-xsm mr-3" />;
      case "Invitation rejected":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      default:
        return;
    }
  };
  handleClick = async data => {
    await this.props.getCurrentNotification(data.id);
    if (data.status !== "read") {
      this.props.editNotifications( data.id, {
        status: "read"
      });
    }

    let method = "";
    let request = "";
    if (data.text === "Invitation") {
      method = "getInviteUser";
      request = data.share_calendar_request;
    } else if (data.text === "Switch request") {
      method = "getSwitchRequest";
      request = data.shift_request;
    } else if (data.text === "Giveaway request") {
      method = "getGiveAwayRequest";
      request = data.shift_request;
    } else {
      method = "";
      request = "";
    }

    const res = method && (await this.props[method](request));

    const status =
      this.props.status && this.props.status.to_user_status
        ? this.props.status.to_user_status
        : this.props.status.status;
    if (status && status !== "pending") {
      this.setState({
        buttons: false,
        singleNotification: data,
        openSwitchRequestPopup: true
      });
    } else {
      this.setState({
        buttons: true,
        singleNotification: data,
        openSwitchRequestPopup: true
      });
    }
  };
  onPopupClose = () => {
    this.setState({ openSwitchRequestPopup: false });
  };

  printNotifications = () => {
    return this.state.groupedNotifications.map( (notifications, key) => {
      return (
        <ul key={key} className="mb-5">
          <p className="color-text-50 fw-500 mb-2">{moment(
            notifications.date
          ).format("MMM Do, YYYY")}</p>
          {notifications.games.map(notification => {
              return (
                <li
                  key={notification.id}
                  onClick={() =>
                    this.handleClick(notification)
                  }
                >
                  <a
                    className={`card card-with-label ${
                      notification &&
                      notification.status !== "read"
                        ? "unread"
                        : ""
                      }`}
                  >
                    <span className="card-label bg-pink" />

                    <div className="card-body">
                      {notification &&
                      notification.sending_user_event && (
                        <p className="date color-text-50 font-xsm">
                          {moment(
                            notification
                              .sending_user_event.date
                          ).format("MMM Do, YYYY")}
                        </p>
                      )}
                      {notification &&
                      notification.receiver_user_event && !notification.sending_user_event && (
                        <p className="date color-text-50 font-xsm">
                          {moment(
                            notification
                              .receiver_user_event.date
                          ).format("MMM Do, YYYY")}
                        </p>
                      )}
                      <p
                        className={
                          notification &&
                          notification.sending_user_event
                            ? "font-md fw-500 mb-1 ellipsis width-limit"
                            : "font-md fw-500 mb-1 ellipsis"
                        }
                      >
                        {notification &&
                        `${
                          notification.sending_user
                            .first_name
                          } ${
                          notification.sending_user
                            .last_name
                          }`}
                      </p>
                      <p className="color-text-70 font-base flex align-center">
                        {this.handleIcon(notification.text)}
                        <span className="ellipsis">
                      {notification.text}
                    </span>
                      </p>
                    </div>
                  </a>
                </li>);
            }
          )}
        </ul>
      );
    })
  };


  render() {
    const {groupedNotifications} = this.state;

    const notifications =
      this.props.notifications.notifications &&
      this.props.notifications.notifications;
    const myNotifications =
      notifications &&
      notifications.map(
        data => data
        // .notifications.map(elem => elem)
        // notificationsMyList.push(data.notifications)
      );
    const notificationsList = myNotifications && myNotifications.flat(Infinity);
    const notificationsId =
      notificationsList && arrayToObject(notificationsList);

    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>

            <h1>Notifications</h1>
          </div>

          <div className="content-body p-0">
            <div className="scrollbar-wrapper">
              <CustomScroll
                allowOuterScroll
                addScrolledClass
                heightRelativeToParent="100%"
              >
                <div className="p-3">
                  <div className="list-wrap">
                    <div>
                      {this.printNotifications()}
                    </div>
                  </div>
                </div>
              </CustomScroll>
            </div>
          </div>
          {this.state.singleNotification && (
            <ResponsiveModal
              singleNotification={this.state.singleNotification}
              buttons={this.state.buttons}
              open={this.state.openSwitchRequestPopup}
              onClose={this.onPopupClose}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
    status: state.notifications.status,
    date: state.events
  };
}

export default connect(
  mapStateToProps,
  {
    getNotifications,
    getNotificationsByDate,
    getCurrentNotification,
    editNotifications,
    getGiveAwayRequest,
    getSwitchRequest,
    getInviteUser
  }
)(NotificationsList);