import React, { Component } from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  StripeProvider,
  Elements,
  injectStripe
} from "react-stripe-elements";
import {Link} from "react-router-dom"
import CardNumberMask from "../UI/cardNumberMask";
import DateMask from "../UI/dateMask";
import CvcMask from "../UI/cvcMask";

class _SplitForm extends React.Component {
  state = {
    errors: {
      cardExpiry: "",
      cardNumber: "",
      cardCvc: ""
    }
  };

  handleSubmit = ev => {
    ev.preventDefault();
    if (this.props.stripe) {
      this.props.stripe
        .createSource({
          type: 'card'
        })
        .then(payload => payload && this.props.handleChange(payload));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleChange = change => {
    if (change.error && change.error.message) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          [change.elementType]: change.error.message
        }
      });
    } else {
      this.setState({
        ...this.state,
        errors: { ...this.state.errors, [change.elementType]: "" }
      });
    }
  };

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="flex dir-col grow-1 payment-form"
      >
        <div className="content-body pb-0">
          <div className="profile-info">
            <div className="form-field">
              <p className="form-label">Card Number</p>
              <div className="form-input">
                <CardNumberElement
                  className="payment-input"
                  style={{
                    base: {
                      fontSize: "16px",
                      color: "#202123",
                      lineHeight: "24px",
                      fontFamily: '"SF Pro Display", serif',
                      "::placeholder": {
                        color: "rgba(35,35,35,.5)"
                      }
                    }
                  }}
                  onChange={this.handleChange}
                  placeholder="Enter Card Number"
                />
                <span className="border" />
              </div>
              {this.state.errors.cardNumber && (
                <p className="error-msg">{this.state.errors.cardNumber}</p>
              )}
            </div>

            <div className="form-field">
              <p className="form-label">Expiration Date</p>
              <div className="form-input">
                <CardExpiryElement
                  className="payment-input"
                  style={{
                    base: {
                      fontSize: "16px",
                      color: "#202123",
                      lineHeight: "24px",
                      fontFamily: '"SF Pro Display", serif',
                      "::placeholder": {
                        color: "rgba(35,35,35,.5)"
                      }
                    }
                  }}
                  onChange={this.handleChange}
                  placeholder="MM/YY"
                />
                <span className="border" />
              </div>
              {this.state.errors.cardExpiry && (
                <p className="error-msg">{this.state.errors.cardExpiry}</p>
              )}
            </div>

            <div className="form-field">
              <p className="form-label">CVC</p>
              <div className="form-input">
                <CardCVCElement
                  className="payment-input"
                  style={{
                    base: {
                      fontSize: "16px",
                      color: "#202123",
                      lineHeight: "24px",
                      fontFamily: '"SF Pro Display", serif',
                      "::placeholder": {
                        color: "rgba(35,35,35,.5)"
                      }
                    }
                  }}
                  onChange={this.handleChange}
                  placeholder="Enter CVC code"
                />
                <span className="border" />
              </div>
              {this.state.errors.cardCvc && (
                <p className="error-msg">{this.state.errors.cardCvc}</p>
              )}
            </div>

            {!this.props.update &&
              <div>
                <p className="font-md fw-500">Total Amount</p>
                {this.props.plan && (
                  <p className="color-text-70 mt-2">
                    {`$${this.props.plan.amount}`} USD
                  </p>
                )}
              </div>
            }

            {this.props.error && (
              <div className="mt-6">
                <p className="error-msg with-icon">{this.props.error}</p>
              </div>
            )}
          </div>
        </div>

        {!this.props.update ?
          <div className="content-footer">
            <button type="submit" className="btn btn-white">
              Buy Now
            </button>
           </div>
          : (
            <div className="content-footer">
              <Link
                to="/manage-payment"
                className="btn btn-link color-text-50"
              >
                Cancel
              </Link>
              <button
                type="button"
                className="btn btn-white"
                onClick={this.handleSubmit}
              >
                Add
              </button>
            </div>
          )
        }
      </form>
    );
  }
}

export default _SplitForm;
