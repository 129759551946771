import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "actions/auth";
import { getManager } from "actions/manager";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

class ManagerList extends React.Component {
   componentDidMount() {
   this.props.getManager();
  }
  render() {
    const Manager =
      this.props.manager.managers && this.props.manager.managers.results;
    return (
      <section className="content-center">
        <div className="container">
          {Manager && Manager.length < 1 ? (
            <div className="content box-shadow justify-start">
              <div className="content-header">
                <Link
                  to={"/settings"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>Manager</h1>
              </div>

              <div className="content-body placeholder-content">
                <img src={require("assets/img/user.svg")} alt="user icon" />
                <p className="font-md">You don't have a manager yet.</p>

                <Link to={`/add-manager`} className="btn-rounded btn-add">
                  <i className="icon-plus" />
                </Link>
              </div>
            </div>
          ) : (
            <div className="content box-shadow">
              <div className="content-header">
                <Link
                  to={"/settings"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>Manager</h1>
              </div>

              <div className="content-body p-0">
                <div className="scrollbar-wrapper">
                  <CustomScroll
                    allowOuterScroll
                    addScrolledClass
                    heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul className="mb-6">
                        {Manager &&
                          Manager.map(data => {
                            return (
                              <li key={data.id}>
                                <Link
                                  to={`/manager/${data.id}`}
                                  className="card card-with-img lg"
                                >
                                  <div className="card-img">
                                    <span className="user-img">
                                      {data.picture ? (
                                        <span
                                          className="img"
                                          style={{
                                            backgroundImage: `url(${
                                              data.picture
                                            })`
                                          }}
                                        />
                                      ) : (
                                        <i className="icon-user" />
                                      )}
                                    </span>
                                  </div>
                                  <div className="card-body row">
                                    <p className="color-text font-md fw-500 ellipsis">
                                      {data.first_name} {data.last_name}
                                    </p>
                                  </div>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </CustomScroll>
                </div>

                <Link to={"/add-manager"} className="btn-rounded btn-add">
                  <i className="icon-plus" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    manager: state.managers
  };
}

export default connect(
    mapStateToProps,
    { getUser, getManager }
  )(ManagerList)
