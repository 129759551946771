import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withFormik, Form, Field } from "formik";
import { inviteUser } from "actions/events";
import { getManager } from "actions/manager";
import { getUser, checkInviteeMail } from "actions/auth";
import * as Yup from "yup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Collapse from "@material-ui/core/Collapse";
import queryString from "query-string";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function getSteps() {
  return ["Email", "Member"];
}
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

let email = "";
let emailError = "";
class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: false,
    teamMember: "",
    teamMemberError: "",
    manager: "none",
    name: "manager",
    errorEmail: "",
    disabled: false,
    managerEmails: [],
  };
  async componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if(Boolean(parsed.team_member) === true){
      this.setState({teamMember: "team"});
    }
    sessionStorage.removeItem('email');
    if(sessionStorage.getItem('email')){
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
    this.props.getManager();
    this.props.getUser();
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isStepOptional = step => {
    return step === 1;
  };

  handleNext = async () => {
    const { activeStep } = this.state;
    const checkInviteeMail = await this.props.checkInviteeMail(email);

    if (activeStep === 0 && !email) {
      await this.setState({
        errorEmail: "This field is mandatory"
      });
    }
    else if (activeStep === 0 && email) {
      if (email === this.props.auth.user.email) {
        await this.setState({
          errorEmail: "Please enter a valid email address"
        });
      }
      else if(checkInviteeMail && checkInviteeMail.payload.status && checkInviteeMail.payload.status === 406){
        this.setState({
          errorEmail: checkInviteeMail.payload.data.message
        });
      }
      else if (activeStep === 0 && !emailError) {
        await this.setState({
          activeStep: activeStep + 1,
          errorEmail: ""
        });
        sessionStorage.removeItem("email");
        sessionStorage.setItem("email", email);
      }
    }
  };
  handleBack = () => {
    sessionStorage.removeItem("email");
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleClick = async () => {
    if (!this.state.teamMember) {
      this.setState({ teamMemberError: "Please select one of the choices." });
      return;
    } else if (
      this.state.teamMember === "team" &&
      this.state.manager === "none"
    ) {
      this.setState({ teamMemberError: "Please select manager" });
    } else {
      await this.setState({ email: sessionStorage.getItem("email") });
      const data =
        this.state.teamMember === "family"
          ? {
            to_email: this.state.email,
            member_type: this.state.teamMember
          }
          : {
            to_email: this.state.email,
            member_type: this.state.teamMember,
            manager: this.state.manager
          };

      const res = await this.props.inviteUser(data);

      if (res && res.payload && res.payload.status === 200) {
        this.props.history.push(
          this.props.lastLocation === "/team" ? "/team" : "/calendar"
        );
      } else {
        this.setState({
          teamMemberError: res.data.non_field_errors
            ? res.data.non_field_errors[0]
            : res.data[0]
        });
      }
    }
    sessionStorage.removeItem("email");
  };

  handleCancel = () => {
    sessionStorage.removeItem("email");
    this.setState({ isOpen: false });
  };
  handleSelect = time => {
    this.setState({ isOpen: false });
  };
  addEmail = email => {
    this.setState({ email });
  };
  handleClickStep(step, errors, values) {
    if (step === 1) {
      !values.email && sessionStorage.removeItem("email") &&
      this.setState({ errorEmail: "This field is mandatory." });
    }
    if (errors && !errors.email && values.email) {
      this.setState({ activeStep: step });
    }
  }
  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { teamMember, manager } = this.state;
    const { activeStep } = this.state;
    const { values, errors, touched, handleSubmit } = this.props;
    const Manager =
      this.props.manager.managers && this.props.manager.managers.results;

    let sessionStorageEmail = sessionStorage.getItem("email");
    email = values.email = values.email ? values.email : sessionStorageEmail ? sessionStorageEmail : '';

    emailError = errors.email;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              return (
                <Step
                  key={label}
                  {...props}
                  className={
                    activeStep >= index ? "step completed-step" : "step"
                  }
                  onClick={() => this.handleClickStep(index, errors, values)}
                >
                  <StepLabel {...labelProps} className="step-label">
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <div className="stepper-body">
            <Form onSubmit={handleSubmit}>
              <div className="stepper-content">
                {activeStep === 0 ? (
                  <div
                    className={
                      (touched.email && errors.email) || this.state.errorEmail
                        ? "form-field has-error"
                        : "form-field"
                    }
                  >
                    <div className="form-input">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                      <span className="border" />
                    </div>
                    {(errors.email || this.state.errorEmail) && (
                      <p className="error-msg">
                        {this.state.errorEmail || errors.email}
                      </p>
                    )}

                    {/* {!errors.email &&
                      this.state.errorEmail && (
                        <p className="error-msg">{this.state.errorEmail}</p>
                      )} */}
                    {/* {errors.email &&
                      this.state.errorEmail && (
                        <p className="error-msg">{errors.email}</p>
                      )} */}
                    {/* {touched.email && errors.email &&
                      <p className="error-msg">{errors.email}</p>} */}
                  </div>
                ) : (
                  <div className="form-field lg">
                    <div className="mb-6">
                      <div className="radio-wrap">
                        <label aria-label="Collapse">
                          <input
                            type="radio"
                            onChange={() =>
                              this.setState({ teamMember: "team" })
                            }
                            name="teamMember"
                            value="team"
                            checked={teamMember === "team"}
                          />
                          <span className="icon" />
                          <span className="text">Team Member</span>
                        </label>
                      </div>
                      <p className="info-text">
                        Adding a team member will let you see each other's
                        calendars.
                      </p>
                    </div>

                    <Collapse in={teamMember === "team"}>

                      { Manager.length === 0 ? (
                        <Link className={'btn btn-link color-primary no-padding mb-8'} to={'/add-manager?comesfrominvite=true'}>Create Manager</Link>
                      ) : (

                        <div
                          className={
                            manager === "none"
                              ? "form-select mb-6 not-selected"
                              : "form-select mb-6"
                          }
                        >
                          <FormControl>
                            <Select
                              value={this.state.manager}
                              onChange={this.handleChange}
                              inputProps={{
                                name: "manager"
                              }}
                            >
                              <MenuItem value="none" disabled>
                                Choose a Manager
                              </MenuItem>
                              {Manager &&
                              Manager.map(data => {
                                return (
                                  <MenuItem value={`${data.id}`} key={data.id}>
                                    {`${data.first_name}
                                         ${data.last_name}`}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          {/* <p className="error-msg with-icon">error message</p> */}

                          <p className="info-text">
                            The invitee will have the same manager as yours.
                          </p>
                        </div>
                      )}
                    </Collapse>

                    <div className="mb-6">
                      <div className="radio-wrap">
                        <label>
                          <input
                            type="radio"
                            onChange={() =>
                              this.setState({ teamMember: "family" })
                            }
                            name="teamMember"
                            value="family"
                          />
                          <span className="icon" />
                          <span className="text">Family Member</span>
                        </label>
                      </div>
                      <p className="info-text">
                        Adding a family member will let them see all the shifts
                        in your calendar.
                      </p>
                    </div>
                    {this.state.teamMemberError && (
                      <p className="error-msg with-icon">
                        {this.state.teamMemberError}
                      </p>
                    )}
                    {/* <p className="error-msg with-icon">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                  </div>
                )}
              </div>

              <div className="content-footer">
                {activeStep === 0 ? (
                  <Link
                    to={`${
                      this.props.lastLocation === "/team" ? "/team" : "calendar"
                      }`}
                    className="btn btn-link color-text-50"
                    onClick={() => {this.handleCancel()}}
                  >
                    Cancel
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-link color-text-50"
                    onClick={this.handleBack}
                  >
                    Back
                  </button>
                )}

                {activeStep === steps.length - 1 ? (
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={this.handleClick}
                  >
                    Send Invite
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={this.handleNext}
                    disabled={this.state.disabled}
                    // disabled={this.state.errorEmail || errors.email}
                  >
                    Next
                  </button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </JssProvider>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};
const InviteUser = withStyles(styles)(HorizontalLinearStepper);
const InviteWithFormik = withFormik({
  mapPropsToValues({ email, shiftWorker }) {
    return {
      email: email || "",
      teamMember: "unTouched"
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Please provide a valid email.")
      .required("This field is mandatory.")
  }),
  async handleSubmit(values, { props, setErrors }) {
    if (!values.email) {
      setErrors({ email: " Please select one of the choices." });
    } else if (values.teamMember === "unTouched") {
      setErrors({ teamMember: " Please select one of the choices." });
    } else {
      const userData = {
        email: values.email,
        role: values.teamMember === "Yes" ? "family_member" : "trial_user"
      };
      sessionStorage.removeItem("email");
    }
  }
})(InviteUser);

function mapStateToProps(state) {
  return {
    manager: state.managers,
    auth: state.user
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    { inviteUser, getManager, getUser, checkInviteeMail }
  )(InviteWithFormik)
)