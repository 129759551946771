import React, { Component, Fragment } from "react";

import moment from "moment";

import { map } from "lodash";

const yesterday = moment(Date.now())
  .subtract(1, "days");

class MyComponent extends Component {

  renderEvents = events => {
    let selectedStartData = moment(this.props._d).format("DD.MM.YYYY");
    let eventjsx = null;
    const curentMonth =
      moment(this.props._d).format("MM YYYY") ===
      moment(this.props.currentDate).format("MM YYYY");

    map(events, data => {
      const myday = moment(data.days).format("DD.MM.YYYY");

      if (myday === selectedStartData) {
        data.events.map(event => {
          if (event.type === "shift") {
            eventjsx = (
              <img
                src={require(`assets/img/${
                  event.name === "Off Day" ? "off-day" : event.start_period
                }${
                  yesterday.isBefore(data.days) && curentMonth ? "" : "-passed"
                }-icon.png`)}
                alt={`${event.start_period}`}
              />
            );
          }
        });
      }
    });
    return eventjsx;
  };

  render() {
    const { currentDate } = this.props;

    let currentMonthEvents = [];
    if(this.props.events){
      this.props.events.map(data => {
        data && data.events.map(event => {
          if(event.name !== 'Off Day' && moment(data.days).format("MM YYYY") === moment(currentDate).format("MM YYYY")){
            currentMonthEvents.push(data);
          }
        });
      });
    }

    const checkCurrent = moment().format("YYYY/MM/DD");
    const now = moment().format("YYYY/MM/DD");
    const check = moment(this.props._d, "YYYY/MM/DD");
    const day = check.format("D");
    // let current = now <= checkCurrent;
    return (
      <Fragment>
        <div
          className={`${
            check.format("MM") !== moment().format("MM") ||
            check.format("YYYY/MM/DD") < now
              ? "disabled-day"
              : ""
          } ${
            check.format("YYYY/MM/DD") === checkCurrent ? "current-day" : "day"
          }`}
        >
          {day}
          <div className="shift-day">
            {this.renderEvents(currentMonthEvents)}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MyComponent;
