import React, { Component } from "react";
import Picker from "pickerjs";
import moment from "moment";
import { Field } from "formik";
import "pickerjs/dist/picker.css";

class DatePicker extends React.Component {
  state = { picker: "" };
  componentDidMount() {
    const a = new Picker(document.querySelector(".js-date-picker"), {
      format: "Do, MMMM YYYY",
      date: this.props.selectedDate
        ? moment(this.props.selectedDate).format("Do MMMM, YYYY")
        : moment(this.props.date).format("Do MMMM, YYYY"),
      headers: true,
      text: {
        title: "",
        confirm: "Select"
      },
      pick: e => {
        this.props.getDate(a.getDate(), this.props.switch);
      }
    });
  }
  handlePicker = () => {};
  render() {
    return (
      <div className="js-date-picker">
        <button type="button" className="btn btn-white ">
          {" "}
          <i className="icon-calendar-o-small" /> <span>{this.props.text}</span>
        </button>
        <p className="selected-date">
          {this.props.selectedDate
            ? moment(this.props.selectedDate).format("MMMM Do, YYYY")
            : moment(this.props.date).format("MMMM Do, YYYY")}
        </p>
      </div>
    );
  }
}

export default DatePicker;
