import {
  SIGN_UP_USER,
  SIGN_IN_USER,
  ACTIVATE_ACCOUNT,
  FORGOT_PASSWORD_USER,
  RESET_PASSWORD_USER,
  GET_USER,
  REMOVE_TOKEN,
  MANAGER_APPROVE,
  GET_USER_BY_ID,
  CHECK_INVITEE_MAIL
} from "actions/types";

const INITIAL_STATE = {
  user: "",
  role: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_UP_USER:
      return { ...state, message: action.payload.message };
    case SIGN_IN_USER:
      return { ...state, token: action.payload.data };
    case REMOVE_TOKEN:
      return { ...state, token: "" };
    case ACTIVATE_ACCOUNT:
      return { ...state, message: action.payload.message };
    case FORGOT_PASSWORD_USER:
      return { ...state, response: action.payload };
    case RESET_PASSWORD_USER:
      return { ...state, response: action.payload };
    case GET_USER:
      const role = action.payload.headers["content-user-role"];
      return {
        ...state,
        user:
          role && role === "admin"
            ? action.payload
            : action.payload.data.results[0],
        role: role
      };
    case GET_USER_BY_ID:
      return { ...state, userById: action.payload };
    case MANAGER_APPROVE:
      return { ...state, managerApprove: action.payload };
    case CHECK_INVITEE_MAIL:
      return { ...state, checkInviteeMail: action.payload };
    default:
      return state;
  }
}
