import "react-phone-number-input/style.css";
import "react-responsive-ui/style.css";
import {
  //   formatPhoneNumber,
  isValidPhoneNumber
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-responsive-ui";
import React from "react";

export default class MyNumber extends React.Component {
  //   state = { value: "",  };

  render() {
    // const { value } = this.state;
    return (
      <div className="phone-number">
        <PhoneInput
          placeholder="Enter phone number"
          value={this.props.value}
          onChange={value => this.props.handleNumber(value)}
        />
        {/* <button onClick={this.handleNumber}>add</button> */}
      </div>
    );
  }
}
