import { axiosWitoutToken, axiosToken, createAxiosToken } from "http/axios";
import {
  GET_EVENTS,
  GET_EVENT,
  GET_EVENTS_BY_TYPE,
  ADD_SHIFT_EVENT,
  SAVE_CURRENT_DATE,
  USER_ENTERED_QUICK_MODE,
  USER_SELECTS_EVENT,
  ADD_EVENT,
  ADD_MENTION,
  EDIT_EVENT,
  INVITE_USER,
  SWITCH_USER_FREE_DATES,
  GIVE_AWAY_BUSY_DATES,
  REQUEST_TO_USER,
  GET_SHARED_CALENDAR_DATE,
  UPDATE_INVITE_USER,
  GET_SHARED_EVENTS,
  GET_EVENT_BY_ID,
  EDIT_CURRENT_EVENT,
  DELETE_CURRENT_EVENT,
  DELETE_SHIFT_EVENT,
  GET_INVITE_USER,
  GET_CURRENT_EVENT,
  SET_ERROR_MESSAGE,
  USER_ADDS_SHIFT_WITH_QUICK_MODE
} from "./types";

export const getEvents = date => dispatch => {
  const url = `/events/calendar-event-by-dates/${date}/`;
  return axiosToken
    .get(url)
    .then(res => {
      localStorage.setItem("trial-period", res.headers["x-content-trial-period"]);
    
      return dispatch({
        type: GET_EVENTS,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const getEvent = (date, eventDate) => dispatch => {
  const url = `events/calendar-event-by-one-date/${date}/`;

  return axiosToken
    .get(url)
    .then(res => {
      return dispatch({
        type: GET_EVENT,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const checkEvents = date => dispatch => {
  const url = `/events/check-event-by-date/${date}/`;
  return axiosToken
    .get(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
};

export const getEventById = id => dispatch => {
  const url = `/events/${id}/`;
  return axiosToken.get(url).then(res => {
    return dispatch({
      type: GET_EVENT_BY_ID,
      payload: res.data
    });
  });
};

export const getEventsByType = ( shift) => dispatch => {
  const url = `/events/${shift ? `?type=${shift}` : ""}`;
  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_EVENTS_BY_TYPE,
        payload: res.data
      });
    });
};
export const addShiftEvent = ( data) => dispatch => {
  const url = `/events/`;
  return axiosToken
    .post(
      url,
      data
    
    )
    .then(res => {
      return dispatch({
        type: ADD_SHIFT_EVENT,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};
export const saveCurrentDate = payload => ({
  type: SAVE_CURRENT_DATE,
  payload
});

export const userEnteredQuickMode = payload => ({
  type: USER_ENTERED_QUICK_MODE,
  payload
});

export const getSharedEvents = ( id) => dispatch => {
  const url = `/events/shared-user-calendar/${id}/`;

  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_SHARED_EVENTS,
        payload: res.data
      });
    });
};

export const addEvent = (data) => dispatch => {
  const url = `/event-dates/`;
  return axiosToken
    .post(
      url,
      data
    )
    .then(res => {
      return dispatch({
        type: ADD_EVENT,
        payload: res
      });
    })
    .catch(err => err.response);
};
export const editEvent = (data,  id) => dispatch => {
  const url = `/events/${id}/`;
  return axiosToken
    .patch(
      url,
      data
    )
    .then(res => {
      return dispatch({
        type: EDIT_EVENT,
        payload: res
      });
    });
};

export const editCurrentEvent = (data,  id) => dispatch => {
  const url = `/events/${id}/`;
  return axiosToken
    .patch(
      url,
      data
     
    )
    .then(res => {
      return dispatch({
        type: EDIT_CURRENT_EVENT,
        payload: res
      });
    });
};

export const deleteCurrentEvent = ( id) => dispatch => {
  const url = `/events/${id}/`;

  return (
    axiosToken
      .delete(
        url
        
      )
      .then(res => {
        return res;
      })
    
      .catch(err => {
        return err.response;
      })
  );
};

export const addMention = payload => ({
  type: ADD_MENTION,
  payload
});

export const inviteUser = (data) => dispatch => {
  const url = `/invite-user/`;
  return axiosToken
    .post(
      url,
      data
      
    )
    .then(res => {
      return dispatch({
        type: INVITE_USER,
        payload: res
      });
    })
    .catch(err => err.response);
};

export const updateInviteUser = ( id, data) => dispatch => {
  const url = `/invite-user/${id}/`;
  return axiosToken
    .patch(
      url,
      data
      
    )
    .then(res => {
      return dispatch({
        type: UPDATE_INVITE_USER,
        payload: res
      });
    })
    .catch(err => err.response);
};

export const getInviteUser = (id) => dispatch => {
  const url = `/invite-user/${id}/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_INVITE_USER,
        payload: res.data
      });
    })
    .catch(err => err.response);
};

export const switchUserFreeDates = (
  busy_date,
  free_date,
 
  id
) => dispatch => {
  const url = `/users/switchable-users/${busy_date}/${free_date}/${id}/`;
  return axiosToken
    .get(
      url
      
    )

    .then(res => {
      return dispatch({
        type: SWITCH_USER_FREE_DATES,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const giveAwayBusyDates = (busy_date,  id) => dispatch => {
  const url = `/users/giveaway-users/${busy_date}/${id}/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GIVE_AWAY_BUSY_DATES,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const requestToUser = (data, token) => dispatch => {
  const url = `/users/shift-request-to-user/`;
  return axiosToken
    .post(
      url,
      data
     
    )
    .then(res => {
      return dispatch({
        type: REQUEST_TO_USER,
        payload: res
      });
    });
};

export const getSharedCalendarsByDate = (date) => dispatch => {
  const url = `events/team-user-shift-by-day/${date}/`;
  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_SHARED_CALENDAR_DATE,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const deleteDateEvent = ( id) => dispatch => {
  const url = `/event-dates/${id}/`;

  return axiosToken
    .delete(
      url
      
    )
    .then(res => {
      return res;
    })
    .then(
      dispatch({
        type: DELETE_SHIFT_EVENT,
        payload: id
      })
    )
    .catch(err => err.response);
};

export const setErrorMessage = payload => ({
    type: SET_ERROR_MESSAGE,
    payload
});