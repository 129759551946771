import React from "react";
import PropTypes from "prop-types";
import Dashboard from "./Dashboard";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import {
  getFeedback,
  editFeedback,
  deleteFeedback
} from "./../../actions/feedback";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createGenerateClassName } from "@material-ui/core/styles";
import EnhancedTableHead from "./adminFeedback/EnhancedTableHead";
import EnhancedTableToolbar from "./adminFeedback/EnhancedTableToolbar";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

function createData(dataObj, count, rowCount) {
  const allFeedback = [];
  let counter = count * rowCount;
  dataObj.map(data => {
    counter += 1;
    const userData = {
      id: data.id,
      number: counter,
      user_name: `${data.user.first_name} ${data.user.last_name}`,
      title: data.user.title,
      date: data.created,
      feedback: data.text,
      role: data.user.role,
      checked: data.for_showing
    };
    // let user = createData(userData);
    allFeedback.push(userData);
  });
  return allFeedback;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "number", label: "#", center: false, sort: true },
  { id: "user_name", label: "User name", center: false, sort: true },
  { id: "title", label: "Title", center: false, sort: true },
  { id: "date", label: "Date submitted", center: false, sort: true },
  { id: "feedback", label: "Feedback", center: false, sort: true },
  { id: "role", label: "Role", center: false, sort: true },
  { id: "checked", label: "Activate/ Deactivate", center: true, sort: true },
  { id: "tools", label: "Tools", center: true, sort: false }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1200
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "number",
    data: [],
    page: 0,
    rowsPerPage: 10,
    openDeleteDialog: false,
    deletedId: null,
    number: 0,
    count: null
  };

  async componentDidMount() {
   
    
    await this.props.getFeedback();
    const feedback =
      this.props.feedback.feedback && this.props.feedback.feedback.data;
    const allFeedback =
      feedback &&
      createData(feedback.results, this.state.page, this.state.rowsPerPage);
    this.setState({ data: allFeedback, count: feedback.count });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = async (event, page) => {
    const {  from, to } = this.state;
 
    await this.props.getFeedback( page + 1, from, to);
    const feedback =
      this.props.feedback.feedback && this.props.feedback.feedback.data;

    const allFeedback =
      feedback && createData(feedback.results, page, this.state.rowsPerPage);
    this.setState({ data: allFeedback, count: feedback.count, page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleOpenDeletDialog = id => {
    this.setState({ openDeleteDialog: true, deletedId: id });
  };

  handleCloseDeletDialog = () => {
    this.setState({ openDeleteDialog: false });
  };
  handleDelete = async id => {
    this.setState({ openDeleteDialog: false });
    const res = await this.props.deleteFeedback( id);
    if (res && res.payload && res.payload.status === 204) {
      await this.props.getFeedback(
        this.state.page,
        this.state.rowsPerPage
      );
      const feedback =
        this.props.feedback.feedback && this.props.feedback.feedback;

      const allFeedback =
        feedback && createData(feedback.results, this.state.page);
      this.setState({ data: allFeedback, count: feedback.count });
    }
  };
  handleChangeStatus = name => event => {
    const stateData = this.state.data;
    this.state.data.map(elem => {
      if (elem.id === name) {
        elem.checked = event.target.checked;
      }
    });
    this.setState({ data: stateData });
    this.props.editFeedback(
         { for_showing: event.target.checked },
      name
    );
  };

  handleDate = async (date, range) => {
    await this.setState({ [range]: date });
    const {  from, to } = this.state;
    await this.props.getFeedback( null, from, to);
    const feedbacks =
      this.props.feedback.feedback && this.props.feedback.feedback.data;
    const allFeedbacks =
      feedbacks &&
      createData(feedbacks.results, this.state.page, this.state.rowsPerPage);
    this.setState({ data: allFeedbacks, count: feedbacks.count });
  };
  
  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page, count } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
    return (
      <Dashboard>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            generateClassName={generateClassName}
            handleDate={this.handleDate}
            minDate={this.state.from}
            // handleSearch={this.handleSearch}
            // handleSearchInput={this.handleSearchInput}
          />
          <div className={classes.tableWrapper + " table-wrapper"}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                rows={rows}
                generateClassName={generateClassName}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy)).map(n => {
                  return (
                    <TableRow hover key={n.id}>
                      <TableCell className="nowrap">{n.number}</TableCell>
                      <TableCell className="nowrap">{n.user_name}</TableCell>
                      <TableCell>{n.title}</TableCell>
                      <TableCell className="nowrap">
                        {moment(n.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{n.feedback}</TableCell>
                      <TableCell className="nowrap">
                        {n.role.replace("_user", "")}
                      </TableCell>
                      <TableCell className="text-center">
                        <div>
                          <Switch
                            className="switch sm"
                            checked={n.checked}
                            onChange={this.handleChangeStatus(n.id)}
                            value={"checked" + n.id}
                          />
                        </div>
                      </TableCell>
                      <TableCell className="text-center">
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={() => this.handleOpenDeletDialog(n.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={rows.length + 2} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleCloseDeletDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Feedback"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the feedback?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeletDialog} color="primary">
              No
            </Button>
            <Button
              onClick={() => this.handleDelete(this.state.deletedId)}
              color="primary"
              variant="contained"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Dashboard>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const table = withStyles(styles)(EnhancedTable);

function mapStateToProps(state) {
  return {
    users: state.user,
    feedback: state.feedback
  };
}

export default connect(
  mapStateToProps,
  { getFeedback, editFeedback, deleteFeedback }
)(table);
