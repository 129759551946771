import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { unSubscribe } from "actions/auth";
import { connect } from "react-redux";

import { instanceOf } from "prop-types";

class Unsubscribe extends React.Component {

  async componentDidMount() {
    const token =
      (this.props.auth.token && this.props.auth.token.auth_token) ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");
    const id = this.props.match.params.id;
    if (!token) {
      localStorage.setItem("unsubscribe", `${id}`, {
        path: "/"
      });
      this.props.history.push("/sign-in");
    } else {
      const res = await this.props.unSubscribe( id);
      res &&
        res.payload &&
        res.payload.status === 200 &&
        localStorage.removeItem("unsubscribe");
      this.props.history.push("/calendar");
    }
  }
  render() {
    return (
      <section className="bg-primary content-center">
        <div className="container">
          <div className="content">
            <div className="info-content with-button color-white">
              <Link to={"/"} className="logo">
                <img src={require("assets/img/logo.svg")} alt="Turno" />
              </Link>

              <h1 className="no-transform mb-4">Successfully unsubscribed!</h1>

              <p className="color-white-70 text-center">
                You've been successfully unsubscribed from Turno's email
                newsletter.
              </p>

              <Link to={"/"} className="btn btn-white mt-10">
                Go to homepage
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.user
  };
}

const Un_Subsribe = Unsubscribe;

export default connect(
  mapStateToProps,
  { unSubscribe }
)(withRouter(Un_Subsribe));
