// import 'date-fns';
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";

const styles = {
  grid: {
    width: "60%"
  },
  root: {
    color: "white"
  }
};

class MaterialUIPickers extends React.Component {
  state = {
    date: null,
    minDate: null
  };

  handleDateChange = date => {
    const selectedDate = moment(moment(date)).format("YYYY-MM-DD");
    this.setState({
      date
      // minDate: this.props.range === "from" ? date : null
    });
    this.props.handleSelect(selectedDate, this.props.range);
  };

  render() {
    const { classes, minDate } = this.props;
    const { date } = this.state;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <InlineDatePicker
          value={date ? date : null}
          onChange={this.handleDateChange}
          format="dd-MM-yyyy"
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

MaterialUIPickers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MaterialUIPickers);
