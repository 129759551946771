import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { map, omit } from "lodash";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { giveAwayBusyDates, requestToUser } from "actions/events";
import { getManager } from "actions/manager";
import moment from "moment";
import DatePicker from "./datePicker";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function getSteps() {
  return ["Giveaway", "Availability"];
}
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: false,
    giveAwayDate: moment(this.props.events.date),
    error: "",
    id: [],
    manager: "none",
    name: "manager",
    disabled: false,
    managerApproval: true
  };
  async componentDidMount() {
  
    await this.props.getManager();
  }
  handleChange = event => {
    this.setState({ disabled: false, error: "" });
    this.setState({ [event.target.name]: event.target.value });
  };

  isStepOptional = step => {
    return step === 1;
  };

  handleNext = async () => {
    const { activeStep } = this.state;
    const role = localStorage.getItem("role");
    if (activeStep === 0) {
      if (role !== "member_user" && this.state.manager === "none") {
        await this.setState({
          error: "This field is mandatory.",
          disabled: true
        });
      } else {
        const res = await this.props.giveAwayBusyDates(
          moment(this.state.giveAwayDate).format("YYYY-MM-DD"),
          this.state.manager
        );
        if (res.status === 400) {
          await this.setState({ error: res.data.detail });
        } else {
          console.log("res", res.payload.data.count);
          if (res && res.payload && res.payload.data.count === 0) {
            await this.setState({
              error: "You don't have available team members.",
              disabled: true
            });
          } else {
            await this.setState({ error: "", disabled: false });
          }
        }
      }
    }
    !this.state.error &&
      this.setState({
        activeStep: activeStep + 1
      });
  };
  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleClick = elem => {
    this.setState({
      isOpen: elem,
      date: this.props.date,
      curentDate: moment()
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  getDate = async (date, type) => {
    if (type === "Giveaway") {
      await this.setState({ giveAwayDate: date, error: "", disabled: false });
    }
  };
  handleCheked = (e, idx) => {
    if (e.target.checked) {
      this.setState({
        id: [...this.state.id, idx]
      });
    } else {
      const result = this.state.id;
      var index = result.indexOf(idx);

      if (index !== -1) result.splice(index, 1);
      this.setState({
        id: result
      });
    }
  };
  handleRequest = async () => {
    let data = null;

    if(this.state.role === 'trial_user'){
      data = {
        busy_date: moment(this.state.giveAwayDate).format("YYYY-MM-DD"),
        manager: this.state.manager,
        users: this.state.id,
      };
    }
    else {
      data = {
        busy_date: moment(this.state.giveAwayDate).format("YYYY-MM-DD"),
        users: this.state.id,
        manager: this.state.manager,
        manager_approval: this.state.managerApproval,
      };
    }

    if(data.users.length === 0){
      this.setState({error: 'Please choose one of the available team members'});
    }
    const res = await this.props.requestToUser(
      data
    );
    if (res && res.payload.status === 200) {
      this.props.history.push("/team");
    } else {
      this.setState({ error: res.payload.status.data.massage });
    }
  };

  handleManagerApproval = () => {
    this.setState(state => ({ managerApproval: !state.managerApproval }));
  };

  handleClickStep(step) {
    const role = localStorage.getItem("role");
    if (role !== "member_user" && this.state.manager === "none") {
      this.setState({ error: "This field is mandatory." });
    } else {
      this.setState({ activeStep: step });
    }
  }
  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep, manager, managerApproval } = this.state;
    const giveAwayBusyDates =
      this.props.events.giveAwayBusyDates &&
      this.props.events.giveAwayBusyDates.data.results;
    const Manager =
      this.props.manager.managers && this.props.manager.managers.results;
    const role = localStorage.getItem("role");

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...props}
                  className={
                    activeStep >= index ? "step completed-step" : "step"
                  }
                  onClick={() => this.handleClickStep(index)}
                >
                  <StepLabel {...labelProps} className="step-label">
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className="stepper-body">
            <div className="stepper-content">
              {activeStep === 0 ? (
                <div className="date-picker form-field">
                  <div className="date">
                    <DatePicker
                      getDate={(date, type) => this.getDate(date, type)}
                      switch={"Giveaway"}
                      date={this.props.events.date && this.props.events.date}
                      selectedDate={this.state.giveAwayDate}
                      text={"Giveaway Shift Date"}
                    />
                  </div>

                  {this.state.error && (
                    <p className="error-msg with-icon">{this.state.error}</p>
                  )}

                  {role === "member_user" && <div className="flex align-center mt-6">
                    <p className="font-md fw-500 mr-5">Manager approval</p>
                    <Switch
                        className="switch"
                        checked={managerApproval}
                        onChange={this.handleManagerApproval}
                        aria-label="Collapse"
                    />
                  </div>}

                  <div
                    className={
                      manager === "none"
                        ? "form-select mt-6 not-selected"
                        : "form-select mt-6"
                    }
                  >
                    {this.state.managerApproval === true && <FormControl>
                      <Select
                        value={this.state.manager}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "manager"
                        }}
                      >
                        <MenuItem value="none" disabled>
                          Choose a Manager
                        </MenuItem>
                        {Manager &&
                          Manager.map(data => {
                            return (
                              <MenuItem value={`${data.id}`} key={data.id}>
                                {`${data.first_name}
                                 ${data.last_name}`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>}

                    {this.state.managerApproval === true && <p className="info-text">
                      The invitee will have the same manager as yours.
                    </p>}
                  </div>
                </div>
              ) : (
                <div className="content-body p-0">
                  <div className="scrollbar-wrapper">
                    <CustomScroll
                      allowOuterScroll
                      addScrolledClass
                      heightRelativeToParent="100%"
                    >
                      <div className="list-wrap p-3 m-0">
                        <ul>
                          {map(giveAwayBusyDates, data => {
                            return (
                              <li key={data.id}>
                                <label className="shift-checkbox">
                                  <input
                                    type="checkbox"
                                    value={data.id}
                                    onClick={e => this.handleCheked(e, data.id)}
                                  />
                                  <div className="card card-with-label">
                                    <span className="card-label bg-primary" />
                                    <div className="card-body">
                                      <p className="font-md fw-500 mb-1 ellipsis">
                                        {`${data.first_name} ${data.last_name}`}
                                      </p>
                                      <p className="color-text-70 font-base">
                                        Giveaway
                                      </p>
                                    </div>
                                  </div>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                        {this.state.error && (
                          <p className="error-msg with-icon">{this.state.error}</p>
                        )}
                      </div>
                    </CustomScroll>
                  </div>
                </div>
              )}
            </div>

            <div className="content-footer">
              {activeStep === 0 ? (
                <Link to={"/team"} className="btn btn-link color-text-50">
                  Cancel
                </Link>
              ) : (
                <button
                  type="button"
                  className="btn btn-link color-text-50"
                  onClick={this.handleBack}
                >
                  Back
                </button>
              )}

              {activeStep === steps.length - 1 ? (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.handleRequest}
                >
                  Request Pickup
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.handleNext}
                  disabled={this.state.disabled}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};

const giveAwaySteper = withStyles(styles)(HorizontalLinearStepper);
function mapStateToProps(state) {
  return {
    events: state.events,
    manager: state.managers,
    auth: state.user
  };
}

export default withRouter(
    connect(
      mapStateToProps,
      { giveAwayBusyDates, requestToUser, getManager }
    )(giveAwaySteper)
  )
