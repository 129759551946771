import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { instanceOf } from "prop-types";

import {
  getNotificationsByDate,
  getNotifications,
  getGiveAwayRequest,
  getSwitchRequest,
  editNotifications,
  getCurrentNotification
} from "actions/notifications";
import { getInviteUser } from "actions/events";
import ResponsiveModal from "./singleNotification";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

const arrayToObject = (array, key) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

class DayNotifications extends React.Component {
 
  state = {
    openSwitchRequestPopup: false,
    openSwitchAcceptedPopup: false,
    openSwitchApprovedPopup: false,
    openPendingAcceptancePopup: false,
    openPendingApprovalPopup: false,
    openSwitchRejectedPopup: false,
    openApprovalRejectedPopup: false,
    buttons: true,
    notifications: ""
  };

  async componentDidMount() {
   
    await this.props.getNotifications();
    await this.props.getNotificationsByDate(this.props.match.params.id);
    const notifications = this.props.notifications.notificationsByDate;
    const notificationsId = arrayToObject(
      this.props.notifications.notifications,
      "id"
    );
    this.setState({ notifications, notificationsId });
  }
  handleIcon = type => {
    switch (type) {
      case "Giveaway request":
        return (
          <i className="icon-calendar-remove color-text-70 font-md mr-3" />
        );
      case "Giveaway covered":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Pending giveaway acceptance from user":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Pending giveaway approval by manager":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Giveaway approved by manager":
        return <i className="icon-check font-xsm mr-3 color-success" />;
      case "Giveaway rejected by manager":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Sharing Calendars Enabled":
        return <i className="icon-eye font-md mr-3 color-text-70" />;
      case "Sharing Calendars Disabled":
        return <i className="icon-eye-disabled font-md mr-3 color-text-70" />;
      case "Pending switch acceptance from user":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Switch request":
        return <i className="icon-switch-horizontal font-xmd mr-3" />;
      case "Switch rejected by user":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Switch rejected by manager":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Switch approved by manager":
        return <i className="icon-check font-xsm mr-3 color-success" />;
      case "Switch covered":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Pending switch approval by manager":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Invitation":
        return <i className="icon-share color-text-70 font-sm mr-3" />;
      case "Invitation accepted":
        return <i className="icon-check font-xsm mr-3" />;
      case "Invitation rejected":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      default:
        return <i className="icon-switch-horizontal font-xmd mr-3" />;
    }
  };
  handleClick = async data => {
    await this.props.getCurrentNotification(data.id);
    if (data.status !== "read") {
      this.props.editNotifications( data.id, {
        status: "read"
      });
    }
    let method = "";
    let request = "";
    if (data.text === "Invitation") {
      method = "getInviteUser";
      request = data.share_calendar_request;
    } else if (data.text === "Switch request") {
      method = "getSwitchRequest";
      request = data.shift_request;
    } else if (data.text === "Giveaway request") {
      method = "getGiveAwayRequest";
      request = data.shift_request;
    } else {
      method = "";
      request = "";
    }

    method && (await this.props[method]( request));

    const status =
      this.props.status && this.props.status.to_user_status
        ? this.props.status.to_user_status
        : this.props.status.status;
    if (status && status !== "pending") {
      this.setState({
        buttons: false,
        singleNotification: data,
        openSwitchRequestPopup: true
      });
    } else {
      this.setState({
        buttons: true,
        singleNotification: data,
        openSwitchRequestPopup: true
      });
    }
    this.setState({ singleNotification: data, openSwitchRequestPopup: true });
  };
  onPopupClose = () => {
    this.setState({ openSwitchRequestPopup: false });
  };
  render() {
    const myarr = this.state.notifications &&
      this.state.notifications.length > 0 && [...this.state.notifications];
    const myNotification = myarr && arrayToObject(myarr, "days");
    const curentDateNotifications =
      myNotification && myNotification[this.props.match.params.id];

    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Notifications</h1>
          </div>

          <div className="content-body p-0">
            <div className="scrollbar-wrapper">
              <CustomScroll
                allowOuterScroll
                addScrolledClass
                heightRelativeToParent="100%"
              >
                <div className="list-wrap p-3 m-0">
                  <p className="color-text-50 fw-500 mb-3">
                    {this.state.notifications &&
                      moment(curentDateNotifications.days).format(
                        "MMMM Do, YYYY"
                      )}
                  </p>
                  <ul>
                    {this.state.notifications &&
                      curentDateNotifications.notifications.map((data, i) => {
                        const currentNotification =
                          this.state.notificationsId &&
                          this.state.notificationsId[data.id];
                        return (
                          <li key={data.id}>
                            <a
                              className={`card card-with-label ${data.status ===
                                "unread" && "unread"}`}
                              href="/"
                              onClick={e => {
                                e.preventDefault();
                                this.handleClick(currentNotification);
                              }}
                            >
                              <span className="card-label bg-pink" />
                              <div className="card-body">
                                {currentNotification.sending_user_event && (
                                  <p className="date color-text-50 font-xsm">
                                    {moment(
                                      currentNotification.sending_user_event
                                        .date
                                    ).format("MMM Do, YYYY")}
                                  </p>
                                )}
                                {currentNotification &&
                                currentNotification.receiver_user_event && !currentNotification.sending_user_event && (
                                  <p className="date color-text-50 font-xsm">
                                    {moment(
                                      currentNotification
                                        .receiver_user_event.date
                                    ).format("MMM Do, YYYY")}
                                  </p>
                                )}
                                <p
                                  className={
                                    currentNotification.sending_user_event
                                      ? "font-md fw-500 mb-1 ellipsis width-limit"
                                      : "font-md fw-500 mb-1 ellipsis"
                                  }
                                >
                                  {currentNotification &&
                                    `${
                                      currentNotification.sending_user
                                        .first_name
                                    } ${
                                      currentNotification.sending_user.last_name
                                    }`}
                                </p>
                                <p className="color-text-70 font-base flex align-center">
                                  {this.handleIcon(data.text)}
                                  <span className="ellipsis">{data.text}</span>
                                </p>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                  {this.state.singleNotification && (
                    <ResponsiveModal
                      singleNotification={this.state.singleNotification}
                      open={this.state.openSwitchRequestPopup}
                      onClose={this.onPopupClose}
                      buttons={this.state.buttons}
                    />
                  )}
                </div>
              </CustomScroll>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
    status: state.notifications.status
  };
}

export default 
  connect(
    mapStateToProps,
    {
      getNotificationsByDate,
      getNotifications,
      getGiveAwayRequest,
      getSwitchRequest,
      getInviteUser,
      editNotifications,
      getCurrentNotification
    }
  )(DayNotifications)

