import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    display: 'flex'
  }
});

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return ( 
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class FormattedInputs extends React.Component {
  state = {
    textmask: this.props.value
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    const { textmask } = this.state;

    return (
      <JssProvider generateClassName={generateClassName}> 
 
          <InputBase
            value={textmask}
            onChange={this.handleChange('textmask')}
            inputComponent={TextMaskCustom}
            placeholder={this.props.placeholder}
          />

      </JssProvider>
    );
  }
}

FormattedInputs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormattedInputs);