import React, { Component, PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { getSharedCalendarsByDate } from "actions/events";
import RequestMenu from "./RequestMenu";

class CalendarDetails extends Component {
  state = {
    startDate: ""
  };

  getDuration = (shiftEvent) => {
    let duration = shiftEvent && (moment(shiftEvent.end_time, "HH:mm").diff(moment(shiftEvent.start_time, "HH:mm")) / 60000 / 60).toFixed(2);

    if (duration < 0) {
      duration = (12 + (+duration + 12)).toFixed(2);
    }

    if(duration === '0.00' || duration === 'NaN'){
      duration = '12.00';
    }

    return duration;
  };

  checkIfShiftIsAvailable = (event) => {
    if(event.event_default !== 'True'){
      return <li key={event.user_id}>
        <div className="shift flex align-center">
          <span className="icon">
            <img
              src={require(`assets/img/${
                event.event_start_period
              }-icon.png`)}
              alt="afternoon icon"
            />
          </span>
          <span className="text mr-1 mt-0 text-wrap">{`${
            event.user_first_name
          } ${event.user_last_name}`}</span>
        </div>
        <p className="shift-time fw-500">
          {moment(event.event_start_time, "h:mm A").format("h:mm A").replace(/^0(?:0:0?)?/, '')}
          -{moment(event.event_end_time, "h:mm A").format("h:mm A").replace(/^0(?:0:0?)?/, '')}
          <span className="font-sm color-text-30">
                    {" "}
            <span className="font-sm color-text-30">
                {`(${this.getDuration(event)}h)`}
            </span>
          </span>
        </p>
      </li>
    }
  };

  render() {
    const { date } = this.props;
    const myEvents = this.props.myEvents;
    const sharedEvenets = this.props.events.events.sharedCalendar;

    return (
      <div className="calendar-info">
        <div className="flex align-center justify-between">
          <span className="color-text-50 fw-500 py-1">
            {moment(date).format("MMMM Do, YYYY")}
          </span>
        </div>
        {(myEvents && myEvents.length > 0) ||
        (sharedEvenets &&
          sharedEvenets.results &&
          sharedEvenets.results.length > 0) ? (
          <ul className="day-details mb-8">
            {myEvents &&
              myEvents.map(elem => {
                return elem.events.map(data => {
                  if (data.type === "shift") {
                    if(data.name === "Off Day"){
                      return  (<li>
                        <div className={"off-day shift flex align-center"}>
                          <span className="icon">
                              <img
                                src={require(`assets/img/off-day-icon.png`)}
                                alt="afternoon icon"
                              />
                          </span>
                          <span className={"text mr-1 text-capitalize"}>
                            {'Not Available'}
                          </span>
                        </div>
                        <p className="shift-time fw-500">24.00h</p>
                      </li>);
                    } else {
                      return (
                        <li>
                          <div className="shift flex align-center">
                          <span className="icon">
                            <img
                              src={require(`assets/img/${
                                data.start_period
                              }-icon.png`)}
                              alt="afternoon icon"
                            />
                          </span>
                            <span className="text mr-1 mt-0 text-wrap user">
                            Me
                          </span>
                          </div>
                          <p className="shift-time fw-500">
                            {moment(data.start_time, "hh:mm A").format("h:mm A").replace(/^0(?:0:0?)?/, '')}-
                            {moment(data.end_time, "hh:mm A").format("h:mm A").replace(/^0(?:0:0?)?/, '')}
                            <span className="font-sm color-text-30">
                            {" "}
                              {`(${this.getDuration(data)}h)`}
                          </span>
                          </p>
                        </li>
                      );
                    }
                  }
                });
              })
            }

            {sharedEvenets &&
              sharedEvenets.results &&
              sharedEvenets.results.map(event => {
                {return this.checkIfShiftIsAvailable(event)}
              })}
          </ul>
        ) : (
          <p className="font-md fw-500 mt-6 mb-10">
            Your team members have no shift for this day.
          </p>
        )}
        <RequestMenu date={date} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state,
    user: state.user
  };
}

export default 
  connect(
    mapStateToProps,
    { getSharedCalendarsByDate }
  )(CalendarDetails)
