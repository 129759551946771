import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getSettings,
  addSettings,
  editSettings
} from "./../../actions/adminDashboard";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import JssProvider from "react-jss/lib/JssProvider";
import Snackbar from "@material-ui/core/Snackbar";
import { createGenerateClassName } from "@material-ui/core/styles";
import SnackbarWrapper from "./SnackeBar";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  margin: {
    margin: theme.spacing.unit
  }
});

class PaperSheet extends React.Component {
  state = {
    days: null,
    errors: null,
    edit: false,
    settingsId: null,
    open: false,
    variant: "",
    message: ""
  };
  async componentDidMount() {
    
    await this.props.getSettings();
    if (
      this.props.settings.data &&
      this.props.settings.data.results.length < 1
    ) {
      this.setState({ days: null, edit: false });
    } else {
      this.setState({
        days: this.props.settings.data.results[0].value,
        settingsId: this.props.settings.data.results[0].id,
        edit: true
      });
    }
  }
  handleChange = e => {
    const days = e.target.value;
    if (days < 7 || days > 30) {
      this.setState({ errors: "Please select a valid number.", days });
    } else {
      this.setState({ errors: null, days });
      // setErrors("");
      // setDays(days);
    }
  };
  handleSave = async () => {
    const data = {
      name: "trial_period",
      value: `${this.state.days}`
    };
    if (!this.state.edit) {
      const res = await this.props.addSettings( data);
      if (res && res.payload && res.payload.status === 201) {
        this.handleClick("success", "Successfully saved.");
        this.setState({ days: res.payload.data.value });
      } else {
        this.handleClick("error", " Failed to save, try again.");
      }
    } else {
      const res = await this.props.editSettings(
        data,
        this.state.settingsId
      );
      if (res && res.payload && res.payload.status === 200) {
        this.handleClick("success", "Successfully saved.");
        this.setState({ days: res.payload.data.value });
      } else {
        this.handleClick("error", " Failed to save, try again.");
      }
    }
  };
  handleClick = (variant, message) => {
    this.setState(prevState => ({ open: !prevState.open, variant, message }));
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    const { days, errors } = this.state;
    return (
      <Dashboard>
        <Paper className={classes.root + " admin-profile sm"}>
          <Typography variant="h5" color="inherit" noWrap>
            Settings
          </Typography>
          <div className="main-input sm">
            <FormControl>
              <InputLabel htmlFor="trial_days">Trial days (7-30)</InputLabel>
              <Input
                id="trial_days"
                type="number"
                inputProps={{
                  min: "7",
                  max: "30"
                }}
                value={days}
                onChange={this.handleChange}
              />
            </FormControl>
            <p className="error-msg">{errors}</p>
          </div>
          <div className="admin-buttons">
            <button
              type="button"
              className="btn btn-white"
              disabled={errors}
              onClick={this.handleSave}
            >
              Save
            </button>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={this.state.open}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            <SnackbarWrapper
              onClose={this.handleClose}
              variant={this.state.variant}
              message={this.state.message}
            />
          </Snackbar>
        </Paper>
      </Dashboard>
    );
  }
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    settings: state.admin.settings,
    users: state
  };
}
const Sheet = withStyles(styles)(PaperSheet);

export default connect(
  mapStateToProps,
  { getSettings, addSettings, editSettings }
)(Sheet);
