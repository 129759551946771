import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import omit from "lodash/omit";
import "react-dates/initialize";
import CellComponent from "./CellComponent";
import "react-dates/lib/css/_datepicker.css";
import {getEvents, saveCurrentDate} from "actions/events";
import {
  getNotificationsByDate,
  getNotifications,
  getNotificationsCount
} from "actions/notifications";
import "react-app-polyfill/ie11";
import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/number/is-nan";
import {getNotes} from "actions/notes";

import Caption from "./Caption";
import {SingleDatePicker} from "react-dates";
import CalendarDetails from "./CalendarDetails";

let nextClick = document.querySelector(
  ".DayPickerNavigation_rightButton__horizontalDefault"
);
let prevClick = document.querySelector(
  ".DayPickerNavigation_leftButton__horizontalDefault"
);

class SingleDatePickerWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      autoFocus: true,
      month: -1,
      day: -1,
      focused: true,
      date: this.props.events.date ? moment(this.props.events.date) : moment(Date.now()),
      currentDate: moment(),
      MonthChange: moment(),
      touchStartX: null,
      selectedDay: null,
      reelContainerWidth: 500,
      num: 0,
      events: [],
      notifications: [],
      notes: [],
      showTrialPeriod: "",
      trial: false,
      flag: false,
      role: null,
      quickMode: false,
      allowCellExistingEventsToUpdate: false,
    };

  }

  onMonthChange = async (num, month) => {
    this.props.saveCurrentDate(month);
    await this.setState({
      date: moment(month).add(num, "months")
    });

    this.setState({allowCellExistingEventsToUpdate: true});

    this.props.getEvents(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    this.props.getNotificationsByDate(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    this.props.getNotes(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
  };

  onDateSelect = async date => {
    await this.setState({
      date,
      show: false
    });
    this.setState({ show: true });
    await this.props.getEvents(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    await this.props.getNotificationsByDate(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    await this.props.getNotes(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
  };

  onTouchStart = e => {
    this.setState({
      touchStartX: e.touches[0].clientX
    });
  };

  onTouchEnd = async e => {
    const difference = this.state.touchStartX - e.changedTouches[0].clientX;
    if (difference >= 50) {
      nextClick.click();
      if (!this.state.flag) {
        this.setState({ flag: true }, () => {
          this.onMonthChange(1, this.state.date);
        });
      }
    } else if (difference <= -50) {
      prevClick.click();
      if (!this.state.flag) {
        this.setState({ flag: true }, () => {
          this.onMonthChange(-1, this.state.date);
        });
      }
    }
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });
  };

  onDateChange = (date) => {
    this.props.saveCurrentDate(date);
    this.setState({date, day: date._d.getDate(), month: date._d.getMonth()});
  };

  onPrevMonthClick = () => {
    this.setState({flag: false});
  };

  onNextMonthClick = () => {
    this.setState({flag: false});
  };

  async componentDidMount() {

    this.updateDimensions();
    window.scrollTo(0, 0);
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    const weekDay = document.querySelectorAll(".DayPicker_weekHeader_li");
    const role = localStorage.getItem("role");

    let weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    for (let i = 0; i < weekDay.length; ++i) {
      weekDay[i].childNodes[0].textContent = weekDays[i];
    }
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });

    nextClick = document.querySelector(
      ".DayPickerNavigation_rightButton__horizontalDefault"
    );
    prevClick = document.querySelector(
      ".DayPickerNavigation_leftButton__horizontalDefault"
    );
    const res = await this.props.getEvents(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );

    res && res.payload && res.payload.headers && localStorage.setItem("role", res.payload.headers["x-content-user-role"])

    this.props.getNotificationsCount();

    if (role !== "blocked_user") {
      this.props.getNotificationsByDate(
        moment(this.state.date)
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      this.props.getNotes(
        moment(this.state.date)
          .startOf("month")
          .format("YYYY-MM-DD")
      );
    }
    this.setState({month: this.state.date._d.getMonth(), day: this.state.date._d.getDate()});
    window.addEventListener("resize", this.updateDimensions);
  }

  async componentDidUpdate(prevProps) {
    const {date, currentDate} = this.state;
    if (this.props.events.events.data !== prevProps.events.events.data) {
      this.setState({events: this.props.events.events.data});
      const weekDay = document.querySelectorAll(".DayPicker_weekHeader_li");

      const calendarBody = document.querySelectorAll(".CalendarMonth_table");
      calendarBody.forEach(el => {
        el.ontouchstart = this.onTouchStart;
        el.ontouchend = this.onTouchEnd;
      });
      nextClick = document.querySelector(
        ".DayPickerNavigation_rightButton__horizontalDefault"
      );
      prevClick = document.querySelector(
        ".DayPickerNavigation_leftButton__horizontalDefault"
      );
      let weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
      for (let i = 0; i < weekDay.length; ++i) {
        weekDay[i].childNodes[0].textContent = weekDays[i];
      }
      if (currentDate.format("MM") === date.format("MM")) {
        weekDay &&
        weekDay[currentDate.format("d")] &&
        weekDay[currentDate.format("d")].classList.add(
          "current_weekday"
        );
      } else {
        weekDay &&
        weekDay[currentDate.format("d")] &&
        weekDay[currentDate.format("d")].classList.remove(
          "current_weekday"
        );
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const reelContainerWidth = window.innerWidth;
    if(this.state.reelContainerWidth !== reelContainerWidth) {
      return true;
    }

    if((this.props.notifications && this.props.notifications.notificationsByDate) || (this.props.notes && this.props.notes.notes.results)){
      return true;
    }

    const {day, month, show, events} = this.state;
    return day !== nextState.day
      || month !== nextState.month
      || show !== nextState.show
      || this.props.events.events.data !== nextProps.events.events.data;
  }

  checkEvents = elem => {
    let currentStartDate = moment(this.state.date).format("YYYY-MM-DD");
    return elem && elem.filter(data => data.days === currentStartDate);

  };

  updateDimensions = () => {
    const reelContainerWidth = window.innerWidth;

    if(this.state.reelContainerWidth !== reelContainerWidth) {
      this.setState({reelContainerWidth: reelContainerWidth});
    }

    if (reelContainerWidth < 500) {
      const margin =
        -23 +
        (reelContainerWidth - 7 * Math.floor((reelContainerWidth - 2) / 7)) / 2;
      if(document.querySelector(".DayPicker")) {
        document.querySelector(".DayPicker").style.margin = "0 " + margin + "px";
      }
    }
  };

  componentWillMount() {
    /*If Not Doing This Way, Them Date Will Remain The Same As On Another Pages*/
    if(this.props.location.pathname !== '/calendar'){
      this.setState({date: this.state.currentDate});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const {events, date, focused, reelContainerWidth, show} = this.state;
    const notifications =
      this.props.notifications && this.props.notifications.notificationsByDate;
    const notes = this.props.notes && this.props.notes.notes.results;
    const notificationsCount =
      this.props.notifications && this.props.notifications.notificationsCount;
    const myNotification = notifications && this.checkEvents(notifications);
    const myNote = notes && this.checkEvents(notes);
    const myEvents = events && this.checkEvents(events);
    const props = omit(this.props, [
      "autoFocus",
      "initialDate",
      "onDateChange"
    ]);
    const trial = localStorage.getItem("trial-period");
    const myrole = localStorage.getItem("role");
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            {trial &&
            trial !== "undefined" &&
            myrole !== "family_member" && myrole !== "blocked_user" && (
              <div className="upgrade-membership-note">
                <Link
                  to={"/upgrade-membership"}
                  className="font-sm fw-200 color-white flex justify-between align-center bg-pink"
                >
                  <p className="mr-4">
                    You only have &nbsp;
                    <span className="fw-600">{trial && `${trial}`}</span>
                    &nbsp; days remaining in your trial.
                    <span className="fw-600 nowrap"> Upgrade now</span>
                  </p>
                  <i className="icon-forward-arrow icon-shadow font-xs"/>
                </Link>
              </div>
            )}
            {myrole &&
            myrole === "blocked_user" && (
              <div className="upgrade-membership-note">
                <Link
                  to={"/upgrade-membership"}
                  className="font-sm fw-200 color-white flex justify-between align-center bg-red"
                >
                  <p className="mr-4">
                    Your trial has expired.
                    <span className="fw-600 nowrap"> Upgrade now</span>
                  </p>
                  <i className="icon-forward-arrow icon-shadow font-xs"/>
                </Link>
              </div>
            )}
            {show && (
              <SingleDatePicker
                {...props}
                id="date"
                date={date}
                isDayBlocked={day => {
                  let dayIsBlocked = false;
                  if (
                    moment(date).format("MM") !==
                    moment(day).format("MM")
                  ) {
                    dayIsBlocked = true;
                  }

                  return dayIsBlocked;
                }}
                focused={focused}
                keepOpenOnDateSelect
                onFocusChange={() => {
                }}
                onNextMonthClick={this.onNextMonthClick}
                onPrevMonthClick={this.onPrevMonthClick}
                renderDayContents={props => {
                  return (
                    <CellComponent
                      {...props}
                      role={myrole}
                      currentDate={date}
                      allowCellExistingEventsToUpdate={this.state.allowCellExistingEventsToUpdate}
                      selected
                    />
                  );
                }}
                renderCalendarInfo={() => (
                  <Caption
                    date={moment(date).format("MMMM YYYY")}
                    onDateSelect={this.onDateSelect}
                    notificationsCount={notificationsCount}
                    role={myrole}
                    currentdate={date}
                  />
                )}
                isOutsideRange={() => false}
                numberOfMonths={1}
                daySize={
                  reelContainerWidth >= 500
                    ? Math.floor(500 / 7)
                    : Math.floor((reelContainerWidth - 2) / 7)
                }
                calendarInfoPosition="top"
                onDateChange={this.onDateChange}
                hideKeyboardShortcutsPanel
                showDefaultInputIcon={false}
                enableOutsideDays
              />
            )}

            <CalendarDetails
              date={date}
              role={myrole}
              notifications={myNotification && myNotification[0]}
              notes={myNote && myNote[0]}
              myEvents={myEvents}
              notificationsByDate={notifications}
            />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    quickMode: state.events.quickMode,
    notifications: state.notifications,
    notes: state.notes,
    auth: state.user
  };
}


export default connect(
  mapStateToProps,
  {
    getEvents,
    getNotificationsByDate,
    getNotes,
    getNotifications,
    saveCurrentDate,
    getNotificationsCount,
  }
)(withRouter(SingleDatePickerWrapper));
