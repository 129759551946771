import {
  GET_PRODUCT,
  GET_PLAN,
  GET_PLAN_BY_ID,
  GET_CUSTOMER_SUBSCRIPTION
} from "actions/types";

const INITIAL_STATE = {
  products: "",
  plans: "",
  plan: "",
  customerSubscription: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PRODUCT:
      return { ...state, products: action.payload.results };
    case GET_PLAN:
      return { ...state, plans: action.payload.results };
    case GET_PLAN_BY_ID:
      return { ...state, plan: action.payload };
    case GET_CUSTOMER_SUBSCRIPTION:
      return { ...state, customerSubscription: action.payload.results[0] };
    // case DELETE_NOTES:
    //   let newState = state;
    //   newState.notes.results.map(data => {
    //     let index = data.notes.map(elem => elem.id).indexOf(action.payload);
    //     if (index !== -1) {
    //       data.notes.splice(index, 1);
    //     }
    //   });

    //   return { ...newState };
    default:
      return state;
  }
}
