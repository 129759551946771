import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import { connect } from "react-redux";
import { getWork } from "actions/work";
import "react-custom-scroll/dist/customScroll.css";

class WorkList extends React.Component {
  componentDidMount() {
   
    this.props.getWork();
  }

  render() {
    const Work = this.props.work.work && this.props.work.work.results;

    return (
      <section className="content-center">
        <div className="container">
          {Work.length > 0 ? (
            <div className="content box-shadow">
              <div className="content-header">
                <Link
                  to={"/settings"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>Work</h1>
              </div>

              <div className="content-body p-0">
                <div className="scrollbar-wrapper">
                  <CustomScroll
                    allowOuterScroll
                    addScrolledClass
                    heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul className="mb-6">
                        {Work.map(data => {
                          return (
                            <li key={data.id}>
                              <Link
                                to={`work/${data.id}`}
                                className="card card-with-img lg"
                              >
                                <div className="card-img">
                                  <span className="user-img">
                                    <i className="icon-building" />
                                  </span>
                                </div>
                                <div className="card-body row">
                                  <p className="color-text font-md fw-500 ellipsis">
                                    {data.hospital_name}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </CustomScroll>
                </div>

                <Link to={"/add-work"} className="btn-rounded btn-add">
                  <i className="icon-plus" />
                </Link>
              </div>
            </div>
          ) : (
            <div className="content box-shadow justify-start">
              <div className="content-header">
                <Link
                  to={"/settings"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>Work</h1>
              </div>

              <div className="content-body placeholder-content">
                <img
                  src={require("assets/img/building.svg")}
                  alt="building icon"
                />
                <p className="font-md">You don't have a workplace yet.</p>

                <Link to={"/add-work"} className="btn-rounded btn-add">
                  <i className="icon-plus" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    work: state.work
  };
}

export default connect(
    mapStateToProps,
    { getWork }
  )(WorkList)
