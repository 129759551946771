import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getWorkById, deleteWork } from "actions/work";

class Work extends React.Component {
  componentDidMount() {
       this.props.getWorkById( this.props.match.params.id);
  }
  handleDelete = async id => {
    const res = await this.props.deleteWork( id);
    res && res.payload.status === 204 && this.props.history.push("/work-list");
  };
  render() {
    const work = this.props.work.workById && this.props.work.workById;

    return (
      <section className="content-center">
        {work && (
          <div className="container">
            <div className="content box-shadow justify-start">
              <div className="content-header bg-white flex-wrap">
                <div className="flex align-center w-100">
                  <Link
                    to={"/work-list"}
                    className="btn-icon w-30 color-text mr-5"
                  >
                    <i className="icon-back-arrow font-md icon-shadow" />
                  </Link>
                  <h1>Work</h1>
                  <Link
                    to={`/edit-work/${work.id}`}
                    className="btn-icon w-30 color-text ml-auto"
                  >
                    <i className="icon-edit font-md icon-shadow" />
                  </Link>
                  <button
                    type="button"
                    className="btn-delete-card btn-icon w-30 ml-3 color-warning"
                    onClick={() => this.handleDelete(work.id)}
                  >
                    <i className="icon-delete font-md icon-shadow" />
                  </button>
                </div>

                <div className="content-header-bottom bg-white">
                  <div className="user-img">
                    <i className="icon-building" />
                  </div>
                </div>
              </div>

              <div className="content-body">
                <div className="profile-info">
                  <p className="font-xl fw-700 mb-5">{work.hospital_name}</p>

                  <div className="mb-5">
                    <p className="font-md fw-500">Address</p>
                    <p className="color-text-70 mt-2">
                      {work.hospital_address}
                    </p>
                  </div>

                  <div className="mb-5">
                    <p className="font-md fw-500">Country</p>
                    <p className="color-text-70 mt-2">{work.country}</p>
                  </div>

                  <div className="mb-5">
                    <p className="font-md fw-500">City</p>
                    <p className="color-text-70 mt-2">{work.city}</p>
                  </div>

                  <div className="mb-5">
                    <p className="font-md fw-500">State/Province</p>
                    <p className="color-text-70 mt-2">{work.state}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    work: state.work
  };
}

export default connect(
    mapStateToProps,
    { getWorkById, deleteWork }
  )(Work)
