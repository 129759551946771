import React from "react";
import { connect } from "react-redux";
import { getEventsByType } from "actions/events";
import { getUser } from "actions/auth";

import moment from "moment";

import { instanceOf } from "prop-types";
import Tabs from "../UI/Tabs";
import MainMenu from "../UI/MainMenu";

class Events extends React.Component {
 
  state = {
    shift: "",
    open: false
  };

  async componentDidMount() {
   this.props.getUser();
   this.props.getEventsByType();
  }

  render() {
    const myshift = [];
    let mypersonal = [];
    const myEvents =
      this.props.events &&
      this.props.events.eventsByType &&
      this.props.events.eventsByType;
    myEvents.map(data => {
      if (data.type === "shift") {
        myshift.push(data);
      } else if (data.type === "personal") {
        mypersonal.push(data);
      }
    });
    myshift &&
      myshift.sort(
        (a, b) =>
          moment(a.start_time, "h:mm A") - moment(b.start_time, "h:mm A")
      );
    const role = this.props.auth.user && this.props.auth.user.role;
    const tab =
      role && role !== "family_member" ? +this.props.match.params.tab : 1;
    return (
      <div className="container">
        {role && (
          <div className="content box-shadow">
            <div className="content-header bg-white">
              <MainMenu />
              {role && role !== "family_member" ? (
                <h1>Events</h1>
              ) : (
                <h1>Personal Events</h1>
              )}
            </div>

            {myEvents && (
              <Tabs
                myEvents={myshift}
                pesonalEvents={mypersonal}
                tab={tab}
                role={role}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default 
  connect(
    mapStateToProps,
    { getEventsByType, getUser }
  )(Events)

