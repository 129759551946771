import React, { Component, Fragment } from "react";
import { PureComponent } from "react";
import { getEvents } from "../../actions/events";
import moment from "moment";
import { map } from "lodash";
import {Amplitude} from "@amplitude/react-amplitude";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const yesterday = moment(Date.now())
  .subtract(1, "days");

class MyComponent extends PureComponent {

  state = {
    events: [],
  };

  renderEvents = events => {
    let selectedStartData = moment(this.props._d).format("DD.MM.YYYY");
    let eventjsx = null;
    const curentMonth =
      moment(this.props._d).format("MM YYYY") ===
      moment(this.props.currentDate).format("MM YYYY");

    map(events, data => {
      const myday = moment(data.days).format("DD.MM.YYYY");

      if (myday === selectedStartData) {
        data.events.map(event => {
          if (event.type === "shift") {
            eventjsx = (
              <img
                src={require(`assets/img/${
                  event.name === "Off Day" ? "off-day" : event.start_period
                  }${
                  yesterday.isBefore(data.days) && curentMonth ? "" : "-passed"
                  }-icon.png`)}
                alt={`${event.start_period}`}
              />
            );
          }
        });
      }
    });
    return eventjsx;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props && this.props.events && this.state.events.length === 0){
      this.setState({events: this.props.events});
    }
  }

  render() {

    const now = moment().format("YYYY/MM/DD");
    const check = moment(this.props._d, "YYYY/MM/DD");
    const myday = moment(this.props._d);
    const day = check.format("D");
    let current = yesterday.isBefore(myday);

    let dayClassName = '';
    const currentMonth = moment(this.props._d).format("MM YYYY") === moment(this.props.currentDate).format("MM YYYY");

    if(currentMonth === true && current === true){
      dayClassName = '';
    }
    else {
      dayClassName = 'disabled-day';
    }

    return (
      <Amplitude>
        {({ logEvent }) => (
          <div
            className={`${check.format("YYYY/MM/DD") === now ? "current-day" : "day"} ${dayClassName}`}
          >
            {day}
            <div className="shift-day">
              {this.props.events &&
                this.renderEvents(this.props.events)}
            </div>
        </div>
        )}
      </Amplitude>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events.events.data,
  };
}

export default connect(
  mapStateToProps,
  {
    getEvents
  }
)(withRouter(MyComponent));
