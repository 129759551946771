import {
  GET_USER_DASHBOARD,
  GET_ADMIN_DASHBOARD,
  GET_SETTINGS,
  ADD_SETTINGS,
  EDIT_SETTINGS
} from "actions/types";

const INITIAL_STATE = {
  userDashboard: "",
  adminDashboard: "",
  settings: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_DASHBOARD:
      return {
        ...state,
        userDashboard: action.payload
      };
    case GET_ADMIN_DASHBOARD:
      return {
        ...state,
        adminDashboard: action.payload
      };
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case ADD_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };

    default:
      return state;
  }
}
