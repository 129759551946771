import React, { PureComponent } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import DatePicker from "./../../calendar/datePicker";

class Caption extends PureComponent {
  state = { isOpen: false };
  handleClick = () => {
    this.setState({
      isOpen: true,
      date: this.props.date,
      curentDate: moment()
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleSelect = time => {
    // this.setState({ date: time, isOpen: false });

    this.props.onDateSelect(moment(time));
    this.setState({ isOpen: false });
  };
  getDate = date => {
    this.props.onDateSelect(moment(date, "MMMM YYYY"));
    this.setState({ isOpen: false });
  };
  render() {
    const { date } = this.props;

    return (
      <div className="calendar-toolbar">
        <div className="calendar-menu pl-4">
          {/* <div className=""  /> */}
          <DatePicker date={date} getDate={this.getDate} />
        </div>
      </div>
    );
  }
}

export default withRouter(Caption);
