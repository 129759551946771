import { GET_CONNECTIONS, UPDATE_INVITE_USER } from "actions/types";

const INITIAL_STATE = {
  connections: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CONNECTIONS:
      return { ...state, connections: action.payload };
    case UPDATE_INVITE_USER:
      let newState = state;
      if (action.payload.data.is_deleted) {
        let index = state.connections.results
          .map(data => data.id)
          .indexOf(action.payload.data.id);
        newState.connections.results.splice(index, 1);
      }
      return { ...newState };
    default:
      return state;
  }
}
