import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  GET_NOTIFICATIONS,
  EDIT_NOTIFICATIONS,
  GET_NOTIFICATIONS_BY_DATE,
  GET_NOTIFICATIONS_COUNT,
  EDIT_SWITCH_REQUEST,
  EDIT_GIVEAWAY_REQUEST,
  GET_GIVEAWAY_REQUEST,
  GET_SWITCH_REQUEST,
  RESET_STATUS,
  GET_CURRENT_NOTIFICATION
} from "./types";

export const getNotificationsByDate = date => dispatch => {
  const url = `/notifications/notifications-by-date/${date}/`;
  return axiosToken.get(url).then(res => {
    return dispatch({
      type: GET_NOTIFICATIONS_BY_DATE,
      payload: res.data
    });
  });
};

export const getCurrentNotification = id => dispatch => {
  const url = `/notifications/${id}/`;
  return axiosToken.get(url).then(res => {
    return dispatch({
      type: GET_CURRENT_NOTIFICATION,
      payload: res.data
    });
  });
};

export const getNotificationsCount = () => dispatch => {
  const url = "/notifications/notifications-count/";
  return axiosToken.get(url).then(res => {
    return dispatch({
      type: GET_NOTIFICATIONS_COUNT,
      payload: res.data
    });
  });
};
export const getNotifications = () => dispatch => {
  const url = "/notifications/";
  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data
      });
    });
};

export const editNotifications = ( id, data) => dispatch => {
  const url = `/notifications/${id}/`;
  return axiosToken
    .patch(
      url,
      data
    
    )
    .then(res => {
      return dispatch({
        type: EDIT_NOTIFICATIONS,
        payload: res.data
      });
    });
};

export const editSwitchRequest = ( id, data) => dispatch => {
  const url = `/accept-switch-request/${id}/`;
  return axiosToken
    .patch(
      url,
      data
     
    )
    .then(res => {
      return res;
    })
    .then(
      dispatch({
        type: EDIT_SWITCH_REQUEST,
        payload: id
      })
    )
    .catch(err => {
      return err.response;
    });
};

export const editGiveAwayRequest = ( id, data) => dispatch => {
  const url = `/accept-giveaway-request/${id}/`;
  return axiosToken
    .patch(
      url,
      data
   
    )
    .then(res => {
      return res;
    })
    .then(
      dispatch({
        type: EDIT_GIVEAWAY_REQUEST,
        payload: id
      })
    )
    .catch(err => {
      return err.response;
    });
};

export const getGiveAwayRequest = ( id) => dispatch => {
  const url = `/accept-giveaway-request/${id}/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_GIVEAWAY_REQUEST,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const getSwitchRequest = ( id) => dispatch => {
  const url = `/accept-switch-request/${id}/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_SWITCH_REQUEST,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const resetStatus = payload => ({
  type: RESET_STATUS,
  payload
});
