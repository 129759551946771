import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import GiveawayStepper from "./GiveawayStepper";

class RequestGiveaway extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/team"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Request Giveaway</h1>
          </div>

          <GiveawayStepper />
        </div>
      </div>
    );
  }
}

export default RequestGiveaway;
