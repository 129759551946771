import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  addShiftEvent,
  getEventsByType,
  addEvent,
  addMention
} from "actions/events";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import { map } from "lodash";
import moment from "moment";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

class AddShift extends React.Component {
  state = {
    id: "",
    error: ""
  };
  componentDidMount() {
   
    this.props.getEventsByType("shift");
  }
  handleAddEvent = async () => {
    const date = moment(this.props.events.date).format("YYYY-MM-DD");
    const data = {
      event: this.state.id,
      date
    };
    if (!this.state.id) {
      this.setState({ error: "Please choose event" });
    } else {
      const res = await this.props.addEvent(data);
      if (res && res.payload && res.payload.status === 201) {
        this.props.history.push("/calendar");
      } else if (res && res.status && res.status === 400) {
        this.setState({
          error: res.data.non_field_errors[0]
        });
      }
    }
  };
  createButton = () => {
    if (this.props.events.eventsByType) {
      return (
        <Link
          to={"/add-shifts"}
          className="btn btn-link color-primary no-padding ml-auto"
          onClick={() => {
            this.props.addMention("createDate");
          }}
        >
          Create Shift
        </Link>
      );
    } else if (!this.props.events.eventsByType) {
      return (
        <Link
          to={"/add-shifts"}
          className="btn btn-link color-primary no-padding ml-auto"
          onClick={() => {
            this.props.addMention("createDate");
          }}
        >
          Create Shift
        </Link>
      );
    } else {
      return null;
    }
  };
  addFooter = () => {
    if (this.props.events.eventsByType) {
      return (
        <div className="content-footer">
          <Link to={"/calendar"} className="btn btn-link color-text-50">
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-white"
            onClick={this.handleAddEvent}
          >
            Add
          </button>
        </div>
      );
    } else {
      return null;
    }
  };
  render() {
    const currentDate = this.props.events.date;
    const shiftEvents =
      this.props.events.eventsByType &&
      this.props.events.eventsByType.filter(data => data.type === "shift");
    const sortedEvents =
      shiftEvents &&
      shiftEvents.sort(
        (a, b) =>
          moment(a.start_time, "hh:mm A") - moment(b.start_time, "hh:mm A")
      );
    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>

            <h1>Add Shift</h1>

            {this.createButton()}
          </div>
          {sortedEvents.length > 0 ? (
            <div className="content-body p-0">
              <div className="scrollbar-wrapper">
                <CustomScroll
                  allowOuterScroll
                  addScrolledClass
                  heightRelativeToParent="100%"
                >
                  <div className="list-wrap p-3 m-0">
                    <p className="color-text-50 fw-500 mb-3">
                      {moment(currentDate).format("MMMM Do, YYYY")}
                    </p>
                    <ul>
                      {map(sortedEvents, data => {
                        console.log(data.name);
                        return (
                          <li
                            onClick={() => {
                              this.setState({ id: data.id });
                            }}
                            key={data.id}
                          >
                            <label className="shift-checkbox">
                              <input type="radio" name="shift_type" />
                              <div className="card card-with-icon lg no-shadow">
                                {data.start_period && (
                                  <span className="icon">
                                    <img
                                      src={require(`assets/img/${
                                        data.name === "Off Day"
                                          ? "off-day"
                                          : data.start_period
                                      }-icon.png`)}
                                      alt="afternoon icon"
                                    />
                                  </span>
                                )}
                                <div className="card-body row">
                                  <p className="font-xmd fw-500 mb-1 ellipsis">
                                    {data.name === "Off Day" ? "Not Available" : data.name}
                                  </p>
                                  {data.name === "Off Day" ? (
                                    <p className="color-text-50 text-uppercase nowrap ml-3">24.00h</p>
                                  ) : (
                                    <p className="color-text-50 text-uppercase nowrap ml-3">
                                      {data.start_time.replace(/^0(?:0:0?)?/, '')}-{data.end_time.replace(/^0(?:0:0?)?/, '')}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </CustomScroll>
              </div>
              {this.state.error && (
                <div className="mx-4 mt-2">
                  <p className="error-msg with-icon">{this.state.error}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="content-body placeholder-content">
              <img src={require("assets/img/list.svg")} alt="list icon" />
              <p className="font-md">You have not created any shifts yet</p>
            </div>
          )}

          {this.addFooter()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default connect(
    mapStateToProps,
    { addShiftEvent, getEventsByType, addEvent, addMention }
  )(AddShift)