import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CardNumberMask from "../UI/cardNumberMask";
import DateMask from "../UI/dateMask";
import CvcMask from "../UI/cvcMask";
import {
  getProduct,
  getPlanById,
  addCard,
  createPayment,
  getCustomerSubscription,
  updatePayment
} from "actions/payment";
import _SplitForm from "./checkout";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import objectCreator from "../../helpers/objectCreator";

const SplitForm = injectStripe(_SplitForm);

class Payment extends React.Component {
  state = {
    value: "",
    error: ""
  };
  async componentDidMount() {
  
    this.props.getPlanById(this.props.match.params.id);
    this.props.getProduct();
    this.props.getCustomerSubscription();
  }
  handleChange = async data => {
    
    const cardData = {
      // Poxel live-i jamanak
      
      card_id: "tok_visa"
    };
    const plan = {
      plan: this.props.payment.plan && this.props.payment.plan.id
    };

    const currentId =
      this.props.payment.customerSubscription &&
      this.props.payment.customerSubscription.user;
    const method = this.props.payment.customerSubscription
      ? this.props.updatePayment
      : this.props.createPayment;
    const res =
      data.token &&
      data.token.id &&
      (await this.props.addCard( cardData));
    if (res && res.status === 201) {
      const createRes = await method( plan, currentId);
      if (
        (createRes && createRes.status && createRes.status === 200) ||
        createRes.status === 201
      ) {
        this.props.history.push("/calendar");
      }
      //  else if (
      //   // petqa poxel backic Haykoi het
      //   createRes.status === 400 &&
      //   createRes.data.non_field_errors[0] === "You already have subscription."
      // ) {
      //   this.props.history.push("/calendar");
      // }
      else {
        this.setState({
          error:
            createRes.data && createRes.data.non_field_errors
              ? createRes.data.non_field_errors[0]
              : "Something went wrong"
        });
      }
    } else if (
      res &&
      res.status === 500 &&
      res.data.search("No such token") !== -1
    ) {
      this.setState({ error: "Invalid curd credentials" });
    } else {
      this.setState({ error: "Something went wrong" });
    }
  };

  render() {
    const productsId =
      this.props.payment.products &&
      objectCreator(this.props.payment.products, "id");
    const plansId = this.props.payment.plan;
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header">
              <Link
                to={"/upgrade-membership"}
                className="btn-icon w-30 color-text mr-5"
              >
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>
              <h1>Payment</h1>
            </div>
            <StripeProvider apiKey="pk_test_9a5Ty9Idq3N7Gho9owg0S538">
              <Elements>
                <SplitForm
                  handleChange={this.handleChange}
                  error={this.state.error}
                  type={
                    productsId && productsId[this.props.match.params.id].name
                  }
                  plan={plansId}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    payment: state.payment
  };
}

export default connect(
    mapStateToProps,
    {
      getProduct,
      getPlanById,
      addCard,
      createPayment,
      getCustomerSubscription,
      updatePayment
    }
  )(Payment)
