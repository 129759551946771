import React from "react";

import ResponsiveModal from "../UI/modal";

import { addShiftEvent, getEventsByType } from "actions/events";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import icons from "helpers/icons";
import "react-custom-scroll/dist/customScroll.css";
import { Amplitude } from "@amplitude/react-amplitude";
import defaultItems from './../../constatnts/icons'

class AddEvents extends React.Component {
  state = {
    personalEvents: {},
    openAddOtherEventPopup: false,
    otherValue: this.props.otherValue,
    error: "",
  };

  onOpenAddOtherEventPopup = () => {
    this.setState({
      openAddOtherEventPopup: true,
      otherValue: ""
    });
  };

  componentDidMount = async () => {
   
    await this.props.getEventsByType( "personal");
  };

  onPopupClose = () => {
    this.setState({
      openAddOtherEventPopup: false
    });
  };

  handleOtherEvent = async currentEvents => {
    if (!this.state.otherValue) {
      this.setState({ otherValue: "", error: "This field is mandatory" });
    } else {
      const res = await this.props.addShiftEvent( {
        name: this.state.otherValue,
        type: "personal"
      });
      if (res.payload && res.payload.status === 201) {
        await this.props.getEventsByType( "personal");
        await this.setState({ otherValue: "" });
        this.props.onClose();
      }
    }
  };
  handleOtherValue = (e, currentEvents) => {
    let obj = {};
    Object.values(currentEvents).map(
      data => (obj[data.toLowerCase()] = data.toLowerCase())
    );
    if (!e.target.value) {
      this.setState({ otherValue: "", error: "This field is mandatory" });
    } else {
      this.setState({
        otherValue: e.target.value,
        error:
          obj[e.target.value.replace(" ", "-").toLowerCase()] ===
          e.target.value.replace(" ", "-").toLowerCase()
            ? "An event with this name already exists."
            : ""
      });
    }
  };
  onClose = () => {
    this.setState({
      otherValue: "",
      error: ""
    });
    this.props.onClose();
  };
  componentWillUnmount() {
    this.setState({ otherValue: "" });
  }
  render() {
    const PersonalEventsNames = Object.values(icons, data => data);
    const currentEvents =
      this.props.events.eventsByType &&
      this.props.events.eventsByType.reduce(
        (arr, elem) => ({
          ...arr,
          [elem.name.replace(" ", "-")]: elem.name.replace(" ", "-")
        }),
        {}
      );
    return (
      <Amplitude>
        {({ logEvent }) => (
          <ResponsiveModal open={this.props.open} onClose={this.onClose}>
            <div className="modal-body">
              <div className="single-view-header with-label-icon">
                <span className="single-view-label bg-purple">
                  <i className="icon-other font-xl color-white box-30" />
                </span>
                <div>
                  <p className="font-lg fw-500 mb-2">Personal Event</p>
                  <p className="color-text-70 text-capitalize">Other</p>
                </div>
              </div>
              <div className="single-view-body">
                <div
                  className={
                    this.state.error
                      ? "form-field mt-6 has-error"
                      : "form-field mt-6"
                  }
                >
                  <div className="form-input">
                    <input
                      type="text"
                      placeholder="Event Name"
                      className="text-capitalize"
                      value={this.state.otherValue}
                      onChange={e => this.handleOtherValue(e, currentEvents)}
                    />
                    <span className="border" />
                  </div>
                </div>
                {this.state.error && (
                  <p className="error-msg">{this.state.error}</p>
                )}
              </div>

              <div className="single-view-footer justify-around">
                <button
                  type="button"
                  className="btn btn-link color-text-50"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={() => this.handleOtherEvent(currentEvents)}
                  disabled={this.state.alreadyHave}
                >
                  Add
                </button>
              </div>
            </div>
          </ResponsiveModal>
        )}
      </Amplitude>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default connect(
    mapStateToProps,
    { addShiftEvent, getEventsByType }
  )(AddEvents)

