import React, { Component } from "react";
import Picker from "pickerjs";
import moment from "moment";

import "pickerjs/dist/picker.css";

class TimePicker extends Component {
  state = { picker: "" };
  componentDidMount() {

    const a = new Picker(
      document.querySelector(`.js-time-picker-${this.props.period}`),
      {
        format: "HH:mm ",
        headers: true,
        text: {
          title: "",
          confirm: "Select"
        },
        pick: e => {
          this.props.getTime(a.getDate(true), this.props.period);
          if (this.props.period === "Start") {
            this.props.handleSelect(a.getDate(true));
          } else if (this.props.period === "End") {
            this.props.handleSelect(a.getDate(true));
          }
        }
      }
    );
  }

  render() {
    return (
      <div className={`js-time-picker-${this.props.period}`}>
        <button type="button" className={`btn btn-white `}>
          <i className="icon-clock" />
          <span>{this.props.period} Time</span>
        </button>
        <p className="selected-time">
          {this.props.time && moment(this.props.time, "h:mm A").format("HH:mm")}
        </p>
      </div>
    );
  }
}

export default TimePicker;
