import React, { Component } from "react";
import Modal from "react-responsive-modal";

export default class ResponsiveModal extends Component {
  onCloseModal = () => {
    this.props.onClose();
  };
  render() {
    const { open } = this.props;
    return (
      <div>
        <Modal
          classNames={{
            overlay: "modal-overlay",
            modal: "modal",
            closeButton: "modal-close-btn"
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
         {this.props.children}
        </Modal>
      </div>
    );
  }
}
