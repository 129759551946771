import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withLastLocation } from "react-router-last-location";
import InviteStepper from "./InviteStepper";

class RequestInvite extends React.Component {
  render() {
    const lastLocation =
      this.props.lastLocation && this.props.lastLocation.pathname;

    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link onClick={() => {sessionStorage.removeItem("email");}}
              to={
                {
                  pathname: this.props.location.state ? `${this.props.location.state.url}` : '/calendar',
                  state: {
                    url: "/request-invite",
                    lastUrl: this.props.location.state ? this.props.location.state.lastUrl : '/calendar'
                  }
                }
              }
              className="btn-icon w-30 color-text mr-5"
            >
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Send Invite</h1>
          </div>

          <InviteStepper lastLocation={lastLocation} />
        </div>
      </div>
    );
  }
}

export default withLastLocation(RequestInvite);
