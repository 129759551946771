import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import HomeMenu from "./UI/HomeMenu";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";
import Flip from "react-reveal/Flip";
import moment from "moment";

class Home extends Component {
  constructor(props) {
    super(props);
    this.howItWorksSection = null;
  }

  scrollToHowItWorks = e => {
    e.preventDefault();
    window.scrollTo({
      top: this.howItWorksSection.offsetTop,
      behavior: "smooth"
    });
  };
  scrollToTop = e => {
    e.preventDefault();
    window.scrollTo({
      top: null,
      behavior: "smooth"
    });
  };

  reinitSlider() {
    this.refs.slick&&this.refs.slick.innerSlider&& this.refs.slick.innerSlider.onWindowResized();
  }

  componentDidMount() {
    window.addEventListener("resize", this.reinitSlider.bind(this));
  }

  render() {
    var pricingSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    var reviewsSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };

    return (
      <div className="overflow-x-hidden">
        <header className="header">
          <div className="wrapper">
            <HomeMenu />

            <a href="/" onClick={this.scrollToTop} className="logo">
              <img src={require("assets/img/logo-dark.svg")} alt="Turno" />
            </a>

            <ul className="menu-desktop">
              <li>
                <a onClick={this.scrollToHowItWorks}>How It Works</a>
              </li>
              <li>
                <a href="mailto:info@turnoapp.com">Contact Us</a>
              </li>
            </ul>

            <Link to={"/sign-in"} className="btn btn-link btn-link-desktop">
              Sign In
            </Link>

            <Link to={"/sign-up"} className="btn btn-white btn-desktop">
              Get Started
            </Link>
          </div>
        </header>

        <section className="banner banner-big">
          <div className="wrapper">
            <Fade left cascade>
              <div className="text">
                <h1 className="h2">
                  Improve your <br />{" "}
                  <span className="color-primary">work</span>{" "}
                  <span className="line" />{" "}
                  <span className="color-blue-light">life</span> balance
                </h1>
                <p>
                  Take control of your shifts. Be proactive, stay flexible and
                  prioritize what matters most
                </p>
                <Link to={"/sign-up"} className="btn btn-white">
                  Try it Free
                </Link>
              </div>
            </Fade>
            <div className="images">
              <Zoom cascade>
                <img
                  src={require("assets/img/notification-img.png")}
                  alt="Notification screenshot"
                  className="small-img"
                />
                <img
                  src={require("assets/img/calendar-img.png")}
                  alt="Calendar screenshot"
                  className="big-img"
                />
              </Zoom>
            </div>
            <Slide right>
              <span className="bg-arrow" />
            </Slide>
          </div>
        </section>

        <section
          className="how-it-works"
          id="how-it-works"
          ref={ref => (this.howItWorksSection = ref)}
        >
          <div className="wrapper">
            <h2 className="h2 text-center">How It Works</h2>
            <ul>
              <li>
                <Slide right cascade>
                  <div className="text">
                    <div>
                      <span className="number">1</span>
                      <div>
                        <h3 className="h3">Create shifts</h3>
                        <p>
                          Create shifts and organize your work calendar. Store
                          every shift in the cloud so you, your family, and your
                          team can access them any time and on any device.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Zoom right>
                  <div className="img">
                    <img
                      src={require("assets/img/create-shifts.svg")}
                      alt="Create Shifts"
                    />
                  </div>
                </Zoom>
              </li>

              <li>
                <Zoom right>
                  <div className="img">
                    <img
                      src={require("assets/img/share-calendar.svg")}
                      alt="Share your calendar"
                    />
                  </div>
                </Zoom>
                <Slide right cascade>
                  <div className="text">
                    <div>
                      <span className="number">2</span>
                      <div>
                        <h3 className="h3">Share your calendar</h3>
                        <p>
                          Share your calendar with team members, family, and
                          friends, instantly and in real time. Keep everyone on
                          the same page so you can be there for the events that
                          matter to you.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </li>

              <li>
                <Slide right cascade>
                  <div className="text">
                    <div>
                      <span className="number">3</span>
                      <div>
                        <h3 className="h3">Switch shifts</h3>
                        <p>
                          Switch shifts in seconds. View your team member’s work
                          calendars, choose shifts to switch or give away, and get
                          your manager or team lead to approve the change – all in
                          one place.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Zoom right>
                  <div className="img">
                    <img
                      src={require("assets/img/switch-shifts.svg")}
                      alt="Switch shifts"
                    />
                  </div>
                </Zoom>
              </li>

              <li>
                <Zoom right>
                  <div className="img">
                    <img
                      src={require("assets/img/connect-calendar.svg")}
                      alt="Connect your calendars"
                    />
                  </div>
                </Zoom>
                <Slide right cascade>
                  <div className="text">
                    <div>
                      <span className="number">4</span>
                      <div>
                        <h3 className="h3">Connect your calendars</h3>
                        <p>
                          Merge your personal calendar and your work one and take
                          control of your time. Prioritize what matters most and
                          get a schedule that suits your life.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </li>
            </ul>
          </div>
        </section>

        <section className="banner banner-small">
          <div className="wrapper">
            <div className="images">
              <Slide left>
                <img
                  src={require("assets/img/connections-img.png")}
                  alt="Connections screenshot"
                  className="small-img"
                />
                <img
                  src={require("assets/img/events-img.png")}
                  alt="Events screenshot"
                  className="big-img"
                />
              </Slide>
            </div>
            <Fade right cascade>
              <div className="text">
                <h2 className="h2">
                  Improve your <br />{" "}
                  <span className="color-primary">work</span>{" "}
                  <span className="line" />{" "}
                  <span className="color-blue-light">life</span> balance
                </h2>
                <p>
                  Take control of your shifts. Be proactive, stay flexible and
                  prioritize what matters most
                </p>
                <Link to={"/sign-up"} className="btn btn-white">
                  Try it Free
                </Link>
              </div>
            </Fade>
            <Slide left>
              <span className="bg-arrow" />
            </Slide>
          </div>
        </section>

        <section className="pricing">
          <div className="wrapper">
            <h2 className="h2 text-center">Pricing</h2>
            <div className="pricing-plans">
              <Slider ref="slick" {...pricingSettings}>
                <div className="plan">
                  <div className="pricing-content">
                    <div className="pricing-item blue">
                      <span>STANDARD</span>
                      <p>
                        $ 0.99 <span>/ month</span>
                      </p>
                    </div>
                  </div>

                  <div className="plan-details">
                    <p className="fw-700 font-md color-primary mt-3">
                      7-Day Free Trial
                    </p>
                    <ul className="list-with-icons">
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Organize your work schedule
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited shifts</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited events</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited notes</span>
                      </li>
                    </ul>
                    <p className="color-text-50 pt-3 text-center">
                      $ 0.99 USD billed monthly
                    </p>
                    <Link to={"/sign-up"} className="btn btn-primary lg mt-6">
                      Get Started
                    </Link>
                  </div>
                </div>

                <div className="plan">
                  <div className="pricing-content">
                    <div className="pricing-item pink">
                      <span>PREMIUM</span>
                      <p>
                        $ 1.99 <span>/ month</span>{" "}
                      </p>
                    </div>
                  </div>

                  <div className="plan-details">
                    <p className="fw-700 font-md color-primary mt-3">
                      7-Day Free Trial
                    </p>
                    <ul className="list-with-icons">
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Organize your work schedule
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited shifts</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited events</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited notes</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited switches</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited giveaways</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Automatic switch suggestions
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Submit requests for manager approval
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Share work schedule with friends and family
                        </span>
                      </li>
                    </ul>
                    <p className="color-text-50 pt-3 text-center">
                      $ 23.99 USD billed yearly
                    </p>
                    <Link to={"/sign-up"} className="btn btn-pink lg mt-6">
                      Get Started
                    </Link>
                  </div>
                </div>

                <div className="plan">
                  <div className="pricing-content">
                    <div className="pricing-item blue-light">
                      <span>PREMIUM</span>
                      <p>
                        $ 3.99 <span>/ month</span>{" "}
                      </p>
                    </div>
                  </div>

                  <div className="plan-details">
                    <p className="fw-700 font-md color-primary mt-3">
                      7-Day Free Trial
                    </p>
                    <ul className="list-with-icons">
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Organize your work schedule
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited shifts</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited events</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited notes</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited switches</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">Unlimited giveaways</span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Automatic switch suggestions
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Submit requests for manager approval
                        </span>
                      </li>
                      <li>
                        <i className="icon-check font-xsm color-primary" />
                        <span className="fw-500">
                          Share work schedule with friends and family
                        </span>
                      </li>
                    </ul>
                    <p className="color-text-50 pt-3 text-center">
                      $ 3.99 USD billed monthly
                    </p>
                    <Link to={"/sign-up"} className="btn btn-blue-light lg mt-6">
                      Get Started
                    </Link>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <section className="reviews">
          <div className="wrapper">
            <Flip top>
              <div className="reviews-slider">
                <Slider {...reviewsSettings}>
                  <div>
                    <div className="slide">
                      <div className="quote">
                        <img
                          src={require("assets/img/quotes.svg")}
                          alt="Quotes"
                        />
                      </div>
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${require("assets/img/denise_silva.png")})`
                        }}
                      />
                      <div className="review-text">
                        <p className="name">Denise</p>
                        <p className="position">Respiratory Therapist</p>
                        <div className="comment">
                          <pre>
                              I love Turno because it takes all the guesswork out of who's working when. The shift switching process is simple and easy to use. If you work shift work you need this app!
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </Flip>
          </div>
        </section>

        <footer className="footer">
          <div className="wrapper">
            <div className="footer-content">
              <span className="logo-img">
                <img src={require("assets/img/logo-dark.svg")} alt="Turno" />
              </span>
              <p className="info">
                <a
                  href="mailto:info@turnoapp.com"
                  className="btn-link color-blue"
                >
                  info@turnoapp.com
                </a>
              </p>
              <div className="social">
                <p className="font-xmd color-blue-50 fw-500 mb-3">
                  Follow us on
                </p>
                <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/TurnoInc">
                      <img
                        src={require("assets/img/facebook.svg")}
                        alt="Facebook icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/turnoinc">
                      <img
                        src={require("assets/img/linkedin.svg")}
                        alt="Linkedin icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/TurnoInc">
                      <img
                        src={require("assets/img/twitter.svg")}
                        alt="Twitter icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyright">
            <div className="wrapper">
              <p className="color-blue-50 font-md">
                &copy; {`${moment().format("YYYY")}`} Turno Inc.. &nbsp;|&nbsp; Made with 
                <span className="color-warning opacity-50"> ❤ </span> in Toronto
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Home;
