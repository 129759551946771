import React from "react";
import { Link } from "react-router-dom";
import { getUser } from "actions/auth";
import { addManager } from "actions/manager";
import { connect } from "react-redux";
import moment from "moment";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumber from "../UI/PhoneNumber";
import DatePicker from "../UI/fullDatePicker";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import loadImage from "blueimp-load-image";
import queryString from "query-string";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class AddManager extends React.Component {
  state = {
    isOpen: false,
    picture: "",
    mobile_number: "",
    birthday: "",
    phoneNumberError: "",
    title: "none",
    redirectToInvite: false,
    redirectToSwitch: false,
  };
  
  async componentDidMount() {
    window.scrollTo(0, 0);
    const parsed = queryString.parse(this.props.location.search);
    if(Boolean(parsed.comesfrominvite) === true) {
      this.setState({redirectToInvite: true});
    } else if(Boolean(parsed.comesfromswitch) === true){
      this.setState({redirectToSwitch: true});
    }

    this.props.setValues({
      ...this.props.values,
      picture: "",
      redirectToInvite: Boolean(parsed.comesfrominvite),
      redirectToSwitch: Boolean(parsed.comesfromswitch),
    });
  }
  checkNumber = () => {
    const value = this.state.mobile_number;
    !value && this.setState({ phoneNumberError: "Phone number required" });
    value &&
      !isValidPhoneNumber(value) &&
      this.props.setValues({
        ...this.props.values,
        phoneNumberError: "Invalid phone number",
        mobile_number: value
      });
  };
  handleNumber = value => {
    this.props.setValues({
      ...this.props.values,
      mobile_number: value
    });
    this.setState({ mobile_number: value, phoneNumberError: "" });
  };
  handleClick = () => {
    this.setState({
      isOpen: true
    });
  };

  upload = async e => {
    const f = e.target.files[0];
    loadImage(
      f,
      canvas => {
        canvas.toBlob(blob => {
          var reader = new FileReader();
          reader.onloadend = () => {
            const dataURL = reader.result;
            this.setState({ picture: dataURL });
            this.props.setValues({
              ...this.props.values,
              picture: dataURL
            });
          };

          reader.readAsDataURL(blob);
        });
      },
      {
        canvas: true,
        orientation: true,
        maxMetaDataSize: 262144,
        maxWidth: 600,
        maxHeight: 300,
        minWidth: 100,
        minHeight: 50
      }
    );
  };
  handleChange = value => {
    this.setState({
      mobile_number: value
    });
    this.props.setValues({
      ...this.props.values,
      mobile_number: value
    });
  };

  handleChangeTitle = e => {
    this.setState({ title: e.target.value });
    this.props.setValues({
      ...this.props.values,
      title: e.target.value
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
    if(this.state.redirectToInvite === true){
      this.props.history.push("/request-invite");
    }
    if(this.state.redirectToSwitch === true){
      this.props.history.push("/request-switch");
    }
  };
  getDate = date => {
    this.setState({ birthday: date });
    this.props.setValues({
      ...this.props.values,
      birthday: date
    });
  };
  handleOnClick = e => {
    e.preventDefault();
    if(this.state.redirectToInvite === true){
      this.props.history.push("/request-invite");
    }
    else if(this.state.redirectToSwitch === true){
      this.props.history.push("/request-switch");
    }
    else {
      if (localStorage.getItem("firstLogin") === "true") {
        localStorage.removeItem("firstLogin");
        this.props.history.push("/calendar");
      } else {
        this.props.history.push("/manager-list");
      }
    }
  };
  render() {
    const { title } = this.state;
    const {
      errors,
      touched,
      handleChange,
      handleSubmit,
      handleBlur,
      values
    } = this.props;

    return (
      <JssProvider generateClassName={generateClassName}>
        <section className="content-center">
          <div className="container">
            <div className="content box-shadow justify-start">
              <div className="content-header bg-white flex-wrap">
                <a
                  href="/"
                  onClick={this.handleOnClick}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </a>
                <h1>Add Manager</h1>

                <div className="content-header-bottom bg-white">
                  <div className="user-img">
                    {!this.state.picture ? (
                      <i className="icon-user" />
                    ) : (
                      // <img className="img" alt="" src={this.state.picture} />
                      <span
                        className="img"
                        style={{
                          backgroundImage: `url(${this.state.picture})`
                        }}
                      />
                    )}
                  </div>
                  <label className="btn btn-link btn-upload color-primary no-padding ml-5">
                    <input type="file" onChange={this.upload} />
                    <span className="font-md">Upload Image</span>
                  </label>
                </div>
              </div>

              <Form className="flex dir-col grow-1">
                <div className="content-body pb-0">
                  <div className="profile-info">
                    <div
                      className={
                        touched.first_name && errors.first_name
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">First Name</p>
                      <div className="form-input">
                        <Field
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.first_name &&
                        errors.first_name && (
                          <p className="error-msg">{errors.first_name}</p>
                        )}
                    </div>

                    <div
                      className={
                        touched.last_name && errors.last_name
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">Last Name</p>
                      <div className="form-input">
                        <Field
                          type="text"
                          name="last_name"
                          placeholder="Enter Last Name"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.last_name &&
                        errors.last_name && (
                          <p className="error-msg">{errors.last_name}</p>
                        )}
                    </div>
                    <div className="form-field">
                      <p className="form-label">Title</p>
                      <div
                        className={
                          title === "none"
                            ? "form-select not-selected"
                            : "form-select"
                        }
                      >
                        <FormControl>
                          <Select
                            value={this.state.title}
                            onChange={e => this.handleChangeTitle(e)}
                            inputProps={{
                              name: "title"
                            }}
                          >
                            <MenuItem value="none" disabled>
                              Choose Title
                            </MenuItem>
                            <MenuItem value="manager">Manager</MenuItem>
                            <MenuItem value="team_lead">Team lead</MenuItem>
                          </Select>
                        </FormControl>
                        {touched.title &&
                          errors.title && (
                            <p className="error-msg with-icon">
                              {errors.title}
                            </p>
                          )}
                        {/* <p className="error-msg with-icon">error message</p> */}
                      </div>
                    </div>

                    <div
                      className={
                        errors.mobile_number
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">Phone</p>
                      <div className="form-input">
                        <PhoneNumber
                          handleNumber={this.handleNumber}
                          value={this.state.mobile_number}
                        />
                      </div>
                      {touched.mobile_number &&
                        errors.mobile_number && (
                          <p className="error-msg">{errors.mobile_number}</p>
                        )}
                    </div>
                    <div
                      className={
                        touched.email && errors.email
                          ? "form-field has-error"
                          : "form-field"
                      }
                    >
                      <p className="form-label">Email</p>
                      <div className="form-input">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter Email Address"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.email &&
                        errors.email && (
                          <p className="error-msg">{errors.email}</p>
                        )}
                    </div>

                    <div className="form-field">
                      <p className="form-label">Birthday</p>

                      {/* <p className="error-msg">{errors.birthday}</p> */}

                      <DatePicker
                        getDate={this.getDate}
                        onKey={this.onKey}
                        birthday={this.state.birthday}
                      />
                    </div>

                    <div className="form-field">
                      <p className="form-label">Gender</p>
                      <div className="flex align-center mt-2">
                        <div className="radio-wrap mr-8">
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className="icon" />
                            <span className="text">Female</span>
                          </label>
                        </div>
                        <div className="radio-wrap">
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className="icon" />
                            <span className="text">Male</span>
                          </label>
                        </div>
                      </div>
                      {errors &&
                        errors.gender && (
                          <p className="error-msg with-icon">
                            {errors.gender}
                          </p>
                        )}
                      {/*  */}
                    </div>
                  </div>
                </div>

                <div className="content-footer">
                  <a
                    href="/"
                    onClick={this.handleOnClick}
                    className="btn btn-link color-text-50"
                  >
                    Cancel
                  </a>
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </JssProvider>
    );
  }
}

const AddManagerFormik = withFormik({
  mapPropsToValues(props) {
    return {
      first_name: props.first_name || "",
      last_name: props.last_name || "",
      email: props.email || "",
      mobile_number: props.mobile_number || "",
      title: props.title || "",
      birthday: props.birthday || "",
      gender: "unTouched",
      picture: props.picture || "",
      redirectToInvite: props.redirectToInvite,
    };
  },
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is mandatory."),
    last_name: Yup.string().required("This field is mandatory."),
    email: Yup.string()
      .email("Please provide a valid email.")
      .required("This field is mandatory."),
    title: Yup.string().required("Please choose a title.")
  }),
  async handleSubmit(values,  { props, setErrors }) {
    if (values.phoneNumberError === "Invalid phone number") {
      setErrors({ mobile_number: values.phoneNumberError });
    }
    if (values.gender === "unTouched") {
      setErrors({ gender: " Please select one of the choices." });
    } else {
      const userData = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        title: values.title,
        birthday: values.birthday
          ? moment(values.birthday).format("YYYY-MM-DD")
          : "",
        gender: values.gender,
        picture: values.picture,
        mobile_number: values.mobile_number
      };
      Object.keys(userData).map(data => {
        if (userData[data] === "" || userData[data] === "none")
          delete userData[data];
      });

      const res = await props.addManager(userData);
      if (res && res.payload && res.payload.status === 201) {
        const firstLogin = localStorage.getItem("firstLogin");
        if(values.redirectToInvite === true){
          props.history.push("/request-invite?team_member=true");
        }
        else if(values.redirectToSwitch === true){
          props.history.push("/request-switch");
        }
        else {
          if (firstLogin === "true") {
            localStorage.removeItem("firstLogin");
            props.history.push("/calendar");
          } else {
            props.history.push("/manager-list");
          }
        }
      }
      if (res && res.status === 400) {
        setErrors({ email: res.data.non_field_errors[0] });
      }
    }
  }
})(AddManager);

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
    mapStateToProps,
    { getUser, addManager }
  )(AddManagerFormik)

