import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { managerApprove } from "actions/auth";

class ManagerApprove extends React.Component {
  componentDidMount() {
    const slug = this.props.match.params.slug;
    const status = this.props.match.params.status;
    this.props.managerApprove(slug, status);
  }
  render() {
    const status = this.props.match.params.status;
    return (
      <section className="bg-primary content-center">
        <div className="container">
          <div className="content">
            <div className="info-content with-button color-white">
              <Link to={"/"} className="logo">
                <img src={require("assets/img/logo.svg")} alt="Turno" />
              </Link>

              <h1 className="no-transform mb-4">
                {status === "accepted"
                  ? "	Switch approved!"
                  : "Switch rejected!"}
              </h1>

              <p className="color-white-70 text-center">
                {this.props.requestStatus &&
                  this.props.requestStatus.data.message}
              </p>

              <Link to={"/"} className="btn btn-white mt-10">
                Go to homepage
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    requestStatus: state.user.managerApprove
  };
}

export default connect(
  mapStateToProps,
  { managerApprove }
)(ManagerApprove);

// export default ManagerApprove;
