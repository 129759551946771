import React, { Component, Fragment } from "react";
import { addFeedback } from "actions/feedback";
import { connect } from "react-redux";

import MainMenu from "../UI/MainMenu";
import InputBase from "@material-ui/core/InputBase";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { Amplitude } from "@amplitude/react-amplitude";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class Feedback extends Component {
  state = {
    text: "",
    feedback: true,
    success: false,
    feedbackError: false,
    textError: ""
  };
  handleChange = async e => {
    await this.setState({ text: e.target.value });
    if (this.state.text.length > 250) {
      this.setState({
        textError: "The text entered exceeds the maximum length."
      });
    } else {
      this.setState({ textError: "" });
    }
  };
  handleSubmit = async () => {
   
    const propsUser = this.props.user.user;
    const feedbackData = {
      user: {
        first_name: propsUser.first_name,
        last_name: propsUser.last_name,
        role: propsUser.role
      },
      text: this.state.text
    };
    if (!this.state.text) {
      this.setState({ textError: "This field is mandatory." });
    } else {
      const res = await this.props.addFeedback(feedbackData);
      if (res && res.payload && res.payload.status === 201) {
        this.setState({ success: true, feedback: false });
      }
      // if (res.status === 401) {
      //   this.setState({ feedbackError: true, feedback: false });
      // }
      else {
        this.setState({ feedbackError: true, feedback: false });
      }
    }
  };
  render() {
    return (
      <Amplitude>
        {({ logEvent }) => (
          <JssProvider generateClassName={generateClassName}>
            <section className="content-center">
              <div className="container">
                <div className="content box-shadow justify-start">
                  <div className="content-header bg-primary flex-wrap">
                    <MainMenu />
                    <h1 className="color-white">Feedback</h1>
                    <div className="content-header-bottom bg-primary">
                      <i className="icon-feedback color-white-30" />
                    </div>
                  </div>

                  {this.state.feedback && (
                    <Fragment>
                      <div className="content-body feedback-content">
                        <p className="color-text-50 fw-500 mb-4">
                          Leave a Feedback
                        </p>
                        <div
                          className={
                            this.state.textError
                              ? "form-field has-error"
                              : "form-field"
                          }
                        >
                          <div className="form-textarea">
                            <InputBase
                              multiline
                              placeholder="Enter Your Feedback"
                              rowsMax={6}
                              value={this.state.text}
                              onChange={e => this.handleChange(e)}
                            />
                            <span className="border" />
                          </div>
                          {this.state.textError && (
                            <p className="error-msg with-icon">
                              {this.state.textError}
                            </p>
                          )}
                          <p className="info-text">
                            The maximum length of a feedback is 250 characters.
                          </p>
                        </div>

                        <a
                          href="https://www.facebook.com/messages/t/276040549885946"
                          onClick={() => {
                            logEvent("click Join the conversation on Facebook");
                          }}
                          className="btn btn-link no-padding color-primary share-link"
                        >
                          <img
                            src={require("assets/img/messenger.png")}
                            alt="Messenger icon"
                          />
                          <span className="fw-500">
                            Join the conversation on Facebook
                          </span>
                        </a>
                      </div>

                      <div className="content-footer justify-end">
                        <button
                          type="button"
                          className="btn btn-white mr-6"
                          onClick={() => {
                            logEvent("click Send FeedBack");
                            this.handleSubmit();
                          }}
                        >
                          Send
                        </button>
                      </div>
                    </Fragment>
                  )}

                  {this.state.success && (
                    <div className="content-body placeholder-content">
                      <img
                        src={require("assets/img/success.svg")}
                        alt="success icon"
                      />
                      <p className="font-md">
                        You feedback was successfully submitted.
                      </p>
                    </div>
                  )}

                  {this.state.feedbackError && (
                    <div className="content-body placeholder-content">
                      <img
                        src={require("assets/img/warning.svg")}
                        alt="warning icon"
                      />
                      <p className="font-md">
                        There was a problem while submitting your feedback.
                      </p>
                      <button
                        className="btn-link color-primary fw-700 mt-5"
                        onClick={() =>
                          this.setState({ feedback: true, text: "" })
                        }
                      >
                        Try again
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </JssProvider>
        )}
      </Amplitude>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
    mapStateToProps,
    { addFeedback }
  )(Feedback)

