import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  addShiftEvent,
  getEventsByType,
  editEvent,
  addEvent,
  getEventById
} from "actions/events";
import { connect } from "react-redux";
import moment from "moment";
import AddEventModal from "./../events/AddEvents";
import Stepper from "./Stepper";

class AddEvent extends React.Component {
  state = {
    id: "",
    icon_name: "",
    start_time: "",
    end_time: "",
    disabled: "",
    error: "",
    open: false,
    name:"",
  };
  openModal = () => {
    this.setState(prevstate => {
      return { open: !prevstate.open };
    });
  };
  onCloseModal = () => {
    this.setState(prevstate => {
      return { open: !prevstate.open };
    });
  };
  async componentDidMount() {
    const res = await this.props.getEventsByType();
  }

  onStartTimeSelect = start_time => {
    this.setState({ start_time, error: "" });
  };
  onEndTimeSelect = end_time => {
    this.setState({ end_time, error: "" });
  };
  handleChange = value => {
    this.setState({ icon_name: value });
  };

  onCreateShift = async () => {
    /*CALENDAR DATE*/
    const date = moment(this.props.events.date).format("YYYY-MM-DD");

    /*GET MODEl EVENT*/
    const modelEvent = await this.props.getEventById(
      this.state.id
    );

    if(modelEvent.payload) {
      let newPersonalEvent = {
        type: "personal",
        model: false, /*condition if needed*/
        name: this.state.icon_name || modelEvent.payload.name
      };

      if (!newPersonalEvent.icon_name) {
        delete newPersonalEvent.icon_name;
      }

      if(this.state.start_time || this.state.end_time){
        if(!this.state.start_time){
          this.setState({error: "Please Select Start Time"});
          return false;
        } else {
          newPersonalEvent.start_time = this.state.start_time;
        }
        if(!this.state.end_time){
          this.setState({error: "Please Select End Time"});
          return false;
        } else {
          newPersonalEvent.end_time = this.state.end_time;
        }
      }

      /*ADD NEW PERSONAL EVENT*/
      const addPersonalEvent = await this.props.addShiftEvent(
        newPersonalEvent,
      );
      if (addPersonalEvent.payload.data) {
        const getNewEvent = await this.props.getEventById(
          addPersonalEvent.payload.data.id
        );
        if (getNewEvent.payload) {
          const createData = {
            event: getNewEvent.payload.id,
            date
          };
          /*ADD EVENT TO CALENDAR*/
          const response = await this.props.addEvent(
            createData,
          );
          response &&
          response.payload.status === 201 &&
          this.props.history.push("/calendar");
        }
        if (getNewEvent.payload.status === 201) {
          if (this.props.events.mention) {
            this.props.history.push("/add-shift");
          } else {
            this.props.history.push("/events/1");
          }
        }
      }
    }
  };
  onhandleIconId = (id) => {
    this.setState({ id, disabled: "cancel"});
  };
  render() {
    let mypersonal = [];

    const myEvents = this.props.events.eventsByType;
    myEvents.map(data => {
      if (data.type === "personal") {
        mypersonal.push(data);
      }
    });

    let currentEvents = mypersonal.reduce(
      (arr, elem) => ({
        ...arr,
        [elem.name]: elem.id
      }),
      {}
    );
    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Add Event</h1>

            <button
              type="button"
              className="btn btn-link color-primary no-padding ml-auto"
              onClick={this.openModal}
            >
              Create event
            </button>
          </div>

          <Stepper
            personalEvents={currentEvents}
            handleChange={this.handleChange}
            onStartTimeSelect={this.onStartTimeSelect}
            onEndTimeSelect={this.onEndTimeSelect}
            onCreateShift={this.onCreateShift}
            onhandleIconId={this.onhandleIconId}
            disabled={this.state.disabled}
            error={this.state.error}
          />
          <AddEventModal open={this.state.open} onClose={this.onCloseModal} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default connect(
  mapStateToProps,
  { addShiftEvent, getEventsByType, editEvent, addEvent, getEventById }
)(AddEvent)
