import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CardNumberMask from "../UI/cardNumberMask";
import DateMask from "../UI/dateMask";
import CvcMask from "../UI/cvcMask";
import {
  getProduct,
  getPlanById,
  addCard,
  createPayment,
  getCustomerSubscription,
  updatePayment
} from "actions/payment";
import _SplitForm from "../upgrade-membership/checkout";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import objectCreator from "../../helpers/objectCreator";
import MainMenu from "../UI/MainMenu";

const SplitForm = injectStripe(_SplitForm);

class ManagePayment extends React.Component {
  state = {
    value: "",
    error: ""
  };
  async componentDidMount() {

  }

  handleChange = async data => {
    let cardData = undefined;
    if(data.error && !data.source){
      cardData = undefined;
    }
    if(data.source && !data.error){
      cardData = {
        // Poxel live-i jamanak
        card_id: data.source.id
        // card_id: "tok_visa"
      };
    }

    if(typeof cardData === 'object') {
      const res = await this.props.addCard(cardData);
      if (res && res.status === 201 && res.data.id) {
        this.props.history.push('/manage-payment');
      }
    }
  };


  render() {
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">

            <div className="content-header bg-primary flex-wrap">
              <MainMenu />
              <h1 className="color-white">Add Payment Method</h1>
              <div className="content-header-bottom bg-primary">
                <i className="icon-credit-card color-white-30" />
              </div>
            </div>

            <StripeProvider apiKey="pk_test_Qmu14jTqEmXd7PMYMoS9hjdF">
              <Elements>
                <SplitForm
                  handleChange={this.handleChange}
                  error={this.state.error}
                  update={true}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    payment: state.payment
  };
}

export default connect(
    mapStateToProps,
    {
      getProduct,
      getPlanById,
      addCard,
      createPayment,
      getCustomerSubscription,
      updatePayment
    }
  )(ManagePayment)

