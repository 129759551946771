import React, { PureComponent } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { saveCurrentDate } from "actions/events";
import DatePicker from "./datePicker";
import MainMenu from "../UI/MainMenu";
import { Amplitude } from "@amplitude/react-amplitude";

class Caption extends PureComponent {
  state = { isOpen: false };
  handleClick = () => {
    this.setState({
      isOpen: true,
      date: this.props.date,
      curentDate: moment()
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleCurrentDate = () => {

    this.props.saveCurrentDate(this.props.currentdate);
  };

  getDate = date => {
    this.props.onDateSelect(moment(date, "MMMM YYYY"));
    this.setState({ isOpen: false });
  };
  handleNotificationsIcon = () => {
    this.props.history.push(
      this.props.role !== "blocked_user"
        ? `/notifications-list`
        : "/upgrade-membership"
    );
  };
  render() {
    const { date, notificationsList, notificationsCount } = this.props;
    return (


      <Amplitude>
        {({ logEvent }) => (
          <div className="calendar-toolbar">
            <div className="calendar-menu">
              <MainMenu {...this.props} />

              <DatePicker date={date} getDate={this.getDate} />

              {this.props.role &&
                this.props.role !== "family_member" &&
                this.props.role !== "standard_user" && (
                  <Link
                    onClick={() => {
                      logEvent("click Go To Switch");
                      this.handleCurrentDate();
                    }}
                    to={
                      this.props.role !== "blocked_user"
                        ? "/request-switch"
                        : "/upgrade-membership"
                    }
                    className="btn-icon color-text"
                  >

                    <i className="icon-switch-horizontal font-xl icon-shadow" />
                  </Link>
                )}
              {this.props.role &&
                this.props.role === "family_member" && (
                  <Link
                    to={{
                      pathname: "/connections",
                      state: { url: "/calendar" }
                    }}
                    className="btn-icon color-text"
                    onClick={() => {
                      logEvent("click Eye Icon");
                    }}
                  >
                    <i className="icon-eye font-lg icon-shadow" />
                  </Link>
                )}
              {this.props.role &&
                this.props.role !== "family_member" &&
                this.props.role !== "standard_user" && (
                  <Link
                    to={{
                      pathname:
                        this.props.role !== "blocked_user"
                          ? "/request-invite"
                          : "/upgrade-membership",
                      state: { url: "/calendar" }
                    }}
                    onClick={() => {
                      logEvent("click Go to Invite User");
                    }}
                    className="btn-icon color-text"
                  >
                    <i className="icon-share font-md icon-shadow" />
                  </Link>
                )}

              <button
                type="button"
                onClick={() => {
                  logEvent(
                    "click Go to Notifications",
                    this.handleNotificationsIcon()
                  );
                }}
                className="btn-icon color-text notifications-btn"
              >
                <i className="icon-notification font-xmd icon-shadow" />
                {notificationsCount && notificationsCount > 0 ? (
                  <span>
                    {notificationsCount > 99 ? "+99" : notificationsCount}
                  </span>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        )}
      </Amplitude>
    );
  }
}

export default connect(
  null,
  { saveCurrentDate }
)(withRouter(Caption));
