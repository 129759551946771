import {
  GET_EVENTS,
  GET_EVENT,
  GET_EVENTS_BY_TYPE,
  ADD_MENTION,
  SWITCH_USER_FREE_DATES,
  GIVE_AWAY_BUSY_DATES,
  GET_SHARED_CALENDAR_DATE,
  GET_SHARED_EVENTS,
  GET_EVENT_BY_ID,
  DELETE_CURRENT_EVENT,
  UPDATE_INVITE_USER,
  SAVE_CURRENT_DATE,
  USER_ENTERED_QUICK_MODE,
  SET_ERROR_MESSAGE
} from "actions/types";

const INITIAL_STATE = {
  events: "",
  eventsByType: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        trialPeriod: action.payload.headers["content-trial-period"]
      };
    case GET_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case GET_EVENTS_BY_TYPE:
      return { ...state, eventsByType: [...action.payload] };
    case DELETE_CURRENT_EVENT:
      let newState = { ...state };
      let index = newState.eventsByType
        .map(elem => elem.id)
        .indexOf(action.payload);
      if (index !== -1) {
        newState.eventsByType.splice(index, 1);
      }
      return { ...newState };

    case GET_EVENT_BY_ID:
      return { ...state, eventById: action.payload };
    case SAVE_CURRENT_DATE:
      return { ...state, date: action.payload };
    case USER_ENTERED_QUICK_MODE:
      return { ...state, quickMode: action.payload };
    case ADD_MENTION:
      return { ...state, mention: action.payload };
    case SWITCH_USER_FREE_DATES:
      return { ...state, switchFreeDates: action.payload };
    case GIVE_AWAY_BUSY_DATES:
      return { ...state, giveAwayBusyDates: action.payload };
    case GET_SHARED_CALENDAR_DATE:
      return { ...state, sharedCalendar: action.payload };
    case GET_SHARED_EVENTS:
      return { ...state, sharedEvents: action.payload };
    case SET_ERROR_MESSAGE:
      return { ...state, quickModeErrorMessage: action.payload };
    default:
      return state;
  }
}
