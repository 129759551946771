import React from "react";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import { getEvents, getInviteUser, getEventById } from "actions/events";
import {
  getNotifications,
  getNotificationsByDate,
  getGiveAwayRequest,
  getSwitchRequest,
  editNotifications,
  getCurrentNotification
} from "actions/notifications";
import { getNotes } from "actions/notes";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ResponsiveModal from "./modal";
import { connect } from "react-redux";
import moment from "moment";
import { Amplitude } from "@amplitude/react-amplitude";

import SingleNotification from "../notifications/singleNotification";

const arrayToObject = array => {
  return array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};
class DayDetails extends React.Component {
  state = {
    notifications: "",
    notes: "",
    shift: "",
    personal: "",
    curentEvent: "",
    singleNotification: "",
    buttons: true
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    const date = moment(this.props.match.params.id)
      .startOf("month")
      .format("YYYY-MM-DD");
    
    this.props.getEvents(date);
    this.props.getNotificationsByDate(date);
    this.props.getNotifications();
    this.props.getNotes(date);
  
  }
  onHandleOpen = (type, curentEvent) => {
    this.setState({
      open: true,
      type,
      curentEvent
    });

    if (
      (type === "Shift" || type === "Personal Events") &&
      !curentEvent.is_shifted
    ) {
      this.props.getEventById(curentEvent.id);
    }
  };
  onHandleClose = () => {
    this.setState({
      open: false
    });
  };
  handleIcon = type => {
    switch (type) {
      case "Giveaway request":
        return (
          <i className="icon-calendar-remove color-text-70 font-md mr-3" />
        );
      case "Giveaway covered":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Pending giveaway acceptance from user":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Pending giveaway approval by manager":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Giveaway approved by manager":
        return <i className="icon-check font-xsm mr-3 color-success" />;
      case "Giveaway rejected by manager":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Sharing Calendars Enabled":
        return <i className="icon-eye font-md mr-3 color-text-70" />;
      case "Sharing Calendars Disabled":
        return <i className="icon-eye-disabled font-md mr-3 color-text-70" />;
      case "Pending switch acceptance from user":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Switch request":
        return <i className="icon-switch-horizontal font-xmd mr-3" />;
      case "Switch rejected by user":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Switch rejected by manager":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Switch approved by manager":
        return <i className="icon-check font-xsm mr-3 color-success" />;
      case "Switch covered":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      case "Pending switch approval by manager":
        return <i className="icon-clock font-md color-text-70 mr-3" />;
      case "Invitation":
        return <i className="icon-share color-text-70 font-sm mr-3" />;
      case "Invitation accepted":
        return <i className="icon-check font-xsm mr-3" />;
      case "Invitation rejected":
        return <i className="icon-close font-xsm mr-3 color-warning" />;
      default:
        return <i className="icon-switch-horizontal font-xmd mr-3" />;
    }
  };
  handleClick = async data => {
    await this.props.getCurrentNotification(data.id);
    if (data.status !== "read") {
      this.props.editNotifications( data.id, {
        status: "read"
      });
    }
    let method = "";
    let request = "";
    if (data.text === "Invitation") {
      method = "getInviteUser";
      request = data.share_calendar_request;
    } else if (data.text === "Switch request") {
      method = "getSwitchRequest";
      request = data.shift_request;
    } else if (data.text === "Giveaway request") {
      method = "getGiveAwayRequest";
      request = data.shift_request;
    } else {
      method = "";
      request = "";
    }

    method && (await this.props[method]( request));
    const status =
      this.props.status && this.props.status.to_user_status
        ? this.props.status.to_user_status
        : this.props.status.status;
    if (status && status !== "pending") {
      this.setState({
        buttons: false,
        singleNotification: data,
        openSwitchRequestPopup: true
      });
    } else {
      this.setState({
        buttons: true,
        singleNotification: data,
        openSwitchRequestPopup: true
      });
    }
  };
  onPopupClose = () => {
    this.setState({ openSwitchRequestPopup: false });
  };
  render() {
    let shift = "";
    let personal = [];
    const notes = [];
    this.props.events &&
      this.props.events.events &&
      this.props.events.events.data
        .filter(data => {
          return data.days === this.props.match.params.id;
        })
        .map(elem => {
          return elem.events.map(event => {
            if (event.type === "shift") {
              shift = event;
            } else if (event.type === "personal") {
              personal.push(event);
            }
          });
        });

    this.props.notes.notes &&
      this.props.notes.notes.results
        .filter(data => {
          return data.days === this.props.match.params.id;
        })
        .map(elem => {
          return elem.notes.map(event => {
            notes.push(event);
          });
        });
    const notifications =
      this.props.notifications &&
      this.props.notifications.notificationsByDate &&
      this.props.notifications.notificationsByDate.filter(data => {
        return data.days === this.props.match.params.id;
      })[0];
    const notificationsId =
      this.props.notifications.notifications &&
      arrayToObject(this.props.notifications.notifications);
    let duration =
      shift &&
      (
        moment(shift.end_time, "HH:mm").diff(
          moment(shift.start_time, "HH:mm")
        ) /
        60000 /
        60
      ).toFixed(2);
    if (duration < 0) {
      duration = (+duration + 24).toFixed(2);
    }

    return (
      <Amplitude>
        {({ logEvent }) => (
          <div className="container">
            <div className="content box-shadow">
              <div className="content-header">
                <Link
                  to={"/calendar"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>
                  {moment(this.props.match.params.id).format("MMMM Do, YYYY")}
                </h1>
              </div>

              <div className="content-body p-0">
                <div className="scrollbar-wrapper">
                  <CustomScroll
                    allowOuterScroll
                    addScrolledClass
                    heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul>
                        {shift && (
                          <li>
                            <a
                              href="/"
                              className={`card card-with-icon ${
                                shift.name === "Off Day" ? "non-clickable" : ""
                              }`}
                              onClick={e => {
                                logEvent("click single Shift in Item List");
                                e.preventDefault();
                                this.onHandleOpen("Shift", shift);
                              }}
                            >
                              <span className="icon">
                                {console.log("ddddddddddd", shift)}
                                <img
                                  src={require(`assets/img/${
                                    shift.name === "Off Day"
                                      ? "off-day"
                                      : shift.start_period
                                  }-icon.png`)}
                                  alt="afternoon icon"
                                />
                              </span>
                              <div className="card-body">
                                <div className="flex justify-between align-center mb-1 w-100">
                                  <p className="font-md fw-500">Shift</p>
                                  {shift && shift.name === "Off Day" ? (
                                    <p className="shift-time fw-500">
                                      24.00h
                                    </p>
                                  ) : (
                                    <p className="shift-time fw-500">
                                      {`${moment(
                                        shift.start_time,
                                        "hh:mm A"
                                      ).format("h:mm A").replace(/^0(?:0:0?)?/, '')} - ${moment(
                                        shift.end_time,
                                        "hh:mm A"
                                      ).format("h:mm A").replace(/^0(?:0:0?)?/, '')} `}
                                      <span className="font-sm color-text-30">
                                        ({duration}h)
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <p className="color-text-70 font-base ellipsis text-capitalize">
                                  {shift.name}
                                </p>
                              </div>
                            </a>
                          </li>
                        )}

                        {notifications &&
                          notifications.notifications &&
                          notifications.notifications.map(data => {
                            const currentNotification =
                              notificationsId && notificationsId[data.id];
                            return (
                              <li key={data.id}>
                                <a
                                  href="/"
                                  className={`card card-with-label ${currentNotification.status ===
                                    "unread" && "unread"}`}
                                  onClick={e => {
                                    logEvent(
                                      "click single Notification in Item List"
                                    );
                                    e.preventDefault();
                                    this.handleClick(currentNotification);
                                  }}
                                >
                                  <span className="card-label bg-pink" />
                                  <div className="card-body">
                                    {currentNotification.sending_user_event && (
                                      <p className="date color-text-50 font-xsm">
                                        {moment(
                                          currentNotification.sending_user_event
                                            .date
                                        ).format("MMM Do, YYYY")}
                                      </p>
                                    )}
                                    <p
                                      className={
                                        currentNotification.sending_user_event
                                          ? "font-md fw-500 mb-1 ellipsis width-limit"
                                          : "font-md fw-500 mb-1 ellipsis"
                                      }
                                    >
                                      {currentNotification &&
                                        `${
                                          currentNotification.sending_user
                                            .first_name
                                        } ${
                                          currentNotification.sending_user
                                            .last_name
                                        }`}
                                    </p>
                                    <p className="color-text-70 font-base flex align-center">
                                      {this.handleIcon(data.text)}
                                      <span className="ellipsis">
                                        {data.text}
                                      </span>
                                    </p>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        {personal &&
                          personal.map(data => {
                            return (
                              <li key={data.id}>
                                <a
                                  href="/"
                                  className="card card-with-label"
                                  onClick={e => {
                                    logEvent(
                                      "click single Notification in Item List"
                                    );
                                    e.preventDefault();
                                    this.onHandleOpen("Personal Events", data);
                                  }}
                                >
                                  <span className="card-label bg-purple" />
                                  <div className="card-body">
                                    <p className="font-md fw-500 mb-1">Event</p>
                                    <p className="color-text-70 font-base ellipsis text-capitalize">
                                      {data.icon_name}
                                    </p>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        {notes &&
                          notes.map(data => {
                            return (
                              <li key={data.id}>
                                <a
                                  href="/"
                                  className="card card-with-label"
                                  onClick={e => {
                                    logEvent(
                                      "click single Notification in Item List"
                                    );
                                    e.preventDefault();
                                    this.onHandleOpen("Notes", data);
                                  }}
                                >
                                  <span className="card-label bg-orange" />
                                  <div className="card-body">
                                    <p className="font-md fw-500 mb-1">Note</p>
                                    <p className="color-text-70 font-base ellipsis">
                                      {data.text}
                                    </p>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </CustomScroll>
                </div>
              </div>
            </div>
            <ResponsiveModal
              open={this.state.open}
              onClose={this.onHandleClose}
              personalEvent={this.state.curentEvent}
              shiftEvent={shift}
              notes={this.state.curentEvent}
              type={this.state.type}
            />
            {this.state.singleNotification && (
              <SingleNotification
                singleNotification={this.state.singleNotification}
                open={this.state.openSwitchRequestPopup}
                onClose={this.onPopupClose}
                buttons={this.state.buttons}
              />
            )}
          </div>
        )}
      </Amplitude>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    notifications: state.notifications,
    status: state.notifications.status,
    notes: state.notes,
    auth: state.user
  };
}


export default connect(
  mapStateToProps,
  {
    getEvents,
    getEventById,
    getNotifications,
    getNotes,
    getNotificationsByDate,
    getGiveAwayRequest,
    getSwitchRequest,
    getInviteUser,
    editNotifications,
    getCurrentNotification
  }
)(withRouter(DayDetails));
