import React from "react";
import { Link } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";
import { connect } from "react-redux";
import { getUser } from "actions/auth";
import { getConnections } from "actions/connection";
import { updateInviteUser } from "actions/events";
import { createGenerateClassName } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import { timingSafeEqual } from "crypto";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class Connections extends React.Component {
  state = { error: "" };
  handleChange = id => async event => {
    const data = this.state[id];
    this.setState(prevState => ({
      error: ""
    }));
    let updateData = {};
    if (data.from_me === "True") {
      updateData.is_deleted = !event.target.checked;
    } else {
      updateData.status = event.target.checked ? "accepted" : "rejected";
    }
    this.setState({
      [id]: { ...this.state[id], checked: event.target.checked }
    });
    const res = await this.props.updateInviteUser(
      id,
      updateData
    );
    if (res.status === 400) {
      this.setState(prevState => ({
        error: res.data.non_field_errors[0],
        [id]: { ...this.state[id], checked: !prevState.checked }
      }));
    }
  };
  async componentDidMount() {
    
    await this.props.getConnections();
    await this.props.getUser();

    this.props.connections.connections &&
      this.props.connections.connections.results.map(data => {
        let checked = false;
        if (data.from_me === "True") {
          checked = !data.is_deleted;
        } else {
          checked = data.status === "accepted" ? true : false;
        }
        this.setState({ [data.id]: { ...data, checked } });
      });
  }
  handleClick = (e, data, role) => {
    e.preventDefault();
    if (!data.is_deleted && data.status === "accepted") {
      if (data.member_type === "family" && role !== "family_member") {
        return false;
      } else {
        this.props.history.push({
          pathname: `/connection-calendar/${data.user}`,
          state: {
            lastUrl: this.props.location.state.lastUrl
              ? this.props.location.state.lastUrl
              : this.props.location.state.url
          }
        });
      }
    } else {
      return false;
    }
  };
  render() {
    const role = this.props.auth.user && this.props.auth.user.role;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="container">
          <div className="content box-shadow">
            <div className="content-header">
              <Link
                // to={`${this.props.location.state.url}`}
                // // to="/calendar"
                to={{
                  pathname: this.props.location.state.lastUrl
                    ? this.props.location.state.lastUrl
                    : this.props.location.state.url,
                  state: {
                    url: "/connections",
                    lastUrl: this.props.location.state.lastUrl
                      ? this.props.location.state.lastUrl
                      : this.props.location.state.url
                  }
                }}
                className="btn-icon w-30 color-text mr-5"
              >
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>
              <h1>Connections</h1>
            </div>
            {this.props.connections.connections &&
            this.props.connections.connections.results.length > 0 ? (
              <div className="content-body p-0">
                <div className="scrollbar-wrapper">
                  <CustomScroll
                    allowOuterScroll
                    addScrolledClass
                    heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul>
                        {this.props.connections.connections.results.map(
                          data => {
                            return (
                              <li
                                className="card card-with-img lg no-shadow"
                                key={data.id}
                              >
                                <div className="card-img">
                                  <a
                                    href="/"
                                    onClick={e =>
                                      this.handleClick(e, data, role)
                                    }
                                  >
                                    <span className="user-img">
                                      {!data.user_picture ? (
                                        <i className="icon-user" />
                                      ) : (
                                        <span
                                          className="img"
                                          style={{
                                            backgroundImage: `url(${
                                              data.user_picture
                                            })`
                                          }}
                                        />
                                      )}
                                    </span>
                                  </a>
                                </div>
                                <div className="card-body row">
                                  <div>
                                    <a
                                      href="/"
                                      onClick={e =>
                                        this.handleClick(e, data, role)
                                      }
                                      className="btn-link color-text font-md fw-500 mb-1 ellipsis"
                                    >
                                      {`${data.first_name} ${data.last_name}`}
                                    </a>
                                    <p className="color-text-50">
                                      {data.member_type === "team"
                                        ? "Team Member"
                                        : "Family Member"}
                                    </p>
                                  </div>
                                  {this.state[data.id] && (
                                    <Switch
                                      className="switch"
                                      checked={
                                        this.state[data.id] &&
                                        this.state[data.id].checked
                                      }
                                      onChange={this.handleChange(data.id)}
                                      value={this.state[data.id].checked}
                                    />
                                  )}
                                </div>
                              </li>
                            );
                          }
                        )}
                        {this.state.error && (
                          <p className="error-msg with-icon">
                            {this.state.error}
                          </p>
                        )}
                      </ul>
                      {role &&
                        role !== "family_member" && (
                          <Link
                            to={{
                              pathname: "/request-invite",
                              state: {
                                url: "/connections",
                                lastUrl: this.props.location.state.lastUrl
                                  ? this.props.location.state.lastUrl
                                  : this.props.location.state.url
                              }
                            }}
                            className={`btn-rounded btn-add `}
                          >
                            <i className="icon-plus" />
                          </Link>
                        )}
                    </div>
                  </CustomScroll>
                </div>
              </div>
            ) : (
              <div className="content-body placeholder-content">
                <img
                  src={require("assets/img/connections.svg")}
                  alt="connections icon"
                />
                <p className="font-md">You do not have any connections yet.</p>
                {role &&
                  role !== "standard_user" &&
                  role !== "family_member" && (
                    <Link
                      to={{
                        pathname: "/request-invite",
                        state: {
                          url: "/connections",
                          lastUrl: this.props.location.state.lastUrl
                            ? this.props.location.state.lastUrl
                            : this.props.location.state.url
                        }
                      }}
                      className="btn btn-link color-primary no-padding mt-3"
                    >
                      Invite Now
                    </Link>
                  )}
                {role &&
                  role !== "family_member" && (
                    <Link
                      to={{
                        pathname:
                          role && role !== "standard_user"
                            ? "/request-invite"
                            : "/upgrade-membership",
                        state: {
                          url: "/connections",
                          lastUrl: this.props.location.state.url
                        }
                      }}
                      className="btn-rounded btn-add"
                    >
                      <i className="icon-plus" />
                    </Link>
                  )}
              </div>
            )}
          </div>
        </div>
      </JssProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.user,
    connections: state.connections
  };
}

export default connect(
    mapStateToProps,
    { getConnections, updateInviteUser, getUser }
  )(Connections)