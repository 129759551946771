import React, { isValidElement } from "react";
import { render } from "react-dom";
import { Formik, Field } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { forgotPassword } from "actions/auth";
import * as yup from "yup";
import Recaptcha from "react-recaptcha";
import { Amplitude } from "@amplitude/react-amplitude";


class ForgotPassword extends React.Component {
  state = { redirect: false, recaptcha: "", email: "" };
  componentDidMount() {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      this.props.history.push("/calendar");
    }
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <section className="bg-primary content-center">
        <div className="container">
          <Formik
            initialValues={{
              recaptcha: this.state.recaptcha,
              email: this.state.email
            }}
            onSubmit={async (values, { setErrors }) => {
              const userData = {
                email: values.email
              };
              const res = await this.props.forgotPassword(userData);
              if (
                res.payload &&
                res.payload.data &&
                res.payload.data.non_field_errors &&
                res.payload.data.non_field_errors.length > 0
              ) {
                setErrors({ email: "This Email does not exist." });
              }
              if (res.payload && res.payload.status === 200) {
                this.setState({ redirect: true });
              }
            }}
            validationSchema={yup.object().shape({
              recaptcha: yup.string().required("This field is mandatory."),
              email: yup
                .string()
                .email("Please provide a valid Email.")
                .required("This field is mandatory.")
            })}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              props
            }) =>
              !this.state.redirect && (
                <div className="content">
                  <div className="sign-up-content color-white">
                    <Link to={"/"} className="logo">
                      <img src={require("assets/img/logo.svg")} alt="Turno" />
                    </Link>

                    <h1 className="mt-6 mb-3">Forgot password?</h1>
                    <p className="color-white-70 text-center mb-6">
                      We will send a reset password link to your email.
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div
                        className={
                          touched.email && errors.email
                            ? "form-field form-field-white has-error"
                            : "form-field form-field-white"
                        }
                      >
                        <div className="form-input">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            autoComplete="off"
                          />
                          <span className="border" />
                        </div>
                        {touched.email &&
                          errors.email && (
                            <p className="error-msg">{errors.email}</p>
                          )}
                      </div>
                      <div className="form-field form-field-white recaptcha">
                        <Recaptcha
                          sitekey="6LdY8ZMUAAAAAFveVYYqIAGmujKU19lyb3oAZwHG"
                          // sitekey="6LeZGo0UAAAAAFe4snPxrzQ90i-RDjR01c03p1lj"
                          render="explicit"
                          theme="light"
                          verifyCallback={response => {
                            setFieldValue("recaptcha", response);
                          }}
                          onloadCallback={() => {
                            console.log("done loading!");
                          }}
                        />
                        {errors.recaptcha &&
                          touched.recaptcha && (
                            <p className="error-msg">{errors.recaptcha}</p>
                          )}
                      </div>
                      <Amplitude>
                        {({ logEvent }) => (
                          <button
                            type="button"
                            onClick={() => {
                              logEvent("click Forgot Password");
                              handleSubmit();
                            }}
                            className="btn btn-white w-100 mt-2"
                          >
                            Send
                          </button>
                        )}
                      </Amplitude>
                      <Link
                        to={"sign-in"}
                        className="link color-white-70 fw-200 mt-5"
                      >
                        Log In
                      </Link>
                    </form>

                    <p className="mt-6">
                      Don't have an account?{" "}
                      <Link
                        to={"/sign-up"}
                        className="link font-base color-white"
                      >
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </div>
              )
            }
          />
          {this.state.redirect && (
            <div className="content">
              <div className="info-content color-white">
                <Link to={"/"} className="logo">
                  <img src={require("assets/img/logo.svg")} alt="Turno" />
                </Link>
                <h1 className="no-transform mb-4">Successfully submitted!</h1>
                <p className="color-white-70 text-center">
                  A reset password link was sent to your email.
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.user };
}
export default connect(
  mapStateToProps,
  { forgotPassword }
)(ForgotPassword);
