import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import {
  deleteCurrentEvent,
  deleteDateEvent,
  saveCurrentDate
} from "actions/events";
import { deleteNotes } from "actions/notes";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { upperFirst } from "lodash";
import moment from "moment";
import { Amplitude } from "@amplitude/react-amplitude";

const defaultEvents = ['pet care', 'sick day', 'date night', 'movies', 'party', 'spa',  'doctor', 'shopping', 'vacation', 'birthday', 'gym'];

class ResponsiveModal extends Component {
  state = {
    error: ""
  };

  onCloseModal = () => {
    this.setState({ error: "" });
    this.props.onClose();
  };

  deleteEvent = async (i, type) => {
    let method = "";
    if (type === "Notes") {
      method = "deleteNotes";
    } else if (type === "Personal Events") {
      method = "deleteDateEvent";
    } else if (type === "Shift") {
      method = "deleteDateEvent";
    }

    const eventId = this.props.eventDateId
      ? this.props.eventDateId
      : i.event_date_id;

    let id = type === "Shift" || type === "Personal Events" ? eventId : i.id;

    const res = await this.props[method]( id);

    if (res && res.status === 204) {
      this.props.saveCurrentDate(this.props.date);
      this.props.history.push("/calendar");
      this.props.history.push("/calendar");
    } else {
      this.setState({ error: res.data.message && res.data.message });
    }
  };

  handleClick = (e, Events) => {
    e.preventDefault();
    this.setState({
      error: "You can't edit this  shift because it has pending status."
    });
  };

  render() {
    const { open } = this.props;
    let Events = "";
    let redirect = "";

    if (this.props.type === "Personal Events") {
      Events = this.props.personalEvent && this.props.personalEvent;
      redirect = "event";
    } else if (this.props.type === "Notes") {
      Events = this.props.notes;
      redirect = "note";
    } else if (this.props.type === "Shift") {
      Events = this.props.shiftEvent;
      redirect = "shift";
    }

    let duration =
      Events &&
      (
        moment(Events.end_time, "HH:mm").diff(
          moment(Events.start_time, "HH:mm")
        ) /
        60000 /
        60
      ).toFixed(2);
    if (duration < 0) {
      duration = (+duration + 24).toFixed(2);
    }

    let isEditble = null;
    const currentDataId = Events &&
      this.props.events.events.data &&
      this.props.events.events.data.filter(
        data =>
          (data.days === this.props.match.params.id && this.props.match.params.id ||
            data.days ===
            moment(this.props.events.date).format("YYYY-MM-DD")) &&
          data.events[0].id === Events.id
      );

    Events &&
    this.props.events.events.data && this.props.events.events.data.map((event) => {
      if(event.days === moment(this.props.events.date).format("YYYY-MM-DD") && event.events[0].id === Events.id){
        isEditble = event.is_editable;
      }
    });

    return (
      <div>
        <Amplitude>
          {({ logEvent }) => (
            <Modal
              classNames={{
                overlay: "modal-overlay",
                modal: "modal",
                closeButton: "modal-close-btn"
              }}
              open={open}
              onClose={this.onCloseModal}
              center
            >
              {Events && (
                <div className="modal-body">
                  {(this.props.type === "Personal Events" ||
                    this.props.type === "Shift") && (
                    <div
                      className={
                        this.props.type === "Shift"
                          ? "single-view-header with-icon"
                          : "single-view-header with-label-icon"
                      }
                    >
                      {this.props.type === "Shift" ? (
                        <span className="single-view-label">
                          <img
                            src={require(`assets/img/${
                              Events.name === "Off Day" ? "off-day" : Events.start_period
                              }-icon.png`)}
                            alt="night icon"
                          />
                        </span>
                      ) : (
                        <span className="single-view-label bg-purple">
                          <i
                            className={`icon-${defaultEvents.indexOf(Events.name) !== -1 ? Events.name.replace(' ', '-') : 'other' } color-white box-30`}
                          />
                        </span>
                      )}

                      <div>
                        <p className="font-lg fw-500 mb-2">{this.props.type}</p>
                        {this.props.type === "Shift" && Events.name !== "Off Day" ? (
                          <p className="color-text-70 text-capitalize">{`${upperFirst(
                            Events.start_period
                          )}`}</p>
                        ) : Events.name === 'Off Day' ? 'Not Available' : (
                          <p className="color-text-70 text-capitalize">{`${upperFirst(
                            Events.name
                          )}`}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {(this.props.type === "Personal Events" ||
                    this.props.type === "Shift") && (
                    <div className="single-view-body">
                      <p className="font-md fw-700 mb-4 text-capitalize">
                        {Events && this.props.type === "Personal Events"
                          ? Events.icon_name
                          : Events.name === 'Off Day' ? 'Not Available' : Events.name}
                      </p>
                      <ul className="list-with-icons">
                        <li>
                          <i className="icon-clock font-md color-black-30" />
                          <span className="color-text-50 w-70">
                            Start Time:
                          </span>
                          {Events && Events.name !== 'Off Day' && (
                            <span>{Events.start_time ? `${moment(
                              Events.start_time,
                              "hh:mm A"
                            ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}` : 'Time not set'}</span>
                          )}
                          {Events.name === 'Off Day' &&
                            <span>00:01 AM</span>
                          }
                        </li>
                        <li>
                          <i className="icon-clock font-md color-black-30" />
                          <span className="color-text-50 w-70">End Time:</span>
                          {Events && Events.name !== 'Off Day' && (
                            <span>{Events.end_time ? `${moment(Events.end_time, "hh:mm A").format(
                              "h:mm A"
                            ).replace(/^0(?:0:0?)?/, '')}` : 'Time not set'}</span>
                          )}
                          {Events.name === 'Off Day' &&
                            <span>11:59 PM</span>
                          }
                        </li>
                      </ul>
                      {this.props.type === "Shift" && Events.name !== 'Off Day' && (
                        <p className="color-text-50 w-70">
                          Duration: {duration}h
                        </p>
                      )}
                      {Events.name === 'Off Day' &&
                        <p className="color-text-50 w-70">
                          Duration: 24.00h
                        </p>
                      }
                    </div>
                  )}
                  {this.props.type === "Notes" &&
                  Events && (
                    <div className="modal-body">
                      <div className="single-view-header with-label">
                        <span className="single-view-label bg-orange" />
                        <div>
                          <p className="font-lg fw-500">Note</p>
                        </div>
                      </div>
                      <div className="single-view-body">
                        <pre className="font-md fw-700">
                          {Events.text}
                        </pre>
                      </div>
                    </div>
                  )}

                  {this.state.error && (
                    <div className="mx-6 mt-2">
                      <p className="error-msg with-icon">{this.state.error}</p>
                    </div>
                  )}
                  <div className="single-view-footer">
                    {/*PERSONAL EVENT EDIT*/}
                    {Events &&
                    (this.props.type === 'Personal Events' || this.props.type === 'Notes') && (
                      <Link
                        to={`/edit-${redirect}/${Events && Events.id}`}
                        className="btn-icon w-30 color-text"
                      >
                        <i className="icon-edit icon-shadow" />
                      </Link>
                    )}
                    {/*DELETE PERSONAL EVENT*/}
                    {Events &&
                    (this.props.type === 'Personal Events' || this.props.type === 'Notes') && (
                      <button
                        type="button"
                        className="btn-icon w-30 color-warning"
                        onClick={() => {
                          logEvent("click Delete Event in Calendar");
                          this.deleteEvent(
                            this.props.type !== "Notes"
                              ? currentDataId[0]
                              : Events,
                            this.props.type
                          );
                        }}
                      >
                        <i className="icon-delete icon-shadow" />
                      </button>
                    )}
                    {/*SHIFT EVENT EDIT*/}
                    {Events &&
                    this.props.type === 'Shift' && isEditble === true && Events.name !== 'Off Day' && (
                      <Link
                        to={`/edit-${redirect}/${Events && Events.id}`}
                        className="btn-icon w-30 color-text"
                      >
                        <i className="icon-edit icon-shadow" />
                      </Link>
                    )}
                    {/*DELETE SHIFT EVENT*/}
                    {Events &&
                    this.props.type === 'Shift' && isEditble === true && (
                      <button
                        type="button"
                        className="btn-icon w-30 color-warning mr-3"
                        onClick={() => {
                          logEvent("click Delete Event in Calendar");
                          this.deleteEvent(
                            this.props.type !== "Notes"
                              ? currentDataId[0]
                              : Events,
                            this.props.type
                          );
                        }}
                      >
                        <i className="icon-delete icon-shadow" />
                      </button>
                    )
                    }

                    {isEditble === false && this.props.type === 'Shift' && (
                      <p className="info-text">
                        You can't edit or delete this shift since it is switched.
                      </p>
                    )}

                  </div>
                </div>
              )}
            </Modal>
          )}
        </Amplitude>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user,
    eventById: state.events.eventById
  };
}

export default connect(
  mapStateToProps,
  {
    deleteCurrentEvent,
    deleteNotes,
    deleteDateEvent,
    saveCurrentDate
  }
)(withRouter(ResponsiveModal));
  