import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import CustomScroll from "react-custom-scroll";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import { Amplitude } from "@amplitude/react-amplitude";
import { removeReduxToken, getUser, signOutUser } from "actions/auth";
import { saveCurrentDate } from "actions/events";
import "react-custom-scroll/dist/customScroll.css";
import moment from "moment";

class MainMenu extends React.Component {


  state = {
    open: false, 
  };

  openMenu = () => {
    this.setState({ open: true });
    document.body.classList.add("overflow-hidden");
  };

  closeMenu = () => {
    this.setState({ open: false });
    document.body.classList.remove("overflow-hidden");
  };
  async componentDidMount() {
    const res = await this.props.getUser();
    this.setState({
      picture: this.props.user.user.picture, 
     });
  }
  componentWillUnmount() {
    document.body.classList.remove("overflow-hidden");
  }

  clearCookie(names) {
    names.forEach(name => {
      localStorage.removeItem(name)
    });
  }
  removeToken = async e => {
    e.preventDefault();
   
    const res = await this.props.signOutUser();
    if (res && res.payload && res.payload.status === 200) {
      this.clearCookie([
        "token",
        "firstLogin",
        "count",
        "role",
        "id",
        "trial-period"
      ]);
      sessionStorage.removeItem("token");
      this.props.removeReduxToken();
      this.props.saveCurrentDate(moment());
      this.props.history.push("/sign-in");
    }
  };
  handleClick = (e, path) => {
    e.preventDefault();

    const role = localStorage.getItem("role");
    const currentPath = this.props.match && this.props.match.url;
    if (role === "blocked_user" && path !== "/calendar") {
      this.props.history.push("/upgrade-membership");
      this.closeMenu();
    } else {
      currentPath === path
        ? this.closeMenu()
        : this.props.history.push(`${path}`);
    }
  };
  render() {
    const open = this.state.open;
    const path = this.props.match && this.props.match.path.replace("/", "");
    const User = this.props.user.user;
    const role = this.props.user.user && this.props.user.user.role;
    return (
      <Amplitude>
        {({ logEvent }) => (
          <div>
            <button
              type="button"
              className="btn-icon color-text w-30 menu-icon"
              // onClick={this.openMenu}
              onClick={() => {
                logEvent("click Open Side Bar Calendar");
                this.openMenu();
              }}
            >
              <i className="icon-menu font-sm icon-shadow" />
            </button>
            <div
              className={open ? "overlay opened" : "overlay"}
              onClick={() => {
                logEvent("click Close Overlay Side Bar Calendar");
                this.closeMenu();
              }}
            />

            <div className={open ? "main-menu opened" : "main-menu"}>
              <div className="scrollbar-wrapper">
                <CustomScroll
                  allowOuterScroll
                  addScrolledClass
                  heightRelativeToParent="100%"
                >
                  <div>
                    <div className="close-btn-wrap">
                      <button
                        onClick={() => {
                          logEvent("click Close Icon Side Bar Calendar");
                          this.closeMenu();
                        }}
                        type="button"
                      >
                        <i className="icon-close" />
                      </button>
                    </div>

                    <div className="user">
                      <div className="user-img">
                        {!User.picture ? (
                          <i className="icon-user" />
                        ) : (
                          <span
                            className="img"
                            style={{
                              backgroundImage: `url(${User.picture})`
                            }}
                          />
                        )}
                        {/* <span className="img" style={{ backgroundImage: 'url(https://badhan.org/img/avatars/defaultFemalePP.png)' }} /> */}
                      </div>
                      {User && (
                        <p className="user-name">{`${User.first_name} ${
                          User.last_name
                        }`}</p>
                      )}
                    </div>

                    <ul className="menu-list">
                      <li>
                        <a
                          href="/"
                          onClick={e => {
                            logEvent("click Side Bar Calendar");
                            this.handleClick(e, "/calendar");
                          }}
                          className={`card card-with-label-icon ${path ===
                            "calendar" && "active"}`}
                        >
                          <span className="card-label bg-blue-light">
                            <i className="icon-calendar-o color-white" />
                          </span>
                          <div className="card-body">
                            <p className="font-md fw-500">Calendar</p>
                          </div>
                        </a>
                      </li>
                      {role &&
                        role !== "family_member" &&
                        role !== "standard_user" && (
                          <li>
                            <a
                              href="/"
                              onClick={e => {
                                logEvent("click Side Bar Team");
                                this.handleClick(e, "/team");
                              }}
                              className={`card card-with-label-icon ${path ===
                                "team" && "active"}`}
                            >
                              <span className="card-label bg-blue-light">
                                <i className="icon-users color-white" />
                              </span>
                              <div className="card-body">
                                <p className="font-md fw-500">Team</p>
                              </div>
                            </a>
                          </li>
                        )}
                      <li>
                        <a
                          href="/"
                          onClick={e => {
                            logEvent("click Side Bar Events");
                            this.handleClick(e, `/events/${0}`);
                          }}
                          className={`card card-with-label-icon ${path ===
                            "events" && "active"}`}
                        >
                          <span className="card-label bg-blue-light">
                            <i className="icon-events color-white" />
                          </span>
                          <div className="card-body">
                            <p className="font-md fw-500">Events</p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          onClick={e => {
                            logEvent("click Side Bar Settings");
                            this.handleClick(e, "/settings");
                          }}
                          className={`card card-with-label-icon ${path ===
                            "settings" && "active"}`}
                        >
                          <span className="card-label bg-blue-light">
                            <i className="icon-settings color-white" />
                          </span>
                          <div className="card-body">
                            <p className="font-md fw-500">Settings</p>
                          </div>
                        </a>
                      </li>

                      {localStorage.getItem("role") !== "blocked_user" ? (
                        <li>
                          <a
                            href="http://help.turnoapp.com"
                            onClick={e => {
                              logEvent("click Side Bar Events");
                            }}
                            className="card card-with-label-icon"
                          >
                            <span className="card-label bg-blue-light">
                              <i className="icon-help color-white" />
                            </span>
                            <div className="card-body">
                              <p className="font-md fw-500">Help</p>
                            </div>
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a
                            href="http://www.turnoapp.com/help"
                            className="card card-with-label-icon"
                            onClick={e => {
                              logEvent("click Side Bar Help");
                              this.handleClick(e, "/help");
                            }}
                          >
                            <span className="card-label bg-blue-light">
                              <i className="icon-help color-white" />
                            </span>
                            <div className="card-body">
                              <p className="font-md fw-500">Help</p>
                            </div>
                          </a>
                        </li>
                      )}

                      <li>
                        <a
                          href="/"
                          onClick={e => {
                            logEvent("click Side Bar Feedback");
                            this.handleClick(e, "/feedback");
                          }}
                          className={`card card-with-label-icon ${path ===
                            "feedback" && "active"}`}
                        >
                          <span className="card-label bg-blue-light">
                            <i className="icon-feedback color-white" />
                          </span>
                          <div className="card-body">
                            <p className="font-md fw-500">Feedback</p>
                          </div>
                        </a>
                      </li>
                      {role &&
                        role !== "family_member" && (
                          <li>
                            <a
                              href="/"
                              onClick={e => {
                                logEvent("click Side Bar Upgrade");
                                this.handleClick(e, "/upgrade-membership");
                              }}
                              // onClick={e =>
                              //   this.handleClick(e, "/upgrade-membership")
                              // }
                              className={`card card-with-label-icon ${path ===
                                "upgrade-membership" && "active"}`}
                            >
                              <span className="card-label bg-blue-light">
                                <i className="icon-upgrade-membership color-white" />
                              </span>
                              <div className="card-body">
                                <p className="font-md fw-500">Upgrade</p>
                              </div>
                            </a>
                          </li>
                        )}
                      <li>
                        <a
                          href="/"
                          onClick={e => {
                            logEvent("click Log Out");
                            this.removeToken(e);
                          }}
                          className="card card-with-label-icon"
                        >
                          <span className="card-label bg-blue-light">
                            <i className="icon-sign-out color-white" />
                          </span>
                          <div className="card-body">
                            <p className="font-md fw-500">Sign Out</p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </CustomScroll>
              </div>
            </div>
          </div>
        )}
      </Amplitude>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  { removeReduxToken, getUser, signOutUser, saveCurrentDate }
)(withRouter(MainMenu));
