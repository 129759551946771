import axios from "axios";



export let axiosToken = null;

export const createAxiosToken = token=>{
  const myToken = localStorage.getItem("token") ||
        sessionStorage.getItem("token") ||token
  axiosToken= axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? "https://turno-api.codebnb.me/api/v0"
        : window.location.host === "my.turnoapp.com"
          ? "https://api.turnoapp.com/api/v0"
          : "https://turno-api.codebnb.me/api/v0",
    headers: {
      Authorization: `JWT ${myToken}`
    }
  });
} 
export const axiosWitoutToken = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://turno-api.codebnb.me/api/v0"
      : window.location.host === "my.turnoapp.com"
        ? "https://api.turnoapp.com/api/v0"
        : "https://turno-api.codebnb.me/api/v0"
});

