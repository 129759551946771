import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MainMenu from "../UI/MainMenu";
import {getCard, getCardInfo, deleteCard} from "../../actions/payment";
import ResponsiveModal from "../UI/modal";

class ManagePayment extends React.Component {

  state = {
    cardInfo : {
      card_type: "",
      exp_date: "",
      first_name: "",
      last_name: "",
      last_four_numbers: "",
    },
    stripeCardId: '',
    openDeletePopup: false,
  };

  async componentDidMount() {

    const res = await this.props.getCard();

    if(res && typeof res.data.results[0] === 'object' && res.status === 200 && !this.state.stripeCardId){
      const cardId = res.data.results[0].card_id;
      const stripeCardId = res.data.results[0].id;
      const cardInfo = await this.props.getCardInfo(cardId);
      this.setState({
        cardInfo: cardInfo.data.results
      });
      this.setState({
        stripeCardId: stripeCardId,
      });
    }
  }

  openDeletePopup = () => {
    this.setState({
      openDeletePopup: true,
    });
  };

  closeDeletePopup = () => {
    this.setState({
      openDeletePopup: false,
    });
  };

  async onYesClick (cardId) {
    const res = await this.props.deleteCard(cardId);
    if(res.status === 204) {
      this.setState({
        stripeCardId: '',
        openDeletePopup: false,
      });
    }
  }

  render() {
    const role = localStorage.getItem("role");
    const {cardInfo, stripeCardId} = this.state;
    const month_name = (date) => {
      let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
      return mlist[date.getMonth()];
    };

    console.log('cardInfo.exp_date', cardInfo.exp_date);

    let month = cardInfo.exp_date ? month_name(new Date(cardInfo.exp_date)) : '';
    let year = cardInfo.exp_date ? new Date(cardInfo.exp_date).getFullYear() : '';

    let img = '';
    if(cardInfo.card_type === "unknown"){
      img = "other";
    }
    else {
      img = cardInfo.card_type;
    }

    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header bg-primary flex-wrap">
              <MainMenu />
              <h1 className="color-white">Payment</h1>
              <div className="content-header-bottom bg-primary">
                <i className="icon-credit-card color-white-30" />
              </div>
            </div>

            { !stripeCardId ? (
            <div className="content-body placeholder-content no-image">
              <p className="font-md fw-500">You don't have a <br/> payment information yet. <br/> Add now !</p>

              <Link to={"/add-payment-method"} className="btn-rounded btn-add">
                <i className="icon-plus" />
              </Link>
            </div>
            ) : (
            <div className="content-body">
              <div className="profile-info">


                <div className="credit-card-header flex align-center justify-between mb-4">
                  <p className="font-xl fw-700 ellipsis mr-2">
                    {cardInfo.card_type && cardInfo.card_type}
                  </p>
                  {img && img !== "other" && <img src={require("assets/img/" + img + ".svg")} alt={cardInfo.card_type && cardInfo.card_type} />}
                  {img && img === "other" && <i className="icon-credit-card" />}
                </div>

                <div className="mb-5">
                  <p className="font-md fw-500">Card Number</p>
                  <p className="color-text-70 mt-2">**** **** <span>{cardInfo.last_four_numbers && cardInfo.last_four_numbers}</span></p>
                </div>

                <div className="mb-5">
                  <p className="font-md fw-500">Expiration Date</p>
                  <p className="color-text-70 mt-2">{month}, {year}</p>
                </div>

                <button
                  type="button"
                  className="btn btn-link no-padding color-text-50 with-icon"
                >
                  <i className="icon-delete icon-shadow color-warning font-" onClick={this.openDeletePopup} />
                  <span className="fw-400" onClick={this.openDeletePopup}>Delete Card</span>
                </button>

                <ResponsiveModal
                    open={this.state.openDeletePopup}
                    onClose={this.onPopupClose}
                >
                  <div className="modal-body">
                    <div className="text-center pt-8 px-6 pb-5">
                      <p className=" font-lg fw-700 mb-4">
                        Are you sure?
                      </p>
                      <p className="font-md">
                        This will remove Your card permanently.
                      </p>
                    </div>
                  </div>
                  <div className="modal-footer justify-around">
                    <button
                        type="button"
                        className="btn btn-link color-text-50"
                        onClick={this.closeDeletePopup}
                    >
                      No
                    </button>
                    <button
                        type="button"
                        className="btn btn-white"
                        onClick={() => this.onYesClick(stripeCardId)}
                    >
                      Yes
                    </button>
                  </div>
                </ResponsiveModal>

              </div>
            </div>
            )}

          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, {
  getCard,
  getCardInfo,
  deleteCard,
})(ManagePayment)
