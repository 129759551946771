import { DELETE_NOTES, GET_NOTES_BY_ID } from "../actions/types";
import { GET_NOTES } from "actions/types";

const INITIAL_STATE = {
  notes: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOTES:
      return { ...state, notes: action.payload };
    case GET_NOTES_BY_ID:
      return { ...state, NoteById: action.payload };
    case DELETE_NOTES:
      let newState = state;
      newState.notes.results.map(data => {
        let index = data.notes.map(elem => elem.id).indexOf(action.payload);
        if (index !== -1) {
          data.notes.splice(index, 1);
        }
      });

      return { ...newState };
    default:
      return state;
  }
}
