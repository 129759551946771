import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Dashboard from "./Dashboard";
import DatePicker from "./DatePicker";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { map, upperFirst } from "lodash";
import { getUser } from "./../../actions/auth";
import {
  getUserDashboard,
  getAdminDashboard
} from "./../../actions/adminDashboard";
import EnhancedTableHead from "./adminStatistics/EnhancedTableHead";
import EnhancedTableToolbar from "./adminStatistics/EnhancedTableToolbar";
import arrayToObject from "./../../helpers/objectCreator";
import icons from "helpers/icons";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const rows = [
  { id: "property", label: "Property" },
  { id: "value", label: "Value" }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    from: null,
    to: null
  };
  async componentDidMount() {
    const id = localStorage.getItem("id");

   
    if (
      ((this.props.users.user.token &&
        this.props.users.user.token.role === "admin") ||
        localStorage.getItem("role") === "admin") &&
      id
    ) {
      await this.props.getUser();
      await this.props.getUserDashboard();
      await this.props.getAdminDashboard();

      // await this.setState({ adminData, personalInfo, shiftInfo });
    }
  }
  shiftInfoCount = shift => {
    let shiftInfo = "";
    let shiftCount = 0;
    if (shift.length < 1) {
      shiftInfo = (
        <ExpansionPanel>
          <ExpansionPanelSummary>
            <div className="expansion-row expansion-row-header">
              <p className="property">
                Total number of shifts created <KeyboardArrowDownIcon />
              </p>
              <p className="value">0</p>
            </div>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      );
    } else {
      map(shift, data => {
        shiftCount = shiftCount + data.shift_event_count;
        shiftInfo = (
          <ExpansionPanel>
            <ExpansionPanelSummary>
              <div className="expansion-row expansion-row-header">
                <p className="property">
                  Total number of shifts created <KeyboardArrowDownIcon />
                </p>
                <p className="value">{shiftCount}</p>
              </div>
            </ExpansionPanelSummary>
            {map(shift, elem => {
              return (
                <ExpansionPanelDetails>
                  <div className="expansion-row">
                    <p className="property">
                      {upperFirst(elem.start_period_type)}
                    </p>
                    <p className="value">{elem.shift_event_count}</p>
                  </div>
                </ExpansionPanelDetails>
              );
            })}
          </ExpansionPanel>
        );
      });
    }
    return shiftInfo;
  };
  personalInfo = personal => {
    let personalInfo = "";
    let personalCount = 0;
    const defaultEvents = [];
    const otherEvents = [];
    if (personal.length < 1) {
      personalInfo = (
        <ExpansionPanel>
          <ExpansionPanelSummary>
            <div className="expansion-row expansion-row-header">
              <p className="property">
                Total number of personal events created{" "}
                <KeyboardArrowDownIcon />
              </p>
              <p className="value">{personalCount}</p>
            </div>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      );
    } else {
      map(personal, data => {
        personalCount = data.personal_event_count + personalCount;
        if (icons.hasOwnProperty(data.personal_event_type.replace(" ", ""))) {
          defaultEvents.push(data);
        } else {
          otherEvents.push(data);
        }
        personalInfo = (
          <ExpansionPanel>
            <ExpansionPanelSummary>
              <div className="expansion-row expansion-row-header">
                <p className="property">
                  Total number of personal events created{" "}
                  <KeyboardArrowDownIcon />
                </p>
                <p className="value">{personalCount}</p>
              </div>
            </ExpansionPanelSummary>

            {map(defaultEvents, elem => {
              return (
                <ExpansionPanelDetails>
                  <div className="expansion-row">
                    <p className="property">
                      {upperFirst(elem.personal_event_type)}
                    </p>
                    <p className="value">{elem.personal_event_count}</p>
                  </div>
                </ExpansionPanelDetails>
              );
            })}
            <ExpansionPanelDetails>
              <div className="expansion-row">
                <p className="property">{"Other"}</p>
                <p className="value">{otherEvents.length}</p>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
    }

    return personalInfo;
  };
  handleDate = async (date, range) => {
    await this.setState({ [range]: date });
    const {  from, to } = this.state;
    this.props.getAdminDashboard( from, to);
  };
  render() {
    const { classes } = this.props;

    const adminData =
      this.props.adminDashboard.data && this.props.adminDashboard.data.results;
    const shiftInfo =
      adminData && adminData.total_personal_events.length > 0
        ? arrayToObject(adminData.total_shift_events, "start_period_type")
        : [];
    const personalInfo =
      adminData && adminData.total_shift_events.length > 0
        ? arrayToObject(adminData.total_personal_events, "personal_event_type")
        : [];
    return (
      <Dashboard>
        {adminData && (
          <Paper className={classes.root + " sm"}>
            <EnhancedTableToolbar
              generateClassName={generateClassName}
              handleDate={this.handleDate}
              minDate={this.state.from}
            />
            <div className={classes.tableWrapper + " table-wrapper"}>
              <Table
                className={classes.table + " expandable-table"}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  rowCount={2}
                  rows={rows}
                  generateClassName={generateClassName}
                />
                <TableBody>
                  <TableRow hover>
                    <TableCell>
                      Total number of trials that do not upgrade
                    </TableCell>
                    <TableCell>{adminData.standard_users_count}</TableCell>
                  </TableRow>

                  {shiftInfo && (
                    <TableRow hover className="expandable-row">
                      <TableCell className="nowrap" colSpan={rows.length}>
                        {this.shiftInfoCount(shiftInfo)}
                      </TableCell>
                    </TableRow>
                  )}

                  {personalInfo && (
                    <TableRow hover className="expandable-row">
                      <TableCell className="nowrap" colSpan={rows.length}>
                        {this.personalInfo(personalInfo)}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow hover>
                    <TableCell>Total number of shifts switched</TableCell>
                    <TableCell>{adminData.switched_shifts_count}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Total number of hours switched</TableCell>
                    <TableCell>{adminData.switched_shifts_hours}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Total number of hours scheduled</TableCell>
                    <TableCell>
                      {adminData.shift_event_hours &&
                        parseFloat(adminData.shift_event_hours).toFixed(2)}
                    </TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>
                      Average time it takes for a shift to be switched
                    </TableCell>
                    <TableCell>
                      {adminData && adminData.average_time_to_approve_switch}
                    </TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Average days between trial to upgrade</TableCell>
                    <TableCell>
                      {adminData && adminData.avg_days_trial_to_upgrade}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>
                      Average days between blocked to upgrade
                    </TableCell>
                    <TableCell>
                      {adminData && adminData.avg_days_blocked_to_upgrade}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 49 }}>
                    <TableCell colSpan={rows.length} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Paper>
        )}
      </Dashboard>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    users: state,
    usersDashboard: state,
    adminDashboard: state.admin.adminDashboard
  };
}
let table = withStyles(styles)(EnhancedTable);

export default connect(
  mapStateToProps,
  { getUser, getUserDashboard, getAdminDashboard }
)(table);
