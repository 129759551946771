import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class About extends React.Component {
  render() {
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header bg-white flex-wrap">
              <Link to={"/settings"} className="btn-icon w-30 color-text mr-5">
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>
              <h1>About</h1>

              <div className="content-header-bottom bg-white">
                <span className="logo-img">
                  <img src={require("assets/img/logo-dark.svg")} alt="Turno" />
                </span>
              </div>
            </div>

            <div className="content-body">
              <div className="profile-info">
                <p className="font-md fw-500 mb-3">About Turno</p>
                <p className="color-text-70 mb-6 pr-2">
                  Turno was created out of the need to help shift workers take
                  control of their time and improve their work-life balance.
                </p>

                <p className="font-md fw-500 mb-3">Version</p>
                <p className="color-text-70 mb-6">1.0</p>

                <p className="font-md fw-500 mb-3">Follow us on</p>
                <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/TurnoInc">
                      <img
                        src={require("assets/img/facebook.svg")}
                        alt="Facebook icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/turnoinc">
                      <img
                        src={require("assets/img/linkedin.svg")}
                        alt="Linkedin icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/TurnoInc">
                      <img
                        src={require("assets/img/twitter.svg")}
                        alt="Twitter icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
