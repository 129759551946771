import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  UPDATE_MANAGER,
  ADD_MANAGER,
  GET_MANAGER,
  GET_MANAGER_BY_ID,
  DELETE_MANAGER
} from "./types";

export const addManager = (data) => dispatch => {
  const url = `/managers/`;
  return axiosToken
    .post(
      url,
      data
      
    )
    .then(res => {
      return dispatch({
        type: UPDATE_MANAGER,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const editManager = (data,  id) => dispatch => {
  const url = `/managers/${id}/`;
  return axiosToken
    .patch(
      url,
      data
     
    )
    .then(res => {
      return dispatch({
        type: ADD_MANAGER,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const getManager = () => dispatch => {
  const url = `/managers/`;
  return axiosToken
    .get(
      url
    
    )
    .then(res => {
      return dispatch({
        type: GET_MANAGER,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const getManagerById = ( id) => dispatch => {
  const url = `/managers/${id}/`;
  return axiosToken
    .get(
      url
    
    )
    .then(res => {
      return dispatch({
        type: GET_MANAGER_BY_ID,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const deleteManager = ( id) => dispatch => {
  const url = `/managers/${id}/`;
  return axiosToken
    .delete(
      url
     
    )
    .then(res => {
      return dispatch({
        type: DELETE_MANAGER,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

