const icons = {
  birthday: "birthday",
  datenight: "date-night",
  gym: "gym",
  movies: "movies",
  shopping: "shopping",
  sickday: "sick-day",
  doctor: "doctor",
  petcare: "pet-care",
  party: "party",
  spa: "spa",
  vacation: "vacation"
  // other: "other"
};

export default icons;
