import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getEventById } from "actions/events";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import EditStepper from "./EditStepper";
import Stepper from "./Stepper";

class EditEvent extends React.Component {
  state = {
    id: "",
    icon_name: "",
    start_time: "",
    end_time: "",
    currentEvents: ""
  };
  async componentDidMount() {
    await this.props.getEventById(this.props.match.params.id);
    const currentEvents =
      this.props && this.props.events && this.props.events.eventById;
    this.setState({ currentEvents });
  }

  onStartTimeSelect = start_time => {
    this.setState({ start_time });
  };
  onEndTimeSelect = end_time => {
    this.setState({ end_time });
  };
  handleChange = value => {
    this.setState({ icon_name: value });
  };
  onCreateShift = async () => {
    let data = {
      icon_name: this.state.icon_name,
      type: "personal"
    };

    if(this.state.start_time || this.state.end_time){
      if(!this.state.start_time){
        this.setState({error: "Please Select Start Time"});
      } else {
        data.start_time = this.state.start_time;
      }
      if(!this.state.end_time){
        this.setState({error: "Please Select End Time"});
      } else {
        data.end_time = this.state.end_time;
      }
    }

    const res = await this.props.editEvent(
      data,
      this.state.id
    );

    if (res.payload.data) {
      const date = moment(this.props.events.date).format("YYYY-MM-DD");
      const createData = {
        event: res.payload.data.id,
        date
      };
      const response = await this.props.addEvent(createData);
      response &&
        response.payload.status === 201 &&
        this.props.history.push("/calendar");
    }

    if (res.payload.status === 201) {
      if (this.props.events.mention) {
        this.props.history.push("/add-shift");
      } else {
        this.props.history.push("/events/0");
      }
    }
  };
  onhandleIconId = id => {
    this.setState({ id });
  };
  render() {
    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Edit Event</h1>
          </div>
          <EditStepper
            personalEvents={this.state.currentEvents}
            handleChange={this.handleChange}
            onStartTimeSelect={this.onStartTimeSelect}
            onEndTimeSelect={this.onEndTimeSelect}
            onCreateShift={this.onCreateShift}
            onhandleIconId={this.onhandleIconId}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    notifications: state.notifications,
    notes: state.notes,
    auth: state.user
  };
}

export default connect(
  mapStateToProps,
  { getEventById }
)(withRouter(EditEvent));
