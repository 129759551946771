import React from "react";
import PropTypes from "prop-types";
import { map, upperFirst } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { getEventsByType, deleteCurrentEvent } from "actions/events";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import moment from "moment";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import { Amplitude } from "@amplitude/react-amplitude";
import AddEvent from "./../events/AddEvents";
import "react-custom-scroll/dist/customScroll.css";
import ResponsiveModal from "../UI/modal";

const icons = {
  birthday: 1,
  datenight: 1,
  gym: 1,
  movies: 1,
  shopping: 1,
  sickday: 1,
  doctor: 1,
  petcare: 1,
  party: 1,
  spa: 1,
  vacation: 1,
  others: 0
};

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class SimpleTabs extends React.Component {
  state = {
    value: 0,
    openShiftPopup: false,
    openDeletePopup: false,
    currentData: null,
    error: "",
    open: false,
    id: null
  };
  openModal = () => {
    this.setState(prevstate => {
      return { open: !prevstate.open };
    });
  };
  onCloseModal = () => {
    this.setState(prevstate => {
      return { open: !prevstate.open, error: "" };
    });
  };
  componentDidMount() {
    this.setState({ value: this.props.tab });
  }
  handleChange = (event, value) => {
    if (value === 0) {
      this.props.getEventsByType();
    }
    this.props.role !== "family_member" &&
      this.setState({ value, id: null, error: "" });
  };
  componentWillUnmount() {
    return null;
  }
  handleDelete = async id => {
    const res = await this.props.deleteCurrentEvent( id);
    if (res && res.status === 204) {
      this.setState({
        openDeletePopup: false,
        openShiftPopup: false,
        id: null
      });
      await this.props.getEventsByType();
    } else if (res && res.status === 409) {
      this.setState({ error: res.data.message, id });
    }
  };

  onOpenShiftPopup = currentData => {
    this.setState({ openShiftPopup: true, currentData, error: "" });
  };

  onOpenDeletePopup = id => {
    this.setState({
      openShiftPopup: false,
      openDeletePopup: true,
      id
    });
  };

  onPopupClose = () => {
    this.setState({
      openShiftPopup: false,
      openDeletePopup: false,
      error: ""
    });
  };
  render() {
    const { classes } = this.props;
    const { value, currentData } = this.state;
    let duration =
      currentData &&
      (
        moment(currentData.end_time, "HH:mm A").diff(
          moment(currentData.start_time, "HH:mm A")
        ) /
        60000 /
        60
      ).toFixed(2);
    if (duration < 0) {
      duration = (+duration + 24).toFixed(2);
    }

    return (
      <Amplitude>
        {({ logEvent }) => (
          <JssProvider generateClassName={generateClassName}>
            <div className={classes.root + " tabs-container"}>
              {this.props.role && (
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    className="tabs"
                  >
                    {this.props.role !== "family_member" && (
                      <Tab
                        label="Shifts"
                        className="tab"
                        onClick={() => {
                          logEvent("click Shifts Tab");
                        }}
                      />
                    )}
                    <Tab
                      label={`${
                        this.props.role !== "family_member"
                          ? "Personal Events"
                          : ""
                      }`}
                      onClick={() => {
                        logEvent("click Personal Events Tab");
                      }}
                      className="tab"
                    />
                  </Tabs>
                </AppBar>
              )}
              {value === 0 && this.props.role !== "family_member" && (
                <TabContainer>
                  {this.props.myEvents && this.props.myEvents.length > 0 ? (
                    <div className="content-body p-0 w-100">
                      <div className="scrollbar-wrapper">
                        <CustomScroll
                          allowOuterScroll
                          addScrolledClass
                          heightRelativeToParent="100%"
                        >
                          <div className="list-wrap p-3 m-0">
                            <ul className="pb-3">
                              {map(this.props && this.props.myEvents, data => {
                                return (
                                  <li key={data.id}>
                                    <a
                                      href="/"
                                      className={`card card-with-icon lg ${
                                        data.name === "Off Day"
                                          ? "non-clickable"
                                          : ""
                                      }`}
                                      onClick={e => {
                                        logEvent("click current Shift");
                                        e.preventDefault();
                                        this.onOpenShiftPopup(data);
                                      }}
                                    >
                                      <span className="icon">
                                        {data.start_period && (
                                          <img
                                            src={require(`assets/img/${
                                              data.name === "Off Day"
                                                ? "off-day"
                                                : data.start_period
                                            }-icon.png`)}
                                            alt="afternoon icon"
                                          />
                                        )}
                                      </span>
                                      <div className="card-body row">
                                        <p className="font-xmd fw-500 ellipsis text-capitalize">
                                          {data.name === "Off Day" ? "Not Available" : data.name}
                                        </p>
                                        {data.name === "Off Day" ? (
                                          <p className="color-text-50 nowrap ml-3">24.00h</p>
                                        ) : (
                                          <p className="color-text-50 nowrap ml-3">
                                            {`${moment(
                                              data.start_time,
                                              "hh:mm A"
                                            ).format("h:mm A").replace(/^0(?:0:0?)?/, '')} 
                                              - 
                                              ${moment(
                                                data.end_time,
                                                "hh:mm A"
                                              ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}`}
                                          </p>
                                        )}
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>

                            {currentData && (
                              <ResponsiveModal
                                open={this.state.openShiftPopup}
                                onClose={this.onPopupClose}
                              >
                                <div className="modal-body">
                                  <div className="single-view-header with-icon">
                                    <span className="single-view-label">
                                      <img
                                        src={require(`assets/img/${
                                          currentData.start_period
                                        }-icon.png`)}
                                        alt="day icon"
                                      />
                                    </span>
                                    <div>
                                      <p className="font-lg fw-500 mb-2">
                                        Shift
                                      </p>
                                      <p className="color-text-70 text-capitalize">
                                        {upperFirst(currentData.start_period)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="single-view-body">
                                    <p className="font-md fw-700 mb-4 text-capitalize">
                                      {upperFirst(currentData.name)}
                                    </p>
                                    <ul className="list-with-icons">
                                      <li>
                                        <i className="icon-clock font-md color-black-30" />
                                        <span className="color-text-50 w-70">
                                          Start Time:
                                        </span>
                                        <span className="color-text-70">
                                          {moment(
                                              currentData.start_time,
                                              "hh:mm A"
                                            ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}
                                        </span>
                                      </li>
                                      <li>
                                        <i className="icon-clock font-md color-black-30" />
                                        <span className="color-text-50 w-70">
                                          End Time:
                                        </span>
                                        <span className="color-text-70">
                                          {moment(
                                            currentData.end_time,
                                            "hh:mm A"
                                          ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}
                                        </span>
                                      </li>
                                    </ul>
                                    <p className="color-text-50 w-70">
                                      Duration: {`${duration}`}h
                                    </p>
                                  </div>
                                  {this.state.error && (
                                    <div className="mx-6 mt-2">
                                      <p className="error-msg with-icon">
                                        {this.state.error}
                                      </p>
                                    </div>
                                  )}
                                  <div className="single-view-footer">
                                    <a
                                      href="/"
                                      onClick={e => {
                                        logEvent("click DELETE current Shift");
                                        e.preventDefault();
                                        this.handleDelete(currentData.id);
                                      }}
                                      className="btn-icon w-30 color-warning"
                                    >
                                      <i className="icon-delete icon-shadow" />
                                    </a>
                                  </div>
                                </div>
                              </ResponsiveModal>
                            )}
                          </div>
                        </CustomScroll>
                      </div>
                    </div>
                  ) : (
                    <div className="content-body placeholder-content">
                      <img
                        src={require("assets/img/list.svg")}
                        alt="list icon"
                      />
                      <p className="font-md">
                        You have not created any shifts yet
                      </p>
                    </div>
                  )}

                  <Link
                    to={"/add-shifts"}
                    className={`btn-rounded btn-add `}
                    onClick={() => {
                      logEvent("click Icon Plus in Shift Tab");
                    }}
                  >
                    <i className="icon-plus" />
                  </Link>
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  {this.props.pesonalEvents &&
                  this.props.pesonalEvents.length > 0 ? (
                    <div className="content-body p-0 w-100">
                      <div className="scrollbar-wrapper">
                        <CustomScroll
                          allowOuterScroll
                          addScrolledClass
                          heightRelativeToParent="100%"
                        >
                          <div className="list-wrap p-3 m-0">
                            <ul className="pb-3">
                              {map(this.props.pesonalEvents, data => {
                                let iconname = data.name
                                  .toLowerCase()
                                  .replace(" ", "");

                                if (data.model === false) {
                                  return;
                                }

                                return (
                                  <li key={data.id}>
                                    <div
                                      className={`card card-with-label-icon no-shadow ${
                                        !icons[iconname]
                                          ? "with-delete-btn"
                                          : ""
                                      } `}
                                    >
                                      <span className="card-label bg-purple">
                                        <i
                                          className={`icon-${
                                            icons[iconname]
                                              ? data.name
                                                  .toLowerCase()
                                                  .replace(" ", "-")
                                              : "other"
                                          } color-white box-30`}
                                        />
                                      </span>
                                      <div className="card-body">
                                        <p className="font-xmd fw-500 ellipsis text-capitalize">
                                          {data.name}
                                        </p>
                                      </div>

                                      {!data.default && (
                                        <button
                                          type="button"
                                          className="btn-delete-card btn-icon w-30 color-warning"
                                          onClick={() => {
                                            logEvent(
                                              "click current Personal Event"
                                            );
                                            this.onOpenDeletePopup(data.id);
                                          }}
                                        >
                                          <i className="icon-delete icon-shadow" />
                                        </button>
                                      )}
                                    </div>
                                    {this.state.error &&
                                      this.state.id &&
                                      this.state.id === data.id && (
                                        <p className="error-msg with-icon">
                                          {this.state.error}
                                        </p>
                                      )}
                                  </li>
                                );
                              })}
                            </ul>
                            <ResponsiveModal
                              open={this.state.openDeletePopup}
                              onClose={this.onPopupClose}
                            >
                              <div className="modal-body">
                                <div className="text-center pt-8 px-6 pb-5">
                                  <p className="font-lg fw-700 mb-4">
                                    Are you sure?
                                  </p>
                                  <p className="font-md">
                                    This will remove all the occurrences of the
                                    shift type on the calendar.
                                  </p>
                                </div>
                              </div>
                              {this.state.error && (
                                <div className="mx-6 mt-2">
                                  <p className="error-msg with-icon">
                                    {this.state.error}
                                  </p>
                                </div>
                              )}
                              <div className="modal-footer justify-around">
                                <button
                                  type="button"
                                  className="btn btn-link color-text-50"
                                  onClick={() => {
                                    logEvent("click NO to Delete Shift");
                                    this.onPopupClose();
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-white"
                                  onClick={() => {
                                    logEvent("click YES to Delete Shift");
                                    this.handleDelete(this.state.id);
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </ResponsiveModal>
                          </div>
                        </CustomScroll>
                      </div>
                    </div>
                  ) : (
                    <div className="content-body placeholder-content">
                      <img
                        src={require("assets/img/list.svg")}
                        alt="list icon"
                      />
                      <p className="font-md">
                        You have not created any personal events yet
                      </p>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn-rounded btn-add"
                    onClick={() => {
                      logEvent("click Icon Plus in Personal Event Tab");
                      this.openModal();
                    }}
                  >
                    <i className="icon-plus" />
                  </button>
                  <AddEvent
                    open={this.state.open}
                    onClose={this.onCloseModal}
                  />
                  {/* <Link
                    to={"/add-events"}
                    className="btn-rounded btn-add"
                    onClick={() => {
                      logEvent("click Icon Plus in Personal Event Tab");
                    }}
                  >
                    <i className="icon-plus" />
                  </Link> */}
                </TabContainer>
              )}
            </div>
          </JssProvider>
        )}
      </Amplitude>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state
  };
}
const EventTabs = withStyles(styles)(SimpleTabs);
export default connect(
  mapStateToProps,
  { getEventsByType, deleteCurrentEvent }
)(EventTabs);
