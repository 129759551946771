import { GET_NOTIFICATIONS, EDIT_NOTIFICATIONS } from "actions/types";
import {
  GET_NOTIFICATIONS_BY_DATE,
  GET_GIVEAWAY_REQUEST,
  GET_SWITCH_REQUEST,
  RESET_STATUS,
  GET_INVITE_USER,
  GET_NOTIFICATIONS_COUNT,
  GET_CURRENT_NOTIFICATION
} from "../actions/types";

const INITIAL_STATE = {
  notifications: "",
  status: "",
  notificationsCount: null,
  currentNotification: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case GET_NOTIFICATIONS_COUNT:
      return { ...state, notificationsCount: action.payload.results.count };
    case GET_NOTIFICATIONS_BY_DATE:
      return { ...state, notificationsByDate: action.payload };
    case GET_CURRENT_NOTIFICATION:
      return { ...state, currentNotification: action.payload };
    case EDIT_NOTIFICATIONS:
      const newState = { ...state };
      newState.notifications.map(elem => {
        if (elem.id === action.payload.id) {
          elem.status = action.payload.status;
        }
      });
      return { ...newState };
    case GET_GIVEAWAY_REQUEST:
      return { ...state, status: action.payload };
    case GET_SWITCH_REQUEST:
      return { ...state, status: action.payload };
    case GET_INVITE_USER:
      return { ...state, status: action.payload };
    case RESET_STATUS:
      return { ...state, status: "" };
    default:
      return state;
  }
}
