import React, { Component } from "react";
import Modal from "react-responsive-modal";
import {getUser} from "actions/auth";
import {
  editSwitchRequest,
  editGiveAwayRequest,
  getGiveAwayRequest,
  getSwitchRequest,
  getCurrentNotification
} from "actions/notifications";
import { updateInviteUser } from "actions/events";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";

class ResponsiveModal extends Component {
  state = {
    error: null,
    buttons: true
  };
  onCloseModal = async () => {
    this.setState({
      error: "",
      buttons: true
    });
    this.props.onClose();
  };
 

  handlerequest = async (type, id, status) => {
    let method = "";
    let changeStatus: "";

    if (type === "switch") {
      method = this.props.editSwitchRequest;
      changeStatus = "to_user_status";
    } else if (type === "giveaway") {
      method = this.props.editGiveAwayRequest;
      changeStatus = "to_user_status";
    } else {
      method = this.props.updateInviteUser;
      changeStatus = "status";
    }
    const dataStatus = { [changeStatus]: status };

    const res = method && (await method( id, dataStatus));
    if (res && res.payload && res.payload.status === 200) {
      this.props.history.push("/notifications-list");
    } else if (res && res.status === 200) {
      this.props.history.push("/notifications-list");
    } else if (res && res.status === 400) {
      const acceptError =
        res.data[0] && res.data[0] === "This shift is no longer available."
          ? res.data[0]
          : `You have already accepted this ${type} request`;
      this.setState({
        error: type !== "none" ? acceptError : res.data.non_field_errors
      });
    }
  };

  handleType = type => {
    switch (type) {
      case "Giveaway request":
        return true;
      case "Switch request":
        return true;
      case "Invitation":
        return true;
      default:
        return false;
    }
  };
  handleInvitation = (data, firstName, lastName) => {
    switch (data) {
      case "Invitation":
        return `${firstName} ${lastName} wants to share calendars with you.`;
      case "Invitation accepted":
        return `You have accepted to share calendars with ${firstName} ${lastName}.`;
      case "Invitation rejected":
        return `You have rejected to share calendars with ${firstName} ${lastName}.`;
      default:
        return;
    }
  };
  render() {
    const { open } = this.props;
    const currentNotification = this.props.currentNotification;
    const types =
      currentNotification && this.handleType(currentNotification.text);
    const status =
      this.props.status && this.props.status.to_user_status
        ? this.props.status.to_user_status
        : this.props.status.status;
    
    if(!this.props.auth.user.id){
      this.props.getUser();
    }
    return (
      <div>
        <Modal
          classNames={{
            overlay: "modal-overlay",
            modal: "modal",
            closeButton: "modal-close-btn"
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
          {currentNotification && (
            <div className="modal-body">
              <div className="single-view-header with-label">
                <span className="single-view-label bg-pink" />
                <div>
                  <p className="font-lg fw-500 mb-2">
                    {`${currentNotification.sending_user.first_name} ${
                      currentNotification.sending_user.last_name
                    }`}
                  </p>

                  <p className="color-text-70">{currentNotification.text}</p>
                </div>
              </div>
              <div className="single-view-body">
                {!currentNotification.text.includes("Invitation") &&
                !currentNotification.text.includes("Switch approved by manager") &&
                !currentNotification.text.includes("Giveaway approved by manager") &&
                (
                  <p className="color-text-50 fw-500 mb-3">
                    {`${
                      currentNotification.request_type !== "switch"
                        ? (currentNotification.receiver_user_event !== null && currentNotification.receiver_user_event.event.user === this.props.auth.user.id ? "Give away your" :
                        currentNotification.receiver_user_event === null && currentNotification.text.includes("Pending giveaway approval by manager") ? 'Give away your' : 'Pick up this')
                        : "Switch this"
                    } `}
                    shift:
                  </p>
                )}
                {currentNotification.text.includes("Switch approved by manager") && (
                  <p className="color-text-50 fw-500 mb-3">
                    Shift switched from your:
                  </p>
                )}
                {currentNotification.text.includes("Giveaway approved by manager") && (
                  <p className="color-text-50 fw-500 mb-3">
                    {currentNotification.receiver_user_event !== null && currentNotification.receiver_user_event.event.user === this.props.auth.user.id ? "Picked up this shift:" : "Give away your shift:"}
                  </p>
                )}
                {!currentNotification.text.includes("Invitation") && (
                  <ul className="list-with-icons">
                    <li>
                      <i className="icon-calendar font-md color-black-30" />
                      <span className="font-md fw-800">
                        {currentNotification.sending_user_event &&
                          currentNotification.sending_user_event.date &&
                          moment(
                            currentNotification.sending_user_event.date
                          ).format("ddd, MMM Do, YYYY")}
                      </span>
                    </li>
                    {currentNotification.sending_user_event &&
                      currentNotification.sending_user_event.event
                        .start_time && (
                        <li>
                          <i className="icon-clock font-md color-black-30" />
                          <span className="color-text-50 w-70">
                            Start Time:
                          </span>
                          <span>
                            {currentNotification.sending_user_event &&
                              moment(
                                currentNotification.sending_user_event.event
                                  .start_time,
                                "hh:mm A"
                              ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}
                          </span>
                        </li>
                      )}
                    {currentNotification.sending_user_event &&
                      currentNotification.sending_user_event.event
                        .start_time && (
                        <li>
                          <i className="icon-clock font-md color-black-30" />
                          <span className="color-text-50 w-70">End Time:</span>
                          <span>
                            {moment(
                              currentNotification.sending_user_event &&
                                currentNotification.sending_user_event.event
                                  .end_time,
                              "hh:mm A"
                            ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}
                          </span>
                        </li>
                      )}
                  </ul>
                )}

                <p className="font-xmd">
                  {this.handleInvitation(
                    currentNotification.text,
                    currentNotification.sending_user.first_name,
                    currentNotification.sending_user.last_name
                  )}
                </p>
                {currentNotification.request_type === "switch" && (
                  <p className="single-view-icon">
                    <i className="icon-switch-vertical color-text-70 font-lg" />
                  </p>
                )}
                {currentNotification.request_type === "switch" && !currentNotification.text.includes("Switch approved by manager") && (
                  <p className="color-text-50 fw-500 mb-3 pt-3">
                    For your shift:
                  </p>
                )}
                {currentNotification.text.includes("Switch approved by manager") && (
                  <p className="color-text-50 fw-500 mb-3">
                    To:
                  </p>
                )}
                {currentNotification.receiver_user_event &&
                  currentNotification.receiver_user_event.date && (
                    <ul className="list-with-icons">
                      <li>
                        <i className="icon-calendar font-md color-black-30" />
                        <span className="font-md fw-800">
                          {currentNotification.receiver_user_event &&
                            currentNotification.receiver_user_event.date &&
                            moment(
                              currentNotification.receiver_user_event.date
                            ).format("ddd, MMM Do, YYYY")}
                        </span>
                      </li>
                      <li>
                        <i className="icon-clock font-md color-black-30" />
                        <span className="color-text-50 w-70">Start Time:</span>
                        <span>
                          {moment(
                            currentNotification.receiver_user_event &&
                              currentNotification.receiver_user_event.event
                                .start_time,
                            "hh:mm A"
                          ).format("h:mm A")}
                        </span>
                      </li>
                      <li>
                        <i className="icon-clock font-md color-black-30" />
                        <span className="color-text-50 w-70">End Time:</span>
                        <span>
                          {moment(
                            currentNotification.receiver_user_event &&
                              currentNotification.receiver_user_event.event
                                .end_time,
                            "hh:mm A"
                          ).format("h:mm A").replace(/^0(?:0:0?)?/, '')}
                        </span>
                      </li>
                    </ul>
                  )}
              </div>
              {this.state.error && (
                <div className="mx-6">
                  <p className="error-msg with-icon">{this.state.error}</p>
                </div>
              )}
              {types && !this.props.buttons && (
                <div className="mx-6 mb-8">
                  <p className="info-text">{`You have already ${status} this ${
                    currentNotification.request_type === "none"
                      ? "invite"
                      : currentNotification.request_type
                  } request`}</p>
                </div>
              )}

              {types && this.props.buttons && (
                <div className="single-view-footer justify-around">
                  <button
                    type="button"
                    className="btn btn-link color-warning"
                    onClick={() =>
                      this.handlerequest(
                        currentNotification.request_type,
                        currentNotification.shift_request
                          ? currentNotification.shift_request
                          : currentNotification.share_calendar_request,
                        "rejected"
                      )
                    }
                  >
                    Reject
                  </button>

                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() =>
                      this.handlerequest(
                        currentNotification.request_type,
                        currentNotification.shift_request
                          ? currentNotification.shift_request
                          : currentNotification.share_calendar_request,
                        "accepted"
                      )
                    }
                  >
                    Accept
                  </button>
                </div>
              )}
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user,
    status: state.notifications.status,
    currentNotification: state.notifications.currentNotification
  };
}

export default connect(
  mapStateToProps,
  {
    editSwitchRequest,
    getCurrentNotification,
    editGiveAwayRequest,
    updateInviteUser,
    getGiveAwayRequest,
    getSwitchRequest,
    getUser
  }
)(withRouter(ResponsiveModal));
