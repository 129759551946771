import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import moment from "moment";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import EditStepper from "./EditStepper";
import { Link } from "react-router-dom";
import { getEventById, editEvent } from "actions/events";
import TimePicker from "../UI/timePicker";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function getSteps() {
  return ["Name", "Duration"];
}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: false,
    name: "",
    start_time: "",
    end_time: "",
    nameError: ""
  };

  async componentDidMount() {
    await this.props.getEventById(this.props.eventId);
    const User = this.props.events.eventById;
    // const User = this.props.user.user && this.props.user.user.manager;
    this.setState({
      name: User && User.name,
      start_time: User && User.start_time,
      end_time: User && User.end_time,
    });
    this.props.setValues({
      ...this.props.values,
      name: User && User.name,
      start_time: User && User.start_time,
      end_time: User && User.end_time,
      id: User && User.id,
    });
  }

  isStepOptional = step => {
    return step === 1;
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1
    });
  };
  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleClick = elem => {
    this.setState({
      isOpen: elem
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleSelect = async time => {
    let formatTime = moment(time).format("HH:mm:ss");
    let myTime = moment(formatTime, "HH:mm:ss");

    if (this.state.isOpen === "Start") {
      await this.setState({
        start_time: moment(time, "hh:mm A").format("hh:mm A")
      });
      await this.props.setValues({
        ...this.props.values,
        start_time: moment(time, "hh:mm A").format("hh:mm A")
      });
    }
    if (this.state.isOpen === "End") {
      await this.setState({
        end_time: moment(time, "hh:mm A").format("hh:mm A")
      });
      await this.props.setValues({
        ...this.props.values,
        end_time: moment(time, "hh:mm A").format("hh:mm A")
      });
    }
    let start =
      this.state.start_time && moment(this.state.start_time, "hh:mm A");
    let end = this.state.end_time && moment(this.state.end_time, "hh:mm A");
    if (start && end) {
      if (!start.isBefore(end)) {
        this.setState({
          timeError: "Please select a valid time."
        });
        this.props.setErrors({
          ...this.props.errors,
          timeError: "Please select a valid time."
        });
      } else {
        this.setState({
          timeError: ""
        });
      }
    }
    if (
      moment(this.state.end_time, "HH:mm").diff(
        moment(this.state.start_time, "HH:mm")
      ) /
        60000 ===
      0
    ) {
      await this.setState({
        timeError: "Please select a valid time."
      });
    } else {
      await this.setState({
        timeError: ""
      });
    }
    this.setState({ isOpen: "" });
  };
  getTime = async (time, period) => {
    if (period === "Start") {
      await this.setState({ start_time: time, isOpen: period });
    } else if (period === "End") {
      await this.setState({ end_time: time, isOpen: period });
    }
  };
  handleClickStep(step, name) {
    if (!name) {
      this.props.setErrors({
        name: "This field is mandatory."
      });
    } else {
      this.setState({ activeStep: step });
    }
  }
  render() {
    const {
      classes,
      eventById,
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleSubmit,
      handleBlur
    } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...props}
                  className={
                    activeStep >= index ? "step completed-step" : "step"
                  }
                  onClick={() => {
                    this.handleClickStep(index, values.name);
                  }}
                >
                  <StepLabel {...labelProps} className="step-label">
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <div className="stepper-body">
            <Form>
              <div className="stepper-content">
                {activeStep === 0 ? (
                  <div
                    className={
                      touched.name && errors.name
                        ? "form-field has-error"
                        : "form-field"
                    }
                  >
                    <div className="form-input">
                      <Field
                        type="text"
                        placeholder="Event Name"
                        className="text-capitalize"
                        name="name"
                        autoComplete="off"
                      />
                      <span className="border" />
                    </div>
                    {touched.name &&
                      errors.name && <p className="error-msg">{errors.name}</p>}
                  </div>
                ) : (
                  <div className="time-picker">
                    <div className="time">
                      <TimePicker
                        getTime={(time, period) => this.getTime(time, period)}
                        handleSelect={this.handleSelect}
                        time={this.state.start_time}
                        period="Start"
                      />
                    </div>
                    <div className="time">
                      <TimePicker
                        getTime={(time, period) => this.getTime(time, period)}
                        handleSelect={this.handleSelect}
                        time={this.state.end_time}
                        period="End"
                      />
                    </div>
                    {this.state.timeError &&
                      this.state.timeError && (
                        <p className="error-msg with-icon">
                          {this.state.timeError}
                        </p>
                      )}
                  </div>
                )}
              </div>

              <div className="content-footer">
                {activeStep === 0 ? (
                  <Link to="/calendar" className="btn btn-link color-text-50">
                    {" "}
                    Cancel{" "}
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-link color-text-50"
                    onClick={this.handleBack}
                  >
                    {" "}
                    Back{" "}
                  </button>
                )}

                {activeStep === steps.length - 1 ? (
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={handleSubmit}
                  >
                    {" "}
                    Save{" "}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={this.handleNext}
                    disabled={errors.name}
                  >
                    {" "}
                    Next{" "}
                  </button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </JssProvider>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};

const EditShiftFormik = withFormik({
  mapPropsToValues(props) {
    return {
      name: props.name || ""
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is mandatory.")
  }),
  async handleSubmit(values, { props, setErrors }) {
    const userData = {
      name: values.name,
      start_time: values.start_time,
      end_time: values.end_time
    };
    const res = await props.editEvent(userData, values.id);
    res.payload &&
      res.payload.status === 200 &&
      props.history.push(`/calendar`);
  }
})(HorizontalLinearStepper);

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}
const EditShift = withStyles(styles)(EditShiftFormik);
export default connect(
    mapStateToProps,
    { getEventById, editEvent }
  )(withRouter(EditShift))
