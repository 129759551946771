import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  GET_USER_DASHBOARD,
  GET_ADMIN_DASHBOARD,
  GET_SETTINGS,
  ADD_SETTINGS,
  EDIT_SETTINGS,
  ERROR
} from "./types";

export const getUserDashboard = ( from, to, search, page) => dispatch => {
  const url = `/users-dashboard/?${from ? `from_date=${from}` : ""}${
    to ? `&to_date=${to}` : ""
  }${search ? `&search=${search}&` : ""}${page ? `&page=${page}` : ""}`;
  return axiosToken
    .get(
      url
    )
    .then(res => {
      return dispatch({
        type: GET_USER_DASHBOARD,
        payload: res
      });
    });
};

export const getAdminDashboard = ( from, to) => dispatch => {
  const url = `/users/admin-dashboard/?${from ? `from_date=${from}` : ""}${
    to ? `&to_date=${to}` : ""
  }`;

  return axiosToken
    .get(
      url
    )
    .then(res => {
      return dispatch({
        type: GET_ADMIN_DASHBOARD,
        payload: res
      });
    });
};
export const getSettings = () => dispatch => {
  const url = `/settings/`;
  return axiosToken
    .get(
      url
    
    )
    .then(res => {
      return dispatch({
        type: GET_SETTINGS,
        payload: res
      });
    });
};

export const addSettings = ( data) => dispatch => {
  const url = `/settings/`;
  return axiosToken
    .post(
      url,
      data
      
    )
    .then(res => {
      return dispatch({
        type: ADD_SETTINGS,
        payload: res
      });
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err
      });
    });
};

export const editSettings = ( data, id) => dispatch => {
  const url = `/settings/${id}/`;
  return axiosToken
    .patch(
      url,
      data
     
    )
    .then(res => {
      return dispatch({
        type: EDIT_SETTINGS,
        payload: res
      });
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err
      });
    });
};
