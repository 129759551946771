import React, { PureComponent } from "react";

import moment from "moment";
import { withRouter } from "react-router-dom";
import { map } from "lodash";
import "./style.css";
import { Amplitude } from "@amplitude/react-amplitude";
import {setErrorMessage, getEventById, getEvents, addEvent, getEvent, deleteDateEvent, userEnteredQuickMode} from "../../actions/events";
import {connect} from "react-redux";

const yesterday = moment(Date.now())
  .subtract(1, "days");
const now = moment(Date.now())
  .format("YYYY/MM/DD");
let quickModeEventDays = [];
let currentDateArray = [];
let quickModeEvents = [];

class CellComponent extends PureComponent {

  state = {
    quickModeEventDays: [],
    quickMode: this.props.quickMode,
    quickModeEventIdChanged: false,
    quickModeEventId: null,
    existingEvents: [],
    quickModeEvents: [],
    noEventsOnMonth: false,
    error: '',
    currentLocation: this.props.location.pathname,
    personalEvents: [],
    notes: [],
    notifications: [],
    updatingState: false,
    preventExistingStateRendering: false,
  };

  renderEvents = events => {
    // console.log('Existing Events');
    let selectedStartData = moment(this.props._d).format("DD.MM.YYYY");
    let eventjsx = null;
    const curentMonth =
      moment(this.props._d).format("MM YYYY") ===
      moment(this.props.currentDate).format("MM YYYY");

    map(events, data => {
      const myday = moment(data.days).format("DD.MM.YYYY");

      if (myday === selectedStartData) {
        data.events.map(event => {
          if (event.type === "shift") {
            eventjsx = (
              <img
                src={require(`assets/img/${
                  event.name === "Off Day" ? "off-day" : event.start_period
                  }${
                  yesterday.isBefore(data.days) && curentMonth ? "" : "-passed"
                  }-icon.png`)}
                alt={`${event.start_period}`}
              />
            );
          }
        });
      }
    });
    return eventjsx;
  };

  renderQuickModeEvents = events => {
    // console.log('Quick Mode Events');
    let selectedStartData = moment(this.props._d).format("DD.MM.YYYY");
    let eventjsx = null;
    const curentMonth =
      moment(this.props._d).format("MM YYYY") ===
      moment(this.props.currentDate).format("MM YYYY");

    map(events, data => {
      const myday = moment(data.days).format("DD.MM.YYYY");

      if (myday === selectedStartData) {
        data.events.map(event => {
          if (event.type === "shift") {
            eventjsx = (
              <img
                src={require(`assets/img/${
                  event.name === "Off Day" ? "off-day" : event.start_period
                  }${
                  yesterday.isBefore(data.days) && curentMonth ? "" : "-passed"
                  }-icon.png`)}
                alt={`${event.start_period}`}
              />
            );
          }
        });
      }
    });
    return eventjsx;
  };

  componentWillUnmount() {
    this.setState({existingEvents: [...this.state.existingEvents, [this.state.quickModeEvents]]});
    this.props.userEnteredQuickMode(false);
  }

  componentDidUpdate() {
    if(this.props.allowCellExistingEventsToUpdate === false){
      if(this.props.events && this.props.events.length > 0 && this.state.existingEvents.length === 0){
        this.setState({existingEvents: this.props.events});
      }
    }
    else if(this.props.allowCellExistingEventsToUpdate === true && this.props.events.length > 0){
      this.setState({existingEvents: this.props.events});
    }

    if(this.props.events && this.props.events.length === 0 && this.state.noEventsOnMonth === false){
      this.setState({noEventsOnMonth: true});
    }

    let personalEvents = [];
    if(this.state.personalEvents.length === 0 && this.props.events) {
      map(this.props.events, elem => {
        map(elem.events, (data) => {
          if (data.type === "personal") {
            personalEvents.push(elem);
          }
        });
      });
      if(personalEvents.length > 0) {
        this.setState({personalEvents: personalEvents});
      }
    }

    if(this.props.notifications && this.state.notifications.length === 0){
      this.setState({notifications: this.props.notifications});
    }
  }

  async addShift (data, date, quickModeEventDay, currentDate) {
    const res = await this.props.addEvent(data);
    if (res && res.payload && res.payload.status === 201) {
      const getEvent = await this.props.getEvent(date);
      if (getEvent && getEvent.payload && getEvent.payload.status === 200 /*&& !isExist*/) {
        getEvent.payload.data.map(async event => {
          if(event.events[0].type === 'shift'){
            /*ADD ITEM TO STATE*/
            if(this.state.noEventsOnMonth === true){
              quickModeEvents.push(event);
            } else {
              await this.setState({ quickModeEvents: [...this.state.quickModeEvents, event ] });
            }
            /*Add day to "quickModeEventDays" array*/
            quickModeEventDays.push(quickModeEventDay);
            currentDateArray.push(currentDate);
            if(this.state.noEventsOnMonth === true){
              await this.setState({preventExistingStateRendering: true});
              await this.setState({quickModeEvents: quickModeEvents});
            }
            this.setState({updatingState: true});
          }
        });
      }
    }
    if (res && res.status && res.status === 400) {
      /*ADDING ERROR*/
      this.props.setErrorMessage('You cannot add a shift for this day');
    }
  };

  async deleteShift (eventDateId, quickModeEventDay, currentDate, data, date) {
    const deleteEventFromDay = await this.props.deleteDateEvent(eventDateId);
    if (deleteEventFromDay && deleteEventFromDay.status === 204) {
      this.setState({updatingState: false});
      const formattedDate = moment(currentDate).format("YYYY-MM-DD");

      if(this.state.quickModeEventIdChanged === true){
        if(this.state.noEventsOnMonth === true){
          quickModeEvents.map(function (event, key) {
            if(event.days === formattedDate){
              quickModeEvents.splice(key, 1);
            }
          });
        } else {
          this.state.quickModeEvents.splice(-1, 1);
        }
        this.addShift(data, date);
      }
      if(this.state.quickModeEventIdChanged === false){
        if(this.state.noEventsOnMonth === true){
          quickModeEvents.map(function (event, key) {
            if(event.days === formattedDate){
              quickModeEvents.splice(key, 1);
            }
          });
        } else {
          this.state.quickModeEvents.splice(-1, 1);
        }
        if(quickModeEventDays.indexOf(quickModeEventDay) !== -1){
          quickModeEventDays.splice(quickModeEventDays.indexOf(quickModeEventDay), 1);
        }
        if(currentDateArray.indexOf(currentDate) !== -1){
          currentDateArray.splice(currentDateArray.indexOf(currentDate), 1);
        }
      }
      if(this.state.noEventsOnMonth === true){
        await this.setState({quickModeEvents: quickModeEvents});
      }
      this.setState({updatingState: true});
    }
    else {
      this.props.setErrorMessage(deleteEventFromDay.data.message && deleteEventFromDay.data.message);
    }
    this.setState({updatingState: false});
  };

  async handleAddShiftEvent (quickModeEvent, currentDate, quickModeEventDay) {
    const date = moment(currentDate).format("YYYY-MM-DD");
    if(typeof this.props.quickMode !== "undefined" && this.props.quickMode === true && quickModeEvent) {
      if (quickModeEvent.id) {
        if(quickModeEvent.id !== this.state.quickModeEventId) {
          this.setState({quickModeEventIdChanged: true});
        }
        else {
          this.setState({quickModeEventIdChanged: false});
        }
        this.setState({quickModeEventId: quickModeEvent.id});

        /*Data that need to be posted*/
        const data = {
          event: quickModeEvent.id,
          date
        };

        /*If No Event On That Date, No New Added Shifts, NO OLD EVENTS --- ADD EVENT*/
        if(quickModeEventDays.indexOf(quickModeEventDay) === -1) {
          this.addShift(data, date, quickModeEventDay, currentDate);
        }
        else {
          /*DELETE SHIFT SINCE IT WAS ADDED ALREADY*/
          /*Get Date Events*/
          const getEvents = await this.props.getEvents(
            date
          );
          let eventDateId = null;
          /*See if day is match --- if so, get event_date_id (eventDateId)*/
          getEvents && getEvents.payload.data && getEvents.payload.data.map( (events) => {
            if(events.days.indexOf(date) !== -1) {
              map(events.events, (event) => {
                if (event.type === "shift") {
                  eventDateId = events.event_date_id;
                }
              });
            }
          });
          if(eventDateId && quickModeEventDays.indexOf(quickModeEventDay) !== -1) {
            this.deleteShift(eventDateId, quickModeEventDay, currentDate, data, date);
          }
        }
      }
      else {
        this.props.setErrorMessage("Please choose event");
      }
    }
  };

  render() {
    const check = moment(this.props._d, "YYYY/MM/DD");
    const day = check.format("D");

    const {personalEvents, notifications} = this.state;
    let notes = null;
    if(this.props.notes && this.props.notes.notes.results && this.state.notes.length === 0){
      notes = this.props.notes.notes.results;
    }

    const myday = moment(this.props._d);
    const time = moment(yesterday);
    myday.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second")
    });
    let current = yesterday.isBefore(myday);
    let dayClassName = '';
    const currentMonth = moment(this.props._d).format("MM YYYY") === moment(this.props.currentDate).format("MM YYYY");

    if(currentMonth === true && current === true){
      dayClassName = '';
    }
    else {
      dayClassName = 'disabled-day';
    }

    let isPersonal = false;

    if (typeof this.props.quickMode === undefined || this.props.quickMode === false) {
      quickModeEventDays = [];
      currentDateArray = [];
    }

    return (
      <Amplitude>
        {({ logEvent }) => (
          <div
            className={`
              ${check.format("YYYY/MM/DD") === now ? "current-day" : "day"} ${dayClassName}
              ${this.props.quickMode && currentMonth && this.state.currentLocation === '/calendar' && quickModeEventDays.indexOf(day) !== -1 ? "selected-day" : ""}
            `}

            onClick={ async() => {
              this.props.setErrorMessage('');
              await logEvent("click Calendar days");
              this.props.quickMode && this.handleAddShiftEvent(this.props.quickModeEvent, this.props.currentDate, day);
            }
            }
          >
            {day}
            <div className="shift-day" data-updating={this.state.updatingState}>
              {this.props.match.path === "/calendar" && (
                <ul>
                  {notifications &&
                  notifications.map((data, i) => {
                    if (
                      moment(data.days).format("DD.MM.YYYY") ===
                      moment(this.props._d).format("DD.MM.YYYY")
                    ) {
                      return (
                        <li
                          key={i}
                          className={
                            current && currentMonth
                              ? "bg-pink"
                              : "bg-pink bg-grey-dark"
                          }
                        />
                      );
                    }
                  })}

                  {
                    (personalEvents &&
                    map(personalEvents, (data) => {
                      if (
                        moment(data.days).format("DD.MM.YYYY") ===
                        moment(this.props._d).format("DD.MM.YYYY")
                      ) {
                        isPersonal = true;
                      }
                    }),
                    isPersonal && (
                      <li
                        className={
                          current && currentMonth
                            ? "bg-purple"
                            : "bg-purple bg-grey-dark"
                        }
                      />
                    ))
                  }

                  {notes &&
                  notes.map((data, i) => {
                    if (
                      moment(data.days).format("DD.MM.YYYY") ===
                      moment(this.props._d).format("DD.MM.YYYY")
                    ) {
                      return (
                        <li
                          key={i}
                          className={
                            current && currentMonth
                              ? "bg-orange"
                              : "bg-orange bg-grey-dark"
                          }
                        />
                      );
                    }
                  })
                  }
                </ul>
              )}

              {this.state.preventExistingStateRendering === false && this.state.existingEvents && this.state.existingEvents.length > 0 && this.state.quickModeEvents.length === 0 &&
              this.props.role !== "family_member" &&
              this.renderEvents(this.state.existingEvents)}

              {this.state.quickModeEvents && this.state.quickModeEvents.length > 0 &&
              this.props.role !== "family_member" &&
              this.renderQuickModeEvents(this.state.quickModeEvents)}
            </div>
          </div>
        )}
      </Amplitude>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickMode: state.events.quickMode,
    quickModeEvent: state.events.eventById,
    events: state.events.events.data,
    notifications: state.notifications.notificationsByDate,
    notes: state.notes,
  };
}

export default connect(
  mapStateToProps,
  {
    setErrorMessage,
    getEventById,
    getEvents,
    addEvent,
    getEvent,
    deleteDateEvent,
    userEnteredQuickMode
  }
)(withRouter(CellComponent));
