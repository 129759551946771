import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
// import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import {
  signupUser,
  followedUser,
  checkMailIdentity,
  deleteCalendarRequest
} from "actions/auth";
import ResponsiveModal from "../UI/modal";

class SignUp extends Component {
  state = {
    openEmailDifferencePopup: true
  };
  async componentDidMount() {
    if (this.props.match.params.id) {
      const res = await this.props.followedUser(this.props.match.params.id);
      sessionStorage.setItem("paramsId1", this.props.match.params.id1);
      this.props.history.replace("/sign-up");
    }
  }
  onPopupClose = () => {
    this.props.setValues({
      ...this.props.values,
      openEmailDifferencePopup: false,
      email: ""
    });
  };

  async onYesClick(values) {
    const slug1 = sessionStorage.getItem("paramsId1");
    const response = await deleteCalendarRequest(
      slug1,
      values.email,
      values.firstName
    );
    if (response.data.message === "Mail is confirmed") {
      const userData = {
        email: values.email,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        title: "",
        role: values.shiftWorker === "Yes" ? "trial_user" : "family_member"
      };
      const res = await this.props.signupUser(userData);
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.email &&
        res.payload.data.email[0] ===
          "user with this email address already exists."
      ) {
        this.props.setErrors({ email: "This email already exists." });
      } else if (res && res.payload && res.payload.status > 300) {
        this.props.setErrors({ error: "Something went wrong" });
      } else {
        this.props.setValues({ signup: true });
      }
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleSubmit,
      handleChange,
      handleBlur
    } = this.props;

    return (
      <Fragment>
        <section className="bg-primary content-center">
          <div className="container">
            {!values.signup && (
              <div className="content">
                <div className="sign-up-content color-white">
                  <Link to={"/"} className="logo">
                    <img src={require("assets/img/logo.svg")} alt="Turno" />
                  </Link>
                  ​<h1 className="my-6">Sign Up</h1>​
                  <Form>
                    <div
                      className={
                        touched.firstName && errors.firstName
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.firstName && errors.firstName && (
                        <p className="error-msg">{errors.firstName}</p>
                      )}
                    </div>
                    ​
                    <div
                      className={
                        touched.lastName && errors.lastName
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.lastName && errors.lastName && (
                        <p className="error-msg">{errors.lastName}</p>
                      )}
                    </div>
                    ​
                    <div
                      className={
                        touched.email && errors.email
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.email && errors.email && (
                        <p className="error-msg">{errors.email}</p>
                      )}
                    </div>
                    ​
                    <div
                      className={
                        touched.password && errors.password
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="password"
                          name="password"
                          placeholder="Password (min 6 characters)"
                        />
                        <span className="border" />
                      </div>
                      {touched.password && errors.password && (
                        <p className="error-msg">{errors.password}</p>
                      )}
                    </div>
                    ​
                    <div className="form-field form-field-white">
                      <div className="sign-up-radio">
                        <p>Are you a shift worker?</p>
                        <div className="flex align-center">
                          <div className="radio-wrap radio-wrap-white mr-7">
                            <label>
                              <input
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="shiftWorker"
                                value="Yes"
                              />
                              ​<span className="icon" />
                              <span className="text">Yes</span>
                            </label>
                          </div>
                          <div className="radio-wrap radio-wrap-white">
                            <label>
                              <input
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="shiftWorker"
                                value="No"
                              />
                              <span className="icon" />
                              <span className="text">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {touched.shiftWorker && errors.shiftWorker && (
                        <p className="error-msg with-icon">
                          {errors.shiftWorker}
                        </p>
                      )}
                      {errors.error && (
                        <p className="error-msg with-icon">{errors.error}</p>
                      )}
                    </div>
                    ​
                    <Amplitude>
                      {({ logEvent }) => (
                        <button
                          type="button"
                          onClick={() => {
                            logEvent("click SignUp");
                            handleSubmit();
                          }}
                          className="btn btn-white w-100 mt-2"
                        >
                          Get Started
                        </button>
                      )}
                    </Amplitude>
                    <p className="fw-200 mt-5 color-white-70">
                      By continuing, you agree to the{" "}
                      <a href="#/" className="link color-white-70">
                        Terms of Service
                      </a>
                    </p>
                    <ResponsiveModal
                      open={values.openEmailDifferencePopup}
                      onClose={this.onPopupClose}
                    >
                      <div className="modal-body">
                        <div className="text-center pt-8 px-6 pb-5">
                          <p className="font-lg fw-700 mb-4">Are you sure?</p>
                          <p className="font-md">
                            This is not the email address that you're invited
                            with. You will not be connected with the person who
                            invited you. <br /> Do you want to continue?
                          </p>
                        </div>
                      </div>
                      <div className="modal-footer justify-around">
                        <button
                          type="button"
                          className="btn btn-link color-text-50"
                          onClick={this.onPopupClose}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className="btn btn-white"
                          onClick={() => this.onYesClick(values)}
                        >
                          Yes
                        </button>
                      </div>
                    </ResponsiveModal>
                  </Form>
                  ​
                  <div className="mt-6">
                    Already have an account?{" "}
                    <Link to={"sign-in"} className="link font-base color-white">
                      Sign in
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {values.signup && (
              <div className="content">
                <div className="info-content color-white">
                  <Link to={"/"} className="logo">
                    <img src={require("assets/img/logo.svg")} alt="Turno" />
                  </Link>
                  <h1 className="no-transform mb-4">Successfully signed up!</h1>
                  <p className="color-white-70 text-center">
                    Please check your email to complete the registration
                    process.
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>
      </Fragment>
    );
  }
}
const SignUpFormik = withFormik({
  mapPropsToValues({ firstName, lastName, email, password, shiftWorker }) {
    return {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      password: password || "",
      shiftWorker: "unTouched",
      error: ""
    };
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("This field is mandatory."),
    lastName: Yup.string().required("This field is mandatory."),
    email: Yup.string()
      .email("Please provide a valid email.")
      .required("This field is mandatory."),
    password: Yup.string()
      .min(6, "Please provide a valid password.")
      .required("This field is mandatory.")
    // shiftWorkerYes: Yup.string().required("This field is mandatory."),
    // shiftWorkerNo: Yup.string().required("This field is mandatory.")
  }),

  async handleSubmit(values, { props, setErrors, setValues }) {
    if (values.shiftWorker === "unTouched") {
      setErrors({ shiftWorker: "Please select one of the choices." });
    } else {
      const userData = {
        email: values.email,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        title: "",
        role: values.shiftWorker === "Yes" ? "trial_user" : "family_member"
      };

      const slug1 = sessionStorage.getItem("paramsId1");
      if (slug1) {
        const response = await checkMailIdentity(slug1, values.email);

        if (response.data.message === "Mail is unique") {
          const res = await props.signupUser(userData);
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.email &&
            res.payload.data.email[0] ===
              "user with this email address already exists."
          ) {
            setErrors({ email: "This email already exists." });
          } else if (res && res.payload && res.payload.status > 300) {
            setErrors({ error: "Something went wrong" });
          } else {
            setValues({ signup: true });
          }
        } else {
          setValues({
            ...values,
            openEmailDifferencePopup: true,
            paramsId1: slug1
          });
        }
      } else {
        const res = await props.signupUser(userData);
        if (
          res &&
          res.payload &&
          res.payload.data &&
          res.payload.data.email &&
          res.payload.data.email[0] ===
            "user with this email address already exists."
        ) {
          setErrors({ email: "This email already exists." });
        } else if (res && res.payload && res.payload.status > 300) {
          setErrors({ error: "Something went wrong" });
        } else {
          setValues({ signup: true });
        }
      }
    }
  }
})(SignUp);

function mapStateToProps(state) {
  return { auth: state.user.message };
}

export default connect(
  mapStateToProps,
  { signupUser, followedUser }
)(SignUpFormik);
