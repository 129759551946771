import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getEventsByType, saveCurrentDate, userEnteredQuickMode } from "actions/events";
import { withRouter } from "react-router";
import Popover from "@material-ui/core/Popover";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { Amplitude } from "@amplitude/react-amplitude";

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2
  }
});

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class SimplePopover extends React.Component {
 
  state = {
    anchorEl: null
  };

  handleClick = event => {
    if (this.props.role === "blocked_user") {
      this.props.history.push("/upgrade-membership");
    } else {
      this.setState({
        anchorEl: event.currentTarget
      });
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleCurrentDate = () => {
    this.props.saveCurrentDate(this.props.date);
  };

  enterQuickMode = () => {
    // querySelectorAll return an array of dom elements, u can access them directly.
    var sections = document.querySelectorAll('.CalendarDay__selected');
    for (let i = 0; i < sections.length; i++){
      sections[i].classList.remove('CalendarDay__selected');
    }
    this.props.userEnteredQuickMode(true);
    this.setState({
      anchorEl: null
    });
  };


  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let mypersonal = [];

    const myEvents = this.props.events.eventsByType;
    myEvents.map(data => {
      if (data.type === "personal") {
        mypersonal.push(data);
      }
    });

    return (
      <JssProvider generateClassName={generateClassName}>
        <Amplitude>
          {({ logEvent }) => (
            <div>
              <button
                className="btn-rounded btn-add"
                aria-owns={open ? "simple-popper" : undefined}
                aria-haspopup="true"
                variant="contained"
                onClick={e => {
                  logEvent("click Plus Icon in Calendar");
                  this.handleClick(e);
                }}
              >
                <i className="icon-plus" />
              </button>

              <Popover
                className="menu add-to-menu"
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <div className="menu-dropdown">
                  <ul className="menu-list">
                    {this.props.role !== "family_member" && (
                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          logEvent("click Add Shift in Calendar");
                          this.handleCurrentDate();
                          this.enterQuickMode()
                        }}
                      >
                        <a href={"/calendar"}>
                          <span className="icon">
                            <i className="bg-primary dot-icon" />
                          </span>
                          <span className="text">Add Shift</span>
                        </a>
                      </li>
                      )}

                    <li
                      onClick={() => {
                        logEvent("click Add Event in Calendar");
                        this.handleCurrentDate();
                      }}
                    >
                      <Link to={"/add-event"}>
                        <span className="icon">
                          <i className="bg-purple dot-icon" />
                        </span>
                        <span className="text">Add Event</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={"/add-note"}
                        onClick={() => {
                          logEvent("click Add Note in Calendar");
                          this.handleCurrentDate();
                        }}
                      >
                        <span className="icon">
                          <i className="bg-orange dot-icon" />
                        </span>
                        <span className="text">Add Note</span>
                      </Link>
                    </li>
                  </ul>

                  <div className="menu-footer">
                    <span className="font-md fw-800 text-capitalize">
                      Add new
                    </span>
                  </div>
                </div>
              </Popover>
            </div>
          )}
        </Amplitude>
      </JssProvider>
    );
  }
}

SimplePopover.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

const AddToEvent = withStyles(styles)(SimplePopover);
export default connect(
    mapStateToProps,
    { getEventsByType, saveCurrentDate, userEnteredQuickMode }
  )(withRouter(AddToEvent))

