import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { getUserById, editUser } from "./../../actions/auth";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import EditIcon from "@material-ui/icons/Edit";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    }
  };
};

class PaperSheet extends React.Component {
  state = {
    editableMode: false,
    first_name: null,
    last_name: null,
    email: null,

    errors: {}
  };
  async componentDidMount() {
    const id = localStorage.getItem("id");
   

    id && (await this.props.getUserById( id));

    const userData =
      this.props.user &&
      this.props.user.user &&
      this.props.user.user.userById &&
      this.props.user.user.userById.data;
    userData &&
      this.setState({
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        id
      });
  }
  handleToggleEditMode = () => {
    this.setState({ editableMode: !this.state.editableMode });
  };

  // handleSaveEdits = () => {
  // 	this.setState({ editableMode: false });
  // };
  handleChange = e => {
    if (!e.target.value) {
      this.setState({
        [e.target.id]: "",
        errors: {
          ...this.state.errors,
          [e.target.id]: "This field is mandatory"
        }
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
        errors: { ...this.state.errors, [e.target.id]: "" }
      });
    }
  };
  handleClick = async () => {
    const {  id, first_name, last_name } = this.state;
    const res = await this.props.editUser({ first_name, last_name }, id);
    res &&
      res.payload &&
      res.payload.status === 200 &&
      this.setState({ editableMode: false });
  };
  render() {
    const { classes } = this.props;
    const { first_name, last_name, email, errors } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <Dashboard>
          <Paper className={classes.root + " admin-profile sm"}>
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              className="flex align-center justify-between"
            >
              Profile
              {!this.state.editableMode ? (
                <IconButton onClick={this.handleToggleEditMode}>
                  <EditIcon />
                </IconButton>
              ) : null}
            </Typography>
            {this.state.editableMode ? (
              <div>
                {/* <div className="main-input">
									<FormControl>
										<InputLabel htmlFor="full_name">Full Name</InputLabel>
										<Input
											id="full_name"
											type="text"
											autocomplete="off"
											value="Admin Name"
											startAdornment={
												<InputAdornment position="start">
													<PersonIcon />
												</InputAdornment>
											}
										/>
									</FormControl>
						
								</div> */}
                <div className="main-input">
                  <FormControl>
                    <InputLabel htmlFor="first_name">First Name</InputLabel>
                    <Input
                      id="first_name"
                      type="text"
                      autoComplete="off"
                      value={first_name}
                      onChange={this.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.first_name && (
                    <p className="error-msg">{errors.first_name}</p>
                  )}
                </div>
                <div className="main-input">
                  <FormControl>
                    <InputLabel htmlFor="last_name">Last Name</InputLabel>
                    <Input
                      id="last_name"
                      type="text"
                      autoComplete="off"
                      value={last_name}
                      onChange={this.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.last_name && (
                    <p className="error-msg">{errors.last_name}</p>
                  )}
                </div>
                <div>
                  <p className="font-xmd fw-500">Password</p>
                  <Link
                    to={"/change-password"}
                    target="_blank"
                    className="btn-link font-md color-primary fw-500 mt-2"
                  >
                    Change Password
                  </Link>
                </div>
                <div className="admin-buttons">
                  <button
                    type="button"
                    className="btn btn-link color-text-50"
                    onClick={this.handleToggleEditMode}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-white ml-6 ml-lg-1"
                    onClick={this.handleClick}
                    disabled={errors.first_name || errors.last_name}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-5">
                  <p className="font-xmd fw-500">Full Name</p>
                  <p className="font-md color-text-70 mt-2">
                    {first_name && last_name && `${first_name} ${last_name}`}
                  </p>
                </div>
                <div className="mb-5">
                  <p className="font-xmd fw-500">Email</p>
                  <p className="font-md color-text-70 mt-2">{email && email}</p>
                </div>
              </div>
            )}
          </Paper>
        </Dashboard>
      </JssProvider>
    );
  }
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    user: state
  };
}
const Sheet = withStyles(styles)(PaperSheet);
export default connect(
  mapStateToProps,
  { getUserById, editUser }
)(Sheet);
