import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const styles = {
  root: {
    width: "100%"
  }
};
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class HomeMenu extends React.Component {

  state = {
    left: false
  };

  scrollToHowItWorks = () => {
      this.setState({ left: false });

      window.scrollTo({
        top: document.getElementById('how-it-works').offsetTop - 64, 
        behavior: "smooth"
      })
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="home-menu-mobile">
          <button type="button" className="btn-icon color-blue home-menu-btn" onClick={this.toggleDrawer('left', true)}>
            <i className="icon-menu font-sm icon-shadow" />
          </button>

          <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
            <div
              className="mobile-menu"
              tabIndex={0}
              onKeyDown={this.toggleDrawer('left', false)}
            >
              <div className="close-btn-wrap">
                <button type="button" onClick={this.toggleDrawer('left', false)}>
                  <i className="icon-close" />
                </button>
              </div>

              <ul>
                <li>
                  <a onClick={this.scrollToHowItWorks}>How It Works</a>
                </li>
                <li>
                  <a href="mailto:info@turnoapp.com">Contact Us</a>
                </li>
              </ul>

              <p className="divider"></p>

              <Link to={"/sign-in"} className="btn btn-link">Sign In</Link>

              <Link to={"/sign-up"} className="btn btn-white">Get Started</Link>

            </div>
          </Drawer>
      
        </div>
      </JssProvider>
    );
  }
}

HomeMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeMenu);
