import React from "react";
import { Link } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import JssProvider from "react-jss/lib/JssProvider";
import moment from "moment";
import { getNotesById, deleteNotes, editNotes } from "actions/notes";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class EditNote extends React.Component {
  state = {
    text: "",
    error: ""
  };
  async componentDidMount() {
 
    await this.props.getNotesById( this.props.match.params.id);
    this.setState({  text: this.props.notes.NoteById.text });
  }
  handleChange = e => {
    this.setState({
      text: e.target.value,
      error: !e.target.value ? "This field is mandatory." : ""
    });
  };
  handleEdit = async id => {
    if (!this.state.text) {
      this.setState({ error: "This field is mandatory." });
    } else {
      const res = await this.props.editNotes(
        this.props.match.params.id,
        { text: this.state.text }
      );
      res &&
        res.payload.status === 200 &&
        this.props.history.push(
          `/day-notes/${moment(this.props.notes.NoteById.date).format(
            "YYYY-MM-DD"
          )}`
        );
    }
  };

  render() {
    let currentNotes = this.props.notes.NoteById;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="container">
          <div className="content box-shadow">
            <div className="content-header">
              <Link
                to={"/calendar"}
                className="btn-icon w-noteById30 color-text mr-5"
              >
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>

              <h1>Edit Note</h1>
            </div>

            <div className="content-body note-content">
              <p className="color-text-50 fw-500 mb-3">
                {currentNotes &&
                  `${moment(currentNotes.date).format("MMMM Do, YYYY")}`}
              </p>

              <div
                className={
                  this.state.error ? "form-field has-error" : "form-field"
                }
              >
                <div className="form-textarea">
                  <InputBase
                    multiline
                    placeholder="Note Text"
                    rowsMax={6}
                    value={this.state.text}
                    onChange={e => this.handleChange(e)}
                  />
                  <span className="border" />
                </div>
                {this.state.error && (
                  <p className="error-msg">{this.state.error}</p>
                )}
                {/* {currentNotes &&
                  !this.state.text && (
                    <p className="error-msg">please put text </p>
                  )} */}
              </div>
            </div>

            <div className="content-footer">
              <Link to={"/calendar"}>
                <button type="button" className="btn btn-link color-text-50">
                  Cancel
                </button>
              </Link>

              <button
                type="button"
                className="btn btn-white"
                onClick={() => this.handleEdit(currentNotes.id)}
                disabled={this.state.error}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    notes: state.notes,
    auth: state.user
  };
}

export default connect(
  mapStateToProps,
  { getNotesById, deleteNotes, editNotes }
)(withRouter(EditNote));
