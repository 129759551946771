import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";
import omit from "lodash/omit";
import "react-dates/initialize";
import CellComponent from "./CellComponent";
import "react-dates/lib/css/_datepicker.css";
import { getEvents, getSharedEvents } from "actions/events";
import { getNotifications } from "actions/notifications";
import { getNotes } from "actions/notes";
import { getConnections } from "actions/connection";
import { instanceOf } from "prop-types";
import Caption from "./Caption";
import { SingleDatePicker } from "react-dates";
import CalendarDetails from "./CalendarDetails";

let nextClick = document.querySelector(
  ".DayPickerNavigation_rightButton__horizontalDefault"
);
let prevClick = document.querySelector(
  ".DayPickerNavigation_leftButton__horizontalDefault"
);

class SingleDatePickerWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      autoFocus: true,
      focused: true,
      date: moment(),
      currentDate: moment(),
      MonthChange: moment(),
      touchStartX: null,
      selectedDay: null,
      reelContainerWidth: 500,
      num: 0,
      events: [],
      notifications: [],
      notes: []
    };

    this.onDateChange = this.onDateChange.bind(this);
  }

  onMonthChange = (num, month) => {
    this.setState({
      date: moment(month).add(num, "months")
    });
  };
  onDateSelect = async date => {
    await this.setState({
      date,
      show: false
    });
    this.setState({ show: true });
  };
  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  };
  onTouchStart = e => {
    this.setState({
      touchStartX: e.touches[0].clientX
    });
  };

  onTouchEnd = async e => {
    const difference = this.state.touchStartX - e.changedTouches[0].clientX;
    if (difference >= 50) {
      nextClick.click();
      if (!this.state.flag) {
        this.setState({ flag: true }, () => {
          this.onMonthChange(1, this.state.date);
        });
      }
    } else if (difference <= -50) {
      prevClick.click();
      if (!this.state.flag) {
        this.setState({ flag: true }, () => {
          this.onMonthChange(-1, this.state.date);
        });
      }
    }
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });
  };

  onDateChange(date) {
    this.setState({ date });
  }
  onPrevMonthClick = () => {
    this.setState({ flag: false });
  };

  onNextMonthClick = () => {
    this.setState({ flag: false });
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    const weekDay = document.querySelectorAll(".DayPicker_weekHeader_li");
    let weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    for (let i = 0; i < weekDay.length; ++i) {
      weekDay[i].childNodes[0].textContent = weekDays[i];
    }
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });

    nextClick = document.querySelector(
      ".DayPickerNavigation_rightButton__horizontalDefault"
    );
    prevClick = document.querySelector(
      ".DayPickerNavigation_leftButton__horizontalDefault"
    );
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions);
   
    this.props.getConnections();

    this.props.getSharedEvents( this.props.match.params.id);
  }
  componentDidUpdate() {
    const weekDay = document.querySelectorAll(".DayPicker_weekHeader_li");
    nextClick = document.querySelector(
      ".DayPickerNavigation_rightButton__horizontalDefault"
    );
    prevClick = document.querySelector(
      ".DayPickerNavigation_leftButton__horizontalDefault"
    );
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });
    const currentDate = this.state.currentDate;
    let weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    for (let i = 0; i < weekDay.length; ++i) {
      weekDay[i].childNodes[0].textContent = weekDays[i];
    }
    if (currentDate.format("MM") === this.state.date.format("MM")) {
      weekDay &&
        weekDay[this.state.currentDate.format("d")] &&
        weekDay[this.state.currentDate.format("d")].classList.add(
          "current_weekday"
        );
    } else {
      weekDay &&
        weekDay[this.state.currentDate.format("d")] &&
        weekDay[this.state.currentDate.format("d")].classList.remove(
          "current_weekday"
        );
    }
  }
  checkEvents = elem => {
    let eventsStartDate = elem.map(data =>
      moment(data.days).format("DD.MM.YYYY")
    );
    let currentStartDate = moment(this.state.date).format("DD.MM.YYYY");
    let myNotification = "";
    eventsStartDate.map(data => {
      if (data === currentStartDate) {
        let index = eventsStartDate.indexOf(data);
        return (myNotification = elem[index]);
      }
    });

    return myNotification;
  };

  updateDimensions = () => {
    const reelContainerWidth = window.innerWidth;
    this.setState({ reelContainerWidth: reelContainerWidth });

    if (reelContainerWidth < 500) {
      const margin =
        -23 +
        (reelContainerWidth - 7 * Math.floor((reelContainerWidth - 2) / 7)) / 2;
      document.querySelector(".DayPicker").style.margin = "0 " + margin + "px";
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const myEvents = this.state.events && this.checkEvents(this.state.events);
    const events =
      this.props.events.sharedEvents && this.props.events.sharedEvents;
    const sharedUser = [];
    this.props.connections &&
      this.props.connections.connections &&
      this.props.connections.connections.results.map(data => {
        if (data.user === +this.props.match.params.id) {
          return sharedUser.push(data);
        }
      });
    const sharedEvents = sharedUser && sharedUser[0];
    const { date, MonthChange, dateProps } = this.state;
    const props = omit(this.props, [
      "autoFocus",
      "initialDate",
      "onDateChange"
    ]);
    // console.log(events);
    return (
      <section className="content-center team">
        <div className="container">
          <div className="content box-shadow justify-start connection-calendar">
            <div className="calendar-owner">
              <Link
                to={{
                  pathname: "/connections",
                  state: { lastUrl: this.props.location.state.lastUrl }
                }}
                className="btn-icon w-30 color-text mr-5"
              >
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>
              <span className="user-img">
                {sharedEvents && sharedEvents.user_picture ? (
                  <span
                    className="img"
                    style={{
                      backgroundImage: `url(${sharedEvents.user_picture})`
                    }}
                  />
                ) : (
                  <i className="icon-user" />
                )}
              </span>

              {sharedEvents && (
                <p className="user-name">{`${sharedEvents.first_name} ${
                  sharedEvents.last_name
                }`}</p>
              )}
            </div>

            {this.state.show && (
              <SingleDatePicker
                {...props}
                id="date"
                date={date}
                focused={this.state.focused}
                onFocusChange={() => {}}
                keepOpenOnDateSelect
                isDayBlocked={day => {
                  let dayIsBlocked = false;
                  if (
                    moment(date).format("MM") !==
                    moment(day).format("MM")
                  ) {
                    dayIsBlocked = true;
                  }

                  return dayIsBlocked;
                }}
                onNextMonthClick={this.onNextMonthClick}
                onPrevMonthClick={this.onPrevMonthClick}
                renderDayContents={props => {
                  return (
                    <CellComponent
                      {...props}
                      events={events}
                      currentDate={this.state.date}
                    />
                  );
                }}
                renderCalendarInfo={() => (
                  <Caption
                    date={moment(date).format("MMMM YYYY")}
                    onDateSelect={this.onDateSelect}
                  />
                )}
                isOutsideRange={() => false}
                numberOfMonths={1}
                daySize={
                  this.state.reelContainerWidth >= 500
                    ? Math.floor(500 / 7)
                    : Math.floor((this.state.reelContainerWidth - 2) / 7)
                }
                calendarInfoPosition="top"
                onDateChange={this.onDateChange}
                hideKeyboardShortcutsPanel
                showDefaultInputIcon={false}
                enableOutsideDays
              />
            )}

            <CalendarDetails
              date={date}
              currentdate={date}
              myEvents={events}
            />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    notifications: state.notifications,
    notes: state.notes,
    auth: state.user,
    connections: state.connections,
    sharedEvents: state.events.sharedEvents
  };
}

export default withRouter(
    connect(
      mapStateToProps,
      { getEvents, getNotifications, getNotes, getSharedEvents, getConnections }
    )(SingleDatePickerWrapper)
  )