import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import DatePicker from "./../DatePicker";
import { withStyles } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import JssProvider from "react-jss/lib/JssProvider";
import SearchAppBar from "./SearchBar";

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { classes, generateClassName, handleDate } = props;
  const handleSelect = (date, range) => {
    handleDate(date, range);
  };

  return (
    <Toolbar className={classNames(classes.root)}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Feedbacks
        </Typography>
      </div>
      <div className="toolbar-filters">
        <div className="datepickers">
          <div className="date-picker">
            <p className="picker-label">From</p>
            <DatePicker
              handleSelect={(date, range) => {
                handleSelect(date, range);
              }}
              range={"from"}
            />
          </div>
          <div className="date-picker">
            <p className="picker-label">To</p>
            <DatePicker
              handleSelect={(date, range) => {
                handleSelect(date, range);
              }}
              range={"to"}
              minDate={props.minDate}
            />
          </div>
        </div>
        {/* <SearchAppBar {...props}/> */}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
export default EnhancedTableToolbar;
