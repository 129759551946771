import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  ADD_NOTES,
  GET_NOTES,
  DELETE_NOTES,
  GET_NOTES_BY_ID,
  EDIT_NOTES
} from "./types";

export const getNotes = date => dispatch => {
  const url = `/notes/notes-by-date/${date}/`;

  return axiosToken.get(url).then(res => {
    return dispatch({
      type: GET_NOTES,
      payload: res.data
    });
  });
};
export const addNotes = (data) => dispatch => {
  const url = "/notes/";

  return axiosToken
    .post(
      url,
      data
    
    )
    .then(res => {
      return dispatch({
        type: ADD_NOTES,
        payload: res
      });
    });
};


export const deleteNotes = ( id) => dispatch => {
  const url = `/notes/${id}/`;

  return axiosToken
    .delete(
      url
     
    )
    .then(res => {
      return res;
    })
    .then(
      dispatch({
        type: DELETE_NOTES,
        payload: id
      })
    );
 
};
export const getNotesById = ( id) => dispatch => {
  const url = `/notes/${id}/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_NOTES_BY_ID,
        payload: res.data
      });
    });
};
export const editNotes = ( id, data) => dispatch => {
  const url = `/notes/${id}/`;
  return axiosToken
    .patch(
      url,
      data
     
    )
    .then(res => {
      return dispatch({
        type: EDIT_NOTES,
        payload: res
      });
    });
};
