import React, { PureComponent } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Amplitude } from "@amplitude/react-amplitude";
import DatePicker from "./../calendar/datePicker";
import MainMenu from "../UI/MainMenu";

class Caption extends PureComponent {
  state = { isOpen: false };
  handleClick = () => {
    this.setState({
      isOpen: true,
      date: this.props.date,
      curentDate: moment()
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  getDate = date => {
    this.props.onDateSelect(moment(date, "MMMM YYYY"));
    this.setState({ isOpen: false });
  };
  render() {
    const { date } = this.props;

    return (
      <Amplitude>
        {({ logEvent }) => (
          <div className="calendar-toolbar">
            <div className="calendar-menu">
              <MainMenu {...this.props} />

              <DatePicker date={date} getDate={this.getDate} />
              <Link
                to={{
                  pathname: "/connections",
                  state: { url: "/team" }
                }}
                onClick={() => {
                  logEvent("click Team View in Team Calendat");
                }}
                className="btn btn-link color-primary no-padding"
              >
                Team view
              </Link>
            </div>
          </div>
        )}
      </Amplitude>
    );
  }
}

export default withRouter(Caption);
