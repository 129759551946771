import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Link } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

import moment from "moment";
import TimePicker from "./timePicker";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

const styles = theme => ({
  root: {
    width: "100%"
  }
});
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});
class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: "",
    startTime: null,
    endTime: null,
    timeError: "",
    nameError: "",
    value: "",
    shiftType: "",
    types: {}
  };

  isStepOptional = step => {
    return step === 1;
  };

  handleNext = async () => {
    const { activeStep } = this.state;
    if (activeStep === 0 && !this.state.value) {
      await this.setState({ nameError: "This field is mandatory." });
    }
    if (
      activeStep === 1 &&
      this.state.startTime === null &&
      this.state.endTime === null
    ) {
      await this.setState({ timeError: "This field is mandatory." });
    } else if (activeStep === 1 && this.state.startTime === null) {
      await this.setState({ timeError: "Start time field is mandatory." });
    } else if (activeStep === 1 && this.state.endTime === null) {
      await this.setState({ timeError: "End time field is mandatory." });
    } else if (
      activeStep === 1 &&
      this.state.endTime !== null &&
      this.state.startTime !== null
    ) {
      if (
        moment(this.state.endTime, "HH:mm").diff(
          moment(this.state.startTime, "HH:mm")
        ) /
          60000 ===
        0
      ) {
        await this.setState({
          timeError: "Please select a valid time."
        });
      } else {
        await this.setState({
          timeError: ""
        });
      }
    }
    !this.state.timeError &&
      !this.state.nameError &&
      this.setState({
        activeStep: activeStep + 1
      });
  };
  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
      timeError: ""
    }));
  };

  handleClick = elem => {
    this.setState({
      isOpen: elem,
      date: this.props.date,
      curentDate: moment()
    });
  };
  handleChange = event => {
    if (event.target.value) {
      this.setState({ nameError: "", value: event.target.value });
      if(event.target.value.toLowerCase() === 'not available'){
        this.setState({
          nameError: "Please select a valid name ."
        });
      }
    } else {
      this.setState({
        nameError: "This field is mandatory.",
        value: event.target.value
      });
    }
    this.props.handleChange(event.target.value);
  };
  handleCancel = () => {
    this.setState({ isOpen: "" });
  };
  handleSelect = async time => {
    let formatTime = time + ":00";
    let myTime = moment(formatTime, "HH:mm:ss");

    let shiftType = "";
    if (this.state.isOpen === "Start") {
      if (
        myTime.isBetween(
          moment("02:59:59", "HH:mm:ss"),
          moment("11:59:59", "HH:mm:ss")
        )
      ) {
        shiftType = "day";
      } else if (
        myTime.isBetween(
          moment("11:59:59", "HH:mm:ss"),
          moment("14:59:59", "HH:mm:ss")
        )
      ) {
        shiftType = "afternoon";
      } else if (
        myTime.isBetween(
          moment("14:59:59", "HH:mm:ss"),
          moment("17:59:59", "HH:mm:ss")
        )
      ) {
        shiftType = "evening";
      } else {
        shiftType = "night";
      }

      await this.setState({ startTime: time, shiftType });

      this.props.onStartTimeSelect(moment(time, "HH:mm A").format("hh:mm A"));
    } else if (this.state.isOpen === "End") {
      await this.setState({ endTime: time });
      this.props.onEndTimeSelect(moment(time, "HH:mm A").format("hh:mm A"));
    }
    let start = this.state.startTime && moment(this.state.startTime);

    let end = this.state.endTime && moment(this.state.endTime);

    if (start && end) {
      // if (!startTime.isBefore(endTime)) {
      //
      //   this.setState({
      //     timeError: "Please select a valid time."
      //   });
      // }
    }

    this.setState({ isOpen: "", timeError: "" });
  };
  async componentDidMount() {
    const currentTime = moment().format("HH:mm");
    this.setState({
      types: this.props.types,
      time: currentTime
    });
  }

  getTime = async (time, period) => {
    if (period === "Start") {
      await this.setState({ startTime: time, isOpen: period });
    } else if (period === "End") {
      await this.setState({ endTime: time, isOpen: period });
    }
  };

  async handleClickStep(step) {
    if (step === 1) {
      !this.state.value &&
        this.setState({
          nameError: "This field is mandatory."
        });
    }
    if (step === 2) {
      if (this.state.startTime === null && this.state.endTime === null) {
        this.setState({ timeError: "This field is mandatory." });
      } else if (this.state.startTime === null) {
        this.setState({ timeError: "Start time field is mandatory." });
      } else if (this.state.endTime === null) {
        this.setState({ timeError: "End time field is mandatory." });
      } else if (this.state.endTime !== null && this.state.startTime !== null) {
        if (
          moment(this.state.endTime, "HH:mm").diff(
            moment(this.state.startTime, "HH:mm")
          ) /
            60000 ===
          0
        ) {
          await this.setState({
            timeError: "Please select a valid time."
          });
        } else {
          await this.setState({
            timeError: "",
            activeStep: step
          });
        }
      }
    } else {
      if (this.state.value && !this.state.timeError) {
        this.setState({ activeStep: step });
      }
    }
  }
  render() {
    const { classes } = this.props;
    const steps = this.props.steps;
    const { activeStep } = this.state;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps &&
              steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...props}
                    className={
                      activeStep >= index ? "step completed-step" : "step"
                    }
                    onClick={() => {
                      this.handleClickStep(index);
                    }}
                  >
                    <StepLabel {...labelProps} className="step-label">
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
          </Stepper>
          <div className="stepper-body">
            <div className="stepper-content">
              {activeStep === 0 ? (
                <div
                  className={
                    this.state.nameError ? "form-field has-error" : "form-field"
                  }
                >
                  <div className="form-input">
                    <input
                      type="text"
                      placeholder="Shift Name"
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <span className="border" />
                  </div>
                  {this.state.nameError && (
                    <p className="error-msg">{this.state.nameError}</p>
                  )}
                </div>
              ) : activeStep === 1 ? (
                <div>
                  <div className="time-picker">
                    <div className="time">
                      <TimePicker
                        getTime={(time, period) => this.getTime(time, period)}
                        handleSelect={this.handleSelect}
                        time={this.state.startTime}
                        period="Start"
                      />
                    </div>
                    <div className="time">
                      <TimePicker
                        getTime={(time, period) => this.getTime(time, period)}
                        handleSelect={this.handleSelect}
                        time={this.state.endTime}
                        period="End"
                      />
                      {/* <p className="selected-time">
                        {this.state.endTime &&
                          moment(this.state.endTime, "HH:mm A").format(
                            "HH:mm A"
                          )}
                      </p> */}
                    </div>
                    {this.state.timeError && (
                      <p className="error-msg with-icon">
                        {this.state.timeError}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="content-body p-0">
                  <CustomScroll
                    allowOuterScroll
                    addScrolledClass
                    heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul>
                        <li
                          className={`card card-with-icon lg no-shadow ${
                            this.state.shiftType === "afternoon"
                              ? "selected"
                              : "disabled"
                          }`}
                        >
                          <span className="icon">
                            <img
                              src={require("assets/img/afternoon-icon.png")}
                              alt="afternoon icon"
                            />
                          </span>
                          <div className="card-body row">
                            <p className="font-xmd fw-500">Afternoon</p>
                          </div>
                        </li>
                        <li
                          className={`card card-with-icon lg no-shadow ${
                            this.state.shiftType === "day"
                              ? "selected"
                              : "disabled"
                          }`}
                        >
                          <span className="icon">
                            <img
                              src={require("assets/img/day-icon.png")}
                              alt="day icon"
                            />
                          </span>
                          <div className="card-body row">
                            <p className="font-xmd fw-500">Day</p>
                          </div>
                        </li>
                        <li
                          className={`card card-with-icon lg no-shadow ${
                            this.state.shiftType === "evening"
                              ? "selected"
                              : "disabled"
                          }`}
                        >
                          <span className="icon">
                            <img
                              src={require("assets/img/evening-icon.png")}
                              alt="evening icon"
                            />
                          </span>
                          <div className="card-body row">
                            <p className="font-xmd fw-500">Evening</p>
                          </div>
                        </li>
                        <li
                          className={`card card-with-icon lg no-shadow ${
                            this.state.shiftType === "night"
                              ? "selected"
                              : "disabled"
                          }`}
                        >
                          <span className="icon">
                            <img
                              src={require("assets/img/night-icon.png")}
                              alt="night icon"
                            />
                          </span>
                          <div className="card-body row">
                            <p className="font-xmd fw-500">Night</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CustomScroll>
                </div>
              )}
              {/* <TimePicker
                isOpen={this.state.isOpen}
                handleCancel={this.handleCancel}
                handleSelect={this.handleSelect}
              /> */}
            </div>

            <div className="content-footer">
              {activeStep === 0 ? (
                <Link
                  to={
                    this.props.lastLocation === "/add-shift"
                      ? "/add-shift"
                      : "/events/0"
                  }
                  className="btn btn-link color-text-50"
                >
                  Cancel
                </Link>
              ) : (
                <button
                  type="button"
                  className="btn btn-link color-text-50"
                  onClick={this.handleBack}
                >
                  {" "}
                  Back{" "}
                </button>
              )}
              {activeStep === steps.length - 1 ? (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.props.onCreateShift}
                  disabled={this.state.timeError}
                >
                  {" "}
                  Add{" "}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.handleNext}
                >
                  {" "}
                  Next{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(HorizontalLinearStepper);
