import React, { Component, Fragment } from "react";
import Picker from "pickerjs";
import moment from "moment";
import { Field } from "formik";
import "pickerjs/dist/picker.css";

class DatePicker extends React.Component {
  state = { picker: "" };
  componentDidMount() {
    const a = new Picker(document.querySelector(".js-date-picker"), {
      format: "DD MMMM YYYY",
      headers: true,
      text: {
        title: "",
        confirm: "Select"
      },
      pick: e => {
        this.props.getDate(a.getDate(true));
      }
    });
  }

  render() {
    return (
      <Fragment>
        <Field
          className="form-input js-date-picker"
          type="text"
          name="birthday"
          onKeyDown={this.props.onKey}
          onKeyPress={this.props.onKey}
          placeholder="Select Your Birthday"
          autoComplete="off"
          value={
            this.props.birthday
              ? moment(this.props.birthday).format("DD MMMM YYYY")
              : ""
          }
        />
        <span className="border" />
      </Fragment>
    );
  }
}

export default DatePicker;
