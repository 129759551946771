import { axiosWitoutToken, axiosToken, createAxiosToken } from "http/axios";
import {
  SIGN_UP_USER,
  SIGN_IN_USER,
  ACTIVATE_ACCOUNT,
  FORGOT_PASSWORD_USER,
  RESET_PASSWORD_USER,
  AUTH_ERROR,
  FOLLOWED_USER,
  GET_USER,
  UPDATE_USER,
  CHANGE_PASSWORD,
  REMOVE_TOKEN,
  MANAGER_APPROVE,
  GET_USER_BY_ID,
  SIGN_OUT_USER,
  RESEND_ACTIVATION,
  CHECK_INVITEE_MAIL
} from "./types";


// createAxiosToken()
export const followedUser = slug => dispatch => {
  const url = `/users/link-followed-user/${slug}/`;
  return axiosWitoutToken
      .get(url)
      .then(res => {
        return dispatch({
          type: FOLLOWED_USER,
          payload: res.data
        });
      })
      .catch(error => {
        return dispatch({ type: AUTH_ERROR, payload: error.response });
      });
};

export const checkMailIdentity = (to_user_slug, mail) => {
  const url = `/users/check-invitation-mail-and-registered-mail-unique/${to_user_slug}/${mail}/`;
  return axiosWitoutToken
      .get(url)
      .then(res => {
        return res
      })
      .catch(error => {
        return error.response;
      });
};

export const checkInviteeMail = (mail) => dispatch => {
  const url = `/users/check-invited-user-mail-not-be-unique-with-manager-mail/${mail}/`;
  return axiosToken
    .get(url)
    .then(res => {
      return dispatch({
        type: CHECK_INVITEE_MAIL, payload: res
      });
    })
    .catch(error => {
      return dispatch({ type: AUTH_ERROR, payload: error.response });
    });
};

export const deleteCalendarRequest = (to_user_slug, mail, first_name) => {
  const url = `/users/delete-share-calendar-request/${to_user_slug}/${mail}/${first_name}/`;
  return axiosWitoutToken
      .get(url)
      .then(res => {
        return res
      })
      .catch(error => {
        return error.response;
      });
};

export const signupUser = data => dispatch => {
  const url = "/users/register/";
  return axiosWitoutToken
      .post(url, data)
      .then(res => {
        return dispatch({
          type: SIGN_UP_USER,
          payload: res.data
        });
      })
      .catch(error => {
        return dispatch({ type: AUTH_ERROR, payload: error.response });
      });
};

export const activateToken = data => dispatch => {
  const url = `/users/activate-account/${data}/`;
  return axiosWitoutToken.get(url).then(res => {
    return dispatch({
      type: ACTIVATE_ACCOUNT,
      payload: res.data
    });
  });
};

export const signinUser = (data, rememberme) => dispatch => {
  const url = "/users/login/";

  return (
      axiosWitoutToken
          .post(url, data)
          .then(async res => {
            const token = res.data.auth_token;
            createAxiosToken(token);
            if (rememberme) {
              await localStorage.setItem("token", token);
            } else {
              await sessionStorage.setItem("token", token);
            }

            return dispatch({
              type: SIGN_IN_USER,
              payload: res
            });

        
          })
          
          .catch(error => {
            return dispatch({ type: AUTH_ERROR, payload: error.response });
          })
  );
};
export const signOutUser = () => dispatch => {
  const url = "users/logout/";

  return axiosToken
      .post(
          url,
          null
      )
      .then(res => {
        return dispatch({
          type: SIGN_OUT_USER,
          payload: res
        });
      })
      .catch(error => {
        return dispatch({ type: AUTH_ERROR, payload: error.response });
      });
};
export const forgotPassword = data => dispatch => {
  const url = "/users/forgot-password/";
  return axiosWitoutToken
      .post(url, data)
      .then(res => {
        return dispatch({
          type: FORGOT_PASSWORD_USER,
          payload: res
        });
      })
      .catch(error => {
        return dispatch({ type: AUTH_ERROR, payload: error.response });
      });
};

export const removeReduxToken = () => ({
  type: REMOVE_TOKEN
});
export const resetPassword = data => dispatch => {
  const url = "/users/reset-password/";
  return axiosWitoutToken.post(url, data).then(res => {
    return dispatch({
      type: RESET_PASSWORD_USER,
      payload: res
    });
  });
};

export const unSubscribe = ( id) => dispatch => {
  const url = `/users/unsubscribe-notifications-email/${id}/`;
  return axiosToken
      .get(
          url
         
      )
      .then(res => {
        return dispatch({
          type: GET_USER,
          payload: res
        });
      });
};

export const getUser = () => dispatch => {
  const url = `/users/`;
  return axiosToken
      .get(
          url
         
      )
      .then(res => {
        return dispatch({
          type: GET_USER,
          payload: res
        });
      })
      .catch(err => {
        return err.response;
      });
};

export const editUser = (data,  id) => dispatch => {
  const url = `/users/${id}/`;
  return axiosToken
      .patch(
          url,
          data
      )
      .then(res => {
        return dispatch({
          type: UPDATE_USER,
          payload: res
        });
      })
      .catch(err => {
        return err.response;
      });
};

export const getUserById = ( id) => dispatch => {
  const url = `/users/${id}/`;
  return axiosToken
      .get(
          url
         
      )
      .then(res => {
        return dispatch({
          type: GET_USER_BY_ID,
          payload: res
        });
      })
      .catch(err => {
        return err.response;
      });
};

export const changePassword = data => dispatch => {
  const url = `/users/change-password/`;
  return axiosToken
      .post(
          url,
          data
         
      )
      .then(res => {
        return dispatch({
          type: CHANGE_PASSWORD,
          payload: res
        });
      })
      .catch(err => {
        return err.response;
      });
};

export const managerApprove = (slug, status) => dispatch => {
  const url = `/users/accept-shift-request-by-manager/${slug}/${status}/`;
  return axiosWitoutToken.get(url).then(res => {
    return dispatch({
      type: MANAGER_APPROVE,
      payload: res
    });
  });
};
export const resendActivation = data => dispatch => {
  const url = "/users/resend-activation-token/";

  return axiosWitoutToken
      .post(url, data)
      .then(res => {
        return dispatch({
          type: RESEND_ACTIVATION,
          payload: res
        });
      })
      .catch(error => {
        return dispatch({ type: AUTH_ERROR, payload: error.response });
      });
};
