import React from "react";
import { Link } from "react-router-dom";
import { addShiftEvent, getEventsByType } from "actions/events";
import { connect } from "react-redux";
import { map } from "lodash";
import { withLastLocation } from "react-router-last-location";
import "react-custom-scroll/dist/customScroll.css";
import Stepper from "../UI/Stepper";
import queryString from 'query-string'
import {userEnteredQuickMode} from "../../actions/events";

class AddShifts extends React.Component {
  state = {
    name: "",
    start_time: "",
    end_time: "",
    activeStep: null,
    queryStringQuickMode: false,
  };

  onStartTimeSelect = start_time => {
    this.setState({ start_time });
  };
  onEndTimeSelect = end_time => {
    this.setState({ end_time });
  };
  handleChange = value => {
    this.setState({ name: value });
  };
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if(Boolean(parsed.quickmode) === true){
      this.setState({queryStringQuickMode: true});
    }
    this.props.getEventsByType( "shift");
  }

  onCreateShift = async () => {
    let data = { ...this.state, type: "shift" };
    const res = await this.props.addShiftEvent( data);
    if (res.payload && res.payload.status === 201) {
      if (this.props.events.mention) {
        this.props.history.push("/add-shift");
      } else {
        console.log(this.state.queryStringQuickMode);
        if(this.state.queryStringQuickMode === true){
          this.props.history.push("/calendar?quickmode=true");
        } else {
          this.props.history.push("/events/0");
        }
      }
    }
  };

  render() {
    const steps = ["Name", "Duration", "Start Period"];
    let types = {};
    this.props.events &&
      this.props.events.eventsByType &&
      map(this.props.events.eventsByType.results, data => {
        types = { ...types, [data.start_period]: data.start_period };
      });
    const lastLocation =
      this.props.lastLocation && this.props.lastLocation.pathname;

    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link
              to={`${
                lastLocation === "/add-shift" ? "/add-shift" : this.state.queryStringQuickMode === true ? "/calendar" : "/events/0"
              }`}
              onClick={() => {this.state.queryStringQuickMode === true ? this.props.userEnteredQuickMode(true) : this.props.userEnteredQuickMode(false)}}
              className="btn-icon w-30 color-text mr-5"
            >
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Add Shifts</h1>
          </div>

          <Stepper
            steps={steps}
            onStartTimeSelect={this.onStartTimeSelect}
            onEndTimeSelect={this.onEndTimeSelect}
            handleChange={this.handleChange}
            onCreateShift={this.onCreateShift}
            types={types}
            lastLocation={lastLocation}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default 
  connect(
    mapStateToProps,
    { addShiftEvent, getEventsByType, userEnteredQuickMode }
  )(withLastLocation(AddShifts))

