import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "actions/auth";
import { getManagerById, getManager, deleteManager } from "actions/manager";

class Manager extends React.Component {
  state = { error: "" };
  componentDidMount() {
   
    this.props.getManagerById(this.props.match.params.id);
  }
  handleDelete = async () => {
    const res = await this.props.deleteManager(
      this.props.match.params.id
    );
    if (res.payload && res.payload.status === 204) {
      this.props.history.push("/manager-list");
    } else {
      this.setState({ error: Object.values(res.data)[0] });
    }
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    const Manager = this.props.manager && this.props.manager.managerById;
    return (
      <section className="content-center">
        <div className="container">
          {!Manager ? (
            <div className="content box-shadow justify-start">
              <div className="content-header">
                <Link
                  to={"/manager-list"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>Manager</h1>
              </div>

              <div className="content-body placeholder-content">
                <img src={require("assets/img/user.svg")} alt="user icon" />
                <p className="font-md">You don't have a manager yet.</p>

                <Link to={"/add-manager"} className="btn-rounded btn-add">
                  <i className="icon-plus" />
                </Link>
              </div>
            </div>
          ) : (
            <div className="content box-shadow justify-start">
              <div className="content-header bg-white flex-wrap">
                <div className="flex align-center w-100">
                  <Link
                    to={"/manager-list"}
                    className="btn-icon w-30 color-text mr-5"
                  >
                    <i className="icon-back-arrow font-md icon-shadow" />
                  </Link>
                  <h1>Manager</h1>
                  <Link
                    to={`/edit-manager/${Manager.id}`}
                    className="btn-icon w-30 color-text ml-auto"
                  >
                    <i className="icon-edit font-md icon-shadow" />
                  </Link>
                  <button
                    type="button"
                    className="btn-delete-card btn-icon w-30 ml-3 color-warning"
                    onClick={this.handleDelete}
                  >
                    <i className="icon-delete font-md icon-shadow" />
                  </button>
                </div>

                <div className="content-header-bottom bg-white">
                  <div className="user-img">
                    {!Manager.picture ? (
                      <i className="icon-user" />
                    ) : (
                      <span
                        className="img"
                        style={{
                          backgroundImage: `url(${Manager.picture})`
                        }}
                      />
                    )}
                  </div>
                  {/* <label className="btn btn-link btn-upload color-primary no-padding ml-5">
                    <input type="file" />
                    <span className="font-md">Upload Image</span>
                  </label> */}
                </div>
              </div>

              <div className="content-body">
                <div className="profile-info">
                  <p className="font-xl fw-700 mb-5">{`${Manager.first_name} ${
                    Manager.last_name
                  }`}</p>

                  {Manager.title && (
                    <div className="mb-5">
                      <p className="font-md fw-500">Title</p>
                      <p className="color-text-70 mt-2">
                        {this.capitalize(Manager.title).replace("_", " ")}
                      </p>
                    </div>
                  )}

                  {Manager.mobile_number && (
                    <div className="mb-5">
                      <p className="font-md fw-500">Phone</p>
                      <p className="color-text-70 mt-2">
                        {Manager.mobile_number}
                      </p>
                    </div>
                  )}

                  <div className="mb-5">
                    <p className="font-md fw-500">Email</p>
                    <p className="color-text-70 mt-2">{Manager.email}</p>
                  </div>

                  {Manager.birthday && (
                    <div className="mb-5">
                      <p className="font-md fw-500">Birthday</p>
                      <p className="color-text-70 mt-2">
                        {moment(Manager.birthday).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  )}
                  {Manager.gender !== "none" && (
                    <div>
                      <p className="font-md fw-500">Gender</p>
                      <p className="color-text-70 mt-2 text-capitalize">
                        {Manager.gender}
                      </p>
                    </div>
                  )}
                </div>
                {this.state.error && (
                  <p className="error-msg with-icon">{this.state.error}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    manager: state.managers
  };
}

export default connect(
    mapStateToProps,
    { getUser, getManagerById, getManager, deleteManager }
  )(Manager)
