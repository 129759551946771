import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MainMenu from "../UI/MainMenu";
import {Amplitude} from "@amplitude/react-amplitude";

class Settings extends React.Component {

  render() {
    const role = localStorage.getItem("role");
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header bg-primary flex-wrap">
              <MainMenu />
              <h1 className="color-white">Settings</h1>
              <div className="content-header-bottom bg-primary">
                <i className="icon-settings color-white-30" />
              </div>
            </div>

            <div className="content-body">
              <div className="list-wrap">
                <ul className="settings-list">
                  <li>
                    <Link to={"/profile"} className="card card-default">
                      <span className="font-md fw-500 text-capitalize">
                        Profile
                      </span>
                      <i className="icon-next-arrow icon-shadow color-black" />
                    </Link>
                  </li>
                  {role &&
                    role !== "family_member" &&
                    role !== "standard_user" && (
                      <li>
                        <Link
                          to={"/manager-list"}
                          className="card card-default"
                        >
                          <span className="font-md fw-500 text-capitalize">
                            Manager
                          </span>
                          <i className="icon-next-arrow icon-shadow color-black" />
                        </Link>
                      </li>
                    )}
                  {role &&
                    role !== "standard_user" && (
                      <li>
                        <Link
                          to={{
                            pathname: "/connections",
                            state: { url: "/settings" }
                          }}
                          className="card card-default"
                        >
                          <span className="font-md fw-500 text-capitalize">
                            Connections
                          </span>
                          <i className="icon-next-arrow icon-shadow color-black" />
                        </Link>
                      </li>
                    )}
                  {role &&
                    role !== "family_member" && (
                      <li>
                        <Link to={"/work-list"} className="card card-default">
                          <span className="font-md fw-500 text-capitalize">
                            Work
                          </span>
                          <i className="icon-next-arrow icon-shadow color-black" />
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link to={"/manage-payment"} className="card card-default">
                      <span className="font-md fw-500 text-capitalize">
                        Payment
                      </span>
                      <i className="icon-next-arrow icon-shadow color-black" />
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className="card card-default">
                      <span className="font-md fw-500 text-capitalize">
                        About
                      </span>
                      <i className="icon-next-arrow icon-shadow color-black" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Settings)
