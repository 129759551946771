import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getEvents, deleteDateEvent } from "actions/events";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import { map, upperFirst } from "lodash";
import ResponsiveModal from "../UI/modal";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

class DayPersonalEvents extends React.Component {
  state = {
    openPersonalEventPopup: false,
    currentEvent: "",
  };
  async componentDidMount() {
    const date = moment(this.props.match.params.id)
      .startOf("month")
      .format("YYYY-MM-DD");
    await this.props.getEvents(date);
  }
  onOpenPersonalEventPopup = value => {
    this.setState({
      openPersonalEventPopup: true,
      currentEvent: value
    });
  };

  onPopupClose = () => {
    this.setState({
      openPersonalEventPopup: false
    });
  };
  deleteEvent = async () => {
    const res = await this.props.deleteDateEvent(
      this.state.currentEvent.event_date_id
    );
    res && res.status === 204 && this.props.history.push("/calendar");
  };
  render() {
    const personalEvents = [];

    this.props.events.events &&
      this.props.events.events.data.map(data => {
        return data.events.map(
          elem =>
            elem.type === "personal" &&
            data.days === this.props.match.params.id &&
            personalEvents.push(data)
        );
      });

    return (
      <div className="container">
        <div className="content box-shadow">
          {" "}
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>

            <h1>Personal Events</h1>
          </div>
          <div className="content-body p-0">
            <div className="scrollbar-wrapper">
              <CustomScroll
                allowOuterScroll
                addScrolledClass
                heightRelativeToParent="100%"
              >
                <div className="list-wrap p-3 m-0">
                  <p className="color-text-50 fw-500 mb-3">
                    {`${moment(this.props.match.params.id).format(
                      "MMMM Do, YYYY"
                    )}`}
                  </p>
                  <ul>
                    {personalEvents &&
                      personalEvents.map(data => {
                        return (
                          <li>
                            <div
                              className="card card-with-label pointer"
                              onClick={() =>
                                this.onOpenPersonalEventPopup(data)
                              }
                            >
                              <span className="card-label bg-purple" />
                              <div className="card-body">
                                <p className="font-md fw-500 mb-1">Event</p>
                                <p className="color-text-70 font-base ellipsis text-capitalize">
                                  {upperFirst(data.events[0].name)}
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </CustomScroll>
            </div>
          </div>
        </div>

        {this.state.currentEvent && (
          <ResponsiveModal
            open={this.state.openPersonalEventPopup}
            onClose={this.onPopupClose}
          >
            <div className="modal-body">
              <div className="single-view-header with-label-icon">
                <span className="single-view-label bg-purple">
                  <i
                    className={`icon-${
                      this.state.currentEvent.events[0].name
                    } color-white box-30`}
                  />
                </span>
                <div>
                  <p className="font-lg fw-500 mb-2">Personal Event</p>
                  <p className="color-text-70 text-capitalize">{`${upperFirst(
                    this.state.currentEvent.events[0].name
                  )}`}</p>
                </div>
              </div>

              <div className="single-view-body">
                <p className="font-md fw-700 mb-4 text-capitalize">{`${upperFirst(
                  this.state.currentEvent.events[0].icon_name
                )}`}</p>
                <ul className="list-with-icons">
                  <li>
                    <i className="icon-clock font-md color-black-30" />
                    <span className="color-text-50 w-70">Start Time:</span>
                    <span>{`${
                      this.state.currentEvent.events[0].start_time ? this.state.currentEvent.events[0].start_time : 'Time not set'
                    }`}</span>
                  </li>
                  <li>
                    <i className="icon-clock font-md color-black-30" />
                    <span className="color-text-50 w-70">End Time:</span>
                    <span>{`${
                      this.state.currentEvent.events[0].end_time ? this.state.currentEvent.events[0].end_time : 'Time not set'
                    }`}</span>
                  </li>
                </ul>
              </div>

              <div className="single-view-footer">
                <Link
                  to={`/edit-event/${this.state.currentEvent.events[0].id}`}
                  className="btn-icon w-30 color-text"
                >
                  <i className="icon-edit icon-shadow" />
                </Link>
                <button
                  type="button"
                  className="btn-icon w-30 color-warning"
                  onClick={this.deleteEvent}
                >
                  <i className="icon-delete icon-shadow" />
                </button>
              </div>
            </div>
          </ResponsiveModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default connect(
  mapStateToProps,
  { getEvents, deleteDateEvent }
)(withRouter(DayPersonalEvents));
