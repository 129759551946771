//  GET_MANAGER,
import { GET_MANAGER,GET_MANAGER_BY_ID } from "actions/types";

const INITIAL_STATE = {
  managers: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MANAGER:
      return { ...state, managers: action.payload };
    case GET_MANAGER_BY_ID:
    return {...state, managerById: action.payload}
    default:
      return state;
  }
}
