import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Amplitude } from "@amplitude/react-amplitude";

import { getEventsByType, saveCurrentDate } from "actions/events";
import Popover from "@material-ui/core/Popover";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2
  }
});

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class SimplePopover extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  handleCurrentDate = () => {
    this.props.saveCurrentDate(this.props.date);
  };
  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Amplitude>
        {({ logEvent }) => (
          <JssProvider generateClassName={generateClassName}>
            <div>
              <button
                className="btn-rounded btn-add"
                aria-owns={open ? "simple-popper" : undefined}
                aria-haspopup="true"
                variant="contained"
                onClick={e => {
                  logEvent("click Team Page plus Icon");
                  this.handleClick(e);
                }}
              >
                <i className="icon-plus" />
              </button>

              <Popover
                className="menu add-to-menu"
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <div className="menu-dropdown">
                  <ul className="menu-list">
                    <li onClick={this.handleCurrentDate}>
                      <Link
                        to={"/request-switch"}
                        onClick={() => {
                          logEvent("click Team Page Switch Icon");
                          this.handleCurrentDate();
                        }}
                      >
                        <span className="icon">
                          <i className="icon-switch-horizontal color-text-70 font-xl" />
                        </span>
                        <span className="text">Switch</span>
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        logEvent("click Team Page GiveAway Icon");
                        this.handleCurrentDate();
                      }}
                    >
                      <Link to={"/request-giveaway"}>
                        <span className="icon">
                          <i className="icon-calendar-remove color-text-70 font-lg" />
                        </span>
                        <span className="text">Giveaway</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: "/request-invite",
                          state: { url: "/team" }
                        }}
                        onClick={() => {
                          logEvent("click Team Page Invite Icon");
                          this.handleCurrentDate();
                        }}
                      >
                        <span className="icon">
                          <i className="icon-share color-text-70 font-md" />
                        </span>
                        <span className="text">Invite</span>
                      </Link>
                    </li>
                  </ul>

                  <div className="menu-footer">
                    <span className="font-md fw-800 text-capitalize">
                      Request
                    </span>
                  </div>
                </div>
              </Popover>
            </div>
          </JssProvider>
        )}
      </Amplitude>
    );
  }
}

SimplePopover.propTypes = {
  classes: PropTypes.object.isRequired
};

const requestMenu = withStyles(styles)(SimplePopover);
function mapStateToProps(state) {
  return {
    events: state.events
  };
}
export default connect(
  null,
  { saveCurrentDate }
)(requestMenu);
