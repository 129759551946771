import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  ADD_FEEDBACK,
  GET_FEEDBACK,
  EDIT_FEEDBACK,
  DELETE_FEEDBACK
} from "./types";

export const addFeedback = (data) => dispatch => {
  const url = `/user-feedback/`;
  return axiosToken
    .post(
      url,
      data
     
    )
    .then(res => {
      return dispatch({
        type: ADD_FEEDBACK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};



export const getFeedback = ( page, from, to) => dispatch => {
  const url = `/user-feedback/?${from ? `from_date=${from}` : ""}${
    to ? `&to_date=${to}` : ""
  }${page ? `&page=${page}` : ""}`;

  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_FEEDBACK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const editFeedback = ( data, id) => dispatch => {
  const url = `/user-feedback/${id}/`;
  return axiosToken
    .patch(
      url,
      data
    
    )
    .then(res => {
      return dispatch({
        type: EDIT_FEEDBACK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};


export const deleteFeedback = (token, id) => dispatch => {
  const url = `/user-feedback/${id}/`;
  return axiosToken
    .delete(
      url
   
    )
    .then(res => {
      return dispatch({
        type: DELETE_FEEDBACK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};
