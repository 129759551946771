import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { getEventById, editCurrentEvent } from "actions/events";
import moment from "moment";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import TimePicker from "../UI/timePicker";
import {upperFirst} from "lodash";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function getSteps() {
  return ["Name", "Duration"];
}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const defaultEvents = ['pet care', 'sick day', 'date night', 'movies', 'party', 'spa',  'doctor', 'shopping', 'vacation', 'birthday', 'gym'];

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: false,
    icon_name: "",
    start_time: "",
    end_time: "",
    name: ""
  };

  isStepOptional = step => {
    return step === 1;
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  handleSelect = async time => {
    let formatTime = time + ":00";
    let myTime = moment(formatTime, "HH:mm:ss");

    if (this.state.isOpen === "Start") {
      await this.setState({
        start_time: moment(time, "hh:mm A").format("hh:mm A")
      });
      this.props.onStartTimeSelect(moment(time, "hh:mm A").format("hh:mm A"));
    }
    if (this.state.isOpen === "End") {
      await this.setState({
        end_time: moment(time, "hh:mm A").format("hh:mm A")
      });
      this.props.onEndTimeSelect(moment(time, "hh:mm A").format("hh:mm A"));
    }
    let start =
      this.state.start_time &&
      moment(
        moment(this.state.start_time, "hh:mm A").format("hh:mm A"),
        "h:mma"
      );
    let end =
      this.state.end_time &&
      moment(moment(this.state.end_time, "hh:mm A").format("hh:mm A"), "h:mma");

    if (start && end) {
      if (!start.isBefore(end)) {
        this.setState({
          timeError: "Please select a valid time."
        });
      } else {
        this.setState({
          timeError: ""
        });
      }
    }

    this.setState({ isOpen: "" });
  };
  async componentDidMount() {

    await this.props.getEventById(this.props.match.params.id);
    const currentEvents =
      this.props && this.props.events && this.props.events.eventById;
    this.setState({
      name: currentEvents.name,
      icon_name: currentEvents.icon_name,
      start_time: currentEvents.start_time,
      end_time: currentEvents.end_time,
      id: currentEvents.id,
    });
  }

  handleChange = event => {

    if(defaultEvents.indexOf(this.state.icon_name) !== -1){
      this.setState({error: "You can change only date for default event"});
      this.setState({name: this.state.name});
    } else {
      this.setState({ icon_name: event.target.value });
      this.props.handleChange(event.target.value);
    }
  };

  handleClick = elem => {
    this.setState({
      isOpen: elem
    });
  };

  onEditPersonalEvent = async () => {
    let data = {
      icon_name: this.state.icon_name,
      type: "personal"
    };

    if(this.state.start_time || this.state.end_time){
      if(!this.state.start_time){
        this.setState({error: "Please Select Start Time"});
      } else {
        data.start_time = this.state.start_time;
      }
      if(!this.state.end_time){
        this.setState({error: "Please Select End Time"});
      } else {
        data.end_time = this.state.end_time;
      }
    }

    const res = await this.props.editCurrentEvent(
      data,
      this.state.id
    );

    res.payload &&
    res.payload.status === 200 &&
    this.props.history.push(`/calendar`);
  };
  getTime = (time, period) => {
    if (period === "Start") {
      this.setState({ startTime: time, isOpen: period });
    } else if (period === "End") {
      this.setState({ endTime: time, isOpen: period });
    }
  };
  handleClickStep = async step => {
    if (step === 1) {
      await this.setState({
        activeStep: step
      });
    } else {
      await this.setState({
        activeStep: step
      });
    }
  };
  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              return (
                <Step
                  key={label}
                  {...props}
                  className={
                    activeStep >= index ? "step completed-step" : "step"
                  }
                  onClick={() => this.handleClickStep(index)}
                >
                  <StepLabel {...labelProps} className="step-label">
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <div className="stepper-body">
            <div className="stepper-content">
              {activeStep === 0 ? (
                <div className="form-field">
                  <div className="form-input">
                    <input
                      type="text"
                      placeholder="Event Name"
                      className="text-capitalize"
                      value={`${upperFirst(this.state.icon_name)}`}
                      onChange={this.handleChange}
                    />
                    <span className="border" />
                  </div>
                  {this.state.error && <p className="error-msg">{this.state.error}</p>}
                </div>
              ) : (
                <div className="time-picker">
                  <div className="time">
                    <TimePicker
                      getTime={(time, period) => this.getTime(time, period)}
                      handleSelect={this.handleSelect}
                      time={this.state.start_time}
                      period="Start"
                    />
                    {/* <p className="selected-time">
                      {this.state.start_time &&
                        moment(this.state.start_time, "hh:mm A").format(
                          "h:mm A"
                        )}
                    </p> */}
                  </div>
                  <div className="time">
                    <TimePicker
                      getTime={(time, period) => this.getTime(time, period)}
                      handleSelect={this.handleSelect}
                      time={this.state.end_time}
                      period="End"
                    />
                    {/* <p className="selected-time">
                      {this.state.end_time &&
                        moment(this.state.end_time, "hh:mm A").format("h:mm A")}
                    </p> */}
                  </div>
                  {this.state.timeError && (
                    <p className="error-msg with-icon">
                      {this.state.timeError}
                    </p>
                  )}
                  {this.props.error && (
                    <p className="error-msg with-icon">{this.props.error}</p>
                  )}
                </div>
              )}

              {/* <TimePicker
                className="timePicker"
                isOpen={this.state.isOpen}
                handleCancel={this.handleCancel}
                handleSelect={this.handleSelect}
              /> */}
            </div>

            <div className="content-footer">
              {activeStep === 0 ? (
                <Link
                  to={"/calendar"}
                  className="btn btn-link color-text-50"
                >
                  Cancel
                </Link>
              ) : (
                <button
                  type="button"
                  className="btn btn-link color-text-50"
                  onClick={this.handleBack}
                >
                  Back
                </button>
              )}

              {activeStep === steps.length - 1 ? (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.onEditPersonalEvent}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={this.handleNext}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

const EditStepper = withStyles(styles)(HorizontalLinearStepper);
function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.auth
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { getEventById, editCurrentEvent }
  )(EditStepper)
)