import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { changePassword } from "actions/auth";
import { Amplitude } from "@amplitude/react-amplitude";

let success = false;
class ChangePassword extends Component {
  async componentDidMount() {
    const token =
      (this.props.user.token && this.props.user.token.auth_token) ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");
    if (!token) {
      this.props.history.push("/");
    }

    this.props.setValues({
      ...this.props.values,
      success: false
    });
  }
  render() {
    const { values, errors, touched, handleSubmit } = this.props;
    success = values.success;

    return (
      <Fragment>
        <section className="bg-primary content-center">
          <div className="container">
            {!success ? (
              <div className="content">
                <div className="sign-up-content color-white">
                  <Link to="/" className="logo">
                    <img src={require("assets/img/logo.svg")} alt="Turno" />
                  </Link>

                  <h1 className="my-6">Change Password</h1>

                  <Form>
                    <div
                      className={
                        touched.currentPassword && errors.currentPassword
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="password"
                          name="currentPassword"
                          placeholder="Current Password (min 6 characters)"
                        />
                        <span className="border" />
                      </div>
                      {touched.currentPassword &&
                        errors.currentPassword && (
                          <p className="error-msg">{errors.currentPassword}</p>
                        )}
                    </div>

                    <div
                      className={
                        touched.newPassword && errors.newPassword
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="password"
                          name="newPassword"
                          placeholder="New Password (min 6 characters)"
                        />
                        <span className="border" />
                      </div>
                      {touched.newPassword &&
                        errors.newPassword && (
                          <p className="error-msg">{errors.newPassword}</p>
                        )}
                    </div>

                    <div
                      className={
                        touched.confirmPassword && errors.confirmPassword
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password (min 6 characters)"
                        />
                        <span className="border" />
                      </div>
                      {touched.confirmPassword &&
                        errors.confirmPassword && (
                          <p className="error-msg">{errors.confirmPassword}</p>
                        )}
                    </div>

                    <div className="flex justify-around align-center mt-10">
                      <Link to={"/profile"} className="btn btn-link color-white-70">
                        Cancel
                      </Link>
                      <Amplitude>
                        {({ logEvent }) => (
                          <button
                            type="button"
                            className="btn btn-white"
                            onClick={() => {
                              logEvent("click Change Password");
                              handleSubmit();
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </Amplitude>
                    </div>
                  </Form>
                </div>
              </div>
            ) : (
              <div className="content">
                <div className="info-content with-button color-white">
                  <Link to={"/"} className="logo">
                    <img src={require("assets/img/logo.svg")} alt="Turno" />
                  </Link>
                  <h1 className="no-transform mb-4">Password changed!</h1>
                  <p className="color-white-70 text-center">
                    Your password has been changed successfully.
                  </p>
                  <Link to={"/profile"} className="btn btn-white mt-10">
                    Back to Profile
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
      </Fragment>
    );
  }
}

const ChangePasswordFormik = withFormik({
  mapPropsToValues({ currentPassword, newPassword, confirmPassword }) {
    return {
      currentPassword: currentPassword || "",
      newPassword: newPassword || "",
      confirmPassword: confirmPassword || ""
    };
  },
  validationSchema: Yup.object({
    currentPassword: Yup.string()
      .min(6, "Please provide a valid password.")
      .required("This field is mandatory."),
    newPassword: Yup.string()
      .min(6, "Please provide a valid password.")
      .required("This field is mandatory."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords do not match")
      .required("This field is mandatory.")
  }),
  async handleSubmit(values, { props, setValues, setErrors }) {
    const userData = {
      old_password: values.currentPassword,
      password: values.newPassword
    };

    const res = await props.changePassword(userData);

    if (res && res.status === 400) {
      setErrors({ currentPassword: res.data.message });
    } else {
      setValues({ success: true });
    }
    // if (mydata.payload.status === 200) {
    //   values.success = true;
    // props.history.push("/calendar");
    // }
  }
})(ChangePassword);

function mapStateToProps(state) {
  return {
    auth: state.user.message,
    user: state
  };
}

export default connect(
  mapStateToProps,
  { changePassword }
)(ChangePasswordFormik);

// export default ChangePassword;
