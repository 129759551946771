import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import requireAuth from "components/requireAuth";
import loginUser from "components/loginUser";
// import loginAdmin from "components/loginAdmin";

import Home from "components/Home";
import Calendar from "components/calendar/Calendar";
import Login from "components/auth/Login";
import SignUp from "components/auth/SignUp";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";

import DayNotifications from "components/notifications/DayNotifications";
import NotificationsList from "components/notifications/NotificationsList";
import DayDetails from "components/calendar/DayDetails";
import DayPersonalEvents from "components/personal-events/DayPersonalEvents";
import DayNotes from "components/notes/DayNotes";
import ManagerApprove from "components/auth/ManagerApprove";
import Events from "components/events/Events";
import AddEvents from "components/events/AddEvents";
import AddShifts from "components/events/AddShifts";
import AddNote from "components/notes/AddNote";
import AddEvent from "components/personal-events/AddEvent";
import AddShift from "components/shifts/AddShift";
import EditNote from "components/notes/EditNote";
import EditEvent from "components/personal-events/EditEvent";
import EditShift from "components/shifts/EditShift";
import TeamCalendar from "components/team/TeamCalendar";
import RequestSwitch from "components/team/RequestSwitch";
import RequestGiveaway from "components/team/RequestGiveaway";
import RequestInvite from "components/team/RequestInvite";
import Settings from "components/settings/Settings";
import ManagePayment from "components/manage-payment/ManagePayment";
import AddPaymentMethod from "components/manage-payment/AddPaymentMethod";
import Profile from "components/settings/Profile";
import EditProfile from "components/settings/EditProfile";
//Manager
import ManagerList from "components/manager/ManagerList";
import Manager from "components/manager/Manager";
import AddManager from "components/manager/AddManager";
import EditManager from "components/manager/EditManager";
// Work
import WorkList from "components/work/WorkList";
import Work from "components/work/Work";
import AddWork from "components/work/AddWork";
import EditWork from "components/work/EditWork";

import Connections from "components/settings/Connections";
import ConnectionCalendar from "components/settings/connection-calendar/ConnectionCalendar";
import About from "components/settings/About";
import Feedback from "components/feedback/Feedback";
import ChangePassword from "components/auth/ChangePassword";
import UpgradeMembership from "components/upgrade-membership/UpgradeMembership";
import Payment from "components/upgrade-membership/Payment";
import Unsubscribe from "components/auth/Unsubscribe";

// Admin dashboard
import AdminUsers from "components/admin/AdminUsers";
import AdminStatistics from "components/admin/AdminStatistics";
import AdminFeedback from "components/admin/AdminFeedback";
import AdminSettings from "components/admin/AdminSettings";
import AdminProfile from "components/admin/AdminProfile";
import AdminDashboard from "components/admin/Dashboard";
// import amplitude from "amplitude-js";
import "./assets/scss/main.scss";
import "./app.css";

class App extends Component {
  componentDidMount() {
    // amplitude.getInstance();
    document.addEventListener(
      "touchmove",
      function(event) {
        event = event.originalEvent || event;
        if (event.scale > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    document.addEventListener(
      "gesturestart",
      function(event) {
        event.preventDefault();
      },
      { passive: false }
    );

    document.addEventListener(
      "touchstart",
      function(event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  }

  render() {

    return (
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={"6b7332bad2ca3964b99d2c1e20579b40"}
      >
        <Amplitude>
          <Fragment>
            {/* after live change to Home component */}
            <Route exact path="/" component={loginUser(Login)} />
            <LastLocationProvider>
              <Switch>
                <Route exact path="/sign-up" component={loginUser(SignUp)} />
                <Route path="/sign-up/:id/:id1" component={loginUser(SignUp)} />
                <Route
                  exact
                  path="/calendar"
                  component={requireAuth(Calendar)}
                />
                <Route exact path="/sign-in" component={loginUser(Login)} />
                <Route path="/sign-in/:token" component={loginUser(Login)} />
                <Route
                  path="/forgot-password"
                  component={loginUser(ForgotPassword)}
                />
                <Route
                  path="/reset-password/:token"
                  component={loginUser(ResetPassword)}
                />
                <Route
                  exact
                  path="/calendar/notifications/:id"
                  component={requireAuth(DayNotifications)}
                />
                <Route
                  exact
                  path="/notifications-list"
                  component={requireAuth(NotificationsList)}
                />
                <Route
                  exact
                  path="/calendar/day-details/:id"
                  component={requireAuth(DayDetails)}
                />
                <Route
                  path="/day-personal-events/:id"
                  component={requireAuth(DayPersonalEvents)}
                />
                <Route
                  path="/day-notes/:id"
                  component={requireAuth(DayNotes)}
                />
                <Route
                  exact
                  path="/manager-shift/:slug/:status"
                  // path="/manager-shift/:slug/:status"

                  component={ManagerApprove}
                />
                <Route path="/events/:tab" component={requireAuth(Events)} />
                <Route path="/add-events" component={requireAuth(AddEvents)} />
                <Route path="/add-shifts" component={requireAuth(AddShifts)} />
                <Route path="/add-note" component={requireAuth(AddNote)} />
                <Route path="/add-event" component={requireAuth(AddEvent)} />
                <Route path="/add-shift" component={requireAuth(AddShift)} />
                <Route
                  path="/edit-note/:id"
                  component={requireAuth(EditNote)}
                />
                <Route
                  path="/edit-event/:id"
                  component={requireAuth(EditEvent)}
                />
                <Route
                  path="/edit-shift/:id"
                  component={requireAuth(EditShift)}
                />
                <Route
                  exact
                  path="/team"
                  component={requireAuth(TeamCalendar)}
                />
                <Route
                  exact
                  path="/request-switch"
                  component={requireAuth(RequestSwitch)}
                />
                <Route
                  exact
                  path="/request-giveaway"
                  component={requireAuth(RequestGiveaway)}
                />
                <Route
                  exact
                  path="/request-invite"
                  component={requireAuth(RequestInvite)}
                />
                <Route
                  exact
                  path="/settings"
                  component={requireAuth(Settings)}
                />
                <Route
                  exact
                  path="/manage-payment"
                  component={requireAuth(ManagePayment)}
                />
                <Route
                  exact
                  path="/add-payment-method"
                  component={requireAuth(AddPaymentMethod)}
                />
                <Route exact path="/profile" component={requireAuth(Profile)} />
                <Route
                  exact
                  path="/edit-profile"
                  component={requireAuth(EditProfile)}
                />
                <Route
                  exact
                  path="/manager-list"
                  component={requireAuth(ManagerList)}
                />
                <Route
                  exact
                  path="/manager/:id"
                  component={requireAuth(Manager)}
                />
                <Route
                  exact
                  path="/add-manager"
                  component={requireAuth(AddManager)}
                />
                <Route
                  exact
                  path="/edit-manager/:id"
                  component={requireAuth(EditManager)}
                />
                <Route
                  exact
                  path="/work-list"
                  component={requireAuth(WorkList)}
                />
                <Route exact path="/work/:id" component={requireAuth(Work)} />
                <Route
                  exact
                  path="/add-work"
                  component={requireAuth(AddWork)}
                />
                <Route
                  exact
                  path="/edit-work/:id"
                  component={requireAuth(EditWork)}
                />
                <Route
                  exact
                  path="/connections"
                  component={requireAuth(Connections)}
                />
                <Route
                  exact
                  path="/connection-calendar/:id"
                  component={requireAuth(ConnectionCalendar)}
                />
                <Route exact path="/about" component={requireAuth(About)} />
                <Route
                  exact
                  path="/feedback"
                  component={requireAuth(Feedback)}
                />
                <Route
                  exact
                  path="/change-password"
                  component={ChangePassword}
                />
                <Route
                  exact
                  path="/upgrade-membership"
                  component={UpgradeMembership}
                />
                <Route
                  exact
                  path="/payment/:id"
                  component={requireAuth(Payment)}
                />
                <Route
                  exact
                  path="/unsubscribe-notifications-email/:id"
                  component={Unsubscribe}
                />
                <Route
                  exact
                  path="/admin"
                  component={requireAuth(AdminDashboard)}
                />
                <Route
                  path="/admin/users"
                  component={requireAuth(AdminUsers)}
                />
                <Route
                  path="/admin/statistics"
                  component={requireAuth(AdminStatistics)}
                />
                <Route
                  path="/admin/feedback"
                  component={requireAuth(AdminFeedback)}
                />
                <Route
                  path="/admin/settings"
                  component={requireAuth(AdminSettings)}
                />
                <Route
                  path="/admin/profile"
                  component={requireAuth(AdminProfile)}
                />
              </Switch>
            </LastLocationProvider>
          </Fragment>
        </Amplitude>
      </AmplitudeProvider>
    );
  }
}

export default withRouter(App);
