import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { addNotes } from "actions/notes";
import InputBase from "@material-ui/core/InputBase";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class AddNote extends React.Component {
  state = { text: "", error:"" };
  handleChange = e => {
    this.setState({ text: e.target.value, error:!e.target.value?"This field is mandatory.":"" });
  };
  onAddNotes = async () => {
    if(!this.state.text){
      this.setState({error: "This field is mandatory."})
    } else {
      const data = {
        text: this.state.text,
        date: moment(this.props.events.date).format("YYYY-MM-DD")
      };
      
      const res = await this.props.addNotes(data);
      res.payload.status === 201 && this.props.history.push("/calendar");
    }
    
  };
  render() {
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="container">
          <div className="content box-shadow">
            <div className="content-header">
              <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
                <i className="icon-back-arrow font-md icon-shadow" />
              </Link>

              <h1>Add Note</h1>
            </div>

            <div className="content-body note-content">
              {this.props.events && (
                <p className="color-text-50 fw-500 mb-3">
                  {moment(this.props.events.date).format("MMMM Do, YYYY")}
                </p>
              )}

              <div className={this.state.error?"form-field has-error":"form-field"}>
                <div className="form-textarea">
                  <InputBase
                    multiline
                    placeholder="Note Text"
                    rowsMax={6}
                    value={this.state.text}
                    onChange={e => this.handleChange(e)}
                  />
                  <span className="border" />
                </div>
               {this.state.error&& <p className="error-msg">{this.state.error}</p>}
              </div>
            </div>

            <div className="content-footer">
              <button
                type="button"
                className="btn btn-link color-text-50"
                onClick={() => this.props.history.push("/calendar")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-white"
                onClick={this.onAddNotes}
                disabled={this.state.error}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    auth: state.user
  };
}

export default 
  connect(
    mapStateToProps,
    { addNotes }
  )(AddNote)

