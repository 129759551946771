import React, { Component } from "react";
import { connect } from "react-redux";


export default ChildComponent => {
  class ComposedComponent extends Component {
    
    componentDidMount() {
      this.shouldNavigateAway();
    }

    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (
        !this.props.auth.token &&
        !localStorage.getItem("token") &&
        !sessionStorage.getItem("token")
      ) {
        this.props.history.push("/");
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }
  // return ComposedComponent;
  function mapStateToProps(state) {
    return { auth: state.user };
  }

  return connect(mapStateToProps)(ComposedComponent);
};
