import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { resetPassword } from "actions";
import { Amplitude } from "@amplitude/react-amplitude";



class ResetPassword extends Component {
  componentDidMount() {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      this.props.history.push("/calendar");
    }
  }
  render() {
    const { errors, touched, handleSubmit } = this.props;

    return (
      <Fragment>
        <section className="bg-primary content-center">
          <div className="container">
            <div className="content">
              <div className="sign-up-content color-white">
                <Link to={"/"} className="logo">
                  <img src={require("assets/img/logo.svg")} alt="Turno" />
                </Link>
                <h1 className="my-6">Reset password</h1>
                <Form>
                  <div
                    className={
                      touched.password && errors.password
                        ? "form-field form-field-white has-error"
                        : "form-field form-field-white"
                    }
                  >
                    <div className="form-input">
                      <Field
                        type="password"
                        name="password"
                        placeholder="New Password (min 6 characters)"
                      />
                      <span className="border" />
                    </div>
                    {touched.password &&
                      errors.password && (
                        <p className="error-msg">{errors.password}</p>
                      )}
                  </div>
                  <div
                    className={
                      touched.password && errors.password
                        ? "form-field form-field-white has-error"
                        : "form-field form-field-white"
                    }
                  >
                    <div className="form-input">
                      <Field
                        name="passwordConfirm"
                        type="password"
                        placeholder="Confirm Password (min 6 characters)"
                      />
                      <span className="border" />
                    </div>
                    {touched.passwordConfirm &&
                      errors.passwordConfirm && (
                        <p className="error-msg">{errors.passwordConfirm}</p>
                      )}
                  </div>
                  <Amplitude>
                    {({ logEvent }) => (
                      <button
                        type="button"
                        className="btn btn-white w-100 mt-2"
                        onClick={() => {
                          logEvent("click Reset Password");
                          handleSubmit();
                        }}
                      >
                        Save
                      </button>
                    )}
                  </Amplitude>
                  <Link
                    to={"/sign-in"}
                    className="link color-white-70 fw-200 mt-5"
                  >
                    Log In
                  </Link>
                </Form>

                <p className="mt-6">
                  Don't have an account?{" "}
                  <Link to={"/sign-up"} className="link font-base color-white">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const ResetPasswordFormik = withFormik({
  mapPropsToValues({ password, passwordConfirm }) {
    return {
      password: password || "",
      passwordConfirm: passwordConfirm || ""
    };
  },
  validationSchema: Yup.object({
    password: Yup.string()
      .min(6, "Please provide a valid password.")
      .required("This field is mandatory."),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("This field is mandatory.")
  }),
  async handleSubmit(values, { props }) {
    const userData = {
      password: values.password,
      activation_token: props.match.params.token
    };
    const mydata = await props.resetPassword(userData);
    if (mydata.payload.status === 200) {
      props.history.push("/sign-in");
    }
  }
})(ResetPassword);

export default connect(
  null,
  { resetPassword }
)(ResetPasswordFormik);
