import React from "react";
import { Link } from "react-router-dom";
import ResponsiveModal from "../UI/modal";
import CustomScroll from "react-custom-scroll";
import moment from "moment";
import { getNotes, deleteNotes } from "actions/notes";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-custom-scroll/dist/customScroll.css";

class DayNotes extends React.Component {
  state = {
    openNotePopup: false,
    currentEvent: ""
  };

  async componentDidMount() {
    
     this.props.getNotes( this.props.match.params.id);
    
  }

  onOpenNotePopup = value => {
    this.setState({
      openNotePopup: true,
      currentEvent: value
    });
  };

  onPopupClose = () => {
    this.setState({
      openNotePopup: false
    });
  };
  deleteEvent = async () => {
    const res = await this.props.deleteNotes(
      this.state.currentEvent.id
    );

    res && res.status === 204 && this.onPopupClose();
  };
  componentWillUnmount() {
    this.props.getNotes(this.props.match.params.id);
  }
  render() {
    let currentNotes = [];
    this.props.notes.notes &&
      this.props.notes.notes.results.map(data => {
        if (data.days === this.props.match.params.id) {
          currentNotes = data.notes;
        }
      });

    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link to={"/calendar"} className="btn-icon w-30 color-text mr-5">
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>

            <h1>Notes</h1>
          </div>

          <div className="content-body p-0">
            <div className="scrollbar-wrapper">
              <CustomScroll
                allowOuterScroll
                addScrolledClass
                heightRelativeToParent="100%"
              >
                <div className="list-wrap p-3 m-0">
                  <p className="color-text-50 fw-500 mb-3">
                    {`${moment(this.props.match.params.id).format(
                      "MMMM Do, YYYY"
                    )}`}
                  </p>
                  <ul>
                    {currentNotes &&
                      currentNotes.map(data => {
                        return (
                          <li>
                            <a
                              className="card card-with-label"
                              onClick={() => this.onOpenNotePopup(data)}
                            >
                              <span className="card-label bg-orange" />
                              <div className="card-body">
                                <p className="font-md fw-500 mb-1">Note</p>
                                <p className="color-text-70 font-base ellipsis text-capitalize">
                                  {data.text}
                                </p>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </CustomScroll>
            </div>
          </div>
        </div>

        <ResponsiveModal
          open={this.state.openNotePopup}
          onClose={this.onPopupClose}
        >
          <div className="modal-body">
            <div className="single-view-header with-label">
              <span className="single-view-label bg-orange" />
              <div>
                <p className="font-lg fw-500">Note</p>
              </div>
            </div>

            <div className="single-view-body">
              <pre className="font-md fw-700">
                {this.state.currentEvent.text}
              </pre>
            </div>

            <div className="single-view-footer">
              <Link
                to={`/edit-note/${this.state.currentEvent.id}`}
                className="btn-icon w-30 color-text"
              >
                <i className="icon-edit icon-shadow" />
              </Link>
              <button
                type="button"
                className="btn-icon w-30 color-warning"
                onClick={this.deleteEvent}
              >
                <i className="icon-delete icon-shadow" />
              </button>
            </div>
          </div>
        </ResponsiveModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notes: state.notes,
    auth: state.user
  };
}

export default connect(
  mapStateToProps,
  { getNotes, deleteNotes }
)(withRouter(DayNotes));
