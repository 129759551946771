import React, { Component, Fragment } from "react";
import Picker from "pickerjs";
import moment from "moment";
import amplitude from "amplitude-js/amplitude";

import "pickerjs/dist/picker.css";

class DatePicker extends Component {
  state = { picker: "" };
  componentDidMount() {
    amplitude.getInstance().logEvent("click Date Picker in Calendar");
    const a = new Picker(document.querySelector(".js-date-picker"), {
      format: "MMMM YYYY",
      headers: true,
      text: {
        title: "",
        confirm: "Select"
      },

      pick: e => {
        this.props.getDate(a.getDate(true));
        amplitude
          .getInstance()
          .logEvent("click Select in Calendar Date Picker");
      }
    });
  }
  render() {
    return (
      <Fragment>
        <a
          href="/"
          className="calendar-date js-date-picker"
          onClick={e => e.preventDefault()}
          value={moment(this.props.date).format("MMMM YYYY")}
        >
          {moment(this.props.date, "MMMM YYYY").format("MMMM YYYY")}
          <i className="icon-caret-down" />
        </a>
      </Fragment>
    );
  }
}

export default DatePicker;
