import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { signinUser, activateToken, resendActivation } from "actions/auth";
import { instanceOf } from "prop-types";
// import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";

class Login extends Component {

  state = {
    showNotifications: this.props.isSubmitting,
    success: false,
    mobileOS: null,
    mobileFirstLogin: false
  };

  componentDidMount() {
    const mobileOS = this.getMobileOperatingSystem();
    if (this.props.match.params.token) {
      if(mobileOS === 'web') {
        localStorage.setItem('mobileFirstLogin', false);
      } else {
        localStorage.setItem('mobileFirstLogin', true);
        this.setState({mobileFirstLogin: true});
      }
      this.props.activateToken(this.props.match.params.token);
    }
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      this.props.history.push("/calendar");
    }

    if(localStorage.getItem('mobileFirstLogin') === 'false' && (mobileOS === 'iOS' || mobileOS === 'Android')){
      this.setState({mobileFirstLogin: true});
    }

    if(localStorage.getItem('mobileFirstLogin') === 'true'){
      this.setState({mobileFirstLogin: true});
    }

    this.setState({mobileOS: mobileOS});
  }

  handleErrors = () => {
    if (this.props.errors) {
      this.setState({ showNotifications: !this.state.showNotifications });
    }
  };

  sendReactivation = async email => {
    const res = await this.props.resendActivation({ email });
    res &&
      res.payload.status === 200 &&
      this.setState(prevState => ({ success: !prevState.success }));
  };

  keyPress = (e) => {
    if(e.keyCode === 13){
      document.querySelector(
        ".btn.btn-white.w-100.mt-6"
      ).click();
    }
  };

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'web'.
   *
   * @returns {String}
   */
  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // if (/windows phone/i.test(userAgent)) { return "Windows Phone"; }
    if (/android/i.test(userAgent)) { return "Android"; }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { return "iOS"; }
    return "web";
  };

  render() {
    const {
      values,
      errors,
      touched,
      setErrors,
      onClick,
      handleSubmit
    } = this.props;
    const { success, mobileOS, mobileFirstLogin } = this.state;
    return (
      <Fragment>
        <section className="bg-primary content-center">
          <div className="container">
            {!success && (
              <div className="content">
                <div className="sign-up-content color-white">
                  <Link to={"/"} className="logo">
                    <img src={require("assets/img/logo.svg")} alt="Turno" />
                  </Link>

                  <h1 className="my-6">Sign In</h1>

                  <Form>
                    <div
                      className={
                        touched.email && errors.email
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          // autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.email && errors.email && (
                        <p className="error-msg">{errors.email}</p>
                      )}
                    </div>

                    <div
                      className={
                        touched.password && errors.password
                          ? "form-field form-field-white has-error"
                          : "form-field form-field-white"
                      }
                    >
                      <div className="form-input" onKeyDown={this.keyPress}>
                        <Field
                          type="password"
                          name="password"
                          placeholder="Password"
                          autoComplete="off"
                        />
                        <span className="border" />
                      </div>
                      {touched.password && errors.password && (
                        <p className="error-msg">{errors.password}</p>
                      )}
                    </div>
                    {errors.credentials && (
                      <div className="form-field form-field-white mb-0 login-error">
                        <p className="error-msg with-icon">
                          {errors.credentials}
                        </p>
                      </div>
                    )}
                    {errors.credentials &&
                      errors.credentials[0] ===
                        "Please activate your account." && (
                        <div className="reactivation-message flex align-center justify-between">
                          <div className="form-field form-field-white mb-0" />
                          {/* <p className="error-msg with-icon">
                              Please activate your account
                            </p> */}
                          {/* </div> */}
                          <button
                            type="button"
                            className="link color-white-70 fw-200 mt-md-3"
                            onClick={() => this.sendReactivation(values.email)}
                          >
                            Send Reactivation
                          </button>
                        </div>
                      )}

                    <div className="flex align-center justify-between mt-10 pt-4">
                      <div className="checkbox-wrap checkbox-wrap-white">
                        <label>
                          <Field
                            type="checkbox"
                            name="rememberMe"
                            checked={values.rememberMe}
                          />

                          <span className="icon" />
                          <span className="text">Remember me</span>
                        </label>
                      </div>

                      <Link
                        to={"/forgot-password"}
                        className={`link color-white-70 fw-200 ${errors.credentials &&
                          errors.credentials[0] ===
                            "Please activate your account." &&
                          "disabled"}`}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <Amplitude>
                      {({ logEvent }) => (
                        <button
                          type="button"
                          onClick={() => {
                            logEvent("click login");
                            handleSubmit();
                          }}
                          // {instrument("click login", handleSubmit)}
                          // onClick={handleSubmit}
                          className="btn btn-white w-100 mt-6"
                        >
                          Log In
                        </button>
                      )}
                    </Amplitude>
                  </Form>

                  <p className="mt-6">
                    Don't have an account?{" "}
                    <Link
                      to={"/sign-up"}
                      className="link font-base color-white"
                    >
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            )}
            {success && (
              <div className="content">
                <div className="info-content color-white">
                  <Link to={"/"} className="logo">
                    <img src={require("assets/img/logo.svg")} alt="Turno" />
                  </Link>
                  <h1 className="no-transform mb-4">
                    Activation mail sent successfully!
                  </h1>
                  <p className="color-white-70 text-center">
                    Please check your email to activate your account.
                  </p>
                </div>
              </div>
            )}
          </div>
          
          {/*Add to home screen notification only in mobile for first login*/}
          {mobileOS && mobileOS !== 'web' && mobileFirstLogin === true &&
            <div className="notification">
              <button className="close" onClick={() => this.setState({mobileOS: 'web'}) }>
                <i className="icon-close"/>
              </button>
              <div className="flex align-center">
                {mobileOS === 'iOS' &&
                  <p>To add this web app to the home screen, tap
                    <img src={require("assets/img/ios-browser-menu-icon.png")} className="ios-menu-img"/> and then Add to Home Screen.
                  </p>}
                {mobileOS === 'Android' && <p>To add this web app to the home screen, tap the menu icon and then Add to Home Screen.</p>}
              </div>
            </div>
          }

        </section>
      </Fragment>
    );
  }
}

const LoginFormik = withFormik({
  mapPropsToValues({ email, password, rememberMe }) {
    return {
      email: email || "",
      password: password || "",
      rememberMe: rememberMe || false
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Please provide a valid email.")
      .required("This field is mandatory."),
    password: Yup.string()
      .min(6, "Please provide a valid password.")
      .required("This field is mandatory.")
  }),
  setErrors(errors) {
    console.log("errors", errors);
  },
  async handleSubmit(values, { props, setErrors }) {
    const offset = new Date().getTimezoneOffset();
    let sign = offset < 0 ? '+' : '-';
    let format = offset < 0 ? (offset * -1) / 60 : offset / 60;
    format = sign.concat(format.toPrecision(2));

    const userData = {
      email: values.email,
      password: values.password,
      login_timezone: format,
    };
    const res = await props.signinUser(userData, values.rememberMe);

    if (res && res.payload && res.payload.data) {
      if (res.payload.status === 401) {
        setErrors({ credentials: Object.values(res.payload.data)[0] });
      } else {
        if (res.payload.data.role === "admin") {
          const token = res.payload.data.auth_token;
          token && localStorage.setItem("token", token);
          localStorage.setItem("role", "admin");
          localStorage.setItem("id", res.payload.data.pk);
          props.history.push("/admin/statistics");
        } else {
          const token = res.payload.data.auth_token;
          if (values.rememberMe) {
            localStorage.setItem("token", token);
          } else {
            sessionStorage.setItem("token", token);
          }
          localStorage.setItem("firstLogin", res.payload.data.first_time_login);
          localStorage.setItem("role", res.payload.data.role);
          if (res.payload.data.first_time_login) {
            if(localStorage.getItem('mobileFirstLogin') === 'true'){
              localStorage.removeItem('mobileFirstLogin');
            }
            props.history.push("/calendar");
          } else {
            if (localStorage.getItem("unsubscribe")) {
              props.history.push(
                `/unsubscribe-notifications-email/${localStorage.getItem("unsubscribe")}`
              );
            } else {
              localStorage.setItem("role", res.payload.data.role);
              props.history.push("/calendar");
            }
            if(localStorage.getItem('mobileFirstLogin') === 'true'){
              localStorage.removeItem('mobileFirstLogin');
            }
          }
        }
      }
    }
  }
})(Login);

export default connect(
  null,
  { signinUser, activateToken, resendActivation }
)(LoginFormik);
