export const ERROR = "error";
export const SIGN_IN_USER = "sign_in_user";
export const SIGN_UP_USER = "sign_up_user";
export const SIGN_OUT_USER = "sign_out_user";
export const REMOVE_TOKEN = "remove_token";
export const ACTIVATE_ACCOUNT = "activate_account";
export const RESEND_ACTIVATION = "resend_activation";
export const FORGOT_PASSWORD_USER = "forgot_passowrd_user";
export const RESET_PASSWORD_USER = "reset_passowrd_user";
export const GET_EVENTS = "get_events";
export const GET_EVENT = "get_event";
export const GET_SHARED_EVENTS = "get_shared_events";
export const GET_NOTIFICATIONS = "get_notifications";
export const GET_NOTIFICATIONS_COUNT = "get_notifications_count";
export const GET_CURRENT_NOTIFICATION = "et_current_notification";
export const GET_NOTIFICATIONS_BY_DATE = "get_notifications_by_date";
export const EDIT_NOTIFICATIONS = "edit_notifications";
export const GET_PERSONAL_EVENTS = "get_personal_events";
export const GET_NOTES = "get_notes";
export const ADD_NOTES = "get_notes";
export const EDIT_NOTES = "edit_notes";
export const DELETE_NOTES = "delete_notes";
export const AUTH_ERROR = "auth_error";
export const GET_EVENTS_BY_TYPE = "get_events_by_type";
export const GET_EVENT_BY_ID = "get_event_by_id";
export const GET_NOTES_BY_ID = "get_notes_by_id";
export const ADD_SHIFT_EVENT = "add_shift_event";
export const SAVE_CURRENT_DATE = "save_current_date";
export const USER_ENTERED_QUICK_MODE = "user_entered_quick_mode";
export const SET_ERROR_MESSAGE = "set_error_message";
export const ADD_EVENT = "add_event";
export const EDIT_EVENT = "edit_event";
export const EDIT_CURRENT_EVENT = "edit_current_event";
export const DELETE_CURRENT_EVENT = "delete_current_event";
export const DELETE_SHIFT_EVENT = "delete_shift_event";
export const ADD_MENTION = "add_mention";
export const INVITE_USER = "invite_user";
export const CHECK_INVITEE_MAIL = "check_invitee_mail";
export const UPDATE_INVITE_USER = "update_invite_user";
export const GET_INVITE_USER = "get_invite_user";
export const SWITCH_USER_FREE_DATES = "switch_user_free dates";
export const EDIT_SWITCH_REQUEST = "edit_switch_request";
export const EDIT_GIVEAWAY_REQUEST = "edit_giveaway_request";
export const GET_GIVEAWAY_REQUEST = "get_giveaway_request";
export const GET_SWITCH_REQUEST = "get_switch_request";
export const RESET_STATUS = "reset_status";
export const FOLLOWED_USER = "followed_user";
export const REQUEST_TO_USER = "request_to_user";
export const GIVE_AWAY_BUSY_DATES = "give_away_busy_dates";
export const GET_USER = "get_user";
export const GET_USER_BY_ID = "get_user_by_id";
export const GET_SHARED_CALENDAR_DATE = "get_shared_calendar_date";
export const UPDATE_USER = "update_user";
export const UPDATE_MANAGER = "update_manager";
export const DELETE_MANAGER = "delete_manager";
export const ADD_MANAGER = "add_manager";
export const GET_MANAGER = "get_manager";
export const GET_MANAGER_BY_ID = "get_manager_by_id";
export const CHANGE_PASSWORD = "change_password";
export const ADD_FEEDBACK = "add_feedback";
export const GET_FEEDBACK = "get_feedback";
export const EDIT_FEEDBACK = "edit_feedback";
export const DELETE_FEEDBACK = "delete_feedback";
export const GET_CONNECTIONS = "get_connections";
export const GET_WORK = "get_work";
export const GET_WORK_BY_ID = "get_work_by_id";
export const EDIT_WORK = "edit_work";
export const ADD_WORK = "add_work";
export const DELETE_WORK = "delete_work";
export const CHECK_PAYMENT = "check_payment";
export const GET_PRODUCT = "get_product";
export const GET_PLAN = "get_plan";
export const ADD_CARD = "add_card";
export const GET_PLAN_BY_ID = "get_plan_by_id";
export const MANAGER_APPROVE = "manager_approve";
export const GET_CUSTOMER_SUBSCRIPTION = "get_customer_subscription";
export const GET_USER_DASHBOARD = "get_user_dashboard";
export const GET_ADMIN_DASHBOARD = "get_admin_dashboard";
export const GET_SETTINGS = "get_settings";
export const ADD_SETTINGS = "add_settings";
export const EDIT_SETTINGS = "edit_settings";