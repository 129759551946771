import React, { Component } from "react";
import { connect } from "react-redux";

export default ChildComponent => {
  class ComposedComponent extends Component {
    // Our component just got rendered

    componentDidMount() {
      this.shouldNavigateAway();
    }

    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (
        (this.props.auth.token && this.props.auth.token.auth_token) ||
        localStorage.getItem("token") ||
        sessionStorage.getItem("token")
      ) {
        if (
          (this.props.auth.token && this.props.auth.token.role === "admin") ||
          localStorage.getItem("role") === "admin"
        ) {
          this.props.history.push("/admin/statistics");
        } else {
          this.props.history.push("/calendar");
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return { auth: state.user };
  }

  return connect(mapStateToProps)(ComposedComponent);
};


