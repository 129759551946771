import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { removeReduxToken, getUserById } from "actions/auth";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  }
});

class MiniDrawer extends React.Component {
  state = {
    open: true,
    userData: null
  };
  async componentDidMount() {
    const id = localStorage.getItem("id");

   
    id && (await this.props.getUserById( id));
    const userData =
      this.props.user.user.userById && this.props.user.user.userById.data;
    userData && this.setState({ userData });
  }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  clearCookie(names, domain = document.domain, path = "/") {
    names.forEach(name => {
      localStorage.removeItem(name)
    });
  }

  handleLogOut = () => {
    this.clearCookie(["token", "firstLogin", "count", "role", "id"]);
    sessionStorage.removeItem("token");
    this.props.removeReduxToken();
    this.props.history.push("/sign-in");
  };
  render() {
    const { classes, theme } = this.props;
    const { userData } = this.state;
    const path = this.props.match.path;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="admin-dashboard">
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={classNames(classes.appBar, {
                [classes.appBarShift]: this.state.open
              })}
            >
              <Toolbar disableGutters={!this.state.open} className="flex">
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  className="ml-auto"
                >
                  {userData && `${userData.first_name} ${userData.last_name}`}
                </Typography>
              </Toolbar>
            </AppBar>

            <Drawer
              variant="permanent"
              className={classNames(classes.drawer, {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open
              })}
              classes={{
                paper: classNames({
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open
                })
              }}
              open={this.state.open}
            >
              <div className={classes.toolbar}>
                <span className="logo-img">
                  <img src={require("assets/img/logo-dark.svg")} alt="Turno" />
                </span>
                <IconButton onClick={this.handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>

              <Divider />

              <List>
                <li>
                  <Link
                    to={"/admin/statistics"}
                    className={path === "/admin/statistics" ? "active" : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <BarChartIcon />
                      </ListItemIcon>
                      <ListItemText>Statistics</ListItemText>
                    </ListItem>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/admin/users"}
                    className={path === "/admin/users" ? "active" : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText>Users</ListItemText>
                    </ListItem>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/admin/feedback"}
                    className={path === "/admin/feedback" ? "active" : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <FeedbackIcon />
                      </ListItemIcon>
                      <ListItemText>Feedback</ListItemText>
                    </ListItem>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/admin/settings"}
                    className={path === "/admin/settings" ? "active" : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText>Settings</ListItemText>
                    </ListItem>
                  </Link>
                </li>
              </List>

              <Divider />

              <List>
                <li>
                  <Link
                    to={"/admin/profile"}
                    className={path === "/admin/profile" ? "active" : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <ListItemText>Profile</ListItemText>
                    </ListItem>
                  </Link>
                </li>
                <li>
                  <a>
                    <ListItem button onClick={this.handleLogOut}>
                      <ListItemIcon>
                        <LogOutIcon />
                      </ListItemIcon>
                      <ListItemText>Log out</ListItemText>
                    </ListItem>
                  </a>
                </li>
              </List>
            </Drawer>

            <main className={classes.content}>
              <div className={classes.toolbar} />
              <div>{this.props.children}</div>
            </main>
          </div>
        </div>
      </JssProvider>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    user: state
  };
}

const drawer = withStyles(styles, { withTheme: true })(MiniDrawer);
export default connect(
  mapStateToProps,
  { getUserById, removeReduxToken }
)(withRouter(drawer));
