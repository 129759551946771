import { GET_WORK, GET_WORK_BY_ID } from "actions/types";

const INITIAL_STATE = {
  work: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WORK:
      return { ...state,work:action.payload };
    case GET_WORK_BY_ID:
    return {...state, workById: action.payload}
    default:
      return state;
  }
}
