import { axiosWitoutToken, axiosToken } from "http/axios";
import {
  EDIT_WORK,
  GET_WORK_BY_ID,
  GET_WORK,
  ADD_WORK,
  DELETE_WORK
} from "./types";

export const editWork = (data,  id) => dispatch => {
  const url = `work-place/${id}/`;
  return axiosToken
    .patch(
      url,
      data
      
    )
    .then(res => {
      return dispatch({
        type: EDIT_WORK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const addWork = (data) => dispatch => {
  const url = `work-place/`;
  return axiosToken
    .post(
      url,
      data
      
    )
    .then(res => {
      return dispatch({
        type: ADD_WORK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const getWork = () => dispatch => {
  const url = `/work-place/`;
  return axiosToken
    .get(
      url
      
    )
    .then(res => {
      return dispatch({
        type: GET_WORK,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const getWorkById = ( id) => dispatch => {
  const url = `/work-place/${id}/`;
  return axiosToken
    .get(
      url
     
    )
    .then(res => {
      return dispatch({
        type: GET_WORK_BY_ID,
        payload: res.data
      });
    })
    .catch(err => {
      return err.response;
    });
};

export const deleteWork = ( id) => dispatch => {
  const url = `/work-place/${id}/`;
  return axiosToken
    .delete(
      url
    )
    .then(res => {
      return dispatch({
        type: DELETE_WORK,
        payload: res
      });
    })
    .catch(err => {
      return err.response;
    });
};
