import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { getUser } from "actions/auth";
import { connect } from "react-redux";
import { upperFirst } from "lodash";
import ResponsiveModal from "../UI/modal";
import {editUser, signOutUser, removeReduxToken} from "../../actions";

class Profile extends React.Component {
  state = {
    picture: "",
    openDeactivatePopup: false
  };
  async componentDidMount() {
    await this.props.getUser();
    this.setState({
      picture: this.props.user.user.picture
    });
    localStorage.getItem("firstLogin") === "true" &&
      !localStorage.getItem("count") &&
      localStorage.setItem("count", 0);
  }

  firstEnter = async () => {
    let FirstLogin = localStorage.getItem("firstLogin");
 
    if (FirstLogin === "true") {
      let count = +localStorage.getItem("count") + 1;
      localStorage.setItem("count", count);
    }
  };
  onOpenDeactivatePopup = () => {
    this.setState({
      openDeactivatePopup: true
    });
  };

  onPopupClose = () => {
    this.setState({
      openDeactivatePopup: false
    });
  };

  clearCookie(names, domain = document.domain, path = "/") {
    names.forEach(name => {
      localStorage.removeItem(name)
    });
  }

  async deactivateUser (userId) {
    console.log(userId);
    if(userId){
      const res = await this.props.editUser({"deactivated": true}, userId);
      if(res.payload.data.deactivated === true){
        this.onPopupClose();
        const sigOutUser = await this.props.signOutUser();
        if(sigOutUser.payload.data === "Success."){
          this.clearCookie(["token", "firstLogin", "count", "role", "id"]);
          sessionStorage.removeItem("token");
          this.props.removeReduxToken();
          this.props.history.push("/sign-up");
        }
      }
    }
  };

  render() {
    const user = this.props.user.user;
    const role = this.props.user.user.role;
    return (
      <section className="content-center">
        <div className="container">
          <div className="content box-shadow justify-start">
            <div className="content-header bg-white flex-wrap">
              <div className="flex align-center w-100">
                <Link
                  to={"/settings"}
                  className="btn-icon w-30 color-text mr-5"
                >
                  <i className="icon-back-arrow font-md icon-shadow" />
                </Link>
                <h1>Profile</h1>
                <Link
                  to={"/edit-profile"}
                  onClick={() => this.firstEnter()}
                  className="btn-icon w-30 color-text ml-auto"
                >
                  <i className="icon-edit font-md icon-shadow" />
                </Link>
              </div>

              <div className="content-header-bottom bg-white">
                <div className="user-img">
                  {!this.state.picture ? (
                    <i className="icon-user" />
                  ) : (
                    <span
                      className="img"
                      style={{
                        backgroundImage: `url(${user.picture})`
                      }}
                    />
                  )}
                  {/* <span className="img" style={{ backgroundImage: 'url(https://badhan.org/img/avatars/defaultFemalePP.png)' }} /> */}
                </div>
              </div>
            </div>

            <div className="content-body">
              <div className="profile-info">
                {user && (
                  <p className="font-xl fw-700 mb-5">
                    {`${upperFirst(user.first_name)} ${upperFirst(
                      user.last_name
                    )}`}
                  </p>
                )}
                {user.title &&
                  role !== "family_member" && (
                    <div className="mb-5">
                      <p className="font-md fw-500">Title</p>
                      <p className="color-text-70 mt-2">{user.title}</p>
                    </div>
                  )}

                {user.mobile_number && (
                  <div className="mb-5">
                    <p className="font-md fw-500">Phone</p>
                    <p className="color-text-70 mt-2">{user.mobile_number}</p>
                  </div>
                )}

                <div className="mb-5">
                  <p className="font-md fw-500">Email</p>
                  <p className="color-text-70 mt-2">{user.email}</p>
                </div>

                {user.birthday && (
                  <div className="mb-5">
                    <p className="font-md fw-500">Birthday</p>
                    <p className="color-text-70 mt-2">{user.birthday}</p>
                  </div>
                )}
                {user.gender !== "none" && (
                  <div className="mb-5">
                    <p className="font-md fw-500">Gender</p>
                    {user && (
                      <p className="color-text-70 mt-2 text-capitalize">
                        {user.gender}
                      </p>
                    )}
                  </div>
                )}

                <div className="mb-5">
                  <p className="font-md fw-500">Password</p>
                  <Link
                    to={"/change-password"}
                    className="btn btn-link color-primary no-padding fw-500 mt-2"
                  >
                    Change Password
                  </Link>
                </div>

                <div>
                  <p className="font-md fw-500">Account</p>
                  <button
                    type="button"
                    className="btn btn-link color-primary no-padding fw-500 mt-2"
                    onClick={this.onOpenDeactivatePopup}
                  >
                    Deactivate Account
                  </button>
                </div>

                <ResponsiveModal
                    open={this.state.openDeactivatePopup}
                    onClose={this.onPopupClose}
                  >
                    <div className="modal-body">
                      <div className="text-center pt-8 px-6 pb-5">
                        <p className="font-lg fw-700 mb-4">
                          Are you sure?
                        </p>
                        <p className="font-md">
                          You will have access to your data for 365 days after deactivation. If you would like to have your data deleted sooner, please contact us at <a href="mailto:support@turnoapp.com" className="btn-link color-primary">support@turnoapp.com</a>.
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer justify-around">
                      <button
                        type="button"
                        className="btn btn-link color-text-50"
                        onClick={this.onPopupClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-white"
                        onClick={() => this.deactivateUser(user.id)}
                      >
                        Deactivate
                      </button>
                    </div>
                  </ResponsiveModal>

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default 
  withRouter(
    connect(
      mapStateToProps,
      { getUser, editUser, signOutUser, removeReduxToken }
    )(Profile)
  )
