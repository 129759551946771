import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import SwitchStepper from "./SwitchStepper";

class RequestSwitch extends React.Component {
  render() {
    const lastLocation =
      this.props.lastLocation && this.props.lastLocation.pathname;
    return (
      <div className="container">
        <div className="content box-shadow">
          <div className="content-header">
            <Link
              to={`${lastLocation === "/team" ? "/team" : "/calendar"}`}
              className="btn-icon w-30 color-text mr-5"
            >
              <i className="icon-back-arrow font-md icon-shadow" />
            </Link>
            <h1>Request Switch</h1>
          </div>
          <SwitchStepper lastLocation={lastLocation} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events
  };
}

export default connect(mapStateToProps)(withLastLocation(RequestSwitch))