import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Dashboard from "./Dashboard";
import DatePicker from "./DatePicker";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { getUser } from "./../../actions/auth";
import { getUserDashboard } from "./../../actions/adminDashboard";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import SimpleSelect from "./adminUsers/SimpleSelect";
import EnhancedTableHead from "./adminUsers/EnhancedTableHead";
import SearchAppBar from "./adminUsers/SearchBar";
import EnhancedTableToolbar from "./adminUsers/ToolBar";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

function createData(dataObj, count, rowCount) {
  const allUsers = [];
  let counter = count * rowCount;
  dataObj.map(data => {
    counter += 1;
    const userData = {
      id: data.id,
      number: counter,
      user_name: `${data.first_name} ${data.last_name}`,
      user_email: data.email,
      title: data.title,
      mobile_number: data.mobile_number,
      birthday: data.birthday,
      gender: data.gender,
      pricing_plan: data.plan[0],
      role: data.role,
      login_number: data.login_count,
      invite_sent_number: data.invite_sent_count,
      login_duration: data.login_duration,
      team_members_number: data.team_members_count,
      family_members_number: data.family_members_count,
      connections_number: data.total_connections_count,
      is_active: data.is_active
    };

    allUsers.push(userData);
  });
  return allUsers;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "number", label: "#", minWidth: false },
  { id: "user_name", label: "User name", minWidth: false },
  { id: "user_email", label: "Email address", minWidth: false },
  { id: "title", label: "Title", minWidth: false },
  { id: "phone_number", label: "Phone number", minWidth: false },
  { id: "birthday", label: "Birthday", minWidth: false },
  { id: "gender", label: "Gender", minWidth: false },
  { id: "pricing_plan", label: "Pricing plan", minWidth: false },
  { id: "role", label: "Role", minWidth: false },
  { id: "login_number", label: "Login number", minWidth: false },
  { id: "invite_sent_number", label: "Invite sent number", minWidth: true },
  { id: "login_duration", label: "Login duration", minWidth: false },
  { id: "team_members_number", label: "Team members number", minWidth: true },
  {
    id: "family_members_number",
    label: "Family members number",
    minWidth: true
  },
  { id: "connections_number", label: "Connections number", minWidth: false }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "number",
    number: 0,
    data: [],
    page: 0,
    rowsPerPage: 10,
    count: null,
    changeRole: {}
  };
  async componentDidMount() {


    await this.props.getUserDashboard();
    const users =
        this.props.usersDashboard.userDashboard &&
        this.props.usersDashboard.userDashboard.data;

    const allUsersData =
        users &&
        createData(users.results, this.state.count, this.state.rowsPerPage);
    this.setState({ data: allUsersData, count: users.count });
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = async (event, page) => {
    await this.props.getUserDashboard(
        this.state.from,
        this.state.to,
        this.state.search,
        page + 1
    );
    const users =
        this.props.usersDashboard.userDashboard &&
        this.props.usersDashboard.userDashboard.data;

    const allUsersData =
        users && createData(users.results, page, this.state.rowsPerPage);
    this.setState({ data: allUsersData, count: users.count, page });
  };
  handleSearchInput = e => {
    this.setState({ search: e.target.value });
  };
  handleSearch = async () => {
    const { search,  page, rowsPerPage } = this.state;


    await this.props.getUserDashboard(
        this.state.from,
        this.state.to,
        search,
        page
    );
    const users =
        this.props.usersDashboard.userDashboard &&
        this.props.usersDashboard.userDashboard.data;
    const allUsersData = users && createData(users.results, page, rowsPerPage);
    this.setState({ data: allUsersData, count: users.count, page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleDate = async (date, range) => {
    await this.setState({ [range]: date });
    const {  from, to } = this.state;
    await this.props.getUserDashboard( from, to);
    const users =
        this.props.usersDashboard.userDashboard &&
        this.props.usersDashboard.userDashboard.data;
    const allUsersData =
        users &&
        createData(users.results, this.state.page, this.state.rowsPerPage);
    this.setState({ data: allUsersData, count: users.count });
  };
  changeStr = str => {
    let newTime = [];
    if (!str) return;
    const dayStr = str.split(" ");
    let timeStr;
    if (dayStr.length > 1) {
      timeStr = dayStr[1].split(":");
      dayStr[0] += "d";
      timeStr[0] += "h";
      timeStr[1] += "m";
      timeStr[2] += "s";
      newTime = [dayStr[0], ...timeStr];
    } else {
      timeStr = dayStr[0].split(":");
      timeStr[0] += "h";
      timeStr[1] += "m";
      timeStr[2] += "s";
      newTime = timeStr;
    }
    return newTime.join(", ");
  };

  handleChangeRole = (id, role) => {
    this.setState({ changeRole: { ...this.state.changeRole, [id]: role } });
  };

  handleRole = (role, isactive, id) => {
    if (!isactive) {
      return "inactive";
    } else {
      return (
        role === "blocked_user" || role === "ambassador_user" || role === "trial_user")
        ? (
          <div className="select-unstyled">
            <SimpleSelect
                value={role}
                id={id}
                handleChangeRole={this.handleChangeRole}
            />
          </div>
      )
      : this.state.changeRole[id] ? (
          this.state.changeRole[id].replace("_user", "")
      ) : (
          role.replace("_user", "")
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page, count } = this.state;
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    return (
        <Dashboard>
          <Paper className={classes.root}>
            <EnhancedTableToolbar
                generateClassName={generateClassName}
                handleDate={this.handleDate}
                handleSearch={this.handleSearch}
                handleSearchInput={this.handleSearchInput}
                minDate={this.state.from}
            />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={data.length}
                    rows={rows}
                    generateClassName={generateClassName}
                />
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy)).map(n => {
                    return (
                        <TableRow hover key={n.id}>
                          <TableCell className="nowrap">{n.number}</TableCell>
                          <TableCell className="nowrap">{n.user_name}</TableCell>
                          <TableCell className="nowrap">{n.user_email}</TableCell>
                          <TableCell>{n.title}</TableCell>
                          <TableCell className="nowrap">
                            {n.mobile_number}
                          </TableCell>
                          <TableCell className="nowrap">{n.birthday}</TableCell>
                          <TableCell>
                            {n.gender !== "none" ? n.gender : ""}
                          </TableCell>
                          {
                            <TableCell className="nowrap">
                              {n.pricing_plan ? n.pricing_plan.interval : ""}
                            </TableCell>
                          }
                          <TableCell className="nowrap">
                            {this.handleRole(n.role, n.is_active, n.id)}
                          </TableCell>
                          <TableCell>{n.login_number}</TableCell>
                          <TableCell>{n.invite_sent_number}</TableCell>
                          <TableCell>{this.changeStr(n.login_duration)}</TableCell>
                          <TableCell>{n.team_members_number}</TableCell>
                          <TableCell>{n.family_members_number}</TableCell>
                          <TableCell>{n.connections_number}</TableCell>
                        </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={rows.length + 2} />
                      </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Dashboard>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const table = withStyles(styles)(EnhancedTable);

function mapStateToProps(state) {
  return {
    users: state.user,
    usersDashboard: state.admin
  };
}

export default connect(
    mapStateToProps,
    { getUser, getUserDashboard }
)(table);
