import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";
import omit from "lodash/omit";
import "react-dates/initialize";
import CellComponent from "./../team/CellComponent";
import "react-dates/lib/css/_datepicker.css";
import { getEvents, getSharedCalendarsByDate } from "actions/events";
import { getNotifications } from "actions/notifications";
import { getNotes } from "actions/notes";
import { instanceOf } from "prop-types";

import Caption from "./Caption";

import { SingleDatePicker } from "react-dates";
import CalendarDetails from "./CalendarDetails";

let nextClick = document.querySelector(
  ".DayPickerNavigation_rightButton__horizontalDefault"
);
let prevClick = document.querySelector(
  ".DayPickerNavigation_leftButton__horizontalDefault"
);

class SingleDatePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      autoFocus: true,
      focused: true,
      date: this.props.events.date ? moment(this.props.events.date) : moment(),
      currentDate: moment(),
      MonthChange: moment(),
      touchStartX: null,
      selectedDay: null,
      reelContainerWidth: 500,
      nextClick: null,
      prevClick: null,
      num: 0,
      events: [],
      notifications: [],
      notes: [],
    };

    this.onDateChange = this.onDateChange.bind(this);
  }

  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  };

  onMonthChange = async (num, month) => {
    await this.setState({
      date: moment(month).add(num, "months")
    });

    await this.props.getEvents(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
  };
  onDateSelect = async date => {
    await this.setState({
      date,
      show: false
    });
    this.setState({ show: true });
    await this.props.getEvents(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
  };
  onTouchStart = e => {
    this.setState({
      touchStartX: e.touches[0].clientX
    });
  };

  onTouchEnd = async e => {
    const difference = this.state.touchStartX - e.changedTouches[0].clientX;
    if (difference >= 50) {
      nextClick.click();
      if (!this.state.flag) {
        this.setState({ flag: true }, () => {
          this.onMonthChange(1, this.state.date);
        });
      }
    } else if (difference <= -50) {
      prevClick.click();
      if (!this.state.flag) {
        this.setState({ flag: true }, () => {
          this.onMonthChange(-1, this.state.date);
        });
      }
    }
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });
  };

  async onDateChange(date) {
    this.setState({date, day: date._d.getDate(), month: date._d.getMonth()});
    await this.props.getSharedCalendarsByDate(
      moment(date).format("YYYY-MM-DD")
    );
    this.setState({ date });
    this.props.getSharedCalendarsByDate(
      moment(date).format("YYYY-MM-DD")
    );
  }
  onPrevMonthClick = () => {
    this.setState({ flag: false });
  };

  onNextMonthClick = () => {
    this.setState({ flag: false });
  };

  componentWillMount() {
    /*If Not Doing This Way, Them Date Will Remain The Same As On Another Pages*/
    this.setState({date: this.state.currentDate});
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    const weekDay = document.querySelectorAll(".DayPicker_weekHeader_li");
    let weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    for (let i = 0; i < weekDay.length; ++i) {
      weekDay[i].childNodes[0].textContent = weekDays[i];
    }
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });

    nextClick = document.querySelector(
      ".DayPickerNavigation_rightButton__horizontalDefault"
    );
    prevClick = document.querySelector(
      ".DayPickerNavigation_leftButton__horizontalDefault"
    );
    this.updateDimensions();

    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });

    window.addEventListener("resize", this.updateDimensions);
    await this.props.getEvents(
      moment(this.state.date)
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    const events = await this.props.events.events.data;
    this.setState({ events });
    this.props.getSharedCalendarsByDate(
      moment(this.state.date).format("YYYY-MM-DD"),
    );
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

  componentDidUpdate() {
    const weekDay = document.querySelectorAll(".DayPicker_weekHeader_li");
    nextClick = document.querySelector(
      ".DayPickerNavigation_rightButton__horizontalDefault"
    );
    prevClick = document.querySelector(
      ".DayPickerNavigation_leftButton__horizontalDefault"
    );
    const calendarBody = document.querySelectorAll(".CalendarMonth_table");
    calendarBody.forEach(el => {
      el.ontouchstart = this.onTouchStart;
      el.ontouchend = this.onTouchEnd;
    });
    const currentDate = this.state.currentDate;
    let weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    for (let i = 0; i < weekDay.length; ++i) {
      weekDay[i].childNodes[0].textContent = weekDays[i];
    }
    if (currentDate.format("MM") === this.state.date.format("MM")) {
      weekDay &&
        weekDay[this.state.currentDate.format("d")] &&
        weekDay[this.state.currentDate.format("d")].classList.add(
          "current_weekday"
        );
    } else {
      weekDay &&
        weekDay[this.state.currentDate.format("d")] &&
        weekDay[this.state.currentDate.format("d")].classList.remove(
          "current_weekday"
        );
    }
  }

  checkEvents = elem => {
    let currentStartDate = moment(this.state.date).format("YYYY-MM-DD");
    const myArr = elem && elem.filter(data => data.days === currentStartDate);
    return myArr;
  };

  updateDimensions = () => {
    const reelContainerWidth = window.innerWidth;
    this.setState({ reelContainerWidth: reelContainerWidth });

    if (reelContainerWidth < 500) {
      const margin =
        -23 +
        (reelContainerWidth - 7 * Math.floor((reelContainerWidth - 2) / 7)) / 2;
      document.querySelector(".DayPicker").style.margin = "0 " + margin + "px";
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const events = this.props.events && this.props.events.events.data;
    const myEvents = events && this.checkEvents(events);
    const { date } = this.state;
    const props = omit(this.props, [
      "autoFocus",
      "initialDate",
      "onDateChange"
    ]);

    return (
      <section className="content-center team">
        <div className="container">
          <div className="content box-shadow justify-start">
            {this.state.show && (
              <SingleDatePicker
                {...props}
                id="date"
                date={date}
                focused={this.state.focused}
                onFocusChange={() => {}}
                keepOpenOnDateSelect
                isDayBlocked={day => {
                  let dayIsBlocked = false;
                  if (
                    moment(this.state.date).format("MM") !==
                    moment(day).format("MM")
                  ) {
                    dayIsBlocked = true;
                  }

                  return dayIsBlocked;
                }}
                onNextMonthClick={this.onNextMonthClick}
                onPrevMonthClick={this.onPrevMonthClick}
                renderDayContents={props => {
                  return (
                    <CellComponent
                      {...props}
                      notifications={this.state.notifications}
                      notes={this.state.notes}
                      events={this.state.events}
                      currentDate={this.state.date}
                    />
                  );
                }}
                renderCalendarInfo={() => (
                  <Caption
                    date={moment(date).format("MMMM YYYY")}
                    onDateSelect={this.onDateSelect}
                  />
                )}
                isOutsideRange={() => false}
                numberOfMonths={1}
                daySize={
                  this.state.reelContainerWidth >= 500
                    ? Math.floor(500 / 7)
                    : Math.floor((this.state.reelContainerWidth - 2) / 7)
                }
                calendarInfoPosition="top"
                onDateChange={this.onDateChange}
                hideKeyboardShortcutsPanel
                showDefaultInputIcon={false}
                enableOutsideDays
              />
            )}

            <CalendarDetails date={date} myEvents={myEvents} />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events,
    notifications: state.notifications,
    notes: state.notes,
    auth: state.user
  };
}

export default withRouter(
    connect(
      mapStateToProps,
      { getEvents, getNotifications, getNotes, getSharedCalendarsByDate }
    )(SingleDatePickerWrapper)
  )