import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import registerServiceWorker from "./registerServiceWorker";
// import { initAmplitude, setAmplitudeUserDevice } from "./utility";
import App from "./App";
import reducers from "reducers";

// export function installationInitializationSuccess(id, token) {
//   setAmplitudeUserDevice(token);

//   return {
//     type: "INSTALLATION_INITIALIZATION_SUCCESS",
//     id,
//     token
//   };
// }
// initAmplitude();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route component={App} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
