import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withCookies, Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import { switchUserFreeDates, requestToUser, getEvents } from "actions/events";
import { getManager } from "actions/manager";
import moment from "moment";
import DatePicker from "./datePicker";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function getSteps() {
  return ["Switch From", "Switch To", "Availability"];
}
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isOpen: false,
    switchFrom: moment(this.props.events.date),
    pickupShift: moment(this.props.events.date),
    sharedUsers: null,
    id: [],
    manager: "none",
    name: "manager",
    error: "",
    dayError: "",
    disabled: false,
    managerApproval: true,
    role: null,
  };
  async componentDidMount() {
    if(this.props.events.date){
      localStorage.setItem('date', moment(this.props.events.date));
    } else {
      this.setState({switchFrom: localStorage.getItem('date')});
    }

    const role = localStorage.getItem("role");
    this.setState({role: role});
    const token =
        (this.props.auth && this.props.auth.token.auth_token) ||
        this.props.cookies.get("token") || sessionStorage.getItem("token");
    this.setState({ token });
    await this.props.getManager(token);
  }

  handleChange = event => {
    this.setState({ disabled: false, error: "", dayError: "" });
    this.setState({ [event.target.name]: event.target.value });
  };

  isStepOptional = step => {
    return step === 1;
  };

  handleNext = async () => {
    const { activeStep } = this.state;

    if (activeStep === 0) {
      if (this.state.role !== "member_user" && this.state.manager === "none") {
        await this.setState({
          error: "This field is mandatory.",
          disabled: true
        });
      } else {

        const response = await this.props.getEvents(
            moment(this.state.switchFrom).format("YYYY-MM-DD")
        );

        const haveShift =
            response &&
            response.payload &&
            response.payload.data.filter(data => data.events[0].type === "shift");

        if (this.state.managerApproval === true && this.state.manager === "none"){
          await this.setState({
            error: "Please Choose Manager"
          });
        }

        if (!this.state.error) {
          await this.setState({ activeStep: activeStep + 1 });
        }
        else {
          if(haveShift && haveShift.length > 0){

          } else {
            await this.setState({
              dayError: "You don't have a shift on the selected date."
            });
          }
        }
      }
    }

    if (activeStep === 1) {
      if (!this.state.pickupShift) {
        await this.setState({
          pickupShift: this.state.switchFrom
        });
      } else {
        const res = await this.props.switchUserFreeDates(
          moment(this.state.switchFrom).format("YYYY-MM-DD"),
          moment(this.state.pickupShift).format("YYYY-MM-DD"),
          this.state.manager
        );
        if (res.payload && res.payload.data.results) {
          if (res.payload.data.results.length < 1) {
            await this.setState({
              error: "You don't have available team members."
            });
          } else {
            await this.setState({ sharedUsers: [...res.payload.data.results] });
          }
        } else {
          await this.setState({ error: res.data.detail });
        }
      }
    }

    !this.state.error &&
    !this.state.dayError &&
    this.setState({
      activeStep: activeStep + 1
    });
  };
  handleBack = async () => {
    const { error, activeStep } = this.state;
    await this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
    if (activeStep === 1 && error) {
      await this.setState({ error: "", disabled: false });
    }
  };

  handleClick = elem => {
    this.setState({
      isOpen: elem,
      date: this.props.date
      // curentDate: moment()
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  getDate = async (date, type) => {
    if (type === "switchFrom") {
      await this.setState({
        switchFrom: date,
        pickupShift: date,
        error: "",
        dayError: "",
        disabled: false
      });
    } else if (type === "pickupShift") {
      await this.setState({
        pickupShift: date,
        error: "",
        dayError: "",
        disabled: false
      });
    }
  };
  handleCheked = (e, idx) => {
    if (e.target.checked) {
      this.setState({
        id: [...this.state.id, idx]
      });
    } else {
      const result = this.state.id;
      var index = result.indexOf(idx);

      if (index !== -1) result.splice(index, 1);
      this.setState({
        id: result
      });
    }
  };

  handleRequest = async () => {
    let data = null;

    await this.setState({disabled: true});

    if(this.state.role === 'trial_user'){
      data = {
        busy_date: moment(this.state.switchFrom).format("YYYY-MM-DD"),
        free_date: moment(this.state.pickupShift).format("YYYY-MM-DD"),
        manager: this.state.manager,
        users: this.state.id,
      };
    }
    else {
      data = {
        busy_date: moment(this.state.switchFrom).format("YYYY-MM-DD"),
        free_date: moment(this.state.pickupShift).format("YYYY-MM-DD"),
        users: this.state.id,
        manager: this.state.manager,
        manager_approval: this.state.managerApproval,
      };
    }

    if(data.users.length === 0){
      this.setState({error: 'Please choose one of the available team members'});
    }
    const res = await this.props.requestToUser(
        data,
        this.props.allCookies.token,
        this.state.manager
    );
    if (res && res.payload.status === 200) {
      this.props.history.push(
          this.props.lastLocation === "/team" ? "/team" : "calendar"
      );
    } else {
      this.setState({ error: res.payload.status.data.massage });
    }
  };

  handleManagerApproval = () => {
    this.setState(state => ({ managerApproval: !state.managerApproval }));
  };

  async handleClickStep(step) {
    if (step === 1) {
      if (this.state.role !== "member_user" && this.state.manager === "none") {
        this.setState({ error: "This field is mandatory." });
      } else {
        const response = await this.props.getEvents(
            moment(this.state.switchFrom).format("YYYY-MM-DD")
        );
        const haveShift =
            response &&
            response.payload &&
            response.payload.data.filter(data => data.events[0].type === "shift");
        if (haveShift && haveShift.length < 1) {
          await this.setState({
            dayError: "You don't have a shift on the selected date."
          });
        }
      }
    } else if (step === 2) {
      const res = await this.props.switchUserFreeDates(
          moment(this.state.switchFrom).format("YYYY-MM-DD"),
          moment(this.state.pickupShift).format("YYYY-MM-DD"),
        this.state.role !== "member_user" ? this.state.manager : 'none'
      );
      if (res.payload && res.payload.data.results) {
        if (res.payload.data.results.length < 1) {
          await this.setState({
            error: "You don't have available team members."
          });
        } else {
          await this.setState({ sharedUsers: [...res.payload.data.results] });
        }
      } else {
        await this.setState({ error: res.data.detail });
      }
    } else {
      this.setState({ activeStep: step });
    }
  }
  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep, manager, date, managerApproval } = this.state;
    const Manager =
        this.props.manager.managers && this.props.manager.managers.results;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className={classes.root + " stepper-container"}>
          <Stepper activeStep={activeStep} className="stepper-header">
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};

              return (
                  <Step
                      key={label}
                      {...props}
                      className={
                        activeStep >= index ? "step completed-step" : "step"
                      }
                      onClick={() => {
                        this.handleClickStep(index);
                      }}
                  >
                    <StepLabel {...labelProps} className="step-label">
                      {label}
                    </StepLabel>
                  </Step>
              );
            })}
          </Stepper>

          <div className="stepper-body">
            <div className="stepper-content">
              {activeStep === 0 ? (
                <div className="date-picker form-field request-content">
                  <div className="date mb-2">
                    <DatePicker
                        getDate={(date, type) => this.getDate(date, type)}
                        switch={"switchFrom"}
                        date={date}
                        selectedDate={this.state.switchFrom}
                        text={"Choose Date"}
                    />
                  </div>

                  {this.state.dayError && (
                      <p className="error-msg with-icon">{this.state.dayError}</p>
                  )}

                  {this.state.role === "member_user" && <div className="flex align-center mt-6">
                    <p className="font-md fw-500 mr-5">Manager approval</p>
                    <Switch
                        className="switch"
                        checked={managerApproval}
                        onChange={this.handleManagerApproval}
                        aria-label="Collapse"
                    />
                  </div>}

                  <Collapse in={managerApproval}>
                    <div
                        className={
                          manager === "none"
                              ? "form-select mt-4 not-selected"
                              : "form-select mt-4"
                        }
                    >
                      {this.state.managerApproval === true && Manager.length > 0 && <FormControl>
                        <Select
                            value={this.state.manager}
                            onChange={this.handleChange}
                            inputProps={{
                              name: "manager"
                            }}
                        >
                          <MenuItem value="none" disabled>
                            Choose a Manager
                          </MenuItem>
                          {Manager &&
                          Manager.map(data => {
                            return (
                                <MenuItem value={`${data.id}`} key={data.id}>
                                  {`${data.first_name}
                                ${data.last_name}`}
                                </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      }

                      {Manager.length > 0 && <p className="info-text"> The invitee will have the same manager as yours. </p>}


                      {!Manager || Manager.length === 0 && (
                        <p className="info-text">
                          You need to create manager for approving the switch request
                        </p>
                      )}
                      {!Manager || Manager.length === 0 && (
                        <Link className={'btn btn-link color-primary no-padding mb-8'} to={'/add-manager?comesfromswitch=true'}>Create Manager</Link>
                      )}

                      {this.state.error && (
                          <p className="error-msg with-icon">{this.state.error}</p>
                      )}

                    </div>
                  </Collapse>

                </div>
              ) : activeStep === 1 ? (
                  <div className="date-picker">
                    <div className="date">
                      <DatePicker
                          getDate={(date, type) => this.getDate(date, type)}
                          switch={"pickupShift"}
                          date={date}
                          selectedDate={this.state.pickupShift}
                          text={"Choose Date"}
                      />
                    </div>
                    {this.state.error && (
                        <p className="error-msg with-icon">{this.state.error}</p>
                    )}
                  </div>
              ) : (
                <div className="content-body p-0">
                  <p className="color-text-50 fw-500 px-5 px-sm-3 py-2">
                    Available Team Members
                  </p>
                  <CustomScroll
                      allowOuterScroll
                      addScrolledClass
                      heightRelativeToParent="100%"
                  >
                    <div className="list-wrap p-3 m-0">
                      <ul>
                        {this.state.sharedUsers &&
                        this.state.sharedUsers.map(data => {
                          return (
                            <li key={data.user.id}>
                              <label className="shift-checkbox">
                                <input
                                    type="checkbox"
                                    value={data.user.id}
                                    onClick={e =>
                                        this.handleCheked(e, data.user.id)
                                    }
                                />
                                <div className="card card-with-icon lg">
                              <span className="icon">
                                <img
                                    src={require(`assets/img/${
                                        data.start_period
                                        }-icon.png`)}
                                    alt="afternoon icon"
                                />
                              </span>
                                <div className="card-body row">
                                  <p className="font-md fw-500 mr-3">
                                    {`${data.user.first_name} ${
                                        data.user.last_name
                                        }`}
                                  </p>
                                  <p className="color-text-50 nowrap">
                                    {moment(
                                        data.start_time,
                                        "hh:mm A"
                                    ).format("hh:mm A").replace(/^0(?:0:0?)?/, '')}
                                    {' - '}
                                    {moment(
                                        data.end_time,
                                        "hh:mm A"
                                    ).format("hh:mm A").replace(/^0(?:0:0?)?/, '')}
                                  </p>
                                </div>
                                </div>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                      {this.state.error && (
                        <p className="error-msg with-icon">{this.state.error}</p>
                      )}
                    </div>
                  </CustomScroll>
                </div>
              )}

              {/* <FullDatePicker
              isOpen={this.state.isOpen}
              handleCancel={this.handleCancel}
              handleSelect={this.handleSelect}
            /> */}
            </div>

            <div className="content-footer">
              {activeStep === 0 ? (
                  <Link
                      to={`${
                          this.props.lastLocation === "/team" ? "/team" : "calendar"
                          }`}
                      className="btn btn-link color-text-50"
                  >
                    Cancel
                  </Link>
              ) : (
                  <button
                      type="button"
                      className="btn btn-link color-text-50"
                      onClick={this.handleBack}
                  >
                    Back
                  </button>
              )}

              {activeStep === steps.length - 1 ? (
                  <button
                      type="button"
                      className={`btn btn-white ${this.state.disabled === true ? 'disabled' : ''}`}
                      disabled={
                        !this.state.sharedUsers || this.state.sharedUsers.length < 1
                      }
                      onClick={this.handleRequest}
                  >
                    Request Switch
                  </button>
              ) : (
                  <button
                      type="button"
                      className="btn btn-white"
                      onClick={this.handleNext}
                      disabled={this.state.disabled}
                  >
                    Next
                  </button>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};
const switchSteper = withStyles(styles)(HorizontalLinearStepper);
function mapStateToProps(state) {
  return {
    events: state.events,
    manager: state.managers,
    auth: state.auth
  };
}

export default withCookies(
    withRouter(
        connect(
            mapStateToProps,
            { switchUserFreeDates, requestToUser, getManager, getEvents }
        )(switchSteper)
    )
);
